import styled from 'styled-components'

export const ImageGalleryWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  height: 91%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
`

export const ConfirmationDialog = styled.div`
  width: 300px;
  display: flex;
  width: 100%;
  height: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ConfirmationDialogButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Title = styled.div`
  width: 100%;
  font-size: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ImageGallery = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`

interface PassthroughProps {
  passthrough?: boolean,
  selected?: boolean
}

export const GalleryItem = styled.div`
  width: 200px;
  height: 230px;
  margin-right: 15px;
  margin-bottom: 10px;
  display: flex;
  border-radius: 2px;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  position: relative;
  border: 1px solid rgba(0,0,0,0.1);
  ${(props: PassthroughProps) => props.passthrough ? 'cursor: pointer;' : ''};
  ${(props: PassthroughProps) => props.selected ? 'background-color: rgba(70, 85, 165, 0.25);' : ''};

  &:hover {
    ${(props: PassthroughProps) => props.passthrough ? 'background-color: rgba(245, 128, 35, 0.35);' : ''};
  }
`

export const ImageName = styled.div`
  width: 200px;
  height: 30px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  padding-top: 6px;
  line-height: 20px;
  z-index: 3;
  text-indent: 2px;
  line-height: 30px;
`

export const ImageDetail = styled.div`
  padding-left: 10px;
  margin-top: 4px;
`

export const EditButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  direction: row;
  margin-bottom: 20px;
`

export const PreviewImageContainer = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.05);
`

export const ImageOverlay = styled.div`
  width: 200px;
  height: 190px;
  position: absolute;
  left: 0px;
  border-radius: 2px;
  top: 0px;
  padding-top: 10px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: space-between;
`

export const SubTitle = styled.div`
  font-size: 14px;
  padding-left: 20px;
  margin-top: 10px;
`

export const ButtonAndTitleContainer = styled.div`
  width: 99%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`