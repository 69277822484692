import { api } from './axios'
import { apiHelper } from '../utils/apiHelper'

export const retrieveTasks = async (): Promise<any> => {
  const retrievedTasks = await apiHelper<any>(() => api.get('/task'))
  return retrievedTasks.data
}

export const retrieveTaskCustomers = async (taskId: number): Promise<any> => {
  const url = `/task/task-customers?taskId=${taskId}`
  const retrievedTaskCustomers = await apiHelper(() => api.get(url))
  return retrievedTaskCustomers.data
}

export const retrieveTaskCustomerTimings = async (taskCustomerId: number): Promise<any> => {
  const url = `/task/task-customer-timings?taskCustomerId=${taskCustomerId}`
  const retrievedTaskCustomerTimings = await apiHelper(() => api.get(url))
  return retrievedTaskCustomerTimings.data
}

export const retrieveFailedNotificationReportConfig = async (taskCustomerId: number): Promise<any> => {
  const url = `/task/failed-notification-report-config?taskCustomerId=${taskCustomerId}`
  const retrievedFailedNotificationReportConfig = await apiHelper(() => api.get(url))
  return retrievedFailedNotificationReportConfig.data
}

export const createNewCustomerTiming = async (taskCustomerId: number, time: any): Promise<any> => {
  return await apiHelper(() => api.post(`/task/task-customer-timings/${taskCustomerId}`, { time }))
}

export const createNewFailedNotificationReportConfig = async (taskCustomerId: number, notificationConfig: string): Promise<any> => {
  return await apiHelper(() => api.post(`/task/failed-notification-report-config/${taskCustomerId}`, { notificationConfig }))
}

export const createNewTask = async (lambdaARN: string, friendlyName: string, global: boolean): Promise<any> => {
  return await apiHelper(() => api.post(`/task`, { lambdaARN, friendlyName, global }))
}

export const createNewTaskCustomer = async (selectedTask: number, customerId: string): Promise<any> => {
  return await apiHelper(() => api.post(`/task/task-customers/${selectedTask}`, { customerId }))
}

export const updateTaskLambdaArn = async (taskItemId: number, lambdaArn: string): Promise<any> => {
  return await apiHelper<any>(() => api.patch(`/task/lambda-arn/${taskItemId}`, { lambdaArn }))
}

export const updateTaskCustomerTiming = async (taskCustomerTimingId: number | string, time: string): Promise<any> => {
  return await apiHelper<any>(() => api.patch(`/task/task-customer-timings/${taskCustomerTimingId}`, { time }))
}

export const deleteFailedNotificationReportConfig = async (failedNotificationReportConfig: number): Promise<any> => {
  return await apiHelper<any>(() => api.delete(`/task/failed-notification-report-config/${failedNotificationReportConfig}`))
}

export const updateTaskCustomer = async (taskCustomerId: number, status: boolean): Promise<any> => {
  return await apiHelper<any>(() => api.patch(`/task/task-customers/${taskCustomerId}`, { status }))
}

export const retrieveTaskGlobalTimings = async (taskId: number): Promise<any> => {
  const retrievedTaskGlobalTimings = await apiHelper(() => api.get(`/task/task-global-timings?taskId=${taskId}`))
  return retrievedTaskGlobalTimings.data
}

export const createNewGlobalTiming = async (taskId: number, time: string): Promise<any> => {
  return await apiHelper(() => api.post(`/task/task-global-timings/${taskId}`, { time }))
}

export const updateTaskGlobalTiming = async (taskGlobalId: number, time: string): Promise<any> => {
  return await apiHelper(() => api.patch(`/task/task-global-timings/${taskGlobalId}`, { time }))
}