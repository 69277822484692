import { types } from 'mobx-state-tree'

const TaskData = types.model('Task', {
  id: types.identifierNumber,
  lambdaArn: types.string,
  friendlyName: types.string,
  global: types.boolean
})

export type ITaskData = typeof TaskData.Type
export default TaskData