import { api } from './axios'
import { apiHelper } from '../utils/apiHelper'

interface AuthenticateUserDto {
  email: string,
  password: string
}

interface CreateUserDto {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  token: string
}

export const login = async (credentials: AuthenticateUserDto): Promise<any> => {
  const getLoginResponse = await apiHelper<any>(() => api.post('/identity', credentials))
  return getLoginResponse.data
}

export const createUser = async (parameters: CreateUserDto): Promise<any> => {
  const createUserResponse = await apiHelper<any>(() => api.post('/Account', parameters))
  return createUserResponse.data
}

export const getFeatures = async (): Promise<any> => {
  const createUserResponse = await apiHelper<any>(() => api.get('/Account/features'))
  return createUserResponse.data.features
}

export const setCurrentCustomer = async (customerId: number, updateMetadata = true): Promise<any> => {
  const customer = await apiHelper<any>(() => api.post(`/identity/customer/${customerId}`, { updateMetadata}))
  return customer.data
}
