import styled from 'styled-components'

export const EditDialogueWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  height: 91%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Title = styled.div`
  width: 100%;
  font-size: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const EditDialogueContent = styled.div`
  padding-top: 20px;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 100%;
  text-align: center;
  border: 1px solid rgba(140, 140, 140, 0.8);
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: 45.5%;
  margin-right: 30px;
  object-fit: scale-down;
`

export const EditDialogueForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10px;
  width: 45.5%;
`

export const FormButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10px;
`

export const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 100%;
  font-size: 16px;
`

export const FormElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
`
