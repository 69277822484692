import { types } from 'mobx-state-tree'

const AggregatorConfig = types.model('AggregatorConfig', {
  friendlyName: types.string
})

const ScheduledUpload = types.model('ScheduledUpload', {
  id: types.identifierNumber,
  uploadTime: types.string,
  confirmed: types.boolean,
  uploaded: types.boolean,
  aggregatorConfig: AggregatorConfig
})

export type IScheduledUpload = typeof ScheduledUpload.Type
export default ScheduledUpload