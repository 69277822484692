import { types } from 'mobx-state-tree'

export const UserSearchResults = types.model('UserSearchResults', {
  id: types.number,
  email: types.string,
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string)
})

const CustomerAccount = types.model('CustomerAccount', {
  id: types.number,
  email: types.string,
  firstName: types.string,
  lastName: types.string,
  hasDatabase: types.optional(types.boolean, false),
  hasListMessage: types.optional(types.boolean, false),
  hasTransactional: types.optional(types.boolean, false),
  isAdmin: types.optional(types.boolean, false),
})

const OrganizationAccount = types.model('OrganizationAccount', {
  id: types.number,
  email: types.string,
  firstName: types.string,
  lastName: types.string,
  hasDatabase: types.optional(types.boolean, false),
  hasListMessage: types.optional(types.boolean, false),
  hasTransactional: types.optional(types.boolean, false),
  isAdmin: types.optional(types.boolean, false),
})

const AccountsData = types.model('AccountsData', {
  customerAccounts: types.optional(types.array(CustomerAccount), []),
  organizationAccounts: types.optional(types.array(OrganizationAccount), []),
  organizationName: types.maybeNull(types.string),
  organizationId: types.number,
})

export type IAccountsData = typeof AccountsData.Type
export default AccountsData
