import { types } from 'mobx-state-tree'

const NotificationSubType = types.model('NotificationSubType', {
  id: types.number,
  name: types.string,
  channel: types.string
})

const CustomerNotificationData = types.model('CustomerNotificationData', {
  name: types.maybeNull(types.string),
  subTypes: types.maybeNull(types.array(NotificationSubType)),
})

export const CustomerBranchData = types.model('CustomerBranchData', {
  id: types.number,
  name: types.string
})

export const CustomerChannelProviders = types.model('CustomerChannelProviders', {
  sms: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
})

const CustomerData = types.model('CustomerData', {
  id: types.number,
  name: types.string,
  email: types.maybeNull(types.string),
  phoneSms: types.maybeNull(types.string),
  phoneVoice: types.maybeNull(types.string),
  code: types.number,
  notificationTypes: types.optional(types.array(CustomerNotificationData), []),
  customerBranches: types.optional(types.array(CustomerBranchData), []),
  allowedApiChannels: types.optional(types.array(types.string), []),
  channelProviders: types.optional(CustomerChannelProviders, {})
})

export type ICustomerData = typeof CustomerData.Type
export default CustomerData
