import React from 'react'
import * as styled from './Notifications.styled'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { inject, observer } from 'mobx-react'
import { INotificationStore } from '../../../../stores/NotificationStore'
import { INotification } from '../../../../models/Notification'
import { notificationTypes } from './Notifications.copy'

interface NotificationsProps {
  notificationStore?: INotificationStore
}

@inject((allStores : any) => {
  return {
    notificationStore: allStores.notificationStore as INotificationStore
  }
})
@observer
class Notifications extends React.Component<NotificationsProps> {
  private renderNotificationIcon (notificationType?: string) {
    switch (notificationType) {
      case notificationTypes.ERROR:
        return <ErrorIcon />
      case notificationTypes.WARNING:
        return <WarningIcon />
      case notificationTypes.SUCCESS:
        return <CheckCircleIcon />
    }
  }

  private renderIndividualNotifications () {
    if (this.props.notificationStore && this.props.notificationStore.notifications) {
      return this.props.notificationStore.notifications.map((notification: INotification) => (
        <styled.IndividualNotification key={notification.id} notificationType={notification.type}>
          <styled.NotificationLeftContent>
            <styled.IconWrapper>
              {this.renderNotificationIcon(notification.type)}
            </styled.IconWrapper>
            <styled.MessageWrapper>
              {notification.message}
            </styled.MessageWrapper>  
          </styled.NotificationLeftContent>
          <styled.NotificationRightContent>
            <styled.CloseButtonWrapper>
              <IconButton key="close" aria-label="Close" color="inherit" onClick={() => this.props.notificationStore ? this.props.notificationStore.removeNotification(notification.id, null) : ''}>
                <CloseIcon />
              </IconButton>
            </styled.CloseButtonWrapper>  
          </styled.NotificationRightContent>
        </styled.IndividualNotification>
      ))
    }
  }

  public render () {
    if (this.props.notificationStore && this.props.notificationStore.notifications) {
      return (
        <styled.NotificationWrapper>
          {this.renderIndividualNotifications()}
        </styled.NotificationWrapper>
      )
    } else {
      return <div />
    }
  }
}

export default Notifications
