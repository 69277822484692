import React, { Component } from 'react'
import { RouteComponentProps, Redirect } from 'react-router'
import Dashboard from '../../hocs/DashBoard/Dashboard'
import MiddleContent from '../../components/DashBoard/MiddleContent'
import RightContent from '../../components/DashBoard/RightContent'
import { IAuthStore } from '../../../stores/AuthStore'
import { ICustomerStore } from '../../../stores/CustomerStore'
import { INotificationStore } from '../../../stores/NotificationStore'
import SvgBee from '../../components/shared/Loading/SvgBee'

interface DashboardProps {
  authStore: IAuthStore,
  customerStore: ICustomerStore
  notificationStore?: INotificationStore
}

class Home extends Component<RouteComponentProps & DashboardProps> {
  public render () {
    return (
      <Dashboard history={this.props.history}>
        {
          // temporary redirect to reports in abscense of a home page for users.
          <Redirect to='/reports' />
        }
        <MiddleContent title='Home Title'>
          <div style={{ textAlign: 'left' }}>
              This page is only visible to <b>admins</b> and just so we have something here, here is one of the loading bee graphics:
            <div style={{ marginTop: 20 }}>
              <SvgBee width={100} height={108} type='large' startingColor='#3b51a2' endingColor='#f58022' duration={2.5} scale='0.25' />
            </div>  
          </div>
        </MiddleContent>
        <RightContent title='Secondary Info'>
          Eventually stuff will be here.  Or maybe we {`don't`} need this side panel on the home page.  
        </RightContent>
      </Dashboard>
    )
  } 
}

export default Home
