import { types, getEnv, flow, cast } from 'mobx-state-tree'
import ApiStatusStore from './ApiStatusStore'
import { apiStatusDefinitions } from '../utils/apiStatusDefinitions'
import { notificationTypeKeys } from '../ui/components/shared/Notifications/Notifications.copy'
import AudienceList from '../models/AudienceLists'
import AudienceListCreation from '../models/AudienceListCreation'
import AudienceListFinalization from '../models/AudienceListFinalization'
import AudienceListSearchResult from '../models/AudienceListSearchResults'
import { createDictonary } from '../utils/generalHelpers'

const AudienceListsStore = types.model({
  apiStatus: ApiStatusStore,
  selectedAudienceListId: types.maybeNull(types.number),
  audienceLists: types.optional(types.array(AudienceList), []),
  audienceListCreation: types.maybeNull(AudienceListCreation),
  audienceListFinalization: types.maybeNull(AudienceListFinalization),
  audienceListSearchResults: types.maybeNull(types.array(AudienceListSearchResult)),
  audienceListSearchResultsCount: types.maybeNull(types.number)
})
  .volatile(() => ({
    mutableSuggestedAttributes: {},
    audienceRecordData: {
      attributes: {} as any,
      audienceListNames: [],
      availableAttributes: [],
      id: 0
    }
  })).actions(self => {
    const { notificationStore, customerStore, api } = getEnv(self)
    const clearAudienceListData = () => {
      self.selectedAudienceListId = null
      self.audienceLists = cast([])
      self.audienceListCreation = null
      self.audienceListFinalization = null
      self.audienceListSearchResults = null
    }
    const getAudienceLists = flow(function* () {
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_AUDIENCE_LISTS)
      clearAudienceListData()
      try {
        notificationStore!.removeNotification(null, notificationTypeKeys.GET_AUDIENCE_LISTS_ERROR)
        const audienceListsResponse = yield api.getAudienceLists(customerStore.setCustomerId)
        self.audienceLists = audienceListsResponse!.audienceLists
      } catch (error) {
        console.log(error)
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_AUDIENCE_LISTS_ERROR)
      }
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_AUDIENCE_LISTS)
    })

    const createAudienceList = flow(function*(name: string, callback: () => void) {
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_AUDIENCE_LIST)
      try {
        notificationStore!.removeNotification(null, notificationTypeKeys.CREATE_AUDIENCE_LIST_ERROR)
        const createAudienceListResponse = yield api.createAudienceList(name, customerStore.setCustomerId)
        self.audienceListCreation = { ...self.audienceListCreation, ...createAudienceListResponse!}
        getAudienceLists()
        self.selectedAudienceListId = createAudienceListResponse.id
        callback()
      } catch (error) {
        console.log(error)
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.CREATE_AUDIENCE_LIST_ERROR)
      }
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_AUDIENCE_LIST)
    })

    const uploadAudienceList = flow(function*(separator: string, file: File, callback: () => void) {
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPLOAD_AUDIENCE_LIST)
      try {
        notificationStore!.removeNotification(null, notificationTypeKeys.UPLOAD_AUDIENCE_LIST_ERROR)
        const uploadAudienceListResponse = yield api.uploadAudienceList(separator, customerStore.setCustomerId, file, self.selectedAudienceListId)
        self.audienceListFinalization = uploadAudienceListResponse.data

        self.mutableSuggestedAttributes = createDictonary(uploadAudienceListResponse.data.suggestedAttributes)
        callback()
      } catch (error) {
        console.log(error)
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.UPLOAD_AUDIENCE_LIST_ERROR)
      }
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPLOAD_AUDIENCE_LIST)
    })

    const finalizeAudienceList = flow(function*(callback: () => void) {
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.FINALIZE_AUDIENCE_LIST)
      try {
        notificationStore!.removeNotification(null, notificationTypeKeys.FINALIZE_AUDIENCE_LIST_ERROR)
        const finalAttributes = Object.entries(self.mutableSuggestedAttributes).reduce((acc, [key, val]) => {
          if (val === 'ignore')
            return acc

          acc[key] = val
          return acc
        }, {})
        yield api.finalizeAudienceList(self.audienceListFinalization!.id, customerStore.setCustomerId, finalAttributes)
        self.audienceListCreation = null
        callback()
      } catch (error) {
        console.log(error)
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.FINALIZE_AUDIENCE_LIST_ERROR)
      }
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.FINALIZE_AUDIENCE_LIST)
    })

    const searchAudienceList = flow(function*(searchType: string, searchValue: string, audienceListId: string, limit: number, offset: number) {
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SEARCH_AUDIENCE_LIST)
      self.audienceListSearchResultsCount = 0
      try {
        notificationStore!.removeNotification(null, notificationTypeKeys.SEARCH_AUDIENCE_LIST_ERROR)
        const audienceSearchResults = yield api.searchAudienceList(customerStore.setCustomerId, audienceListId, searchType, searchValue, limit, offset)
        self.audienceListSearchResults = audienceSearchResults.audienceRecords
        self.audienceListSearchResultsCount = audienceSearchResults.recordCount
      } catch (error) {
        console.log(error)
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.SEARCH_AUDIENCE_LIST_ERROR)
      }
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SEARCH_AUDIENCE_LIST)
    })

    const getAudienceRecord = flow(function*(recordId: number) {
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_AUDIENCE_RECORD)
      try {
        notificationStore!.removeNotification(null, notificationTypeKeys.GET_AUDIENCE_RECORD_ERROR)
        const audienceRecordData = yield api.getAudienceRecord(customerStore.setCustomerId, recordId)
        console.log('123456')
        console.log(audienceRecordData)
        console.log('123456')
        self.audienceRecordData = audienceRecordData
      } catch (error) {
        console.log(error)
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_AUDIENCE_RECORD_ERROR)
      }
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_AUDIENCE_RECORD)
    })

    const putAudienceRecord = flow(function*(recordId: number, attributes: {[key: string]: string}, callback: () => void) {
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.PUT_AUDIENCE_RECORD)
      try {
        notificationStore!.removeNotification(null, notificationTypeKeys.PUT_AUDIENCE_RECORD_ERROR)
        yield api.putAudienceRecord(customerStore.setCustomerId, recordId, attributes)
        notificationStore!.addNotification('success', 'The record has been updated.', notificationTypeKeys.PUT_AUDIENCE_RECORD_SUCCESS)
        callback()
      } catch (error) {
        console.log(error)
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.PUT_AUDIENCE_RECORD_ERROR)
      }
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.PUT_AUDIENCE_RECORD)
    })

    const setSelectedAudienceListId = (id: number) => {
      self.selectedAudienceListId = id
    }

    const setAttributes = (values: {[key: string]: string}) => {
      self.mutableSuggestedAttributes = values
    }

    const deleteAudienceRecord = flow(function*(recordId: number, callback: () => void) {
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.DELETE_AUDIENCE_RECORD)
      try {
        notificationStore!.removeNotification(null, notificationTypeKeys.DELETE_AUDIENCE_RECORD_ERROR)
        yield api.deleteAudienceRecord(customerStore.setCustomerId, recordId)
        notificationStore!.addNotification('success', 'The record has been deleted.', notificationTypeKeys.DELETE_AUDIENCE_RECORD_SUCCESS)
        callback()
      } catch (error) {
        console.log(error)
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.DELETE_AUDIENCE_RECORD_ERROR)
      }
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.DELETE_AUDIENCE_RECORD)
    })

    const addBlankAudienceListRecord = (audienceListId: number) => {
      const audienceListName = self.audienceLists.find(list => list.id === audienceListId)

      self.audienceRecordData = {
        id: 0,
        availableAttributes: [
          // @ts-ignore
          { displayName: 'First Name', propertyName: 'firstName' },
          // @ts-ignore
          { displayName: 'Last Name', propertyName: 'lastName' },
          // @ts-ignore
          { displayName: 'Custom Id', propertyName: 'customId' },
          // @ts-ignore
          { displayName: 'Email', propertyName: 'email' },
          // @ts-ignore
          { displayName: 'Phone Number', propertyName: 'phone' }
        ],
        // @ts-ignore
        audienceListNames: [audienceListName?.name],
        attributes: {firstName: '', lastName: '', customId: '', phone: '', email: ''}
      }
    }

    const createAudienceRecord = flow(function*(audienceListId: number, attributes: {[key: string]: string}, callback: () => void) {
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_AUDIENCE_RECORD)
      try {
        notificationStore!.removeNotification(null, notificationTypeKeys.CREATE_AUDIENCE_RECORD_ERROR)
        yield api.createAudienceRecord(customerStore.setCustomerId, audienceListId, attributes)
        notificationStore!.addNotification('success', 'The record has been created.', notificationTypeKeys.CREATE_AUDIENCE_RECORD_SUCCESS)
        callback()
      } catch (error) {
        console.log(error)
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.CREATE_AUDIENCE_RECORD_ERROR)
      }
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_AUDIENCE_RECORD)
    })
    const deleteAudienceList = flow(function*(audienceListId: number, callback: () => void) {
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.DELETE_AUDIENCE_LIST)
      try {
        notificationStore!.removeNotification(null, notificationTypeKeys.DELETE_AUDIENCE_LIST_ERROR)
        notificationStore!.removeNotification(null, notificationTypeKeys.DELETE_AUDIENCE_LIST_SUCCESS)
        yield api.deleteAudienceList(customerStore.setCustomerId, audienceListId)
        notificationStore!.addNotification('success', 'The audience list was successfully deleted.', notificationTypeKeys.DELETE_AUDIENCE_LIST_SUCCESS)
        getAudienceLists()
        callback()
      } catch (error) {
        console.log(error)
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.DELETE_AUDIENCE_LIST_ERROR)
      }
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.DELETE_AUDIENCE_LIST)
    })
    const clearAudienceListRecordSearchCount = () => {
      self.audienceListSearchResultsCount = 0
    }

    return {
      getAudienceLists,
      createAudienceList,
      uploadAudienceList,
      setSelectedAudienceListId,
      finalizeAudienceList,
      setAttributes,
      searchAudienceList,
      getAudienceRecord,
      putAudienceRecord,
      deleteAudienceRecord,
      addBlankAudienceListRecord,
      createAudienceRecord,
      deleteAudienceList,
      clearAudienceListRecordSearchCount
    }
  }).views(self =>({
  }))

export type IAudienceListsStore = typeof AudienceListsStore.Type
export default AudienceListsStore
