import { types, flow, cast, getEnv } from 'mobx-state-tree'
import jwtDecode from 'jwt-decode'
import Role from '../models/Role'
import { History } from 'history'
import { eraseCookie } from '../utils/generalHelpers'
import { apiStatusDefinitions } from '../utils/apiStatusDefinitions'
import { notificationTypeKeys } from '../ui/components/shared/Notifications/Notifications.copy'
import { roles } from '../utils/roleDefinitions'
import ApiStatusStore from './ApiStatusStore'
import { ClientResource, LoadedClerk, SignInResource } from '@clerk/types'

const AuthStore = types.model({
  features: types.optional(types.array(types.string), []),
  roles: types.optional(types.array(Role), []),
  jwtPayload: types.maybeNull(types.string),
  apiStatus: ApiStatusStore,
  email: types.maybeNull(types.string),
  resetRequired: types.maybeNull(types.boolean)
}).actions(self => {
  const { notificationStore, api } = getEnv(self)
  const getFeatures = flow(function* (this: any) {
    try {
      const features = yield api.getFeatures()
      self.features = cast(features)
    } catch (error) {
      console.log(error)
    }
  })
  const parseJwtPayloadFromCookie = (betaAuth = false, user: any = null) => {
    const cookies = document.cookie.split(';')
    const accessToken = cookies.find(cookie => cookie.includes('__session'))
    if (user && user.publicMetadata) {
      const roles = user.publicMetadata.roles || []
      let rolesArray = roles.map((role: string, i: number) => ({ id: i + 1, name: role }))
      self.roles = cast(rolesArray)
      self.email = user.primaryEmailAddress.emailAddress

      try {
        const jwtPayload = jwtDecode<any>(accessToken as string)
        if (jwtPayload) {
          if (jwtPayload.resetRequired) {
            self.resetRequired = jwtPayload.resetRequired === 'True' || jwtPayload.resetRequired === 'true'
          }
        }
      } catch { }
      return
    }

  }
  const logOut = (onComplete = () => { }) => {
    try {
      eraseCookie('access_token')
      self.jwtPayload = ''
      self.roles = cast([])
      onComplete()
    } catch (err) {
      console.log(err)
      // need to do something here. Not sure how this would fail but sometimes it does.
    }
  }
  const createUser = flow(function* (this: any, { firstName, lastName, email, password, token, history }: { firstName: string, lastName: string, email: string, password: string, token: string, history: History }) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_USER)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.USER_CREATION_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.USER_CREATION_SUCCESS)
      yield api.createUser({ firstName, lastName, email, password, token })
      notificationStore!.addNotification('success', 'Success!  Please login using the account credentials you just created.', notificationTypeKeys.USER_CREATION_SUCCESS)
      history.push('/login')
    } catch (error) {
      if (error.data === 'Please use the email address this link was sent to.') {
        notificationStore!.addNotification('error', 'Please use the email address this link was sent to when creating your account.', notificationTypeKeys.USER_CREATION_ERROR)
      } else {
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.USER_CREATION_ERROR)
      }
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_USER)
  })

  return {
    parseJwtPayloadFromCookie,
    logOut,
    createUser,
    getFeatures,
    authenticateAndGetUserDetails: flow(function* (this: any, { email, password }: { email: string, password: string }, clerk: LoadedClerk) {
      const { notificationStore, api } = getEnv(self)
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.AUTHENTICATE)
      try {
        notificationStore!.removeNotification(null, notificationTypeKeys.LOGIN_ERROR)
        const authData = yield api.login({ email, password })
        if (typeof authData === 'string') {
          const response: SignInResource = yield clerk.client.signIn.create({
            strategy: 'ticket',
            ticket: authData
          })
          const session = clerk.client.activeSessions.find(s => s != null)

          //parseJwtPayloadFromCookie(true, session?.user)
          self.apiStatus.toggleAPIStatus(apiStatusDefinitions.AUTHENTICATE)

          location.reload()
          return
        }
        parseJwtPayloadFromCookie(false)
      } catch (error) {
        notificationStore!.addNotification('error', 'The username or password you have entered is invalid.', notificationTypeKeys.LOGIN_ERROR)
      }
      self.apiStatus.toggleAPIStatus(apiStatusDefinitions.AUTHENTICATE)
    })
  }
}).views(self => ({
  isAuthorized() {
    return self.roles.some(role => roles.indexOf(role.name) >= 0)
  }
}))

export type IAuthStore = typeof AuthStore.Type
export default AuthStore
