import styled from 'styled-components'

export const MenuWrapper = styled.div`
  display: flex;
  width: 150px;
  flex-direction: column;
`

export const InnerMenuWrapper = styled.div`
  border-right: 1px solid #b8b8b8;
  margin-top: 50px;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-content: center;
`
interface ButtonContainer {
  active?: boolean
}

export const ButtonContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #969696;
  ${(props: ButtonContainer) => { return props.active ? 'border-right: 5px solid #f58022' : ''}};
  
  &:hover {
    border-right: 5px solid #f58022;
    cursor: pointer;
    color: #4b4b4b;
  }
`

export const Icon = styled.div`
`

export const IconName = styled.div`
  font-size: 12px;
  text-align: center;
`