import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './CustomerSchedule.styled'
import { ITaskStore } from '../../../../stores/TaskStore'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import TaskAddButton from './TaskAddButton'
import { apiStatusDefinitions } from '../../../../utils/apiStatusDefinitions'
import EagerUpdatingLoader from '../../shared/Loading/EagerUpdatingLoader'
import ButtonLoading from '../../shared/Loading/ButtonLoading'
import CronSyntaxLink from './CronSyntaxLink'

interface MediaLibraryContainerProps {
  taskStore?: ITaskStore
}

@inject((allStores : any) => {
  return {
    taskStore: allStores.taskStore as ITaskStore
  }
})
@observer
class CustomerSchedule extends Component<MediaLibraryContainerProps> {
  public state = {
    timeValueError: false,
    activeChangeValue: null,
    activeChangeId: null
  }

  public componentDidMount = () => {
    this.props.taskStore!.retrieveTaskCustomerTiming()
  }

  private handleTimeChange = (timeId: string | number) => (event: any) => {
    let error = false
    if (!event.target.value || !event.target.value.length) {
      error = true
    } 
    this.setState({ activeChangeId: timeId, activeChangeValue: event.target.value, timeValueError: error })
  }

  private onFocusStateChange = (id: number) => {
    this.setState({ activeChangeId: id, activeChangeValue: null, timeValueError: false })
  }

  private saveTimeScheduling = async () => {
    if (!this.state.activeChangeValue || this.state.activeChangeValue && !String(this.state.activeChangeValue!).length) {
      this.setState({ timeValueError: true })
      return
    }
    await this.props.taskStore!.createNewCustomerTiming(this.state.activeChangeValue)
    this.cancelTiming()
  }

  private updateCustomerTimeScheduling = async (id: number) => {
    if (this.state.activeChangeId === id && String(this.state.activeChangeValue).length > 0) {
      await this.props.taskStore!.updateTaskCustomerTiming(id, this.state.activeChangeValue!)
      this.setState({ activeChangeId: null, activeChangeValue: null, timeValueError: false })
    }
  }

  private cancelTiming = () => {
    this.setState({ activeChangeId: null, activeChangeValue: null, timeValueError: false })
    this.props.taskStore!.setTaskModalFunctionType('EDIT')
  }

  private onBlurUpdated = (id: number | null) => (event: any) => {
    if (id && this.state.activeChangeId === id) {
      if (!this.state.activeChangeValue) {
        this.setState({ activeChangeId: null, activeChangeValue: null })
      }
    }
  }

  public render () {
    return (
      <div>
        <styled.CustomerScheduleDialogueWrapper>
          <div>
            <styled.Title>
              {this.props.taskStore!.getCustomerName()} {this.props.taskStore!.getTaskName(this.props.taskStore!.selectedTask!)} Schedulings:
            </styled.Title>
            <styled.SubTitle>
              {this.props.taskStore!.taskModalFunctionType === 'EDIT' ? (
                <div>Click the + button in order to add a new timing <CronSyntaxLink /> for this customer task.
                  {this.props.taskStore! && this.props.taskStore!.taskCustomerTimings && this.props.taskStore!.taskCustomerTimings.length ? ( 
                    <p>Additionally, change any of the existing task timings <CronSyntaxLink /> and click save below that specifc timing to have {`it's`} value updated.</p>  
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div>After entering a time <CronSyntaxLink /> for this customer task please click the save button below.</div>
              )}
            </styled.SubTitle>
            <styled.TableRow>
              <>
                {this.props.taskStore!.apiStatus.checkApiStatus(apiStatusDefinitions.GET_TASK_CUSTOMER_TIMINGS) ? (
                  <styled.LoaderContainer>
                    <CircularProgress color="secondary" />
                    <styled.LoadingText>Loading, please wait.</styled.LoadingText>
                  </styled.LoaderContainer>
                ) : (
                  <>
                    {this.props.taskStore! && this.props.taskStore!.taskCustomerTimings && this.props.taskStore!.taskCustomerTimings.length ? (
                      <>
                        <styled.ItemColumn />
                        <styled.TimeColumn>
                          <strong>Time</strong>
                        </styled.TimeColumn>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            </styled.TableRow>
            {this.props.taskStore! && this.props.taskStore!.taskCustomerTimings && this.props.taskStore!.taskCustomerTimings.map((timing, i) => {
              const timeValue = timing.id === this.state.activeChangeId ? this.state.activeChangeValue || timing.time : timing.time
              return (
                <>
                  <styled.TableRow key={`${timing.id}_table_row`}>
                    <styled.ItemColumn>
                      {i + 1}
                    </styled.ItemColumn>
                    <styled.CronSyntaxInputContainer>
                      <TextField
                        onChange={this.handleTimeChange(timing.id)}
                        type='text'
                        name='new'
                        value={timeValue}
                        placeholder='Enter a task time'
                        label='Enter a task time'
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        key={`new_formField`}
                        disabled={this.props.taskStore!.taskModalFunctionType === 'ADD'}
                        error={this.state.timeValueError && this.state.activeChangeId === timing.id}
                        onBlur={this.onBlurUpdated(timing.id)}
                        onFocus={() => this.onFocusStateChange(timing.id)}
                        helperText={this.state.timeValueError && this.state.activeChangeId === timing.id ? 'This field must not be blank.' : ''}
                      />
                    </styled.CronSyntaxInputContainer>
                  </styled.TableRow>
                  {this.state.activeChangeId === timing.id && (
                    <>
                      <styled.ButtonRow>
                        <Button onClick={this.cancelTiming} variant='contained' style={{ marginRight: 10, color: 'white' }} color='secondary'>
                          Cancel
                        </Button>
                        <Button onClick={() => this.updateCustomerTimeScheduling(timing.id)} style={{ minWidth: 157 }} variant='contained' color='primary'>
                          {this.props.taskStore!.apiStatus.checkApiStatus(apiStatusDefinitions.UPDATE_TASK_CUSTOMER_TIMING) ? (
                            <ButtonLoading />
                          ) : (
                            <>
                              Save Updated Timing
                            </>
                          )}
                        </Button>
                      </styled.ButtonRow>
                    </>
                  )}
                </>
              )
            })}
            {this.props.taskStore!.taskModalFunctionType === 'ADD' && (
              <>
                <styled.TableRow key={`new_table_row`}>
                  <styled.ItemColumn>
                    {this.props.taskStore!.taskCustomerTimings.length + 1}
                  </styled.ItemColumn>
                  
                  <styled.TimeColumn>
                    <TextField
                      onChange={this.handleTimeChange('new')}
                      type='text'
                      name='new'
                      value={this.state.activeChangeValue ? this.state.activeChangeValue : ''}
                      placeholder='Enter a new task time'
                      label='Enter a new task time'
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      key={`new_formField`}
                      error={this.state.timeValueError && this.state.activeChangeId === 'new'}
                      helperText={this.state.timeValueError && this.state.activeChangeId === 'new' ? 'This field must not be blank.' : ''}
                    />

                  </styled.TimeColumn>

                </styled.TableRow>
                <styled.ButtonRow>
                  <Button onClick={this.cancelTiming} variant='contained' style={{ marginRight: 10, color: 'white' }} color='secondary'>
                    Cancel
                  </Button>
                  <Button onClick={this.saveTimeScheduling} style={{ minWidth: 157 }} variant='contained' color='primary'>
                    {this.props.taskStore!.apiStatus.checkApiStatus(apiStatusDefinitions.CREATE_NEW_CUSTOMER_TIMING) ? (
                      <ButtonLoading />
                    ) : (
                      <>
                        Save New Timing
                      </>
                    )}
                  </Button>
                </styled.ButtonRow>
              </>
            )}
          </div>
        </styled.CustomerScheduleDialogueWrapper>
        {this.props.taskStore!.taskModalFunctionType === 'EDIT' ? (
          <TaskAddButton taskAddButtonFunction={() => this.props.taskStore!.setTaskModalFunctionType('ADD')} />
        ) : (
          <div></div>
        )}
        {this.props.taskStore!.apiStatus.checkApiStatus(apiStatusDefinitions.UPDATE_TASK_CUSTOMER_TIMING) && (
          <EagerUpdatingLoader text='Updating...' />
        )}
      </div>
    )
  }
}

export default CustomerSchedule