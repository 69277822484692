import { types, getEnv, flow, cast, addDisposer, protect, unprotect } from 'mobx-state-tree'
import TemplatesData, { ITemplatesData } from '../models/Templates'
import TemplateEditorData from '../models/TemplateEditorData'
import FileUploadItems from '../models/ListCommunicationsUploadItems'
import EditableStaticConfigItem from '../models/EditableStaticConfigItems'
import ScheduledUpload, { IScheduledUpload } from '../models/ScheduledUpload'
import { observable, reaction } from 'mobx'
import { apiStatusDefinitions } from '../utils/apiStatusDefinitions'
import { notificationTypeKeys } from '../ui/components/shared/Notifications/Notifications.copy'
import { modalState } from '../utils/modalState'
import ApiStatusStore from './ApiStatusStore'
import { set, isEmpty, cloneDeep } from 'lodash'
import { compileTemplate, finalizeTemplateObject, getSubtypeConfigIdFromName } from '../utils/templateHelpers'
import { daysSince, getPreviewLink, sumArray, formatPhoneNumber } from '../utils/dateHelpers'
import parse from 'html-react-parser'
import HandleBars, { template } from 'handlebars/dist/cjs/handlebars'
import CommunicationChannelStore from './CommunicationChannelStore'
import { CustomerBranchData } from '../models/CustomerData'
import moment from 'moment'
import 'moment/locale/fr';
import 'moment/locale/pl';
import 'moment/locale/es';
import 'moment/locale/es';
moment.locale('en');

//HandleBars.registerHelper("dateFormat", require("handlebars-dateformat"));
HandleBars.registerHelper("dateFormat", function dateFormat(date, format, locale) {
  const d = moment(date)
  if (typeof locale === 'string') {
    d.locale(locale)
  }
  return d.format(format);
});
HandleBars.registerHelper('daysSince', daysSince)
HandleBars.registerHelper('preview', getPreviewLink)
HandleBars.registerHelper("sumArray", sumArray)
HandleBars.registerHelper("formatPhoneNumber", formatPhoneNumber)

interface TemplateDataDto {
  emailSubject?: string,
  currentYear?: string
}

interface RecipientDataDto {
  fullName?: string
}

interface CustomItemDto {
  isbn?: string
}

interface NotificationDetailsItemsDto {
  lineItemDescription?: string,
  lineItemDetail01?: string,
  'dateFormat lineItemDetail01 "dddd, MMMM Do"'?: string,
  pickupLocation?: string,
  pickupDate?: string,
  dueDate?: string,
  title?: string,
  custom?: CustomItemDto
}

interface NotificationDetailsDto {
  items: NotificationDetailsItemsDto[]
}

export interface TemplateObjectDto {
  id?: string,
  templateData?: TemplateDataDto,
  recipient?: RecipientDataDto,
  recipientId?: string,
  notificationDetails?: NotificationDetailsDto,
  images?: any,
  copy?: any,
  content?: any
}

const TemplateStore = types.model({
  templateData: types.maybeNull(TemplatesData),
  selectedCustomerTemplate: types.maybeNull(types.string),
  configBranches: types.maybeNull(types.array(CustomerBranchData)),
  templateEditorData: types.optional(TemplateEditorData, { previewOrEditMode: false, contentModified: false, testEmailSent: false }),
  editableStaticConfigItems: types.maybeNull(types.array(EditableStaticConfigItem)),
  selectedSubtype: types.maybeNull(types.string),
  selectedSubTypeConfigId: types.maybeNull(types.number),
  selectedSubTypeIsCustomTemplate: types.optional(types.boolean, false),
  selectedListMessageFrom: types.maybeNull(types.string),
  selectedListMessageEmail: types.maybeNull(types.string),
  templateSaved: types.optional(types.boolean, false),
  listMessagesModalState: types.optional(types.string, 'edit-copy'),
  listMessageFileUploadHeaders: types.maybeNull(types.array(types.string)),
  listMessageFileUploadItems: types.maybeNull(FileUploadItems),
  scheduledUploads: types.maybeNull(types.array(ScheduledUpload)),
  scheduledUploadActionId: types.maybeNull(types.number),
  scheduledUploadsCalled: false,
  apiStatus: ApiStatusStore,
  communicationChannelStore: CommunicationChannelStore,
  savingPropertyName: types.maybeNull(types.string),
  previewImageDate: types.optional(types.Date, new Date(Date.now()))
}).actions(self => {
  const { notificationStore, customerStore, modalStore, api, listApi } = getEnv(self)
  const getCustomerTemplates = flow(function* (source?: string, callBack?: () => void) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_CUSTOMER_TEMPLATES)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_CUSTOMER_TEMPLATES_ERROR)
      const channel = self.communicationChannelStore.communicationChannel ? self.communicationChannelStore.communicationChannel : self.communicationChannelStore.channelDefaultOrSelectedValue
      const type = !channel || channel === 'email' ? self.templateEditorData.templateType : channel
      const templateData: ITemplatesData = yield api.getCustomerTemplates({ customerId: customerStore.setCustomerId, notificationType: self.selectedCustomerTemplate, templateType: type, versionId: self.templateEditorData.setTemplateVersionId, branchId: customerStore.setCustomerBranchId, imageDate: self.previewImageDate.toISOString(), configId: self.selectedSubTypeConfigId })
      if (templateData) {
        // fromReaction && unprotect(self)
        self.templateData = { ...templateData, editorTemplate: templateData.template }
        let templateObject = finalizeTemplateObject(templateData, customerStore.setCustomerId, self.selectedSubtype, self.selectedCustomerTemplate)
        self.templateData!.templateObject = observable(templateObject)
        // fromReaction && protect(self)
      }
    } catch (error) {
      console.log(error)
      if (source === 'listCommunications') {
        callBack && callBack()
      } else {
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_CUSTOMER_TEMPLATES_ERROR)
      }

    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_CUSTOMER_TEMPLATES)
    return
  })
  const nullifyVersionId = () => {
    if (self.templateEditorData) {
      self.templateEditorData!.setTemplateVersionId = ''
    }
  }
  const clearModal = () => {
    modalStore.changeModalVisbility()
    modalStore.updateModalAttributes('')
  }
  const setSelectedSubtypeConfigId = (configurationId: number) => {
    self.selectedSubTypeConfigId = configurationId
  }
  const listMessageCustomerFileUpload = flow(function* (date: string, isFile: boolean, dedupe: boolean, audienceListId?: number[] | null, dynamicListId?: number | null, recurringFrequency = 'None') {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPLOAD_LIST_MESSAGE_CUSTOMER_FILE)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.UPLOAD_LIST_MESSAGE_CUSTOMER_FILE_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.UPLOAD_LIST_MESSAGE_CUSTOMER_FILE_SUCCESS)
      const result: { data: any } = yield api.listMessageCustomerFileUpload(customerStore.setCustomerId, self.selectedSubTypeConfigId, null, date, isFile, dedupe, audienceListId, dynamicListId, recurringFrequency)
      // @ts-ignore
      if (result!.data!.success) {
        self.listMessageFileUploadItems = { fileUploadScheduleId: result!.data!.scheduledUploadId, fileLength: result!.data!.fileLength }
        if (audienceListId || dynamicListId) {
          notificationStore!.addNotification('success', 'Your audience list based list communication has been scheduled.', notificationTypeKeys.UPLOAD_LIST_MESSAGE_CUSTOMER_FILE_SUCCESS)
          clearModal()
        } else {
          self.listMessagesModalState = modalState.UPLOAD_SUCCESS
        }
      } else {
        // @ts-ignore
        notificationStore!.addNotification('error', `Your file is missing the following columns: ${result!.data!.missingHeaders.join(', ')}.`, notificationTypeKeys.UPLOAD_LIST_MESSAGE_CUSTOMER_FILE_ERROR)
      }
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.UPLOAD_LIST_MESSAGE_CUSTOMER_FILE_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPLOAD_LIST_MESSAGE_CUSTOMER_FILE)
  })
  const getListCommincationUploadHeaders = flow(function* () {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_LIST_COMMUNICATION_UPLOAD_HEADERS)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_LIST_COMMUNICATION_UPLOAD_HEADERS)
      const returnedHeaders: string[] = yield api.getListCommincationUploadHeaders(self.selectedSubTypeConfigId, customerStore.setCustomerId)
      self.listMessageFileUploadHeaders = cast(returnedHeaders)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_LIST_COMMUNICATION_UPLOAD_HEADERS)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_LIST_COMMUNICATION_UPLOAD_HEADERS)
  })
  const closeAndClearCustomerSuccessModal = () => {
    self.listMessageFileUploadItems = null
  }
  const confirmScheduledUpload = flow(function* (scheduledUploadId?: number) {
    if (scheduledUploadId) {
      self.scheduledUploadActionId = scheduledUploadId
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CONFIRM_SCHEDULED_UPLOAD)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.CONFIRM_SCHEDULED_UPLOAD_SUCCESS)
      notificationStore!.removeNotification(null, notificationTypeKeys.CONFIRM_SCHEDULED_UPLOAD_ERROR)
      yield api.confirmScheduledUpload(scheduledUploadId || self.listMessageFileUploadItems!.fileUploadScheduleId, customerStore.setCustomerId)
      notificationStore!.addNotification('success', 'The scheduled communication has been successfully confirmed.', notificationTypeKeys.CONFIRM_SCHEDULED_UPLOAD_SUCCESS)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.CONFIRM_SCHEDULED_UPLOAD_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CONFIRM_SCHEDULED_UPLOAD)
    closeAndClearCustomerSuccessModal()
    if (scheduledUploadId) {
      self.scheduledUploadActionId = null
    } else {
      clearModal()
    }
  })
  const cancelScheduledUpload = flow(function* (scheduledUploadId?: number) {
    if (scheduledUploadId) {
      self.scheduledUploadActionId = scheduledUploadId
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CANCEL_SCHEDULED_UPLOAD)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.CANCEL_SCHEDULED_UPLOAD_SUCCESS)
      notificationStore!.removeNotification(null, notificationTypeKeys.CANCEL_SCHEDULED_UPLOAD_ERROR)
      yield api.cancelScheduledUpload(scheduledUploadId || self.listMessageFileUploadItems!.fileUploadScheduleId, customerStore.setCustomerId)
      notificationStore!.addNotification('success', 'The scheduled communication has been successfully cancelled.', notificationTypeKeys.CANCEL_SCHEDULED_UPLOAD_SUCCESS)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.CANCEL_SCHEDULED_UPLOAD_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CANCEL_SCHEDULED_UPLOAD)
    closeAndClearCustomerSuccessModal()
    if (scheduledUploadId) {
      self.scheduledUploadActionId = null
    } else {
      clearModal()
    }
  })
  const displayListMessageCustomerUploadModal = (subjectPopulated: boolean, subjectCallback?: (value: boolean) => void) => {
    subjectCallback && subjectCallback(false)
    if (subjectPopulated) {
      self.listMessagesModalState = modalState.UPLOAD_CUSTOMERS
      modalStore.updateModalAttributes('Upload List Messages Customers')
      modalStore.changeModalVisbility()
    } else {
      subjectCallback && subjectCallback(true)
    }

  }
  const setSelectedTemplate = (templateId: string, source?: string, callBack?: () => void) => {
    self.selectedCustomerTemplate = templateId
    self.templateData = null
    self.templateSaved = false
    self.selectedSubtype = null
    self.selectedSubTypeConfigId = null
    self.selectedSubTypeIsCustomTemplate = false
    nullifyVersionId()
    getCustomerTemplates(source, callBack)
  }
  const setSelectedSubtype = (subtype: string) => {
    self.selectedSubtype = subtype
    const selectedSubType = self.templateData?.subTypes?.find(subType => subType.name === subtype)
    self.selectedSubTypeConfigId = selectedSubType!.configId!
    self.selectedSubTypeIsCustomTemplate = selectedSubType!.isCustomTemplate
    nullifyVersionId()
    getCustomerTemplates()
  }
  const setTemplateObjectItem = (objectReference: string, value: string) => {
    let obj = Object.assign({}, self.templateData!.templateObject)
    set(obj, objectReference, value)
    self.templateData!.templateObject = obj
  }
  const saveAndSetPreview = (admin: boolean | null) => {
    self.templateSaved = true
    if (!admin) {
      localStorage.setItem(`template_${customerStore.setCustomerId}_${self.selectedCustomerTemplate}${self.selectedSubtype ? `_${self.selectedSubtype}` : ``}`, JSON.stringify(self.templateData!.templateObject))
    }
    if (self.templateEditorData.previewOrEditMode) {
      if (self.templateEditorData.templateType === 'subject' && self.templateEditorData!.previewOrEditMode) {
        self.templateData!.subjectTemplate = self.templateData!.editorTemplate
        self.templateData!.template = self.templateData!.editorTemplate
      } else {
        self.templateData!.template = self.templateData!.editorTemplate
      }
      const templateData = self.templateData!
      const finaltemplateObject = finalizeTemplateObject(templateData, customerStore.setCustomerId, self.selectedSubtype, self.selectedCustomerTemplate)
      self.templateData!.templateObject = observable(finaltemplateObject)
    }
  }
  const saveAndSetDefaults = flow(function* (this: any) {
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.SAVE_TEMPLATE_DEFAULTS_ERROR)
      self.templateSaved = true
      yield api.saveTemplateDefaults({ customerId: customerStore.setCustomerId, notificationType: self.selectedCustomerTemplate, defaults: JSON.stringify(self.templateData!.templateObject) })
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.SAVE_TEMPLATE_DEFAULTS_ERROR)
    }
  })
  const resetTemplates = () => {
    self.templateData = null
    self.selectedCustomerTemplate = null
    self.templateSaved = false
    self.selectedSubTypeConfigId = null
    self.selectedSubtype = null
    self.selectedSubTypeIsCustomTemplate = false
    self.templateEditorData!.previewOrEditMode = false
    self.templateEditorData!.contentModified = false
    self.templateEditorData!.testEmailSent = false
    self.templateEditorData!.templateType = 'html'
    self.communicationChannelStore.communicationChannel = null
  }
  const sendTestEmail = flow(function* (this: any, emailAddress: string, setModalVisibility?: (visibility: boolean) => void) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SEND_TEST_EMAIL)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.SEND_TEST_EMAIL_SUCCESS)
      notificationStore!.removeNotification(null, notificationTypeKeys.SEND_TEST_EMAIL_ERROR)
      if (self.templateData.beta) {
        
        yield api.sendTestEmail({ toEmail: emailAddress, htmlContent: self.templateData.betaHtml, subject: "MessageBee Test Email", plainTextContent: "Please view this email in your browser." })
      } else {
        if (self.templateEditorData.templateType === 'subject' && self.templateEditorData!.previewOrEditMode) {
          self.templateData!.subjectTemplate = self.templateData!.editorTemplate
          self.templateData!.template = self.templateData!.editorTemplate
        } else {
          self.templateData!.template = self.templateData!.editorTemplate
        }
        const compiledTemplateText = compileTemplate(self.templateData!, self.templateData!.template)
        const compiledSubjectTemplateText = compileTemplate(self.templateData!, self.templateData!.subjectTemplate)
        let compiledPlainText = "Please view this message in your web browser."
        if (self.templateData!.plainText) {
          compiledPlainText = compileTemplate(self.templateData!, self.templateData!.plainText)
        }

        yield api.sendTestEmail({ toEmail: emailAddress, htmlContent: compiledTemplateText, subject: compiledSubjectTemplateText, plainTextContent: compiledPlainText })
      }
      notificationStore!.addNotification('success', 'The test email was successfully sent.  It should arrive shortly.', notificationTypeKeys.SEND_TEST_EMAIL_SUCCESS)
      if (self.templateEditorData.previewOrEditMode) {
        self.templateEditorData.testEmailSent = true
      }
      setModalVisibility && setModalVisibility(false)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.SEND_TEST_EMAIL_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SEND_TEST_EMAIL)
  })
  const abandonEdits = () => {
    self.templateEditorData!.previewOrEditMode = false
    self.templateEditorData!.contentModified = false
    self.templateEditorData!.testEmailSent = false
    self.templateEditorData!.templateType = 'html'
    nullifyVersionId()
    getCustomerTemplates()
  }
  const getTemplateVersions = flow(function* (this: any) {
    const templateVersions = yield api.retrieveTemplateVersions(customerStore.setCustomerId, self.selectedCustomerTemplate, self.templateEditorData.templateType, self.communicationChannelStore.communicationChannel || self.communicationChannelStore.channelDefaultOrSelectedValue)
    self.templateEditorData.templateVersions = templateVersions!
  })
  const setPreviewOrEditMode = (mode: boolean) => {
    if (mode) {
      self.templateEditorData!.previewOrEditMode = true
      getTemplateVersions()
    } else {
      abandonEdits()
    }
  }
  const setContentHasBeenChanged = (edited: boolean, content: string) => {
    self.templateEditorData!.contentModified = edited
    self.templateData!.editorTemplate = content
  }
  const setTestEmailSent = (sent: boolean) => {
    self.templateEditorData!.testEmailSent = sent
  }
  const setTemplateVersion = (versionId: string) => {
    self.templateEditorData.setTemplateVersionId = versionId
    if (versionId) {
      getCustomerTemplates()
    }
  }
  const saveContentEdits = flow(function* (this: any) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SAVE_MODIFICATION_EDITS)
    try {
      self.templateData!.template = self.templateData!.editorTemplate
      notificationStore!.removeNotification(null, notificationTypeKeys.SAVE_MODIFICATION_EDITS_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.SAVE_MODIFICATION_EDITS_SUCCESS)

      let templateContent = ''
      if (self.templateEditorData.templateType === 'subject') {
        templateContent = self.templateData!.subjectTemplate
      } else {
        templateContent = self.templateData!.template
      }

      const channel = self.communicationChannelStore.communicationChannel ? self.communicationChannelStore.communicationChannel : self.communicationChannelStore.channelDefaultOrSelectedValue
      const type = !channel || channel === 'email' ? self.templateEditorData.templateType : channel

      yield api.saveContentEdits(customerStore.setCustomerId, self.selectedCustomerTemplate, templateContent, type, channel, self.selectedSubTypeConfigId, self.selectedSubTypeIsCustomTemplate)
      self.templateEditorData!.previewOrEditMode = false
      self.templateEditorData!.contentModified = false
      self.templateEditorData!.testEmailSent = false
      self.templateEditorData!.templateType = 'html'
      getCustomerTemplates()
      notificationStore!.addNotification('success', 'Your modifications have been saved.', notificationTypeKeys.SAVE_MODIFICATION_EDITS_SUCCESS)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.SAVE_MODIFICATION_EDITS_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SAVE_MODIFICATION_EDITS)
  })
  const setEditorType = (templateType: string) => {
    if (self.templateEditorData.templateType !== templateType) {
      self.templateEditorData.templateType = templateType
      setTemplateVersion("")
      getCustomerTemplates()
      getTemplateVersions()
    }
  }
  const getListMessage = flow(function* (id: number, callback?: (subject: string) => void) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_LIST_COMMUNICATION)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_LIST_COMMUNICATION_ERROR)
      const listCommunication: { template: string, subject: string, plainText: string, customizableSenderEmail: string | null, customizableSenderFrom: string | null, beta: boolean, betaHtml: string } = yield listApi.getListCommunication(customerStore.setCustomerId, id)
      self.selectedListMessageFrom = listCommunication.customizableSenderFrom
      self.selectedListMessageEmail = listCommunication.customizableSenderEmail
      const templateData = { defaults: '', revolvData: listCommunication!.template, template: listCommunication!.template, editorTemplate: '', subTypes: null, subjectTemplate: listCommunication.subject, plainText: listCommunication.plainText, templateObject: {}, beta: listCommunication.beta, betaHtml: listCommunication.betaHtml }
      self.templateData = templateData
      let templateObject = finalizeTemplateObject(templateData, customerStore.setCustomerId, self.selectedSubtype, self.selectedCustomerTemplate)
      self.templateData!.templateObject = observable(templateObject)
      callback && callback(templateData.subjectTemplate)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_LIST_COMMUNICATION_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_LIST_COMMUNICATION)
  })
  const getBaseTemplate = flow(function* (baseTemplateId: number) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_BASE_TEMPLATE)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_BASE_TEMPLATE_ERROR)
      const baseTemplate: { template: string } = yield api.getBaseTemplate(customerStore.setCustomerId, baseTemplateId)
      const templateData = { defaults: '', revolvData: baseTemplate!.template, template: baseTemplate!.template, editorTemplate: '', subTypes: null, subjectTemplate: '', plainText: '', templateObject: {}, beta: false, betaHtml: '' }
      self.templateData = templateData
      let templateObject = finalizeTemplateObject(templateData, customerStore.setCustomerId, self.selectedSubtype, self.selectedCustomerTemplate)
      self.templateData!.templateObject = observable(templateObject)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_BASE_TEMPLATE_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_BASE_TEMPLATE)
  })
  const updateBaseTemplate = flow(function* (baseTemplateId: number, revolvContent: string) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_BASE_TEMPLATE)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.UPDATE_BASE_TEMPLATE_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.UPDATE_BASE_TEMPLATE_SUCCESS)
      yield api.updateBaseTemplate(customerStore.setCustomerId, baseTemplateId, revolvContent)
      notificationStore!.addNotification('success', 'Your base template modifications have been saved.', notificationTypeKeys.UPDATE_BASE_TEMPLATE_SUCCESS)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.UPDATE_BASE_TEMPLATE_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_BASE_TEMPLATE)
  })

  const saveTemplate = flow(function* (configId: number, revolvContent?: string, htmlContent?: string) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SAVE_TEMPLATE)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.SAVE_TEMPLATE_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.SAVE_TEMPLATE_SUCCESS)
      const templateObject: TemplateObjectDto = self.templateData!.templateObject
      let custom: string[] = []
      if (templateObject!.notificationDetails && templateObject!.notificationDetails!.items && templateObject!.notificationDetails!.items![0].custom) {
        custom = Object.keys(templateObject!.notificationDetails!.items![0].custom)
      }
      let defined: string[] = []
      if (templateObject!.recipient) {
        defined = Object.keys(templateObject!.recipient)
      }
      let variables = {}
      yield listApi.saveTemplate(parseInt(String(configId)), customerStore.setCustomerId, self.templateData!.subjectTemplate, revolvContent, htmlContent, self.templateData!.plainText || "Please view this message in your web browser.", { copyItem1: 'test' }, defined, custom, variables)
      yield getListMessage(parseInt(String(configId)))
      notificationStore!.addNotification('success', 'Your template modifications have been saved.', notificationTypeKeys.UPDATE_BASE_TEMPLATE_SUCCESS)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.SAVE_TEMPLATE_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SAVE_TEMPLATE)
  })
  const clearEmailData = () => {
    if (self.templateData) {
      self.templateData = null
    }
  }
  const setListCommunicationSubject = (subject: string) => {
    self.templateData!.subjectTemplate = subject
  }
  const setTemplateContent = (template: string) => {
    self.templateData!.template = template
    self.templateData!.editorTemplate = template
  }
  const retrieveEditableFields = flow(function* () {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_EDITABLE_FIELDS)
    try {
      const configId = getSubtypeConfigIdFromName(self.templateData!.subTypes!, self.selectedSubtype!)
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_EDITABLE_FIELDS_ERROR)
      const editableFields: any = yield api.retrieveEditableFields(customerStore.setCustomerId, configId, customerStore.setCustomerBranchId)

      if (editableFields && editableFields.length > 0) {
        self.editableStaticConfigItems = editableFields
      }
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_EDITABLE_FIELDS_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_EDITABLE_FIELDS)
  })
  const clearEditableFields = () => {
    self.editableStaticConfigItems = null
  }
  const changeEditableFieldItem = (propertyName: string, value: string) => {
    const objectToChange = self.editableStaticConfigItems!.find(item => item.propertyName === propertyName)
    if (value !== objectToChange!.staticPropertyValue) {
      objectToChange!.isModified = true
      objectToChange!.staticPropertyValue = value
    }
  }
  const setModifiedEditableFieldItem = (propertyName: string, value: string) => {
    const objectToChange = self.editableStaticConfigItems!.find(item => item.propertyName === propertyName)
    if (value !== objectToChange!.staticPropertyValue) {
      objectToChange!.isModified = true
    }
  }
  const saveEditableField = flow(function* (propertyName: string) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SAVE_EDITABLE_FIELDS)
    try {
      self.savingPropertyName = propertyName
      const configId = getSubtypeConfigIdFromName(self.templateData!.subTypes!, self.selectedSubtype!)
      const objectToChange = self.editableStaticConfigItems!.find(item => item.propertyName === propertyName)
      notificationStore!.removeNotification(null, notificationTypeKeys.SAVE_EDITABLE_FIELDS_ERROR)
      yield api.patchEditableFields(configId, customerStore.setCustomerId, objectToChange!.propertyName, objectToChange!.staticPropertyValue, objectToChange!.isCustomProperty, customerStore.setCustomerBranchId)
      objectToChange!.isModified = false
      self.savingPropertyName = null
      getCustomerTemplates()
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.SAVE_EDITABLE_FIELDS_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SAVE_EDITABLE_FIELDS)
  })
  const filterCommunicationChannel = (type: string) => {
    self.communicationChannelStore.communicationChannel = type
    self.templateData = null
    nullifyVersionId()
    self.templateEditorData!.previewOrEditMode = false
    self.templateEditorData!.contentModified = false
    self.templateEditorData!.testEmailSent = false
    self.templateEditorData!.templateType = 'html'
    self.selectedSubtype = null
    self.selectedSubTypeConfigId = null
    self.selectedSubTypeIsCustomTemplate = false
    self.selectedCustomerTemplate = null
  }
  const setPreviewImageDate = (date: Date) => {
    self.previewImageDate = date
    getCustomerTemplates()
  }
  const retrieveConfigSpecificCustomerBranches = flow(function* () {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_CONFIG_SPECIFIC_CUSTOMER_BRANCHES)
    try {
      const configId = getSubtypeConfigIdFromName(self.templateData!.subTypes!, self.selectedSubtype!)
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_CONFIG_SPECIFIC_CUSTOMER_BRANCHES_ERROR)
      const configSpecificCustomerBranches: any = yield api.retrieveConfigSpecificCustomerBranches(configId, customerStore.setCustomerId)
      const convertObjectToArray = Object.entries(configSpecificCustomerBranches.customerBranches).map(([id, name]) => {
        return { id: parseInt(id), name: (name as string) }
      })
      if (convertObjectToArray && convertObjectToArray.length > 0) {
        self.configBranches = cast(convertObjectToArray)
      }
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_CONFIG_SPECIFIC_CUSTOMER_BRANCHES_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_CONFIG_SPECIFIC_CUSTOMER_BRANCHES)
  })
  const updateConfigSpecificSenderInfo = flow(function* (configId: number, senderEmail?: string, senderName?: string) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.POST_CONFIG_SPECIFIC_SENDER_INFO)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.POST_CONFIG_SPECIFIC_SENDER_INFO_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.POST_CONFIG_SPECIFIC_SENDER_INFO_SUCCESS)
      if (senderEmail || senderName) {
        yield api.updateConfigSpecificSenderInfo(customerStore.setCustomerId, configId, senderEmail, senderName)
        notificationStore!.addNotification('success', 'Your base template modifications have been saved.', notificationTypeKeys.POST_CONFIG_SPECIFIC_SENDER_INFO_SUCCESS)
      }
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.POST_CONFIG_SPECIFIC_SENDER_INFO_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.POST_CONFIG_SPECIFIC_SENDER_INFO)
  })
  const setPlainTextTemplate = (plainTextTemplate: string) => {
    self.templateData!.plainText = plainTextTemplate
  }

  return {
    getCustomerTemplates,
    // afterCreate,
    clearModal,
    setSelectedSubtypeConfigId,
    listMessageCustomerFileUpload,
    getListCommincationUploadHeaders,
    confirmScheduledUpload,
    cancelScheduledUpload,
    displayListMessageCustomerUploadModal,
    setSelectedTemplate,
    setSelectedSubtype,
    setTemplateObjectItem,
    saveAndSetPreview,
    resetTemplates,
    saveAndSetDefaults,
    sendTestEmail,
    abandonEdits,
    getTemplateVersions,
    setPreviewOrEditMode,
    setContentHasBeenChanged,
    setTestEmailSent,
    setTemplateVersion,
    saveContentEdits,
    setEditorType,
    getListMessage,
    getBaseTemplate,
    updateBaseTemplate,
    saveTemplate,
    clearEmailData,
    setListCommunicationSubject,
    setTemplateContent,
    retrieveEditableFields,
    clearEditableFields,
    changeEditableFieldItem,
    saveEditableField,
    filterCommunicationChannel,
    setModifiedEditableFieldItem,
    setPreviewImageDate,
    retrieveConfigSpecificCustomerBranches,
    updateConfigSpecificSenderInfo,
    setPlainTextTemplate
  }
}).views(self => ({
  getTemplateData() {
    return self.templateData
  },
  get parseAndRenderTemplate() {
    let templateObject: TemplateObjectDto = cloneDeep(self.templateData!.templateObject)
    const template = HandleBars.compile(self.templateData!.template!)
    return !isEmpty(templateObject) ? parse(template(templateObject)) : parse(self.templateData!.template!)
  },
  get parseAndRenderSubject() {
    const template = HandleBars.compile(self.templateData!.subjectTemplate)
    return !isEmpty(self.templateData!.templateObject!) ? template(self.templateData!.templateObject!) : self.templateData!.subjectTemplate
  },
  get parseAndRenderPreheader() {

    const preheader = (self.templateData.templateObject as any).content?.preHeader
    if (preheader == null || preheader === '' || typeof preheader !== 'string')
      return null
    const template = HandleBars.compile(preheader)
    return !isEmpty(self.templateData!.templateObject!) ? template(self.templateData!.templateObject!) : preheader
  },
  get previewOrSendEmailButtonDisabled() {
    if (self.templateEditorData!.previewOrEditMode && self.templateEditorData!.contentModified) {
      return false
    }
    return true
  },
}))

export type ITemplateStore = typeof TemplateStore.Type
export default TemplateStore
