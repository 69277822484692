import { types, getEnv, flow, cast } from 'mobx-state-tree'
import User from '../models/User'
import { UserSearchResults } from '../models/AccountsData'
import { History } from 'history'
import { apiStatusDefinitions } from '../utils/apiStatusDefinitions'
import { notificationTypeKeys } from '../ui/components/shared/Notifications/Notifications.copy'
import ApiStatusStore from './ApiStatusStore'
import Settings from '../models/Settings'

const UserStore = types.model({
  user: types.maybeNull(User),
  settings: types.maybeNull(Settings),
  customerId: types.optional(types.number, 1),
  resetPasswordRedirect: types.maybeNull(types.boolean),
  userSearchResults: types.maybeNull(types.array(UserSearchResults)),
  apiStatus: ApiStatusStore
}).actions(self => {
  const { notificationStore, api } = getEnv(self)
  const requestPasswordReset = flow(function* (this: any, { email }: { email: string }) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.REQUEST_PASSWORD_RESET)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.USER_CREATION_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.USER_CREATION_SUCCESS)
      yield api.requestPasswordReset({ email })
      notificationStore!.addNotification('success', 'Success!  An email invitation was sent to that email address.', notificationTypeKeys.USER_CREATION_SUCCESS)
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.USER_CREATION_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.REQUEST_PASSWORD_RESET)
  })
  const resetPassword = flow(function* (this: any, { password, token, history }: { password: string, token: string, history: History }) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.RESET_PASSWORD)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.RESET_PASSWORD_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.RESET_PASSWORD_SUCCESS)
      yield api.resetPassword({ password, token })
      notificationStore!.addNotification('success', 'Success!  Your password has been reset.  Please login with your new password below.', notificationTypeKeys.REQUEST_PASSWORD_SUCCESS)
      history.push('/login')
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.REQUEST_PASSWORD_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.RESET_PASSWORD)
  })
  const authedResetPassword = flow(function* (this: any, { currentPassword, newPassword, onUpdate }: { currentPassword: string, newPassword: string, onUpdate?: () => void }) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.RESET_PASSWORD)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.RESET_PASSWORD_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.RESET_PASSWORD_SUCCESS)
      yield api.authedResetPassword({ currentPassword, newPassword })
      notificationStore!.addNotification('success', 'Success!  Your password has been reset.', notificationTypeKeys.REQUEST_PASSWORD_SUCCESS)
      onUpdate && onUpdate()
    } catch (error) {
      notificationStore!.addNotification('error', error?.data, notificationTypeKeys.REQUEST_PASSWORD_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.RESET_PASSWORD)
  })
  const getSettings = flow(function* (this: any) {
    try {
      const data = yield api.getUserSettings()
      self.settings = cast(data)
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.UPDATE_SETTINGS_DIGEST_FREQUENCY_ERROR)
    }
  })
  const updateDigestFrequency = flow(function* (this: any) {
    if (self.settings == null) {
      return
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.RESET_PASSWORD)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.UPDATE_SETTINGS_DIGEST_FREQUENCY_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.UPDATE_SETTINGS_DIGEST_FREQUENCY_SUCCESS)
      yield api.updateDigestFrequency({ digestFrequency: self.settings!.digestFrequency, digestFailReport: self.settings.digestFailReport })
      notificationStore!.addNotification('success', 'Success!  Your notification preferences have been update', notificationTypeKeys.UPDATE_SETTINGS_DIGEST_FREQUENCY_SUCCESS)
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.UPDATE_SETTINGS_DIGEST_FREQUENCY_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_SETTINGS_DIGEST_FREQUENCY)
  })
  const removeResetPasswordRedirect = () => {
    self.resetPasswordRedirect = false
  }
  const userSearch = flow(function* (this: any, searchTerm: string) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.ADMIN_RESET_PASSWORD_USER_SEARCH)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.ADMIN_RESET_PASSWORD_USER_SEARCH_ERROR)
      const userSearchResults = yield api.userSearch(searchTerm)
      self.userSearchResults = cast(userSearchResults)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.ADMIN_RESET_PASSWORD_USER_SEARCH_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.ADMIN_RESET_PASSWORD_USER_SEARCH)
  })
  const resetUserPassword = flow(function* (this: any, id: number, password: string, callback?: () => void) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.ADMIN_RESET_PASSWORD)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.ADMIN_RESET_PASSWORD_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.ADMIN_RESET_PASSWORD_SUCCESS)
      yield api.resetUserPassword(id, password)
      notificationStore!.addNotification('success', `Success!  That user\'s password has been changed to "${password}"`, notificationTypeKeys.UPDATE_SETTINGS_DIGEST_FREQUENCY_SUCCESS)
      callback && callback()
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.ADMIN_RESET_PASSWORD_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.ADMIN_RESET_PASSWORD)
  })

  return {
    requestPasswordReset,
    resetPassword,
    authedResetPassword,
    removeResetPasswordRedirect,
    updateDigestFrequency,
    getSettings,
    userSearch,
    resetUserPassword
  }
})

export type IUserStore = typeof UserStore.Type
export default UserStore
