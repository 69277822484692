import React, { Component } from 'react'
import { ICustomerStore } from '../../../stores/CustomerStore'
import { IReportStore } from '../../../stores/ReportStore'
import { observer } from 'mobx-react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { seriesToolTips } from '../../pages/Reports/Reports.copy'
import * as styled from './DataTableOverView.styled'
import ToolTip from '../shared/ToolTip'

interface DataTableProps {
  customerStore: ICustomerStore
  reportStore: IReportStore,
}

@observer
class DataTableMail extends Component<DataTableProps> {
  public render() {
    let rows = this.props.reportStore.unformattedData
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="right">
              <styled.DataTableHeaderContainer>Mailed Zip<ToolTip titleText={seriesToolTips['DELIVERED']} /></styled.DataTableHeaderContainer>
            </TableCell>
            <TableCell align="right">
              <styled.DataTableHeaderContainer>In Transit<ToolTip titleText={seriesToolTips['OPENS']} /></styled.DataTableHeaderContainer>
            </TableCell>
            <TableCell align="right">
              <styled.DataTableHeaderContainer>In Local Area<ToolTip titleText={seriesToolTips['UNIQUE_OPENS']} /></styled.DataTableHeaderContainer>
            </TableCell>
            <TableCell align="right">
              <styled.DataTableHeaderContainer>Destinations<ToolTip titleText={seriesToolTips['BOUNCES']} /></styled.DataTableHeaderContainer>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={`dataTable_1`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.0)' }}>
            <TableCell component="th" scope="row" style={{ fontSize: '0.7rem', fontWeight: 500, color: 'rgba(0, 0, 0, 0.54)' }}>
              10/16/2019
            </TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40031</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>71711</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40202</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40031</TableCell>
          </TableRow>
          <TableRow key={`dataTable_2`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
            <TableCell component="th" scope="row" style={{ fontSize: '0.7rem', fontWeight: 500, color: 'rgba(0, 0, 0, 0.54)' }}>
              10/16/2019
            </TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40010</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40202</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40031</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40031</TableCell>
          </TableRow>
          <TableRow key={`dataTable_3`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.0)' }}>
            <TableCell component="th" scope="row" style={{ fontSize: '0.7rem', fontWeight: 500, color: 'rgba(0, 0, 0, 0.54)' }}>
              10/17/2019
            </TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40010</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40202</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40031</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40031, 40034</TableCell>
          </TableRow>
          <TableRow key={`dataTable_$4`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
            <TableCell component="th" scope="row" style={{ fontSize: '0.7rem', fontWeight: 500, color: 'rgba(0, 0, 0, 0.54)' }}>
              10/18/2019
            </TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40010</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40202</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40031</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40031, 40034</TableCell>
          </TableRow>
          <TableRow key={`dataTable_5`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.0)' }}>
            <TableCell component="th" scope="row" style={{ fontSize: '0.7rem', fontWeight: 500, color: 'rgba(0, 0, 0, 0.54)' }}>
              10/18/2019
            </TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40010</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40202</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40031</TableCell>
            <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>40031, 40034</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }
}

export default DataTableMail
