import { types } from 'mobx-state-tree'

const PatronList = types.model('PatronList', {
  id: types.number,
  name: types.string,
  previewCount: types.maybeNull(types.number),
  previewCountUpdating: types.optional(types.boolean, false),
  previewUpdatedTimestamp: types.maybeNull(types.string)
})


export type IPatronList = typeof PatronList.Type
export default PatronList

