import { types } from 'mobx-state-tree'

const Customer = types.model('Customer', {
  id: types.identifierNumber,
  name: types.string
})

const TaskCustomer = types.model('TaskCustomer', {
  id: types.identifierNumber,
  customer: Customer,
  active: types.boolean
})

export type ITaskCustomer = typeof TaskCustomer.Type
export default TaskCustomer