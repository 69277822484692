import { types, flow, getEnv, cast } from 'mobx-state-tree'
import { apiStatusDefinitions } from '../utils/apiStatusDefinitions'
import { notificationTypeKeys } from '../ui/components/shared/Notifications/Notifications.copy'
import ApiStatusStore from './ApiStatusStore'
import { getStartingEndDate } from '../utils/generalHelpers'
import { ISearchData } from '../models/SearchData'
import SearchData from '../models/SearchData'
import sortBy from 'lodash/orderBy'

const SearchStore = types.model({
  data: types.maybeNull(types.array(SearchData)),
  startDate: types.optional(types.string, getStartingEndDate(1)),
  endDate: types.optional(types.string, getStartingEndDate(0)),
  searchType: types.maybeNull(types.string),
  searchTerm: types.maybeNull(types.string),
  apiStatus: ApiStatusStore
}).actions(self => {
  const { notificationStore, customerStore, api } = getEnv(self)
  const performSearch = flow(function* (this: any, searchType: string, searchTerm: string, filteredNotificationSubTypes: string) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.PERFORM_SEARCH)
    notificationStore!.removeNotification(null, notificationTypeKeys.PERFORM_SEARCH_API_ERROR)
    notificationStore!.removeNotification(null, notificationTypeKeys.PERFORM_SEARCH_INPUT_ERROR)
    if (!searchType) {
      notificationStore!.addNotification('error', 'You must select a search type before performing a search.', notificationTypeKeys.PERFORM_SEARCH_INPUT_ERROR)
    // } else if (!searchTerm) {
    //   notificationStore!.addNotification('error', 'You must enter a search term before performing a search.', notificationTypeKeys.PERFORM_SEARCH_INPUT_ERROR)
    } else {
      try {
        self.searchType = searchType
        self.searchTerm = searchTerm
        let searchData: ISearchData[] = []
        searchData! = yield api.performDetailedSearch(searchType, searchTerm, self.startDate, self.endDate, customerStore.setCustomerId, filteredNotificationSubTypes)
        if (searchData) {
          searchData = sortBy(searchData, (searchItem) => {
            return new Date(searchItem.sentTimestamp)
          }).reverse()
        }
        self.data = cast(searchData)
      } catch (error) {
        console.error(error)
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.PERFORM_SEARCH_API_ERROR)
      }
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.PERFORM_SEARCH)
  })
  const filterDates = (date: string, type: string) => {
    if (type === 'start') {
      self.startDate = date
    } else {
      self.endDate = date
    }
  }
  return {
    performSearch,
    filterDates
  }
})

export type ISearchStore = typeof SearchStore.Type
export default SearchStore