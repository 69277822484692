import { types } from 'mobx-state-tree'

const AudienceList = types.model('AudienceList', {
  id: types.identifierNumber,
  name: types.string,
  count: types.number
})

export type IAudienceList = typeof AudienceList.Type
export default AudienceList
