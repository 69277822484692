import { types, flow, getEnv, cast } from 'mobx-state-tree'
import { apiStatusDefinitions } from '../utils/apiStatusDefinitions'
import { notificationTypeKeys } from '../ui/components/shared/Notifications/Notifications.copy'
import ApiStatusStore from './ApiStatusStore'
import TemplateImageItem from '../models/TemplateImageItem'
import TemplateImateConfigurationItem from '../models/TemplateImageConfigurationItem'

const TemplateCustomizationStore = types.model({
  templateImageFunctionState: types.optional(types.string, 'IMAGE_SELECTION'),
  templateImageFunctionType: types.optional(types.string, 'ADD'),
  templateImageData: types.optional(types.array(TemplateImageItem), []),
  templateImageConfigurationBranchOverrides: types.optional(types.array(TemplateImateConfigurationItem), []),
  selectedTemplateImageId: types.maybeNull(types.number),
  selectedTemplateImageConfigurationId: types.maybeNull(types.number),
  fallbackImageEdit: types.optional(types.boolean, false),
  previouslySelectedImageId: types.maybeNull(types.number),
  admin: types.optional(types.boolean, false),
  apiStatus: ApiStatusStore
}).actions(self => {
  const { notificationStore, modalStore, customerStore, api, templateStore } = getEnv(self)
  const populateAndDisplayTemplateImageSelectionModal = (admin?: boolean) => {
    if (admin) {
      self.admin = admin
    }
    modalStore.updateModalAttributes('Template Image Selection')
    modalStore.changeModalVisbility()
  }
  const setTemplateImageFunctionState = (newState: string) => {
    self.templateImageFunctionState = newState
    if (newState.includes('ADD')) {
      self.templateImageFunctionType = 'ADD'
      self.selectedTemplateImageConfigurationId = null
    } else if (newState.includes('EDIT')) {
      self.templateImageFunctionType = 'EDIT'
    }
  }
  const createNewTemplateImage = flow(function* (this: any, variableName: string, friendlyName: string, html?: string) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_NEW_TEMPLATE_IMAGE)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.CREATE_NEW_TEMPLATE_IMAGE_ERROR)
      yield api.createNewTemplateImage(customerStore.setCustomerId, variableName, friendlyName, html)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.CREATE_NEW_TEMPLATE_IMAGE_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_NEW_TEMPLATE_IMAGE)
    setTemplateImageFunctionState('IMAGE_SELECTION')
  })
  const retrieveTemplateImages = flow(function* (this: any) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.RETRIEVE_TEMPLATE_IMAGES)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.RETRIEVE_TEMPLATE_IMAGES_ERROR)
      const templateImages = yield api.retrieveTemplateImages(customerStore.setCustomerId)
      self.templateImageData = cast(templateImages!)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.RETRIEVE_TEMPLATE_IMAGES_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.RETRIEVE_TEMPLATE_IMAGES)
  })

  const retrieveImageConfigurationBranchOverrides = flow(function* (this: any) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.RETRIEVE_IMAGE_CONFIGURATIONS_BRANCH_OVERRIDES)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.RETRIEVE_IMAGE_CONFIGURATIONS_BRANCH_OVERRIDES_ERROR)
      const imageConfigurationBranchOverrides = yield api.retrieveImageConfigurationsBranchOverrides(customerStore.setCustomerId, self.selectedTemplateImageId, customerStore.setCustomerBranchId)
      self.templateImageConfigurationBranchOverrides = cast(imageConfigurationBranchOverrides)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.RETRIEVE_IMAGE_CONFIGURATIONS_BRANCH_OVERRIDES_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.RETRIEVE_IMAGE_CONFIGURATIONS_BRANCH_OVERRIDES)
  })

  const clearImageConfigurationBranchOverrides = () => {
    self.templateImageConfigurationBranchOverrides = cast([])
  }

  const setSelectedTemplateImage = (templateImageId: number) => {
    self.selectedTemplateImageId = templateImageId
    self.templateImageFunctionState = 'IMAGE_DETAIL'
    self.templateImageFunctionType = 'EDIT'
  }
  const setSelectedTemplateConfigurationId = (templateImageConfigurationId: number | null) => {
    self.selectedTemplateImageConfigurationId = templateImageConfigurationId
    self.templateImageFunctionState = 'EDIT_DATES'
    self.templateImageFunctionType = 'EDIT'
  }
  const createNewTemplateImageConfiguration = flow(function* (this: any, startDate: string, endDate: string, url: string, html?: string) {
    if (html == '<p><br></p>') {
      html = null
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_NEW_TEMPLATE_IMAGE_CONFIGURATION)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.CREATE_NEW_TEMPLATE_IMAGE_CONFIGURATION_ERROR)    
      const startDateJs = new Date(startDate)
      const endDateJs = new Date(endDate)
      endDateJs.setHours(23, 59, 59, 999)
      const startDateAsIso = startDateJs.toISOString()
      const endDateAsIso = endDateJs.toISOString()
      yield api.createNewTemplateImageConfiguration(self.selectedTemplateImageId, customerStore.setCustomerId, startDateAsIso, endDateAsIso, url, html, customerStore.setCustomerBranchId)
    } catch (error) {
      if (error.data.includes('MBERROR:')) {
        notificationStore!.addNotification('error', error.data.replace('MBERROR: ', ''), notificationTypeKeys.CREATE_NEW_TEMPLATE_IMAGE_CONFIGURATION_ERROR)
      } else {
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.CREATE_NEW_TEMPLATE_IMAGE_CONFIGURATION_ERROR)
      }
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_NEW_TEMPLATE_IMAGE_CONFIGURATION)
    setTemplateImageFunctionState('IMAGE_DETAIL')
  })
  const editTemmplateImageConfiguration = flow(function* (this: any, startDate: string, endDate: string, url: string, html?: string) {
    if (html == '<p><br></p>') {
      html = null
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.EDIT_TEMPLATE_IMAGE_CONFIGURATION)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.EDIT_TEMPLATE_IMAGE_CONFIGURATION_ERROR)
      const startDateJs = new Date(startDate)
      const endDateJs = new Date(endDate)
      endDateJs.setHours(23, 59, 59, 999)
      const startDateAsIso = startDateJs.toISOString()
      const endDateAsIso = endDateJs.toISOString()
      yield api.editTemplateImageConfiguration(self.selectedTemplateImageId, self.selectedTemplateImageConfigurationId, customerStore.setCustomerId, startDateAsIso, endDateAsIso, url, html, customerStore.setCustomerBranchId)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.EDIT_TEMPLATE_IMAGE_CONFIGURATION_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.EDIT_TEMPLATE_IMAGE_CONFIGURATION)
    setTemplateImageFunctionState('IMAGE_DETAIL')
  })
  const selectNewConfigurationImage = (templateConfigurationId: number | null, fallback: boolean, previouslySelectedImageId: number | null) => {
    setTemplateImageFunctionState('ADD_IMAGE_GALLERY_PASSTHROUGH')
    self.fallbackImageEdit = fallback
    self.previouslySelectedImageId = previouslySelectedImageId
    if (!fallback) {
      self.selectedTemplateImageConfigurationId = templateConfigurationId
    }
  }
  const saveConifgurationImage = flow(function* (this: any, imageId: number) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.ASSIGN_CONFIGURATION_IMAGE)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.ASSIGN_CONFIGURATION_IMAGE_ERROR)
      yield api.saveConifgurationImage(self.selectedTemplateImageId, self.selectedTemplateImageConfigurationId, customerStore.setCustomerId, imageId, customerStore.setCustomerBranchId)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.ASSIGN_CONFIGURATION_IMAGE_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.ASSIGN_CONFIGURATION_IMAGE)
    setTemplateImageFunctionState('IMAGE_DETAIL')
  })
  const saveFallbackImage = flow(function* (this: any, imageId: number) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.ASSIGN_FALLBACK_IMAGE)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.ASSIGN_FALLBACK_IMAGE_ERROR)
      yield api.saveFallbackImage(self.selectedTemplateImageId, customerStore.setCustomerId, imageId)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.ASSIGN_FALLBACK_IMAGE_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.ASSIGN_FALLBACK_IMAGE)
    setTemplateImageFunctionState('IMAGE_DETAIL')
  })
  const updateFallbackLinkUrlAndOrHtml = flow(function* (this: any, url: string, html?: string) {
    if (html == '<p><br></p>') {
      html = null
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_FALLBACK_LINK_URL_AND_OR_HTML)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.UPDATE_FALLBACK_LINK_URL_AND_OR_HTML_ERROR)
      yield api.updateFallbackLinkUrlAndOrHtml(self.selectedTemplateImageId, customerStore.setCustomerId, url, html)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.UPDATE_FALLBACK_LINK_URL_AND_OR_HTML_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_FALLBACK_LINK_URL_AND_OR_HTML)
    setTemplateImageFunctionState('IMAGE_DETAIL')
  })
  const setSelectedImageFromImageGallery = (passthrough: boolean | null, imageId: number) => {
    if (passthrough) {
      if (self.fallbackImageEdit) {
        saveFallbackImage(imageId)
      } else {
        saveConifgurationImage(imageId)
      }
    }
    return
  }
  const clearPreviouslySelected = () => {
    self.previouslySelectedImageId = null
  }
  const clearModal = () => {
    self.templateImageFunctionState = 'IMAGE_SELECTION'
    self.templateImageFunctionType = 'ADD'
    self.templateImageData = cast([])
    self.selectedTemplateImageId = null
    self.selectedTemplateImageConfigurationId = null
    self.fallbackImageEdit = false
    self.admin = false
    modalStore.changeModalVisbility()
    modalStore.updateModalAttributes('')
    if (templateStore.templateData) {
      templateStore.saveAndSetPreview()
    }
    retrieveTemplateImages().then(() => {
      if (templateStore.templateData) {
        templateStore.saveAndSetPreview()
      }
    })
  }
  return {
    populateAndDisplayTemplateImageSelectionModal,
    setTemplateImageFunctionState,
    createNewTemplateImage,
    retrieveTemplateImages,
    retrieveImageConfigurationBranchOverrides,
    clearImageConfigurationBranchOverrides,
    setSelectedTemplateImage,
    setSelectedTemplateConfigurationId,
    createNewTemplateImageConfiguration,
    editTemmplateImageConfiguration,
    selectNewConfigurationImage,
    saveConifgurationImage,
    saveFallbackImage,
    updateFallbackLinkUrlAndOrHtml,
    setSelectedImageFromImageGallery,
    clearPreviouslySelected,
    clearModal
  }
}).views(self => ({
  get templateImageConfigurations() {
    const { customerStore } = getEnv(self)
    return customerStore.setCustomerBranchId ? self.templateImageConfigurationBranchOverrides : self.templateImageData.find(templateImage => templateImage.id === self.selectedTemplateImageId)!.templateImageConfigurations
  }
}))

export type ITemplateCustomizationStore = typeof TemplateCustomizationStore.Type
export default TemplateCustomizationStore
