import styled from 'styled-components'

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  background: rgba(255, 255, 255, 0.35);
  overflow-x: none;
`

export const ModalContainer = styled.div`
  width: 70%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 3px;
  box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.15);
  overflow-x: none;
`

export const ModalTitleBar = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  background-color: #3f51b5;
  align-content: center;
`

export const ModalTitle = styled.div`
  font-size: 14px;
  height: 100%;
  line-height: 40px;
  text-indent: 10px;
  font-weight: bolder;
  display: flex;
  flex-direction: row;
`

export const ModalExit = styled.div`
  font-size: 18px;
  line-height: 40px;
  font-weight: bolder;
  margin-right: 0px;
  margin-top: -1px;
  width: 36px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
`

export const Logo = styled.img`
  width: 16px;
  height: 16px;
  margin-top: 12px;
  margin-right: 6px;
  margin-left: 9px;
  opacity: 0.8;
`

export const ModalContent = styled.div`
  flex-grow: 1;
  width: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: none;
  max-height: 100%;
`
