import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

interface ToolTipProps {
  titleText: string,
  arrow?: boolean,
  style?: any,
  direction?: any,
  fontAwesomeStyle?: any
}

const ToolTip: React.FC<ToolTipProps> = (props: ToolTipProps) => {
  return (
    <Tooltip style={props.style} title={props.titleText} placement={props.direction || 'bottom'}>
      <span>&nbsp;<FontAwesomeIcon style={props.fontAwesomeStyle} size='xs' icon={faQuestionCircle} /></span>
    </Tooltip>
  )
}

export default ToolTip
