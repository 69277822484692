import React, { Component } from 'react'
import * as Styled from './Calendar.styled'
import { observer } from 'mobx-react'
import * as dateFns from 'date-fns'
import { getLongMonthName, getMonthNumber } from '../../../../utils/dateHelpers'
import { ITemplateImateConfigurationItem } from '../../../../models/TemplateImageConfigurationItem'

interface CalendarProps {
  selectedDate: string,
  setDateFunction: (date: string, type: string) => void,
  type: string,
  templateImageConfigurations?: ITemplateImateConfigurationItem[],
  selectedConfiguration?: number | null
}

@observer
class Calendar extends Component<CalendarProps, any> {  
  public state = {
    selectedDate: new Date(),
    monthName: null,
    monthNumber: null,
    headerDate: ''
  }

  private selectDate = (value: Date, blocked?: boolean) => {
    if(!blocked) {
      const newlySelectedDate = `${value.getMonth() + 1}/${value.getDate()}/${value.getFullYear()}`
      this.props.setDateFunction(newlySelectedDate, this.props.type)
      this.setState({selectedDate: value})
    }
  }

  public componentDidMount = () => {
    const newDate = new Date(this.props.selectedDate)
    this.setState({ headerDate: newDate, selectedDate: this.props.selectedDate, monthName: getLongMonthName(newDate), monthNumber: getMonthNumber(newDate) })
  }

  private nextMonth = () => {
    const newDate = dateFns.addMonths(this.state.headerDate as any, 1)
    this.setState({ headerDate: newDate, monthName: getLongMonthName(newDate), monthNumber: getMonthNumber(newDate) })
  }

  private prevMonth = () => {
    const newDate = dateFns.subMonths(this.state.headerDate as any, 1)
    this.setState({ headerDate: newDate, monthName: getLongMonthName(newDate), monthNumber: getMonthNumber(newDate) })
  }

  private renderHeader = () => {
    return (
      <Styled.MonthControlsAndTitleContainer>
        <Styled.MonthToggleSwitch onClick={this.prevMonth}>{`<`}</Styled.MonthToggleSwitch>
        <div>{this.state.monthName}</div>
        <Styled.MonthToggleSwitch onClick={this.nextMonth}>{`>`}</Styled.MonthToggleSwitch>
      </Styled.MonthControlsAndTitleContainer>
    )
  }

  private renderCells = () => {
    const selectedDate = this.props.selectedDate
    const { headerDate } = this.state
    const monthStart = dateFns.startOfMonth(headerDate  as any)
    const monthEnd = dateFns.endOfMonth(monthStart)
    const startDate = dateFns.startOfWeek(monthStart)
    const endDate = dateFns.endOfWeek(monthEnd)

    const dateFormat = "D"
    const rows = []

    let days = []
    let day = startDate
    let formattedDate = ""

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat)
        const dateForComparison = dateFns.format(day, 'MM/DD/YYYY')
        let blocked = false
        if (this.props.templateImageConfigurations) {
          let filteredConfigurations
          if (this.props.selectedConfiguration) {
            filteredConfigurations = this.props.templateImageConfigurations.filter(configuration => configuration.id !== this.props.selectedConfiguration)
          } else {
            filteredConfigurations = this.props.templateImageConfigurations
          }
          
          filteredConfigurations.forEach(range => {
            const currentDate = new Date(dateForComparison)
            const startDate = new Date(range.startDate)
            const endDate = new Date(range.endDate)
            if (currentDate >= startDate && currentDate <= endDate) {
              blocked = true
            }
          })
        }

        const cloneDay = day
        days.push(
          <Styled.Day
            status={!dateFns.isSameMonth(day, monthStart) ? 'inactive' : dateFns.isSameDay(day, selectedDate  as any) ? 'active' : ''}
            key={day.toString()}
            onClick={() => this.selectDate(dateFns.parse(cloneDay), blocked)}
            blocked={blocked}
          >
            {formattedDate}
          </Styled.Day>
        )
        day = dateFns.addDays(day, 1)
      }
      rows.push(
        <Styled.Row key={day.toString()}>
          {days}
        </Styled.Row>
      )
      days = []
    }
    return rows
  }

  public render() {
    return(
      <Styled.CalendarWrapper>
        {this.renderHeader()}
        <Styled.Row>
          <Styled.Day header>S</Styled.Day>
          <Styled.Day header>M</Styled.Day>
          <Styled.Day header>T</Styled.Day>
          <Styled.Day header>W</Styled.Day>
          <Styled.Day header>T</Styled.Day>
          <Styled.Day header>F</Styled.Day>
          <Styled.Day header>S</Styled.Day>
        </Styled.Row>
        {this.renderCells()}
      </Styled.CalendarWrapper>
    )
  }
}

export default Calendar