import { types, getEnv, flow, cast  } from 'mobx-state-tree'
import ApiStatusStore from './ApiStatusStore'
import { apiStatusDefinitions } from '../utils/apiStatusDefinitions'
import { notificationTypeKeys } from '../ui/components/shared/Notifications/Notifications.copy'
import PatronDatabaseMetaData from '../models/PatronDatabaseMetaData'
import PatronList from '../models/PatronLists'
import { getUniqueItemsInComparedArray } from '../utils/generalHelpers'
import isEqual from 'lodash/isEqual'
import { v4 as uuidv4 } from 'uuid'

const createPropertyNameValue = (properties: any) => {
  Object.keys(properties).forEach((key: string) => {
    if (properties[key].type === 'object') {
      properties[key].properties = createPropertyNameValue(properties[key].properties)
    } else {
      properties[key].property = key
      properties[key].locked = true
    }
  })
  return properties
}

const attributeAUiStrings = {
  mapping: '"Incoming Header"',
  property: '"Property Name"',
  type: '"Data Type"'
}

const checkForEmptyAttributeAndOrDuplicate = (properties: any, attributeName: string, checkForDuplicates: boolean) => {
  const duplicationArray: string[] = []
  Object.keys(properties).forEach(key => {
    const property = properties[key]
    if (property.type === 'object') {
      checkForEmptyAttributeAndOrDuplicate(property.properties, attributeName, checkForDuplicates)
      return
    }

    if (!property[attributeName] || !(/\S/.test(property[attributeName]) || !(property[attributeName].isFinite()) || property[attributeName]?.includes('newMappingField_'))) {
      throw new Error(`You must provide a value for each ${attributeAUiStrings[attributeName]}`)
    } else if (checkForDuplicates && duplicationArray.includes(property[attributeName])) {
      throw new Error(`Every instance of ${attributeAUiStrings[attributeName]} must be unique`)
    } else {
      duplicationArray.push(property[attributeName])
    }
  })
}

const createPropertiesObjectForSaving = (properties: any) => {
  const updatedProperties = {}
  Object.entries(properties).forEach(([propertyName, propertyValues]: any) => {
    const { property, locked, ...rest} = propertyValues

    if (property == null || rest.type === 'object') {
      updatedProperties[propertyName] = { ...rest, properties: createPropertiesObjectForSaving(rest.properties) }
    } else {
      updatedProperties[property] = { ...rest }
    }
  })
  return updatedProperties
}

const lockAllProperties = (properties: any) => {
  Object.values(properties).forEach((propertyValues: any) => {
    propertyValues.locked = true
  })
}

export enum MappingErrorTypes {
  NEW_PROPERTY_ERROR
}

export const MappingErrorText = {
  [MappingErrorTypes.NEW_PROPERTY_ERROR]: 'You can only add one new mapping field at a time.  Please edit the existing new field.'
}

const DynamicListsStore = types.model({
  patronDatabaseMetaData: types.maybeNull(PatronDatabaseMetaData),
  audienceListIndexName: types.maybeNull(types.string),
  lists: types.maybeNull(types.array(PatronList)),
  list: types.maybeNull(PatronList),
  patronDatabaseExists: types.maybeNull(types.boolean),
  mappingError: types.array(types.number), 
  modificationsMade: types.optional(types.boolean, false),
  saveListFlag: types.optional(types.boolean, false),
  enumeratedPropertyNames: types.array(types.string),
  apiStatus: ApiStatusStore
}).volatile(() => ({
  filterDefinition: {},
  initialFilterDefinition: {},
  enumeratedProperties: {}
})).actions(self => {
  const { notificationStore, customerStore, api } = getEnv(self)
  /* 
    Patron Database Meta Data
  */
  const clearMappingError = (error: MappingErrorTypes) => {
    if (self.mappingError.includes(error)) {
      self.mappingError = cast(self.mappingError.filter(innerError => innerError === error))
    }
  }
  const setModificationsMade = (status: boolean) => {
    self.modificationsMade = status
  }
  const clearListValues = () => {
    self.list = null
    self.filterDefinition = {}
    self.initialFilterDefinition = {}
    self.patronDatabaseMetaData = null
    self.saveListFlag = false
  }
  const createPatronDatabase = flow(function* (callback: () => void) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_PATRON_DATABASE)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.CREATE_PATRON_DATABASE_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.CREATE_PATRON_DATABASE_SUCCESS)
      const createPatronDatabaseResponse = yield api.createPatronDatabase(customerStore.setCustomerId)
      const parsedResponse = JSON.parse(createPatronDatabaseResponse.data.schema.replace(/(\r\n|\n|\r)/gm,""))
      const updatedProperties = createPropertyNameValue(parsedResponse.properties)
      self.patronDatabaseMetaData = parsedResponse
      self.patronDatabaseMetaData!.initialProperties = updatedProperties
      self.patronDatabaseMetaData!.properties = updatedProperties
      setModificationsMade(false)
      callback()
      notificationStore!.addNotification('success', 'Your patron database has been created.  Begin editing it below:', notificationTypeKeys.CREATE_PATRON_DATABASE_SUCCESS)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.CREATE_PATRON_DATABASE_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_PATRON_DATABASE)
  })
  
  const getPatronDatabase = flow(function* () {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_PATRON_DATABASE)
    try {
      clearListValues()
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_PATRON_DATABASE_ERROR)
      const getPatronDatabaseResponse = yield api.getPatronDatabase(customerStore.setCustomerId)
      const parsedResponse = JSON.parse(getPatronDatabaseResponse.data.schema.replace(/(\r\n|\n|\r)/gm,""))
      const updatedProperties = createPropertyNameValue(parsedResponse.properties)
      self.patronDatabaseMetaData = parsedResponse
      self.patronDatabaseMetaData!.initialProperties = updatedProperties
      self.patronDatabaseMetaData!.properties = updatedProperties
      self.patronDatabaseExists = true
      self.audienceListIndexName = getPatronDatabaseResponse.data.audienceListIndexName
      setModificationsMade(false)
    } catch (error) {
      console.log(error)
      // @ts-ignore
      if (error.status === 400 && error.data.includes("Patron Database does not exist for this customer")) {
        self.patronDatabaseExists = false
      } else {
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_PATRON_DATABASE_ERROR)
      }
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_PATRON_DATABASE)
  })
  const updatePatronDatabase = flow(function* () {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_PATRON_DATABASE)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.UPDATE_PATRON_DATABASE_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.UPDATE_PATRON_DATABASE_SUCCESS)
      // @ts-ignore
      const { initialProperties, properties, ...rest } = self.patronDatabaseMetaData
      
      try {
        checkForEmptyAttributeAndOrDuplicate(properties, 'mapping', true)
        checkForEmptyAttributeAndOrDuplicate(properties, 'property', true)
        checkForEmptyAttributeAndOrDuplicate(properties, 'type', false)
      } catch (error) {
        notificationStore!.addNotification('error', `${(error as any).message}`, notificationTypeKeys.UPDATE_PATRON_DATABASE_ERROR)
        return
      }

      const updatedProperties = createPropertiesObjectForSaving(properties)
      const updatedNested = Object.entries(updatedProperties)
        .filter(([_, value] : any) => value.type === 'object').map(([key, _]) => key)
      const schema = { ...rest, properties: updatedProperties }
      const addedFields: string[] = getUniqueItemsInComparedArray(Object.keys(initialProperties), Object.keys(updatedProperties)).concat(updatedNested)

      yield api.updatePatronDatabase(customerStore.setCustomerId, JSON.stringify(schema), addedFields)
      notificationStore!.addNotification('success', 'Your patron database mapping updates have been saved.', notificationTypeKeys.SAVE_COMMUNICATION_PREFERENCES_SUCCESS)
      setModificationsMade(false)
      lockAllProperties(properties)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.UPDATE_PATRON_DATABASE_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_PATRON_DATABASE)
  })
  const updatePropertyValue = (value: string | boolean, elasticField: string, propertyName: string, nestedFieldName : string | null = null) => {
    let objectToModify = self.patronDatabaseMetaData!.properties[elasticField]
    if (objectToModify.type === 'object') {
      const currentObject = objectToModify.properties[nestedFieldName]
      if (propertyName === 'type' && value === 'date') {
        currentObject[propertyName] = 'string'
        currentObject.format = 'date-time'
      } else {
        currentObject[propertyName] = value
      }

      objectToModify.properties = { ...objectToModify.properties, [nestedFieldName!]: currentObject }
    } else {
      if (propertyName === 'type' && value === 'date') {
        objectToModify[propertyName] = 'string'
        objectToModify.format = 'date-time'
      } else {
        objectToModify[propertyName] = value
      }
    }
    
    self.patronDatabaseMetaData!.properties = { ...self.patronDatabaseMetaData!.properties, [elasticField]: objectToModify }
    setModificationsMade(true)
  }
  const addNewRootNestedProperty = (nestedFieldName : string) => {
    const newProperty = {
      type: 'object',
      properties: {}
    }

    self.patronDatabaseMetaData!.properties = { ...self.patronDatabaseMetaData!.properties, [nestedFieldName]: newProperty }
   
    setModificationsMade(true)
  }
  const newProperty = (nestedFieldName : string | null = null) => {
    // if (addMappingError(MappingErrorTypes.NEW_PROPERTY_ERROR)) return
    const newProperty = {
      mapping: '',
      type: '',
      description: 'A new field to be mapped'
    }

    const mappingFieldName = `newMappingField_${uuidv4()}`
    if (nestedFieldName == null) {
      self.patronDatabaseMetaData!.properties = { ...self.patronDatabaseMetaData!.properties, [mappingFieldName]: newProperty }
    } else {
      const nestedProperty = self.patronDatabaseMetaData!.properties[nestedFieldName]
      nestedProperty.properties = { ...nestedProperty.properties, [mappingFieldName]: newProperty }
      self.patronDatabaseMetaData!.properties = { ...self.patronDatabaseMetaData!.properties, [nestedFieldName]: nestedProperty }
    }
    clearMappingError(MappingErrorTypes.NEW_PROPERTY_ERROR)
    setModificationsMade(true)
  }
  /*
    Lists
  */
  const getPatronLists = flow(function* () { 
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_PATRON_LISTS)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_PATRON_LISTS_ERROR)
      const getPatronLists = yield api.getPatronLists(customerStore.setCustomerId)
      self.lists = getPatronLists.lists
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_PATRON_LISTS_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_PATRON_LISTS)
  })
  const createPatronList = flow(function* (name: string, callback: () => void) { 
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_PATRON_LISTS)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.CREATE_PATRON_LISTS_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.CREATE_PATRON_LISTS_SUCCESS)
      yield api.createPatronList(customerStore.setCustomerId, name)
      notificationStore!.addNotification('success', 'Your patron list has been created.', notificationTypeKeys.CREATE_PATRON_LISTS_SUCCESS)
      callback()
      getPatronLists()
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.CREATE_PATRON_LISTS_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_PATRON_LISTS)
  })
  const getPatronList = flow(function* (listId: number) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_PATRON_LIST)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_PATRON_LIST_ERROR)
      const getPatronList = yield api.getPatronList(customerStore.setCustomerId, listId)
      self.enumeratedProperties = getPatronList.enumeratedProperties
      self.enumeratedPropertyNames = Object.keys(getPatronList.enumeratedProperties) as any
      self.list = { id: getPatronList.id, name: getPatronList.name, previewCount: getPatronList.previewCount, previewCountUpdating: getPatronList.previewCountUpdating, previewUpdatedTimestamp: getPatronList.previewUpdatedTimestamp }
      self.filterDefinition = JSON.parse(getPatronList.filterDefinition)
      self.initialFilterDefinition = JSON.parse(getPatronList.filterDefinition)
      self.patronDatabaseMetaData = JSON.parse(getPatronList.schema.replace(/(\r\n|\n|\r)/gm,""))
      self.patronDatabaseMetaData!.properties = JSON.parse(getPatronList.schema.replace(/(\r\n|\n|\r)/gm,"")).properties
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_PATRON_LIST_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_PATRON_LIST)
    return self.initialFilterDefinition
  })
  const updateFilterDefinition = (filterDefinition: any) => {
    if (filterDefinition && !(isEqual(filterDefinition, self.filterDefinition))) {
      self.saveListFlag = true
      self.filterDefinition = filterDefinition
    }
  }
  const savePatronListFilterDefinition = flow(function* (listId: number) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SAVE_PATRON_LIST_FILTER_DEFINITION)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.SAVE_PATRON_LIST_FILTER_DEFINITION_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.SAVE_PATRON_LIST_FILTER_DEFINITION_SUCCESS)
      yield api.savePatronListFilterDefinition(customerStore.setCustomerId, listId, JSON.stringify(self.filterDefinition))
      notificationStore!.addNotification('success', 'Your patron list filter definition has been saved.', notificationTypeKeys.SAVE_PATRON_LIST_FILTER_DEFINITION_SUCCESS)
      self.saveListFlag = false
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.SAVE_PATRON_LIST_FILTER_DEFINITION_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SAVE_PATRON_LIST_FILTER_DEFINITION)
  })
  const deleteDynamicList = flow(function*(dynamicListId: number, callback: () => void) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.DELETE_DYNAMIC_LIST)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.DELETE_DYNAMIC_LIST_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.DELETE_DYNAMIC_LIST_SUCCESS)
      yield api.deleteDynamicList(customerStore.setCustomerId, dynamicListId)
      notificationStore!.addNotification('success', 'The dynamic list was successfully deleted.', notificationTypeKeys.DELETE_DYNAMIC_LIST_SUCCESS)
      getPatronLists()
      callback()
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.DELETE_DYNAMIC_LIST_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.DELETE_DYNAMIC_LIST)
  })
  
  return {
    createPatronDatabase,
    getPatronDatabase,
    updatePropertyValue,
    newProperty,
    addNewRootNestedProperty,
    updatePatronDatabase,
    getPatronLists,
    createPatronList,
    getPatronList,
    updateFilterDefinition,
    savePatronListFilterDefinition,
    deleteDynamicList
  }
}).views(self =>({
  getDisabled(fieldName: string): boolean {
    return self.patronDatabaseMetaData!.required.find(field => field === fieldName) ? true : false
  },
  get filterDefinitionModificationFlag() {
    if (isEqual(self.filterDefinition, self.initialFilterDefinition)) {
      return true
    }
    return false
  }
}))

export type IDynamicListsStore = typeof DynamicListsStore.Type
export default DynamicListsStore