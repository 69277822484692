import React, { Component } from 'react'
import { ICustomerStore } from '../../../stores/CustomerStore'
import { IReportStore } from '../../../stores/ReportStore'
import { observer } from 'mobx-react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { isEven } from '../../../utils/generalHelpers'
import { failedToolTips } from '../../pages/Reports/Reports.copy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import * as styled from './DataTableOverView.styled'
import ToolTip from '../shared/ToolTip'
import Button from "@material-ui/core/Button"
import { CSVLink } from 'react-csv'
import { sentenceCaseObjectAttributes } from 'utils/generalHelpers'
import { format } from 'date-fns'
import { toJS } from 'mobx'
import { startCase } from 'lodash'

interface DataTableProps {
  customerStore: ICustomerStore
  reportStore: IReportStore,
}

interface AvaiableChannels {
  all: boolean,
  sms: boolean,
  email: boolean,
  voice: boolean,
  mail: boolean
}

const RenderEmailOrPhoneLabel: React.FC<AvaiableChannels> = ( channels: AvaiableChannels) => {
  if (channels.email && (channels.sms || channels.voice)) {
    return (
      <>
        Email/Phone
      </>
    )
  } else if (channels.email && !(channels.sms || channels.voice)) {
    return (
      <>
        Email
      </>
    )
  } else if (channels.sms || channels.voice) {
    return (
      <>
        Phone
      </>
    )
  } else {
    return (
      <>
      </>
    )
  } 
}

interface FailureReportRow { 
  date: string
  email: string
  phone: string
  barcode: string
  reason: string
  notificationName?: string
}

@observer
class DataTableOverView extends Component<DataTableProps> {
  public render() {
    let rows = toJS(this.props.reportStore.bounces)
    rows.forEach((row: FailureReportRow) => {
      row.date = format(new Date(row.date), "MM/DD/YYYY hh:mm A")
      row["Notification Name"] = row.notificationName
      delete row.notificationName
    })
    rows.sort((a: FailureReportRow, b: FailureReportRow) => {
      let dateA = new Date(a.date).getTime()
      let dateB = new Date(b.date).getTime()
      return dateA < dateB ? -1 : 1
    })
    
    return (
      <>
        {rows && rows.length > 0 && (
          <styled.DownloadReportContainer>
            <CSVLink
              data={sentenceCaseObjectAttributes(rows)}
              filename={`message-bee-failure-report.csv`}
            >
              <Button style={{ marginTop: "-4px", marginRight: 20 }} variant="outlined" color="secondary">
              Download Failures Report
                <FontAwesomeIcon size='1x' style={{ marginLeft: 5, marginTop: 2 }} icon={faFileDownload} /> 
              </Button>
            </CSVLink>  
          </styled.DownloadReportContainer>
        )}

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="left">
                <styled.DataTableHeaderContainer>{RenderEmailOrPhoneLabel(this.props.reportStore.communicationChannelStore.availableChannels)} <ToolTip titleText={failedToolTips['FAILED']} /></styled.DataTableHeaderContainer>
              </TableCell>
              <TableCell align="left">
                <styled.DataTableHeaderContainer>Barcode</styled.DataTableHeaderContainer>
              </TableCell>
              <TableCell align="left">
                <styled.DataTableHeaderContainer>Reason <ToolTip titleText={failedToolTips['REASON']} /></styled.DataTableHeaderContainer>
              </TableCell>
              <TableCell align="left">
                <styled.DataTableHeaderContainer>Notification Name</styled.DataTableHeaderContainer>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.map((failure: any, i: number) => {
              console.log(failure)
              const isRowShaded = isEven(i)
              return (
                <TableRow key={`dataTable_${i}`} style={ isRowShaded ? { backgroundColor: 'rgba(0, 0, 0, 0.05)' } : {} }>
                  <TableCell align="left" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>{failure.date}</TableCell>
                  <TableCell align="left" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>{failure.email || failure.phone}</TableCell>
                  <TableCell align="left" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>{failure.barcode}</TableCell>
                  <TableCell align="left" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>{failure.reason}</TableCell>
                  <TableCell align="left" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>{failure["Notification Name"]}</TableCell>
                </TableRow>
              )}
            )}
          </TableBody>
        </Table>
      </>
    )
  }
}

export default DataTableOverView
