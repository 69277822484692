import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './ImageSelection.styled'
import { ITemplateCustomizationStore } from '../../../../stores/TemplateCustomizationStore'
import TemplateImagePlusButton from './TemplateImagePlusButton'
import { format } from 'date-fns'
import { apiStatusDefinitions } from '../../../../utils/apiStatusDefinitions'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

interface MediaLibraryContainerProps {
  templateCustomizationStore?: ITemplateCustomizationStore,
  admin?: boolean
}

@inject((allStores : any) => {
  return {
    templateCustomizationStore: allStores.templateCustomizationStore as ITemplateCustomizationStore
  }
})
@observer
class ImageSelection extends Component<MediaLibraryContainerProps> {
  public state = {
    activeTile: null
  }

  public componentDidMount () {
    this.props.templateCustomizationStore!.retrieveTemplateImages()
  }

  public render () {
    return (
      <div>
        <styled.ImageSelectionContainer>
          <styled.Title>
            Template Image Selection
          </styled.Title>
          <styled.SubTitle>
            Click on a container to begin editing.
          </styled.SubTitle>
          {this.props.templateCustomizationStore!.apiStatus.checkApiStatus(apiStatusDefinitions.RETRIEVE_TEMPLATE_IMAGES) ? (
            <styled.ImagesLoader>
              <CircularProgress style={{ marginBottom: 20, marginTop: '-60px', marginRight: 44 }} color="secondary" />
              <styled.LoadingText>Loading, please wait.</styled.LoadingText>
            </styled.ImagesLoader>
          ) : (
            <styled.Images>
              {this.props.templateCustomizationStore!.templateImageData.length ? (
                <>
                {this.props.templateCustomizationStore!.templateImageData.map(item => {
                  const activeConfiguration = item.templateImageConfigurations.find(configuration => new Date(configuration.startDate) <= new Date() && new Date(configuration.endDate) >= new Date())
                  
                  let image
                  let date
                  if (activeConfiguration) {
                    image = activeConfiguration.mediaItem
                    date = format(activeConfiguration.endDate as any, 'MM/DD/YYYY')
                  } else {
                    image = item.fallbackMediaItem
                    date = 'Indefinitely' 
                  }

                  return (
                    <styled.TemplateImageItem key={`template_image_${item.id}`} onMouseOver={() => this.setState({ activeTile: item.id })} onClick={() => this.props.templateCustomizationStore!.setSelectedTemplateImage(item.id)}>
                      <styled.VariableName>
                        {item.variableName}
                      </styled.VariableName>
                      <styled.PreviewImageContainer>
                        {image ? (
                          <img style={{ maxWidth: '100%', maxHeight: '100%', zIndex: 1 }} src={image.previewPath} />
                        ) : (
                          <>
                            Image Undefined
                          </>
                        )}
                        
                      </styled.PreviewImageContainer>
                      <styled.FriendlyName>{item.friendlyName}</styled.FriendlyName>
                      <styled.EffectiveUntil>
                        {image ? (
                          <>
                            Effective Until: {date}
                          </>
                        ) : (
                          <>
                            Undefined Date
                          </>
                        )}
                      </styled.EffectiveUntil>
                    </styled.TemplateImageItem>
                  )
                })}
                </>
              ) : (
                <>
                  There are currently no template images.  Please ask your admin to add some.
                </>
              )}
            </styled.Images>
          )}
        </styled.ImageSelectionContainer> 
        {this.props.templateCustomizationStore!.admin && (
          <TemplateImagePlusButton redirectFunction={this.props.templateCustomizationStore!.setTemplateImageFunctionState} location="ADD_TEMPLATE_IMAGE" />
        )} 
      </div>
    )
  }
}

export default ImageSelection