import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { IMediaLibraryStore } from '../../../../stores/MediaLibraryStore'
import ImageGallery from './ImageGallery'
import UploadDialogue from './UploadDialogue'
import EditDialogue from './EditDialogue'

interface MediaLibraryContainerProps {
  mediaLibraryStore?: IMediaLibraryStore
}

const mediaLibraryContent = {
  IMAGE_GALLERY: <ImageGallery />,
  REVOLV_IMAGE_GALLERY_WITH_PASSTHROUGH: <ImageGallery passthrough />,
  UPLOAD: <UploadDialogue />,
  EDIT: <EditDialogue />
}

@inject((allStores : any) => {
  return {
    mediaLibraryStore: allStores.mediaLibraryStore as IMediaLibraryStore
  }
})
@observer
class MediaLibraryContainer extends Component<MediaLibraryContainerProps> {
  public render () {
    return (
      <>
        {mediaLibraryContent[this.props.mediaLibraryStore!.mediaLibraryFunctionState]}
      </>
    )
  }
}

export default MediaLibraryContainer