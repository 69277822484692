import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import * as styled from './DropDowns.styled'
import { IReportStore } from '../../../../stores/ReportStore'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { timezoneValues } from '../../../pages/Reports/Reports.copy'
import { observer } from 'mobx-react'

interface TimezoneProps {
  reportStore: IReportStore
}

@observer
class Timezone extends Component<TimezoneProps> {
  public state = {
    labelWidthTimezone: 0
  }
  
  private InputLabelRefTimezone: any

  public componentDidMount () {
    this.setState({
      labelWidthTimezone: (ReactDOM.findDOMNode(this.InputLabelRefTimezone) as any).offsetWidth
    })
  }

  private handleChange = (event: any) => {
    this.props.reportStore.setTimezoneOffset(event.target.value)
  }

  public render () {
    return (
      <>
        <styled.NotificationTypeContainer style={{ display: 'none' }}>
          <FormControl style={{ width: '95%', marginRight: 20, marginBottom: 10 }} variant="outlined">
            <InputLabel
              ref={ref => {
                this.InputLabelRefTimezone = ref
              }}
              htmlFor={`outlined-timezone-simple`}
            >
              Select a Timezone
            </InputLabel>
            <Select
              value={this.props.reportStore.filters.timezone}
              onChange={this.handleChange}
              placeholder='Select a Timezone...'
              autoWidth
              style={{ minWidth: 250, width: '100%', backgroundColor: 'rgba(255, 255, 255, 0)', textAlign: 'left' }}
              input={<OutlinedInput placeholder='Select a Timezone' labelWidth={this.state.labelWidthTimezone} name='timezone' id={`outlined-timezone-simple`} />}
            >
              {timezoneValues.map(timezone => {
                return (
                  <MenuItem key={`default_timezone_channel_${timezone.value}`} value={timezone.value}>
                    {timezone.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>  
        </styled.NotificationTypeContainer>
      </>
    )
  }
}

export default Timezone
