import React from 'react'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

interface UploadButtonProps {
  uploadMediaLibraryFunction: any
}

const UploadButton: React.SFC<UploadButtonProps> = ({ uploadMediaLibraryFunction }: UploadButtonProps) => {
  return (
    <Fab style={{ position: 'absolute', right: 18, bottom: 16, color: 'white' }} color="secondary" onClick={() => uploadMediaLibraryFunction('UPLOAD')} aria-label="add">
      <AddIcon />
    </Fab>
  )
}

export default UploadButton
