import { api } from './axios'
import { apiHelper } from '../utils/apiHelper'

export const getFailedFileNotifiers = async () => {
  const request = await apiHelper<any>(() => api.get('/failed-file-notifiers'))
  return request.data
}

export const addFailedFilesNotifier = async (failedFileNotifierId : number) => {
  await apiHelper<any>(() => api.post(`/failed-file-notifiers/${failedFileNotifierId}`))
}

export const removeFailedFilesNotifier = async (failedFileNotifierId : number) => {
  await apiHelper<any>(() => api.delete(`/failed-file-notifiers/${failedFileNotifierId}`))
}

export const getAdminAccounts = async () => {
  const request = await apiHelper<any>(() => api.get('/account/admins'))
  return request.data
}

export const getFailedFiles = async () => {
  const request = await apiHelper<any>(() => api.get('/failed-files'))
  return request.data
}

export const getFailedFile = async (failedFileId : number) => {
  const request = await apiHelper<any>(() => api.get(`/failed-files/${failedFileId}`))
  return request.data
}

export const requeueFaileFile = async (failedFileId : number, fileText : string) => {
  const request = await apiHelper<any>(() => api.post(`/failed-files/${failedFileId}`, { fileText }))
  return request.data
}

export const removeFailedFile = async (failedFileId : number) => {
  const request = await apiHelper<any>(() => api.delete(`/failed-files/${failedFileId}`))
  return request.data
}
