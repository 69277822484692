import { api } from './axios'
import { apiHelper } from '../utils/apiHelper'
import { getTimezoneCode, getUtcOffset } from '../utils/generalHelpers'

export const performDetailedSearch = async (searchType: string, searchTerm: string, startDate: string, endDate: string, customerId: number, filteredNotificationSubTypes?: string) => {
  const timezone = getTimezoneCode()
  const utcOffset = getUtcOffset()
  const url = `/report/search?${searchType}=${searchTerm}&startDate=${startDate}&endDate=${endDate}&customerId=${customerId}&utcOffset=${utcOffset}&timezone=${timezone}&notificationTypes=${filteredNotificationSubTypes}`
  const getDetailedSearchData = await apiHelper<any>(() => api.get(url))
  return getDetailedSearchData.data.items
}