import React, { Component } from 'react'
import { ICustomerData } from '../../../../models/CustomerData'
import FormControl from '@material-ui/core/FormControl'
import * as styled from './CustmerDropDown.styled'
import orderBy from 'lodash/orderBy'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from "@material-ui/core/TextField"
import {
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import CircularProgress from '@material-ui/core/CircularProgress'


interface CustomerDropDownProps {
  customers?: ICustomerData[],
  customerApiCallStatus: boolean,
  setSelectedCustomerFunction: (customerId: number) => void,
  resetTemplates: () => void,
  setCustomerId?: number,
  resetListCommunications: () => void
  foundCustomer?: ICustomerData
}

const theme = createTheme({
  palette: {
    primary: {
      main: `rgba(255, 255, 255, 1)`,
    },
    secondary: {
      main: `rgba(255, 255, 255, 1)`,
    },

  },
})

class CustomerDropDown extends Component<CustomerDropDownProps> {
  public state = {
    inputValue: null
  }

  private handleChange = (id: number) => {
    this.props.resetTemplates()
    this.props.setSelectedCustomerFunction(id)
    this.props.resetListCommunications()
  }

  public render() {
    const orderedCustomers = orderBy(this.props.customers!, 'name', 'asc')
    let customerValue = {}
    let customerInputValue = ''
    const foundCustomer = this.props.foundCustomer

    if (foundCustomer) {
      customerValue = foundCustomer
      customerInputValue = this.state.inputValue || foundCustomer.name
    }

    if (this.props.customerApiCallStatus && !(this.props.customers!.length > 0)) {
      return (
        <ThemeProvider theme={theme}>
          <CircularProgress size="30px" style={{ marginLeft: 10 }} color="primary"/>
        </ThemeProvider>
      )
    } else {
      if (this.props.customers!.length === 1) {
        return <styled.CustomerTitle>{this.props.customers![0].name}</styled.CustomerTitle>
      } else {
        return (
          <ThemeProvider theme={theme}>
            <FormControl>
              <Autocomplete
                id="customer-combo-box"
                color="primary"

                value={customerValue}
                inputValue={customerInputValue}
                onChange={(event: any, newValue: any) => {
                  if (newValue) {
                    this.handleChange(newValue.id)
                  } 
                }}
                onInputChange={(event, newInputValue) => {
                  this.setState({ inputValue: newInputValue })
                }}
                  
                options={orderedCustomers}
                // @ts-ignore
                getOptionLabel={(option) => option.name}
                style={{ width: 250, borderBottom: 0, textIndent: 0, }}
                renderInput={(props) =>
                  <TextField
                    {...props}
                    color="primary"
                    style={{ textIndent: 8 }}
                    InputProps={{ style: { color: 'white', paddingLeft: 8 }, ...props.InputProps }}
                    InputLabelProps={{ style: { color: 'rgba(255, 255, 255, 0.85)' }, ...props.InputLabelProps }}
                    label="Customer"
                  />
                }
              />
            </FormControl>
          </ThemeProvider>
        )
      } 
    }
    
  }
}

export default CustomerDropDown
