import styled from 'styled-components'

export const EagerLoadingContainer = styled.div`
  width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const EagerLoadingText = styled.div`
  font-size: 12px;
  margin-left: 8px;
  color: #969696;
`