import { types } from 'mobx-state-tree'

const GeographicReportDataCoordinates = types.model('ReportDataPoint', {
  longitude: types.string,
  latitude: types.string
})

const GeographicReportData = types.model('GeographicReportData', {
  id: types.string, 
  name: types.string,
  location: types.string,
  time: types.string,
  dateCreated: types.string,
  dateModified: types.string,
  object: types.string,
  locationCoordinates: types.maybeNull(GeographicReportDataCoordinates)
})

export type IGeographicReportData = typeof GeographicReportData.Type
export default GeographicReportData