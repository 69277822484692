import React, { Component, useMemo, useRef } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './AddOrEditFallbackLinkUrl.styled'
import { ITemplateCustomizationStore } from '../../../../stores/TemplateCustomizationStore'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import JoditEditor from 'jodit-react'
import { InputLabel } from '@material-ui/core'

interface MediaLibraryContainerProps {
  templateCustomizationStore?: ITemplateCustomizationStore
}

@inject((allStores: any) => {
  return {
    templateCustomizationStore: allStores.templateCustomizationStore as ITemplateCustomizationStore
  }
})
@observer
class AddOrEditFallbackLinkUrl extends Component<MediaLibraryContainerProps> {
  public constructor(props: MediaLibraryContainerProps) {
    super(props)

    this.determineFallbackLinkUrl = this.determineFallbackLinkUrl.bind(this)
  }

  public state = {
    form: {
      fallbackLinkUrl: '',
      fallbackHtml: ''
    },
    formErrors: {
      fallbackLinkUrl: false,
    },
    saveActive: false
  }

  public componentDidMount = () => {
    if (this.props.templateCustomizationStore && this.props.templateCustomizationStore!.selectedTemplateImageId && this.props.templateCustomizationStore!.templateImageFunctionType === 'EDIT') {
      const image = this.props.templateCustomizationStore!.templateImageData.find(image => image.id === this.props.templateCustomizationStore!.selectedTemplateImageId)
      if (this.state.form.fallbackLinkUrl.length === 0 && image!.fallbackLinkUrl) {
        const form = this.state.form

        const updatedForm = {
          fallbackLinkUrl: image!.fallbackLinkUrl,
          fallbackHtml: image!.fallbackHtml || ''
        }

        this.setState({ form: { ...form, ...updatedForm } })
      }
    }
  }

  private handleChange = (name: string) => (event: any) => {
    const form = this.state.form

    const updatedForm = {
      [name]: event.target.value
    }

    this.setState({ form: { ...form, ...updatedForm } })

  }

  private checkFieldsAndSubmit = () => {
    const formErrors = this.state.formErrors
    console.log(this.state.form)
    if (this.state.form.fallbackLinkUrl.length < 1) {
      formErrors.fallbackLinkUrl = true
    } else {
      formErrors.fallbackLinkUrl = false
    }

    if (this.state.form.fallbackLinkUrl.length > 0) {
      this.setState({ formErrors: formErrors })
      this.props.templateCustomizationStore!.updateFallbackLinkUrlAndOrHtml(this.state.form.fallbackLinkUrl, this.state.form.fallbackHtml.length > 0 ? this.state.form.fallbackHtml : undefined)

    } else {
      this.setState({ formErrors: formErrors })
      return
    }
  }

  private determineFallbackLinkUrl = () => {
    let linkUrl = ''
    let html = ''

    if (this.state.form.fallbackLinkUrl.length > 0) {
      linkUrl = this.state.form.fallbackLinkUrl
    }

    //if (this.state.form.fallbackHtml.length > 0) {
    html = this.state.form.fallbackHtml
    // }

    if (this.props.templateCustomizationStore && this.props.templateCustomizationStore!.selectedTemplateImageId && this.props.templateCustomizationStore!.templateImageFunctionType === 'EDIT') {
      const image = this.props.templateCustomizationStore!.templateImageData.find(image => image.id === this.props.templateCustomizationStore!.selectedTemplateImageId)
      if (this.state.form.fallbackLinkUrl.length === 0 && image!.fallbackLinkUrl) {
        linkUrl = image!.fallbackLinkUrl
      }

      // if (this.state.form.fallbackHtml.length === 0 && image!.fallbackHtml) {
      //   html = image!.fallbackHtml
      // }
    }
    return { linkUrl, html }
  }

  public render() {

    const fallbackData = this.determineFallbackLinkUrl()
    return (
      <styled.AddTemplateImageContainer>
        <styled.AddTemplateImageForm>
          <styled.Label>
            {this.props.templateCustomizationStore!.templateImageFunctionType === 'EDIT' ? (
              <>
                Modify the text fields below in order to change the fallback link url or fallback HTML:
              </>
            ) : (
              <>
                Enter a fallback link URL or some fallback HTML to change the presentation or click destination of the associated image
              </>
            )}
          </styled.Label>
          <styled.FormElementWrapper>
            <TextField
              style={{ width: '98%', marginBottom: 10, marginTop: '-6px' }}
              onChange={this.handleChange('fallbackLinkUrl')}
              type='text'
              name='description'
              fullWidth
              value={fallbackData.linkUrl}
              placeholder='Enter a link url for this fallback image'
              label='Enter a link url for this fallback image'
              margin="normal"
              variant="outlined"
              required
              error={this.state.formErrors.fallbackLinkUrl}
              helperText={this.state.formErrors.fallbackLinkUrl ? "You must enter a link url for this fallback image before continuing." : null}
            />
          </styled.FormElementWrapper>
          <styled.FormElementWrapper>
            <InputLabel>
              Fallback HTML
            </InputLabel>
            <Editor
              value={fallbackData.html}
              onChange={(content: string) => this.setState({ form: { ...this.state.form, fallbackHtml: content } })}
            />
          </styled.FormElementWrapper>
          <styled.FormButtonContainer>
            <Button onClick={() => this.props.templateCustomizationStore!.setTemplateImageFunctionState('IMAGE_DETAIL')} variant='contained' color='primary' style={{ marginRight: 10 }}>
              Cancel
            </Button>
            <Button onClick={this.checkFieldsAndSubmit} variant='contained' color='primary'>
              {this.props.templateCustomizationStore!.templateImageFunctionType === 'EDIT' ? (
                <>
                  Save Edits
                </>
              ) : (
                <>
                  Save Fallback Link and/or HTML
                </>
              )}
            </Button>
          </styled.FormButtonContainer>
        </styled.AddTemplateImageForm>
      </styled.AddTemplateImageContainer>
    )
  }
}

export default AddOrEditFallbackLinkUrl

const config = {
  // readonly: false, // all options from https://xdsoft.net/jodit/doc/
  useSplitMode: true,
  // removeButtons: ["file", "outdent", "selectall", "print", "about", "video"],
}

function Editor(props: any) {
  const { value, onChange } = props
  const editor = useRef(null)

  return (
    <>
      <JoditEditor
        ref={editor}
        // @ts-ignore
        config={config}
        value={value}
        onChange={(content: string) => onChange(content)}
      />
    </>
  )
}