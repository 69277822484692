import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './CustomerTaskConfiguration.styled'
import { ITaskStore } from '../../../../stores/TaskStore'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TaskAddButton from './TaskAddButton'
import { apiStatusDefinitions } from '../../../../utils/apiStatusDefinitions'
import EagerUpdatingLoader from '../../shared/Loading/EagerUpdatingLoader'
import ButtonLoading from '../../shared/Loading/ButtonLoading'

interface MediaLibraryContainerProps {
  taskStore?: ITaskStore
}


@inject((allStores : any) => {
  return {
    taskStore: allStores.taskStore as ITaskStore
  }
})
@observer
class CustomerTaskConfiguration extends Component<MediaLibraryContainerProps> {
  public state = {
    newConfigItem: ''
  }

  public componentDidMount = () => {
    this.props.taskStore!.retrieveFailedNotificationReportConfig()
  }

  private handleChange = (event: any) => {
    this.setState({ newConfigItem: event.target.value })
  }

  private cancelNewConfigurationAddition = () => {
    this.props.taskStore!.setTaskModalFunctionType('EDIT')
    this.setState({ newConfigItem: '' })
  }

  private submitNewConfig = () => {
    this.props.taskStore!.createNewFailedNotificationReportConfig(this.state.newConfigItem)
    this.setState({ newConfigItem: '' })
  }

  public render () {
    return (
      <styled.CustomerTaskConfigDialogueWrapper>
        <div>
          <styled.Title>
            {this.props.taskStore!.getCustomerName()} {this.props.taskStore!.getTaskName(this.props.taskStore!.selectedTask!)} Schedulings:
          </styled.Title>
          <styled.SubTitle>
            {this.props.taskStore!.taskModalFunctionType === 'EDIT' ? (
              <div>Click the + button in order to add a new {this.props.taskStore!.getTaskName(this.props.taskStore!.selectedTask!)} configuration for this customer.    
                {this.props.taskStore! && this.props.taskStore!.taskCustomerTimings && this.props.taskStore!.failedNotificationReportConfigs.length ? (
                  <>  Additionally, you can delete any of the existing ones.</>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div>After adding a new {this.props.taskStore!.getTaskName(this.props.taskStore!.selectedTask!)} please click the save button below.</div>
            )}
          </styled.SubTitle>
          {this.props.taskStore!.apiStatus.checkApiStatus(apiStatusDefinitions.GET_FNR_CONFIGS) ? (
            <styled.LoaderContainer>
              <CircularProgress color="secondary" />
              <styled.LoadingText>Loading, please wait.</styled.LoadingText>
            </styled.LoaderContainer>
          ) : (
            <>
              {this.props.taskStore! && this.props.taskStore!.taskCustomerTimings && this.props.taskStore!.failedNotificationReportConfigs.length ? (
                <>
                  <styled.TableRow>
                    <styled.ItemColumn />
                    <styled.ConfigColumn>
                      <strong>Configuration ID</strong>
                    </styled.ConfigColumn>
                  </styled.TableRow>
                  {this.props.taskStore! && this.props.taskStore!.taskCustomerTimings && this.props.taskStore!.failedNotificationReportConfigs.map((config, i) => {
                    return (
                      <styled.TableRow key={`${config.id}_table_row`}>
                        <styled.ItemColumn>
                          {i + 1}
                        </styled.ItemColumn>
                        <styled.ConfigColumn>
                          {config.notificationConfig}
                        </styled.ConfigColumn>
                        <styled.DeleteConfig onClick={() => this.props.taskStore!.deleteFailedNotificationReportConfig(config.id)}>
                          x
                        </styled.DeleteConfig>
                      </styled.TableRow>
                    )
                  })}
                </>
              ) : (
                <>
                </>
              )}
            </>
          )}
          {this.props.taskStore!.taskModalFunctionType === 'ADD' && (
              <>
                <styled.TableRow key={`new_table_row`}>
                  <styled.ItemColumn>
                    {this.props.taskStore!.failedNotificationReportConfigs.length + 1}
                  </styled.ItemColumn>
                  <styled.ConfigColumn>
                    <TextField
                      onChange={this.handleChange}
                      type='text'
                      name='new'
                      value={this.state.newConfigItem}
                      placeholder='Enter new configuration ID'
                      label='Enter new configuration ID'
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      key={`new_formField`}
                    />
                  </styled.ConfigColumn>
                </styled.TableRow>
                <styled.ButtonRow>
                  <Button onClick={this.cancelNewConfigurationAddition.bind(this)} variant='contained' style={{ marginRight: 10, color: 'white' }} color='secondary'>
                    Cancel
                  </Button>
                  <Button onClick={this.submitNewConfig.bind(this)} variant='contained' style={{ minWidth: 238 }} color='primary'>
                    {this.props.taskStore!.apiStatus.checkApiStatus(apiStatusDefinitions.CREATE_NEW_FAILED_NOTIFICATION_REPORT_CONFIG) ? (
                      <ButtonLoading />
                    ) : (
                      <>
                        Save New Configuration ID
                      </>
                    )}
                  </Button>
                </styled.ButtonRow>
              </>
          )}
          {this.props.taskStore!.taskModalFunctionType === 'EDIT' ? (
            <TaskAddButton taskAddButtonFunction={() => this.props.taskStore!.setTaskModalFunctionType('ADD')} />
          ) : (
            <div></div>
          )}
        </div>
        {this.props.taskStore!.apiStatus.checkApiStatus(apiStatusDefinitions.DELETE_FAILED_NOTIFICATION_REPORT_CONFIG) && (
          <EagerUpdatingLoader text='Updating...' />
        )}
      </styled.CustomerTaskConfigDialogueWrapper>    
    )
  }
}

export default CustomerTaskConfiguration