import styled from 'styled-components'

export const DataTableHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
`

export const ClickDetailContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 20px;
    margin-bottom: 6px;
    margin-top: 6px;
`

export const ClickDetailLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90px;
`

export const ClickDetailRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 400px;
`

export const ClickURLButtonAndUrlContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`

export const ExpansionButton = styled.div`
    margin-right: 10px;
    margin-top: 1px;
    border-radius: 50%;
    min-width: 14px;
    min-height: 14px;
    width: 14px;
    height: 14px;
    line-height: 17px;
    font-size: 10px;
    color: white;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #f58022;
    flex-grow: 0;
`

export const URLContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
`

export const EmailItem = styled.div`
    margin-bottom: 4px;
`

export const ClickReportNoteBold = styled.span`
    margin-right: 3px;
    font-style: bold;
`

export const CustomURLToolTipContainer = styled.div`
    position: absolute;
    bottom: 16px;
    left: 20px;
    width: 90%;
    margin-bottom: 20px;
    z-index: 99;
    word-wrap: break-word;
    background-color: #373737;
    padding: 20px;
    color: white;
    border-radius: 3px;
`

export const CopyTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 1px;
    margin-left: 8px;
`

export const CopyText = styled.div`
    font-size: 10px;
    margin-left: 3px;
`

export const DownloadReportContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

    & a {
        text-decoration: none;
    }
`