import styled from 'styled-components'

export const DataTableHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

export const Event = styled.div`
    margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
`

export const ExpandedDetailsContainer = styled.div`
    display: none;
`

export const ExpansionAndTimeContainer = styled.div`
    display: flex;
    flex-direction: row;
`

export const ExpansionButton = styled.div`
    margin-right: 10px;
    margin-top: 1px;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    line-height: 17px;
    font-size: 10px;
    color: white;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f58022;
`
