import React, { Component } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { observer, inject } from 'mobx-react'
import * as styled from './UploadCustomers.styled'
import { ITemplateStore } from '../../../../stores/TemplateStore'
import { IAudienceListsStore } from '../../../../stores/AudienceListsStore'
// import 'react-quill/dist/quill.snow.css'
import '../../../content/style/quill/theme.css'
import CircularProgress from '@material-ui/core/CircularProgress'
import { apiStatusDefinitions } from '../../../../utils/apiStatusDefinitions'
import Button from '@material-ui/core/Button'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { format, addMinutes } from 'date-fns'
import { DateTime } from 'luxon'
import LuxonUtils from '@date-io/luxon'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { IDynamicListsStore } from 'stores/DynamicLists'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Box } from '@material-ui/core'

interface EditTemplateCopyProps {
  templateStore?: ITemplateStore
  audienceListsStore?: IAudienceListsStore
  dynamicListsStore?: IDynamicListsStore
}

interface ScheduledDateProps {
  date: Date,
  handleDate(date: Date | null): void
}

const ScheduledDate: React.FC<ScheduledDateProps> = ({ date, handleDate }: ScheduledDateProps) => {
  return (
    <styled.DateContainer>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <styled.DateItems>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Date picker dialog"
            format="MM/dd/yyyy"
            value={date}
            onChange={handleDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </styled.DateItems>
        <styled.DateItems>
          <KeyboardTimePicker
            margin="normal"
            id="time-picker"
            label="Time picker"
            value={date}
            onChange={handleDate}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </styled.DateItems>
      </MuiPickersUtilsProvider>
    </styled.DateContainer>
  )
}

@inject((allStores: any) => {
  return {
    templateStore: allStores.templateStore as ITemplateStore,
    audienceListsStore: allStores.audienceListsStore as IAudienceListsStore,
    dynamicListsStore: allStores.dynamicListsStore as IDynamicListsStore
  }
})
@observer
class UploadCustomers extends Component<EditTemplateCopyProps> {
  public state = {
    copyForEditing: null,
    form: {
      fileName: ''
    },
    formErrors: {
      fileName: false
    },
    date: DateTime.now(),
    file: null,
    uploadScreen: false,
    audienceList: [],
    dynamicList: null,
    listType: false,
    dedupe: true,
    recurringExpanded: false,
    recurringFrequency: 'None',
    showPreview: false
  }

  public componentDidMount = () => {
    this.props.audienceListsStore!.getAudienceLists()
    this.props.dynamicListsStore?.getPatronLists()
  }

  private handleDate = (date: Date | null) => {
    console.log(date)
    if (date)
      this.setState({ date })
  }

  private checkFieldsAndSubmit = () => {
    const formErrors = this.state.formErrors
    if (this.state.file) {
      this.setState({ formErrors: formErrors })
      this.props.templateStore!.listMessageCustomerFileUpload(format(new Date(this.state.date), 'YYYY-MM-DD[T]HH:mm:ssZZ'), true, this.state.dedupe)
    } else {
      if ((!this.state.listType && this.state.audienceList) || (this.state.listType && this.state.dynamicList)) {
        this.props.templateStore!.listMessageCustomerFileUpload(this.state.date.toISO(), false, this.state.dedupe, this.state.audienceList, this.state.dynamicList, this.state.recurringFrequency)
      } else {
        this.setState({ formErrors: formErrors })
        return
      }
    }
  }

  private goToPreview = () => {
    this.setState({ showPreview: true })
  }

  private backFromPreview = () => {
    this.setState({ showPreview: false })
  }

  private onAudienceListChange = (ids: number[] | string) => {
    this.setState({ audienceList: typeof ids === 'string' ? ids.split(',') : ids, })
  }

  private onDynamicListChange = (id: number) => {
    if (id === 0) {
      this.setState({ dynamicList: null })
      return
    }
    this.setState({ dynamicList: id })
  }

  private handleListTypeChange = () => {
    this.setState({ listType: !this.state.listType })
  }

  private handleDedupe = (dedupe: boolean) => {
    this.setState({ dedupe })
  }

  private toggleExpandRecurring = () => {
    this.setState({ recurringExpanded: !this.state.recurringExpanded })
  }

  private setRecurringFrequency = (recurringFrequency: string) => {
    this.setState({ recurringFrequency })
  }


  public render() {
    if (this.state.showPreview)
      return (
        <styled.Container>
          <styled.Title>
            Summary of Scheduled Communication:
          </styled.Title>

          <styled.UploadDialogueContent style={{ marginLeft: '24px' }}>
            <styled.ListSendingStep>
              <styled.ListSendingStepNumber>
                1
              </styled.ListSendingStepNumber>
              <Typography variant="h6">Selected Lists:</Typography>
            </styled.ListSendingStep>
            {this.state.dynamicList != null &&
              <styled.ListSendingStep>
                <Typography component="div" style={{ marginLeft: 60 }}>
                  Dynamic List:
                </Typography>
                <Typography component="div" style={{ marginLeft: 60 }}>
                  {this.props.dynamicListsStore!.lists.find(list => list.id === this.state.dynamicList)?.name}
                </Typography>
              </styled.ListSendingStep>
            }
            {this.state.audienceList.length > 0 &&
              <styled.ListSendingStep>
                <Typography component="div" style={{ marginLeft: 60 }}>
                  Audience Lists:
                </Typography>
                <Typography component="div" style={{ marginLeft: 60 }}>
                  {
                    this.state.audienceList.map(id => {
                      return this.props.audienceListsStore!.audienceLists.find(list => list.id === id)
                    }).map(list => {
                      return <Typography key={list.id}>{list?.name}</Typography>
                    })
                  }
                </Typography>
              </styled.ListSendingStep>
            }
            <styled.ListSendingStep style={{ marginTop: '16px' }}>
              <styled.ListSendingStepNumber>
                2
              </styled.ListSendingStepNumber>
              <Typography variant="h6">Dedupe? {this.state.dedupe ? 'Yes' : 'No'}</Typography>
            </styled.ListSendingStep>
            <styled.ListSendingStep style={{ marginTop: '16px' }}>
              <styled.ListSendingStepNumber>
                3
              </styled.ListSendingStepNumber>
              <Typography variant="h6">Send on {this.state.date?.toLocaleString(DateTime.DATETIME_FULL)}</Typography>
            </styled.ListSendingStep>

          </styled.UploadDialogueContent>
          <styled.UploadDialogueForm>
            <styled.FormButtonContainer>
              <Button onClick={this.backFromPreview} variant='contained' color='primary' style={{ marginRight: 10 }}>
                Back
              </Button>
              <Button onClick={this.checkFieldsAndSubmit} variant='contained' color='primary'>
                Submit
              </Button>
            </styled.FormButtonContainer>
          </styled.UploadDialogueForm>
        </styled.Container>
      );

    return (
      <styled.Container>
        <styled.Title>
          Select an audience recipients list and schedule a communication below:
        </styled.Title>
        {this.props.audienceListsStore!.apiStatus.checkApiStatus(apiStatusDefinitions.GET_AUDIENCE_LISTS) && this.props.dynamicListsStore!.apiStatus.checkApiStatus(apiStatusDefinitions.GET_PATRON_LISTS) ? (
          <styled.LoadingAudienceLists>
            <CircularProgress style={{ marginBottom: 20, marginTop: 0 }} color="secondary" />
            <styled.LoadingText>Loading, please wait...</styled.LoadingText>
          </styled.LoadingAudienceLists>
        ) : (
          <styled.UploadDialogueContent>
            <styled.ListSelectionMethod>
              <styled.ListSendingStep>
                <styled.ListSendingStepNumber>
                  1
                </styled.ListSendingStepNumber>
                <div>Select list(s) to receive this communication:</div>
              </styled.ListSendingStep>
              <styled.ListSendingStep>
                <styled.ListSendingFormWrapper style={{ paddingTop: 4 }}>
                  <FormControl variant="outlined" style={{ width: '300px' }}>
                    <InputLabel id="dynamic_list_label">Select a dynamic recipient list</InputLabel>
                    <Select
                      labelId="dynamic_list_label"
                      id="dynamic_list"
                      value={this.state.dynamicList}
                      onChange={(event) => this.onDynamicListChange(event.target.value as number)}
                      label="Select a dynamic recipient list"
                    >
                      <MenuItem value={0}>None</MenuItem>
                      {this.props.dynamicListsStore!.lists?.map((list) =>
                        <MenuItem key={list.id} value={list.id}>{list.name}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </styled.ListSendingFormWrapper>
              </styled.ListSendingStep>
              <styled.ListSendingStep style={{ marginTop: '16px' }}>
                <styled.ListSendingFormWrapper style={{ paddingTop: 4 }}>
                  <FormControl variant="outlined" style={{ width: '300px' }}>
                    <InputLabel id="audience_list_label">Select an audience recipient list</InputLabel>
                    <Select
                      multiple
                      labelId="audience_list_label"
                      id="audience_list"
                      value={this.state.audienceList}
                      onChange={(event) => this.onAudienceListChange(event.target.value as number[])}
                      label="Select an audience recipient list"
                    >
                      {this.props.audienceListsStore!.audienceLists?.map((list) =>
                        <MenuItem key={list.id} value={list.id}>{list.name}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </styled.ListSendingFormWrapper>
              </styled.ListSendingStep>
              <styled.ListSendingStep style={{ paddingTop: 30 }}>
                <styled.ListSendingStepNumber>
                  2
                </styled.ListSendingStepNumber>
                <div>Some lists may contain multiple entries with the same email/phone for multiple people. This setting will ensure that only one communication goes out for each email/phone.</div>
              </styled.ListSendingStep>
              <styled.ListSendingStep>
                <FormGroup style={{ marginLeft: 60 }}>
                  <FormControlLabel control={<Checkbox checked={this.state.dedupe} onChange={(event: any) => this.handleDedupe(event.target.checked)} />} label="Deduplicate Email/Phone" />
                </FormGroup>
              </styled.ListSendingStep>
              <styled.ListSendingStep style={{ paddingTop: 30 }}>
                <styled.ListSendingStepNumber>
                  3
                </styled.ListSendingStepNumber>
                <div>Schedule a future time for this message to be sent or, leave it as is, and it will send immediately:</div>
              </styled.ListSendingStep>
              <styled.ListSendingStep>
                <styled.ListSendingFormWrapper>
                  <styled.SchedulDateContainer>
                    <ScheduledDate date={this.state.date} handleDate={this.handleDate} />
                  </styled.SchedulDateContainer>
                </styled.ListSendingFormWrapper>
              </styled.ListSendingStep>
              <styled.ListSendingStep style={{ paddingTop: 30, cursor: 'pointer' }} onClick={this.toggleExpandRecurring}>
                <styled.ListSendingStepNumber>
                  4
                </styled.ListSendingStepNumber>
                <div>Optional: Setup Recurring Messages (<strong>+ Expand</strong>)</div>
              </styled.ListSendingStep>
              {
                this.state.recurringExpanded &&
                <styled.ListSendingStep>
                  <styled.ListSendingFormWrapper style={{ paddingTop: 4 }}>
                    <FormControl variant="outlined" style={{ width: '300px' }}>
                      <InputLabel id="recurrence_label">Select recurrence frequency</InputLabel>
                      <Select
                        labelId="recurrence_label"
                        id="recurring"
                        value={this.state.recurringFrequency}
                        onChange={(event) => this.setRecurringFrequency(event.target.value as string)}
                        label="Select frequency"
                      >
                        <MenuItem value={'None'}>None</MenuItem>
                        <MenuItem value={'Daily'}>Daily</MenuItem>
                        <MenuItem value={'Weekly'}>Weekly</MenuItem>
                        <MenuItem value={'Monthly'}>Monthly</MenuItem>
                      </Select>
                    </FormControl>
                  </styled.ListSendingFormWrapper>
                </styled.ListSendingStep>
              }
            </styled.ListSelectionMethod>
          </styled.UploadDialogueContent>
        )}
        <styled.UploadDialogueForm>
          <styled.FormButtonContainer>
            <Button onClick={this.props.templateStore!.clearModal} variant='contained' color='primary' style={{ marginRight: 10 }}>
              Cancel
            </Button>
            <Button onClick={this.goToPreview} variant='contained' color='primary'>
              Next
            </Button>
          </styled.FormButtonContainer>
        </styled.UploadDialogueForm>
      </styled.Container>
    )
  }
}

export default UploadCustomers