import React from 'react'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

interface TemplatePlusButtonProps {
  redirectFunction: any,
  location: string
}

const TemplateImagePlusButton: React.SFC<TemplatePlusButtonProps> = ({ redirectFunction, location }: TemplatePlusButtonProps) => {
  return (
    <Fab style={{ position: 'absolute', right: 18, bottom: 16, color: 'white' }} color="secondary" onClick={() => redirectFunction(location)} aria-label="add">
      <AddIcon />
    </Fab>
  )
}

export default TemplateImagePlusButton
