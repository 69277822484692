import startCase from 'lodash/startCase'
import { DateTime } from 'luxon'

export const eraseCookie = (cookieName: string) => {
  document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

export const isEven = (value: number) => {
  return value % 2 === 0
}

export const getStartingEndDate = (additionalDays: number) => {
  const today = new Date()
  today.setDate(today.getDate() - additionalDays)
  return `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`
}

export const stringToObject = (str: string, val: string) => {
  let i, obj = {}, strarr = str.split(".")
  let x = obj
  for (i = 0; i < strarr.length - 1; i++) {
    x = x[strarr[i]] = {}
  }
  x[strarr[i]] = val
  return obj
}

export const emailIsValid = (email: string) => {
  return /\S+@\S+\.\S+/.test(email)
}

export const translateZipToLatLong = (zip: string) => {
  if (zip === '40031') {
    return { longitude: '-85.400814', latitude: '38.429416' }
  } else if (zip === '40202') {
    return { longitude: '-85.754048', latitude: '38.256783' }
  } else {
    return { longitude: '-85.696419', latitude: '38.332696' }
  }
}

export const getUtcOffset = (): string => {
  const pad = (value: number): string => {
    return value < 10 ? '0' + value.toString() : value.toString()
  }

  const date = new Date()
  const sign = date.getTimezoneOffset() > 0 ? "-" : "+"
  const offset = Math.abs(date.getTimezoneOffset())
  const hours = pad(Math.floor(offset / 60))
  const minutes = pad(offset % 60)
  return sign + hours + ":" + minutes
}

export const getTimezoneCode = (): string => {
  return DateTime.local()
    .toFormat('ZZZZ')
}

export const atLeastTwoAreTrue = (a: boolean, b: boolean, c: boolean) => {
  return a ? (b || c) : (b && c)
}

export const createDictonary = (objectArray: { givenHeader: string, suggestedPropertyName: string }[]) => {
  const reducer = (accumulator: { [key: string]: string }, attributePair: { givenHeader: string, suggestedPropertyName: string }) => {
    return { ...accumulator, [attributePair.givenHeader]: attributePair.suggestedPropertyName }
  }
  return objectArray.reduce(reducer, {})
}

export function sentenceCaseObjectAttributes<T>(array: any) {
  return array.map((object: any) => {
    const result: { [key: string]: any } = {}
    for (const [key, value] of Object.entries(object)) {
      result[startCase(key.toLowerCase())] = value
    }
    return result
  })
}

export const capitalize = (string: string) => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// Get any items in array 2 that aren't in array 1.  This assumes array 2 has everything 
// in array 1 plus more.
export const getUniqueItemsInComparedArray = <T>(array1: T[], array2: T[]): T[] => {
  const uniqueKeys: T[] = []
  array2.forEach((arrayItem: T) => {
    if (!array1.includes(arrayItem)) {
      uniqueKeys.push(arrayItem)
    }
  })
  return uniqueKeys
}