import React from 'react'
import * as styled from './MiddleContent.styled'

interface MiddleContentProps {
  overflowHorizontal?: boolean
  title: string,
  children: any
}

const MiddleContent: React.FunctionComponent<MiddleContentProps> = ({ overflowHorizontal, title, children }: MiddleContentProps) => {
  return (
    <styled.MiddleContentContainer overflowHorizontal={overflowHorizontal}>
      <styled.MiddleContentHeader>
        {title}
      </styled.MiddleContentHeader>
      <styled.MiddleContentBody>
        {children}
      </styled.MiddleContentBody>
    </styled.MiddleContentContainer>
  )
}

export default MiddleContent
