import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './EditArn.styled'
import { ITaskStore } from '../../../../stores/TaskStore'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ButtonLoading from '../../shared/Loading/ButtonLoading'
import { apiStatusDefinitions } from '../../../../utils/apiStatusDefinitions'

interface MediaLibraryContainerProps {
  taskStore?: ITaskStore
}

@inject((allStores : any) => {
  return {
    taskStore: allStores.taskStore as ITaskStore
  }
})
@observer
class EditArn extends Component<MediaLibraryContainerProps> {
  public state = {
    arn: ''
  }

  private handleArnChange = (event: any) => {
    this.setState({ arn: event.target.value })
  }

  private cancelFnrEdit = () => {
    this.props.taskStore!.clearModal()
  }

  private saveArnEdit = () => {
    this.props.taskStore!.updateTaskLambdaArn(this.state.arn)
  }

  public render () {
    return (
      <div>
        <styled.EditArnDialogueWrapper>
          <div>
            <styled.Title>
              Modify {this.props.taskStore!.getTaskName(this.props.taskStore!.selectedTask!)} Lambda ARN:
            </styled.Title>
            <styled.SubTitle>
              Edit this particular {`task's`} lambda ARN to change which lambda function is invoked by when this task is initiated.  Click save after editing to make this change permanent or cancel to close this dialogue.  
            </styled.SubTitle>
            <styled.ArnInputContainer>
              <TextField
                onChange={this.handleArnChange}
                type='text'
                name='new'
                value={this.state.arn.length < 1 ? this.props.taskStore!.taskArn : this.state.arn}
                placeholder='Enter new configuration item'
                label='Enter new configuration item'
                fullWidth
                margin="normal"
                variant="outlined"
                key={`new_formField`}
              />
            </styled.ArnInputContainer>
            <styled.ButtonRowWrapper>
              <styled.ButtonRow>
                <Button onClick={this.cancelFnrEdit.bind(this)} variant='contained' style={{ marginRight: 10, color: 'white' }} color='secondary'>
                  Cancel
                </Button>
                <Button onClick={this.saveArnEdit.bind(this)} style={{ minWidth: 137 }} variant='contained' color='primary'>
                  {this.props.taskStore!.apiStatus.checkApiStatus(apiStatusDefinitions.UPDATE_TASK_LAMBDA_ARN) ? (
                    <ButtonLoading />
                  ) : (
                    <>Save New ARN</>
                  )}
                </Button>
              </styled.ButtonRow>
            </styled.ButtonRowWrapper>
          </div>
        </styled.EditArnDialogueWrapper>
      </div>
    )
  }
}

export default EditArn