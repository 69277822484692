import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './EditDialogue.styled'
import { IMediaLibraryStore } from '../../../../stores/MediaLibraryStore'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

interface MediaLibraryContainerProps {
  mediaLibraryStore?: IMediaLibraryStore
}

@inject((allStores : any) => {
  return {
    mediaLibraryStore: allStores.mediaLibraryStore as IMediaLibraryStore
  }
})
@observer
class EditDialgoue extends Component<MediaLibraryContainerProps> {
  public state = {
    formErrors: {
      name: false,
      description: false
    },
    saveActive: false
  }

  private handleChange = (name: string) => (event: any) => {
    if (name === 'name') {
      this.props.mediaLibraryStore!.updateEditTargetName(event.target.value)
    } else if (name === 'description') {
      this.props.mediaLibraryStore!.updateEditTargetDescription(event.target.value)
    } else {
      this.props.mediaLibraryStore!.updateEditTargetAltText(event.target.value)
    }
  }

  private checkFieldsAndSubmit = () => {
    const formErrors = this.state.formErrors
    if (this.props.mediaLibraryStore!.editTargetName!.length < 1) {
      formErrors.name = true
    } else {
      formErrors.name = false
    }

    if (this.props.mediaLibraryStore!.editTargetDescription!.length < 1) {
      formErrors.description = true
    } else {
      formErrors.description = false
    }

    if (this.props.mediaLibraryStore!.editTargetName!.length > 0 && this.props.mediaLibraryStore!.editTargetDescription!.length > 0) {
      this.setState({formErrors: formErrors})
      this.props.mediaLibraryStore!.saveEditsToDatabase()
    } else {
      this.setState({formErrors: formErrors})
      return
    }
  }

  public render () {
    return (
      <styled.EditDialogueWrapper>
        <styled.Title>
          Edit the below item from your media library:
        </styled.Title>
        <styled.EditDialogueContent>
          <styled.EditDialogueForm>
            <styled.Label>
              Please update the form below:
            </styled.Label>
            <styled.FormElementWrapper>
              <TextField
                style={{ width: '95%', marginRight: 20, marginBottom: 10, marginTop: '-6px' }}
                onChange={this.handleChange('name')}
                type='text'
                name='name'
                fullWidth
                value={this.props.mediaLibraryStore!.editTargetName!}
                placeholder='Enter a name'
                label='Enter a name'
                margin="normal"
                variant="outlined"
                required
                error={this.state.formErrors.name}
                helperText={ this.state.formErrors.name ? "You must enter a name before continuing." : null}
              />
            </styled.FormElementWrapper>
            <styled.FormElementWrapper>
              <TextField
                style={{ width: '95%', marginRight: 20, marginBottom: 10, marginTop: '-6px' }}
                onChange={this.handleChange('description')}
                type='text'
                name='description'
                fullWidth
                value={this.props.mediaLibraryStore!.editTargetDescription!}
                placeholder='Enter a description'
                label='Enter a description'
                margin="normal"
                variant="outlined"
                multiline
                rows="3"
                rowsMax="3"
                required
                error={this.state.formErrors.description}
                helperText={ this.state.formErrors.description ? "You must enter a description before continuing." : null}
              />
            </styled.FormElementWrapper>
            <styled.FormElementWrapper>
              <TextField
                style={{ width: '95%', marginRight: 20, marginBottom: 10, marginTop: '-6px' }}
                onChange={this.handleChange('altText')}
                type='text'
                name='altText'
                fullWidth
                value={this.props.mediaLibraryStore!.editTargetAltText!}
                placeholder='Alt Text'
                label='Alt Text'
                margin="normal"
                variant="outlined"
                multiline
                rows="1"
                rowsMax="1"
              />
            </styled.FormElementWrapper>
            <styled.FormButtonContainer>
              <Button onClick={() => this.props.mediaLibraryStore!.setMediaLibraryFunctionState('IMAGE_GALLERY')} variant='contained' color='primary' style={{ marginRight: 10 }}>
                Cancel
              </Button>
              <Button onClick={this.checkFieldsAndSubmit} variant='contained' color='primary'>
                Save Edits
              </Button>
            </styled.FormButtonContainer>
          </styled.EditDialogueForm>
          <styled.ImageContainer>
            <img src={this.props.mediaLibraryStore!.editTargetImage!.itemPath} style={{ objectFit: 'scale-down', maxWidth: '100%', maxHeight: '100%' }}/>
          </styled.ImageContainer>
        </styled.EditDialogueContent>
      </styled.EditDialogueWrapper>
    )
  }
}

export default EditDialgoue