import styled from 'styled-components'
import HeaderLogo from '../../../content/img/headerLogoWhiteTextOrangeBee.png'

export const HeaderFlexReset = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
`
export const HeaderLeft = styled.div`
  width: 225px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: 16px;
`

export const SearchIcon = styled.div`
  width: 20px;
  height: 40px;
  margin-left: 6px;
  margin-top: -4px;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Search = styled.div`
  position: relative;
  border-radius: 3px;
  background-color: ${(props: { customerApiCallStatus?: boolean }) => props.customerApiCallStatus ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.15)'};
  margin-Left: 0;
  width: '100%';
  color: #ffffff;
      
  &:hover {
    background-color: rgba(255,255,255,.25),
  }    
`

export const HeaderLogoContainer = styled.div`
  height: 72px;
  width: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('${HeaderLogo}');
  background-position: center;
`

export const HeaderRight = styled.div`
  margin-top: 12px;
  text-align: right;
`
