import React, { useEffect, useState } from 'react'
import { Badge, Divider, Menu, MenuItem } from '@material-ui/core'
import { Mail, DeleteOutline } from '@material-ui/icons'
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import { deleteNotification, getNotifications, markNotificationAsRead } from 'api/user'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { hasUnread } from 'api/smsMessaging'

const useHasUnreadMessages = (hasSmsMessaging: boolean, customerId?: number) => {
  return useQuery(['sms-messaging', customerId, 'unread'], async () => {
    return await hasUnread()
  }, { refetchInterval: 30000, enabled: hasSmsMessaging })
}

type NotificationProps = {
  hasSmsMessaging?: boolean
  customerId?: number
}
const Notifications: React.FC<NotificationProps> = ({ hasSmsMessaging = false, customerId }) => {
  const history = useHistory();
  const queryClient = useQueryClient()
  const { data } = useQuery<any>('notifications', async () => {
    return await getNotifications()
  }, { refetchInterval: 30000, refetchIntervalInBackground: false })

  const { data: unreadMessageQuery } = useHasUnreadMessages(hasSmsMessaging, customerId)

  let notifications: any[] = []
  if (data != null) {
    notifications = data.notifications
  }

  const markDeleted = useMutation<any, any, number[]>(async (ids) => {
    await deleteNotification(ids)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('notifications')
    },
  })

  const markRead = useMutation(async () => {
    const ids = (notifications || []).map((x: any) => x.id)
    await markNotificationAsRead(ids)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('notifications')
    },
  })

  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })
  const unreadNotificationCount = (notifications || []).filter((item: any) => item?.read == false).length
    + (unreadMessageQuery?.hasUnread ? 1 : 0)

  return (
    <span style={{ marginRight: '10px' }} onClick={() => markRead.mutate()}>
      <Badge badgeContent={unreadNotificationCount} color="secondary" style={{ cursor: 'pointer' }} {...bindTrigger(popupState)}>
        <Mail color="inherit" />
      </Badge>
      <Menu {...bindMenu(popupState)}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        {(notifications || []).map((notification: any) => {
          return (<div key={notification.id}>
            <MenuItem style={{ whiteSpace: 'pre-line' }} onClick={() => history.push(notification.link)}>
              {notification.message}
              <DeleteOutline onClick={() => { markDeleted.mutate([notification.id]) }} />
            </MenuItem>
            <Divider />
          </div>)
        })}
        {unreadMessageQuery?.hasUnread &&
          <MenuItem style={{ whiteSpace: 'pre-line' }} onClick={() => history.push('/sms-messaging')}>
            You have unread messages
          </MenuItem>
        }
        {
          notifications && notifications.length > 0 ?
            <MenuItem onClick={() => { markDeleted.mutate((notifications || []).map((x: any) => x.id)); popupState.close() }}>Clear all</MenuItem>
            : unreadMessageQuery?.hasUnread ? '' : <MenuItem>You currently have no notifications</MenuItem>
        }

      </Menu>
    </span>
  )
}

export default Notifications
