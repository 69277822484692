/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { ResponsiveLine } from '@nivo/line'

class LineChart extends Component {
  constructor(props) {
    super(props)

    this.renderColorArray = this.renderColorArray.bind(this)
  }

  renderColorArray = (data) => {
    return data.map((series) => {
      return series.color
    })
  }

  renderDataWithDateRanges = (data, startDate, endDate) => {
    return data.map((series) => {
      const newSeriesData = series.data.filter((dataPoint) => !(dataPoint.x > startDate && dataPoint.x < endDate))
      return newSeriesData
    })
  }

  render() {
    if(this.props.data) { 
      return (
        <ResponsiveLine
          data={this.props.data}
          margin={{ top: 20, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
          curve="cardinal"
          axisTop={null}
          lineWidth={3}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: this.props.axisLabel.x,
            legendOffset: 36,
            legendPosition: 'middle'
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: this.props.axisLabel.y,
            legendOffset: -55,
            legendPosition: 'middle'
          }}
          colors={this.renderColorArray(this.props.data)}
          pointSize={14}
          pointBorderWidth={2}
          useMesh={true}
        />
      ) 
    } else {
      return <div></div>
    } 
  }
}
  
  export default LineChart
