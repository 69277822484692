import { api } from './axios'
import { apiHelper } from '../utils/apiHelper'

export const uploadMediaItem = async (customerId: number, name: string, description: string, file: File, altText?: string) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('customerId', `${customerId}`)
  formData.append('name', name)
  altText && formData.append('altText', altText)
  formData.append('description', description)

  const header = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  return await apiHelper<any>(() => api.post('/media-library/upload', formData, header))
}

export const retrieveMediaLibraryItems = async (customerId: number) => {
  const mediaItemsData = await apiHelper<any>(() => api.get(`/media-library?customerId=${customerId}`))
  return mediaItemsData.data.media
}


export const updateMediaItem = async (customerId: number, mediaItemId: number, name: string, description: string, altText?: string) => {
  return await apiHelper<any>(() => api.patch(`/media-library/item/${mediaItemId}`, { customerId, mediaItemId, name, description, altText }))
}

export const archiveMediaItem = async (customerId: number, mediaItemId: number) => {
  return await apiHelper<any>(() => api.patch(`/media-library/item/${mediaItemId}/archive`, { customerId, mediaItemId }))
}

export const retrieveActiveMediaLibraryItems = async (customerId: number) => {
  const mediaItemsData = await apiHelper<any>(() => api.get(`/media-library/active?customerId=${customerId}&imageDate=${new Date().toISOString()}`))
  return mediaItemsData.data.media
}