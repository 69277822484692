import { api } from './axios'
import { chartColors, seriesNames } from '../ui/pages/Reports/Reports.copy'
import { apiHelper } from '../utils/apiHelper'
import { getTimezoneCode, getUtcOffset } from '../utils/generalHelpers'
import { mockedData } from '../utils/mockedData'

interface DayData {
  bounce: number,
  click: number,
  deferred: number,
  delivered: number,
  dropped: number,
  open: number,
  other: number,
  sent: number
  uniqueOpen: number
}

interface DayBreakdown {
  [day: string]: DayData
}

interface NotificationTypeBreakDown {
  [notificationType: string]: DayBreakdown
}

interface AdjustedData {
  id: string,
  color: string,
  data: {
    x: string,
    y: number
  }[]
}


const formatData = (reportData: NotificationTypeBreakDown): AdjustedData[] => {
  const data = [] as AdjustedData[]
  const notificationTypeEntries = Object.keys(reportData)

  notificationTypeEntries.map((notificationType: string) => {
    const entries = Object.entries(reportData[notificationType])
    seriesNames.map((series, i): void => {
      const foundSeries = data.find(item => item.id === series)  
      if (foundSeries) {
        entries.map((dataPoint: any[]): void => {
          if (dataPoint[0] !== 'Total' && dataPoint[0] !== 'Deferred') {
            const foundDate = foundSeries.data.find(item => item.x === dataPoint[0])
            if (foundDate) {
              foundDate.y = foundDate.y + dataPoint[1][series]
            } else {
              foundSeries.data.push({ x: dataPoint[0], y: dataPoint[1][series] })
            }
          }
        })
      } else {
        const newSeries = { id: series, color: chartColors[i], data: [] as { x: string, y: number }[] }
        entries.map((dataPoint: any[]): void => {
          if (dataPoint[0] !== 'Total' && dataPoint[0] !== 'Deferred') {
            const foundDate = newSeries.data.find(item => item.x === dataPoint[0])
            if (foundDate) {
              foundDate.y = foundDate.y + dataPoint[1][series]
            } else {
              newSeries.data.push({ x: dataPoint[0], y: dataPoint[1][series] })
            }
          }         
        })
        data.push(newSeries)
      }    
    })
  })
  
  data.forEach(series => {
    series.data.sort((a: any, b: any) => {
      let dateA = new Date(a.x).getTime()
      let dateB = new Date(b.x).getTime()
      return dateA < dateB ? -1 : 1
    })
  })

  return data
}

interface ClickGroupData {
  date: string,
  email: string,
  url: string
}

const formatClickGroups = (data: any): { url: string, clicks: number }[] => {
  const clickGroupSummary: { url: string, clicks: number }[] = data
  return clickGroupSummary
}

const translateNotificationCodeToName = (notificationData: any, notificationCodeToName: {[key: number]: string}) => {
  const modifiedNotificationData = notificationData.map((item: any) => {
    console.log(item)
    
    return [ notificationCodeToName[parseInt(item[0])], item[1] ]
  })
  return modifiedNotificationData.reverse()
}

export const retrieveReportData = async (startDate: string, endDate: string, customerId: number, notificationSubTypeValues: string, timezone: string, channel: string, asyncRequest = false, asyncReportName = ''): Promise<any> => {
  timezone = getTimezoneCode()
  const utcOffset = getUtcOffset()
  if (channel !== 'mail') {
    let url = `/report/graph?customerId=${customerId}&startDate=${startDate}&endDate=${endDate}&notificationTypes=${notificationSubTypeValues}&utcOffset=${utcOffset}&timezone=${timezone}&asyncRequest=${asyncRequest}&asyncReportName=${asyncReportName}`
    const getReportData = await apiHelper<any>((): any => api.get(url))

    if (getReportData.data.requiresAsync || asyncRequest) {
      return {
        formattedData: null,
        unformattedData: null,
        bounces: null,
        clickGroups: null,
        requiresAsync: getReportData.data.requiresAsync
      }
    }

    return {
      formattedData: formatData(getReportData.data.notificationTypeAndDayBreakdown),
      unformattedData: translateNotificationCodeToName(Object.entries(getReportData.data.notificationTypeAndDayBreakdown), getReportData.data.notificationCodeToName),
      bounces: getReportData.data.bounces,
      clickGroups: formatClickGroups(getReportData.data.clickGroups),
      requiresAsync: getReportData.data.requiresAsync
    }
  }
}

export const retrieveReportClickData = async (startDate: string, endDate: string, customerId: number, notificationSubTypeValues: string, timezone: string, channel: string): Promise<any> => {
  timezone = getTimezoneCode()
  const utcOffset = getUtcOffset()
  if (channel !== 'mail') {
    let url = `/report/graph/clicks?customerId=${customerId}&startDate=${startDate}&endDate=${endDate}&notificationTypes=${notificationSubTypeValues}&utcOffset=${utcOffset}&timezone=${timezone}`
    const getReportData = await apiHelper<any>((): any => api.get(url))

    return {
      formattedData: null,
      unformattedData: null,
      bounces: null,
      clickGroups: formatClickGroups(getReportData.data.clickGroups)
    }
  }
}

export const getDownloadableReports = async (customerId?: number, offset: number = 0, limit = 20) => {
  const request = await apiHelper<any>(() => api.get(`/report/download?customerId=${customerId}&offset=${offset}&limit=${limit}`))
  return request.data
}

export const getReportLink = async (reportId : number) => {
  console.log(reportId)
  const request = await apiHelper<any>(() => api.get(`/report/download/${reportId}`))
  return request.data
}