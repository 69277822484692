import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import * as styled from './DropDowns.styled'
import { ICustomerStore } from '../../../../stores/CustomerStore'
import { IReportStore } from '../../../../stores/ReportStore'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { observer } from 'mobx-react'

interface NotificationTypesAndSubTypesProps {
  customerStore: ICustomerStore,
  reportStore: IReportStore
}

@observer
class NotificationTypesAndSubTypes extends Component<NotificationTypesAndSubTypesProps> {
  public state = {
    labelWidthType: 0,
    labelWidthSubType: 0
  }
  
  private InputLabelRefType: any
  private InputLabelRefSubType: any

  public componentDidMount () {
    this.setState({
      labelWidthType: (ReactDOM.findDOMNode(this.InputLabelRefType) as any).offsetWidth,
      labelWidthSubType: (ReactDOM.findDOMNode(this.InputLabelRefSubType) as any).offsetWidth
    })
  }

  private renderNotificationTypeSelects = () => {
    const customer = this.props.customerStore.customerData
    return customer && this.props.reportStore.communicationChannelStore.filterNotificationTypesBasedOnChannel.map((notification: any) => {
      return (
        <MenuItem key={`notification_${notification.name!}`} value={notification.name!}>
          {notification.name!}
        </MenuItem>
      )
    })
  }
    
  private renderNotificationSubTypeSelects = () => {
    const customer = this.props.customerStore
    if (this.props.reportStore.filters.notificationType !== 'all') {
      const notificationData = this.props.reportStore.notificationData
      return notificationData && notificationData.map((notificationSubType: any) => {
        return (
          <MenuItem key={`notification_subtype_${notificationSubType.id}`} value={notificationSubType.id}>
            {notificationSubType.name}
          </MenuItem>
        )
      })
    }
  }

  private handleChangeType = (event: any) => {
    this.props.reportStore.setNotificationType(event.target.value) 
  }

  private handleChangeSubType = (event: any) => {
    this.props.reportStore.setNotificationSubType(event.target.value)
  }

  private renderNotificationDropDown = (type: string, onChange: (event: any) => void, value: string | number, labelWidth: number, selectRenderer: (type: string | undefined) => void) => {
    const definedType = this.props.reportStore.filters.notificationType
    return (
      <FormControl style={{ width: '95%', marginRight: 20, marginBottom: 10 }} variant="outlined">
        <InputLabel
          ref={ref => {
            type === 'type' ? this.InputLabelRefType = ref : this.InputLabelRefSubType = ref
          }}
          htmlFor={`outlined-${type}-simple`}
        >
          {type === 'type' ? 'Notification Type' : definedType === 'all' ? 'Select a Notification Type' : 'Notification Sub-Type'}
        </InputLabel>
        <Select
          disabled={(type === 'subType' && definedType === 'all')}
          value={value === 0 ? '' : value}
          onChange={onChange}
          placeholder={type === 'subType' && definedType === 'all' ? 'Select a Notification Type' : type}
          autoWidth
          style={{ minWidth: 250, width: '100%', backgroundColor: 'rgba(255, 255, 255, 0)', textAlign: 'left' }}
          input={<OutlinedInput placeholder={type === 'subType' && definedType === 'all' ? 'Select a Notification Type' : type} labelWidth={labelWidth} name={type} id={`outlined-${type}-simple`} />}
        >
          <MenuItem key='notification_all' value="all">
                All
          </MenuItem>
          {selectRenderer(type)}
        </Select>
      </FormControl>  
    )
  }

  public render () {
    return (
      <>
        <styled.Label>
            Notification Types/Sub-Types:
        </styled.Label>
        <styled.NotificationTypeContainer>
          {this.renderNotificationDropDown('type', this.handleChangeType, this.props.reportStore.filters.notificationType, this.state.labelWidthType, this.renderNotificationTypeSelects)}
          {this.renderNotificationDropDown('subType', this.handleChangeSubType, this.props.reportStore.filters.notificationSubType, this.state.labelWidthSubType, this.renderNotificationSubTypeSelects)}
        </styled.NotificationTypeContainer>
      </>
    )
  }
}

export default NotificationTypesAndSubTypes
