import { types } from 'mobx-state-tree'

const StaticData = types.model('StaticData', {})
  .volatile(() => ({
    custom: {},
    defined: {
      subject: types.maybeNull(types.string),
      preHeader: types.maybeNull(types.string),
      wording1: types.maybeNull(types.string),
      wording2: types.maybeNull(types.string),
      wording3: types.maybeNull(types.string)
    },
    images: {}
  }))

const SubTypes = types.model('SubTypeData', {
  configId: types.identifierNumber,
  name: types.string,
  static: StaticData,
  isCustomTemplate: types.optional(types.boolean, false)
})

const TemplatesData = types.model('TemplateData', {
  defaults: types.string,
  revolvData: types.maybeNull(types.string),
  template: types.string,
  editorTemplate: types.string,
  subTypes: types.maybeNull(types.array(SubTypes)),
  subjectTemplate: types.string,
  plainText: types.maybeNull(types.string),
  beta: types.optional(types.boolean, false),
  betaHtml: types.optional(types.string, ''),
}).volatile(() => ({
  templateObject: {}
}))

export type ITemplatesData = typeof TemplatesData.Type
export type ISubtypes = typeof SubTypes.Type
export default TemplatesData

