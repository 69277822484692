import styled from 'styled-components'

export const LoadingWrapper = styled.div`
  position: relative;
  width: 26px;
  height: 24px;
  margin-top: 1px;  
  margin-left: 4px;  
  top: 0px;
`

export const LoadingWrapperInnerContainer = styled.div`
  position: absolute;
  width: 26px;
  height: 24px;
  top: 0px;

  -webkit-animation: AnimatedGradient 1s ease infinite;
  -moz-animation: AnimatedGradient 1s ease infinite;
  animation: AnimatedGradient 1s ease infinite;

  @-webkit-keyframes AnimatedGradient {
    0%{opacity: 0.5}
    50%{opacity: 1}
    100%{opacity: 0.5}
  }
  @-moz-keyframes AnimatedGradient {
    0%{opacity: 0.5}
    50%{opacity: 1}
    100%{opacity: 0.5}
  }
  @keyframes AnimatedGradient { 
    0%{opacity: 0.5}
    50%{opacity: 1}
    100%{opacity: 0.5}
  }
`
