import React, { Component } from 'react'
import { observer } from 'mobx-react'
import * as styled from './ReportsContainer.styled'
import LineChart from '../shared/Charts/LineChart'
import { IReportStore } from '../../../stores/ReportStore'
import { ICustomerStore } from '../../../stores/CustomerStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'
import { apiStatusDefinitions } from '../../../utils/apiStatusDefinitions'
import DataTableOverView from './DataTableOverView'
import DataTableFailures from './DataTableFailures'
import DataTableClicks from './DataTableClicks'

interface ReportContainerProps {
  reportStore: IReportStore,
  customerStore: ICustomerStore
}

@observer
class ReportContainer extends Component<ReportContainerProps, any> {
  private renderDataTable = () => {
    switch (this.props.reportStore.filters.dataTableSelection) {
      case 'Overview':
        return <DataTableOverView reportStore={this.props.reportStore} customerStore={this.props.customerStore} />
      case 'Failures':
        return <DataTableFailures reportStore={this.props.reportStore} customerStore={this.props.customerStore} />
      case 'Clicks':
        return <DataTableClicks reportStore={this.props.reportStore} customerStore={this.props.customerStore} />    
    }
  }

  public render() {
    const data = this.props.reportStore.retrieveFilteredData
    const reportStore = this.props.reportStore
    let showEmailFields = false
    // this is confusing but if channelvisibility is true it means the channel visibility drop down will not show
    // Reason being it ties directly to hidden which must be true when something is hidden, i.e. not more than one channel.
    if (this.props.reportStore.communicationChannelStore.availableChannels.email && ((!this.props.reportStore.filters.communicationChannel && this.props.reportStore.communicationChannelStore.channelVisbility) || ((this.props.reportStore.filters.communicationChannel === 'all' && this.props.reportStore.communicationChannelStore.channelVisbility) || this.props.reportStore.filters.communicationChannel === 'email' || this.props.reportStore.communicationChannelStore.communicationChannel === 'email'))) {
      showEmailFields = true
    }

    if (data.length && !(this.props.reportStore && this.props.reportStore.apiStatus.checkApiStatus(apiStatusDefinitions.REPORT_DATA))) {
      return (
        <styled.ReportOuterContainer>
          {!this.props.reportStore.showGraph ? (
            <styled.ReportInnerContainer noGraph>  
              <styled.ChartNote>
                <b>Note:</b> The graph is not rendered when only one day of data is returned.
              </styled.ChartNote>
            </styled.ReportInnerContainer>
          ) : (
            <styled.ReportInnerContainer>  
              <LineChart axisLabel={{ x: 'Day', y: 'Messages' }} startDate={this.props.reportStore.filters.startDate} endDate={this.props.reportStore.filters.endDate} data={data} />
              <styled.ChartNote>
                <b>Note:</b> This report contains data showing how many messages were opened and bounced for any given day.  Hover over any point to learn more about that days activity.
              </styled.ChartNote>
            </styled.ReportInnerContainer>
          )}
          <styled.ReportTabAndLabelContainer>
            <styled.DataTableTitle>
              Data
            </styled.DataTableTitle>
            <styled.DataTableNavigationContainer>
              <styled.DataTableNavigationItem 
                onClick={() => reportStore.filterDataTable('Overview')} 
                active={reportStore.filters.dataTableSelection === 'Overview'}>
                  Overview
              </styled.DataTableNavigationItem>
              <styled.DataTableNavigationItem 
                onClick={() => reportStore.filterDataTable('Failures')} 
                active={reportStore.filters.dataTableSelection === 'Failures'}>
                  Failures
              </styled.DataTableNavigationItem>
              {showEmailFields && (
                <styled.DataTableNavigationItem 
                  onClick={() => reportStore.filterDataTable('Clicks')}
                  active={reportStore.filters.dataTableSelection === 'Clicks'}>
                    Clicks
                </styled.DataTableNavigationItem>
              )}
            </styled.DataTableNavigationContainer>
          </styled.ReportTabAndLabelContainer>
          <styled.DataTableContainer>
            {this.renderDataTable()}
          </styled.DataTableContainer> 
        </styled.ReportOuterContainer>
      )
    } else if (this.props.reportStore && this.props.reportStore.apiStatus.checkApiStatus(apiStatusDefinitions.REPORT_DATA)) {
      return (
        <styled.ReportOuterContainer>
          <styled.LoadingContainer>
            <styled.FontAwesomeContainer>
              <styled.FontAwesomeInnerContainer>
                <FontAwesomeIcon size='10x' icon={faChartArea} />
              </styled.FontAwesomeInnerContainer>
              <i>Loading...</i>
            </styled.FontAwesomeContainer>
          </styled.LoadingContainer>
        </styled.ReportOuterContainer>
      )
    } else {
      return (
        <styled.ReportOuterContainer>
          <styled.LoadingContainer>
            <div style={{ textAlign: 'left', height: '100%', width: '100%', marginTop: 14 }}>
              Please click the <b>{`'Retrieve Report Data'`}</b> button ( {`->`} ) to the right in order to populate your Message Bee notification report. 
            </div>
          </styled.LoadingContainer>
        </styled.ReportOuterContainer>
      )
    }
  }
}

export default ReportContainer
