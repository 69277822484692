import React, { useRef } from 'react'
import { observer, inject } from 'mobx-react'
import { ICustomerStore } from '../../../../stores/CustomerStore'
import * as styled from './DropDowns.styled'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'

interface EditAudienceListsProps {
  customerStore?: ICustomerStore
  callback?: (branchId: null | number) => void
  marginLeft?: number
  width?: string
  branches?: { id: number, name: string }[] | null
  configSpecific?: boolean
  hideLabel?: boolean
}

const CustomerBranch: React.FC<EditAudienceListsProps> = inject((stores: any) => ({
  customerStore: stores.customerStore as ICustomerStore
}))(observer(({ customerStore, callback, marginLeft = 10, width = '95%', branches, configSpecific, hideLabel = false }: EditAudienceListsProps) => {
  const InputLabelRef = useRef<null | HTMLLabelElement>(null)

  const handleChange = (e: React.ChangeEvent<{name?: string | undefined, value: unknown}>) => {
    customerStore!.setSelectedCustomerBranchId(e.target.value as number, callback)
  }

  return (
    <div hidden={!branches?.length}>
      {!hideLabel && (
        <styled.Label>
          Branches:  
        </styled.Label>   
      )}
      <FormControl style={{ width, marginLeft, marginBottom: 10 }} variant="outlined">
        <InputLabel
          ref={InputLabelRef}
          htmlFor={`outlined-defaultReportFilters-simple`}
        >
          {`Select a customer branch`}
        </InputLabel>
        <Select
          value={
            configSpecific 
              ? branches?.find(branch => branch.id === customerStore!.setCustomerBranchId) 
                ? customerStore!.setCustomerBranchId 
                : 0
              : customerStore!.setCustomerBranchId 
                ? customerStore!.setCustomerBranchId 
                : 0
          }
          onChange={(e) => handleChange(e)}
          placeholder={`Select a customer branch...`}
          autoWidth
          style={{ minWidth: 250, width: '100%', textAlign: 'left' }}
          input={<OutlinedInput placeholder={`Select a customer branch:`} labelWidth={InputLabelRef?.current?.offsetWidth || 187} name='defaultFilters' id={`outlined-defaultReportFilters-simple`} />}
        >
          <MenuItem key="all_branches" value={0}>
            All Branches
          </MenuItem>
          {branches && branches!.slice().sort((a, b) => a.name > b.name ? 1 : -1).map(customerBranch => {
            // Currently list message is not being displayed in this email template
            return (
              <MenuItem key={`customer_branch${customerBranch.name}`} value={customerBranch.id}>
                {customerBranch.name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}))

export default CustomerBranch