import { types } from 'mobx-state-tree'
import { getStartingEndDate } from '../utils/generalHelpers'

const ReportFilters = types.model('ReportFilters', {
  deselectedSeries: types.optional(types.array(types.string), ["bounce", "click", "dropped"]),
  startDate: types.optional(types.string, getStartingEndDate(7)),
  endDate: types.optional(types.string, getStartingEndDate(0)),
  notificationType: 'all',
  notificationSubType: types.optional(types.union(types.number, types.string), 0),
  dataTableSelection: 'Overview',
  timezone: types.optional(types.string, '-0400')
}).actions(self => ({
  afterCreate() {
    const dt = new Date()
    const tz = dt.getTimezoneOffset()
    const tzPrePad = String((tz / 60) * 100)
    const tzPadded = tzPrePad.padStart(4, "0")
    tz > 0 ? self.timezone = `-${tzPadded}` : self.timezone = tzPadded
  }
}))


export type IReportFilters = typeof ReportFilters.Type
export default ReportFilters