import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './AddTask.styled'
import { ITaskStore } from '../../../../stores/TaskStore'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ButtonLoading from '../../shared/Loading/ButtonLoading'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { apiStatusDefinitions } from '../../../../utils/apiStatusDefinitions'

interface MediaLibraryContainerProps {
  taskStore?: ITaskStore
}

@inject((allStores : any) => {
  return {
    taskStore: allStores.taskStore as ITaskStore
  }
})
@observer
class AddTask extends Component<MediaLibraryContainerProps> {
  public state = {
    arn: '',
    arnError: false,
    friendlyName: '',
    friendlyNameError: false,
    global: false,
  }

  private handleTextInputFormChange = (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ 
      [type]: event.target.value
    })

    this.handleFormErrorChange(type, false)
  }

  private handleCheckBoxFormChange = (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [type]: event.target.checked
    })
  }

  private handleFormErrorChange = (type: string, value: boolean) => {
    this.setState({ 
      [`${type}Error`]: value
    })
  }

  private cancelTaskAdd = () => {
    this.props.taskStore!.clearModal()
  }

  private saveNewTask = () => {
    if (this.state.arn.length < 1) {
      this.handleFormErrorChange('arn', true)
    }
    if (this.state.friendlyName.length < 1) {
      this.handleFormErrorChange('friendlyName', true)
    }
    if (!this.state.arnError && !this.state.friendlyNameError && this.state.arn.length > 0 && this.state.friendlyName.length) {
      this.props.taskStore!.createNewTask(this.state.arn, this.state.friendlyName, this.state.global)
    }
  }

  public render () {
    return (
      <div>
        <styled.AddNewTaskDialogueWrapper>
          <div>
            <styled.Title>
              Add New Task:
            </styled.Title>
            <styled.SubTitle>
              Add a new task by providing a Lambda ARN and friendly name below, once entered press {`"save"`} to create the new task or cancel to close this dialogue.
            </styled.SubTitle>
            <styled.FormItemContainer>
              <TextField
                onChange={this.handleTextInputFormChange('arn')}
                type='text'
                name='arn'
                value={this.state.arn}
                placeholder='Enter new Lambda ARN'
                label='Enter new Lambda ARN'
                fullWidth
                margin="normal"
                variant="outlined"
                key={`new_arn_formField`}
                error={this.state.arnError}
                helperText={this.state.arnError ? "You must enter a Lambda ARN." : ""}
              />
            </styled.FormItemContainer>
            <styled.FormItemContainer>
              <TextField
                onChange={this.handleTextInputFormChange('friendlyName')}
                type='text'
                name='friendlyName'
                value={this.state.friendlyName}
                placeholder='Enter new friendly name'
                label='Enter new friendly name'
                fullWidth
                margin="normal"
                variant="outlined"
                key={`new_friendlyName_formField`}
                error={this.state.friendlyNameError}
                helperText={this.state.friendlyNameError ? "You must enter a Friendly Name." : ""}
              />
            </styled.FormItemContainer>
            <styled.FormItemContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.global}
                    onChange={this.handleCheckBoxFormChange('global')}
                    name="global"
                    color="primary"
                  />
                }
                label="Global Task"
              />
            </styled.FormItemContainer>
            <styled.ButtonRowWrapper>
              <styled.ButtonRow>
                <Button onClick={this.cancelTaskAdd.bind(this)} variant='contained' style={{ marginRight: 10, color: 'white' }} color='secondary'>
                  Cancel
                </Button>
                <Button onClick={this.saveNewTask.bind(this)} style={{ minWidth: 161 }} variant='contained' color='primary'>
                  {this.props.taskStore!.apiStatus.checkApiStatus(apiStatusDefinitions.CREATE_NEW_TASK) ? (
                    <ButtonLoading />
                  ) : (
                    <>
                      Create New Task
                    </>
                  )}
                </Button>
              </styled.ButtonRow>
            </styled.ButtonRowWrapper>
          </div>
        </styled.AddNewTaskDialogueWrapper>
      </div>
    )
  }
}

export default AddTask