import { types, getEnv, flow, cast } from 'mobx-state-tree'
import User from '../models/User'
import ApiStatusStore from './ApiStatusStore'
import { INotificationStore } from './NotificationStore'
import { notificationTypeKeys } from '../ui/components/shared/Notifications/Notifications.copy'
import { FailedFileListItem, FailedFile } from '../models/FailedFile'
import * as failedFilesApi from '../api/failedFilesApi'
import { apiStatusDefinitions } from '../utils/apiStatusDefinitions'

interface Env {
  api : typeof failedFilesApi
  notificationStore: INotificationStore
}

const FailedFilesStore = types.model('FailedFilesStore', {
  adminUsers: types.optional(types.array(User), []),
  notifiers: types.optional(types.array(User), []),
  failedFiles: types.optional(types.array(FailedFileListItem), []),
  selectedFile: types.maybe(FailedFile),
  apiStatus: ApiStatusStore
}).views(self => ({
  get unselectedAdminUsers() {
    return self.adminUsers.filter(admin => self.notifiers.find(notifier => notifier.id === admin.id) === void 0)
  }
})).actions(self => {
  const { api, notificationStore } = getEnv<Env>(self)
  const getFailedFileNotifiers = flow(function*() {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_NOTIFIERS)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_NOTIFIERS_ERROR)
      const data = yield api.getFailedFileNotifiers()
      self.notifiers = cast(data.notifiers)
    } catch (error) {
      notificationStore!.addNotification('error', 'We were unable to load failed file notifiers.  Please try again.', notificationTypeKeys.GET_NOTIFIERS_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_NOTIFIERS)
  })

  const getAdminAccounts = flow(function*() {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_ADMIN_ACCOUNTS)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_ADMIN_ACCOUNTS_ERROR)
      const data = yield api.getAdminAccounts()
      self.adminUsers = cast(data.accounts)
    } catch (error) {
      notificationStore!.addNotification('error', 'We were unable to load admin accounts.  Please try again.', notificationTypeKeys.GET_ADMIN_ACCOUNTS_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_ADMIN_ACCOUNTS)
  })

  const addNotifier = flow(function*(accountId : number) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.ADD_NOTIFIER)
    try {
      const user = self.adminUsers.find(user => user.id === accountId)
      if (user === void 0)
        return

      self.notifiers.push({...user})
      notificationStore!.removeNotification(null, notificationTypeKeys.ADD_NOTIFIER_ERROR)
      yield api.addFailedFilesNotifier(accountId)
    } catch (error) {
      notificationStore!.addNotification('error', 'Could not add notifier.  Please try again.', notificationTypeKeys.ADD_NOTIFIER_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.ADD_NOTIFIER)
  })

  const removeNotifier = flow(function*(accountId : number) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.REMOVE_NOTIFIER)
    try {
      const notifierIndex = self.notifiers.findIndex(user => user.id === accountId)
      if (notifierIndex === -1)
        return

      self.notifiers.splice(notifierIndex, 1)
      notificationStore!.removeNotification(null, notificationTypeKeys.REMOVE_NOTIFIER_ERROR)
      yield api.removeFailedFilesNotifier(accountId)
    } catch (error) {
      notificationStore!.addNotification('error', 'Could not remove notifier.  Please try again.', notificationTypeKeys.REMOVE_NOTIFIER_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.REMOVE_NOTIFIER)
  })

  const getFailedFiles = flow(function*() {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_FAILED_FILES)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_FAILED_FILES_ERROR)
      const data = yield api.getFailedFiles()
      self.failedFiles = cast(data.files)
    } catch (error) {
      notificationStore!.addNotification('error', 'We were unable to load failed files.  Please try again.', notificationTypeKeys.GET_FAILED_FILES_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_FAILED_FILES)
  })

  const getFailedFile = flow(function*(failedFileId : number) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_FAILED_FILE)
    if (self.selectedFile && self.selectedFile.fileText) {
      self.selectedFile!.fileText = ''
    }
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_FAILED_FILE_ERROR)
      const data = yield api.getFailedFile(failedFileId)
      self.selectedFile = cast(data)
    } catch (error) {
      notificationStore!.addNotification('error', 'We were unable to load file.  Please try again.', notificationTypeKeys.GET_FAILED_FILE_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_FAILED_FILE)
  })

  const requeueFailedFile = flow(function*(failedFileId : number, callback: () => void) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.REQUEUE_FAILED_FILE)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.REQUEUE_FAILED_FILE_ERROR)
      yield api.requeueFaileFile(failedFileId, self.selectedFile!.fileText)
      callback()
    } catch (error) {
      notificationStore!.addNotification('error', 'Could not remove failed file.  Please try again.', notificationTypeKeys.REQUEUE_FAILED_FILE_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.REQUEUE_FAILED_FILE)
  })

  const removeFailedFile = flow(function*(failedFileId : number, callback: () => void) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.REMOVE_FAILED_FILE)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.REMOVE_FAILED_FILE_ERROR)
      yield api.removeFailedFile(failedFileId)
      callback()
    } catch (error) {
      notificationStore!.addNotification('error', 'Could not remove failed file.  Please try again.', notificationTypeKeys.REMOVE_FAILED_FILE_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.REMOVE_FAILED_FILE)
  })

  return {
    getFailedFileNotifiers,
    getAdminAccounts,
    addNotifier,
    removeNotifier,
    getFailedFiles,
    getFailedFile,
    requeueFailedFile,
    removeFailedFile
  }
})

export type IFailedFilesStore = typeof FailedFilesStore.Type
export default FailedFilesStore