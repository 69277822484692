import { api } from './axios'
import { apiHelper } from '../utils/apiHelper'
import { audienceSearchAttributes } from '../ui/pages/Advanced/AudienceLists/EditAudienceList.copy'

export interface AudienceList {
  id: number,
  name: string,
  isInterest: boolean
}

type GetAudienceLists = {
  audienceLists: Array<AudienceList>
}
export const getAudienceLists = async (customerId: number): Promise<GetAudienceLists> => {
  const audienceLists = await apiHelper<any>(() => api.get(`/list-message/audience-list?customerId=${customerId}`))
  return audienceLists.data
}

export const createAudienceList = async (name: string, customerId: number): Promise<any> => {
  const audienceListCreation = await apiHelper<any>(() => api.post(`/list-message/audience-list`, { name, customerId }))
  return audienceListCreation.data
}

export const uploadAudienceList = async (separator: string, customerId: number, file: File, audienceListId: number): Promise<any> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('separator', separator)
  formData.append('audienceListId', `${audienceListId}`)
  formData.append('customerId', `${customerId}`)

  const header = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  return await apiHelper<any>(() => api.post('/list-message/audience-list/upload', formData, header))
}

export const finalizeAudienceList = async (uploadId: number, customerId: number, attributeMetadata: { [key: string]: string }[]): Promise<any> => {
  const audienceListCreation = await apiHelper<any>(() => api.post(`/list-message/audience-list/upload/${uploadId}`, { customerId, attributeMetadata }))
  return audienceListCreation.data
}

export const searchAudienceList = async (customerId: number, audienceListId: number, searchType: string, searchValue: string, limit: number, offset: number): Promise<any> => {
  let searchTypeString = ''
  Object.values(audienceSearchAttributes).forEach(attribute => {
    if (attribute === searchType) {
      searchTypeString += `&${attribute}=${searchValue}`
    } else {
      searchTypeString += `&${attribute}=`
    }
  })

  const searchAudienceListsData = await apiHelper<any>(() => api.get(`/list-message/audience-list/records?audienceListId=${audienceListId}&customerId=${customerId}${searchTypeString}&limit=${limit}&offset=${offset}`))
  return searchAudienceListsData.data
}

export const getAudienceRecord = async (customerId: number, recordId: string): Promise<any> => {
  const recordData = await apiHelper<any>(() => api.get(`/list-message/audience-list/records/${recordId}/?customerId=${customerId}`))
  return recordData.data
}

export const putAudienceRecord = async (customerId: number, recordId: number, attributes: { [key: string]: string }): Promise<any> => {
  const audienceListCreation = await apiHelper<any>(() => api.put(`/list-message/audience-list/records/${recordId}`, { customerId, attributes }))
  return audienceListCreation.data
}

export const deleteAudienceRecord = async (customerId: number, recordId: number): Promise<any> => {
  await apiHelper<any>(() => api.delete(`/list-message/audience-list/records/${recordId}`, { data: { customerId } }))
}

export const createAudienceRecord = async (customerId: number, audienceListId: number, attributes: { [key: string]: string }): Promise<any> => {
  await apiHelper<any>(() => api.post(`/list-message/audience-list/records`, { customerId, audienceListId, attributes }))
}

export const deleteAudienceList = async (customerId: number, id: number): Promise<any> => {
  await apiHelper<any>(() => api.delete(`/list-message/audience-list/${id}`, { data: { customerId } }))
}

export const getAudienceList = async (customerId: number, audienceListId: number): Promise<any> => {
  const audienceLists = await apiHelper<any>(() => api.get(`/list-message/audience-list/${audienceListId}?customerId=${customerId}`))
  return audienceLists.data
}

export const updateAudienceListInterest = async (customerId: number, audienceListId: number, isInterest: boolean): Promise<any> => {
  await apiHelper<any>(() => api.post(`/list-message/audience-list/${audienceListId}/interest`, { customerId, audienceListId, isInterest }))
}

interface Widget {
  id: number,
  name: string,
  accessCode: string,
  audienceLists: Array<AudienceList>
}

type GetWidgets = {
  widgets: Array<Omit<Widget, 'audienceLists'>>
}
export const getWidgets = async (customerId: number): Promise<GetWidgets> => {
  const widget = await apiHelper<any>(() => api.get(`/list-message/widget/?customerId=${customerId}`))
  return widget.data
}

export const getWidget = async (customerId: number, widgetId: number): Promise<Widget> => {
  const widget = await apiHelper<any>(() => api.get(`/list-message/widget/${widgetId}?customerId=${customerId}`))
  return widget.data
}

export const createWidget = async (customerId: number, name: string): Promise<any> => {
  await apiHelper<any>(() => api.post(`/list-message/widget`, { customerId, name }))
}

export const editWidget = async (customerId: number, widgetId: number, audienceListId: number): Promise<any> => {
  await apiHelper<any>(() => api.post(`/list-message/widget/${widgetId}`, { customerId, widgetId, audienceListId }))
}

interface SmsInterestSettings {
  keywords: {
    id: number,
    keyword: string
  }[]
}

export const getKeywords = async (customerId: number, audienceListId: number): Promise<SmsInterestSettings> => {
  const keywords = await apiHelper<any>(() => api.get(`/list-message/audience-list/${audienceListId}/interest/keyword?customerId=${customerId}`))
  return keywords.data
}

export const addKeyword = async (customerId: number, audienceListId: number, keyword: string): Promise<any> => {
  await apiHelper<any>(() => api.post(`/list-message/audience-list/${audienceListId}/interest/keyword`, { customerId, keyword }))
}

export const deleteKeyword = async (customerId: number, audienceListId: number, keywordId: number): Promise<any> => {
  await apiHelper<any>(() => api.delete(`/list-message/audience-list/${audienceListId}/interest/keyword/${keywordId}?customerId=${customerId}`))
}