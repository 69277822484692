import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'

interface ChipProps {
  label: string,
  style: {
    backgroundColor: string,
    cursor: string,
    opacity: number,
    
  },
  children: any
}

const LoginRedirect: React.FC<ChipProps> = (props: ChipProps) => {
  return (
    <Chip
      avatar={
        <Avatar>
          {props.children}
        </Avatar>
      }
      style={{ backgroundColor: props.style.backgroundColor, cursor: props.style.cursor, opacity: props.style.opacity }}
      label={props.label}
      color='secondary'
    />
  )
}

export default LoginRedirect
