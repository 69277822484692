import { types } from 'mobx-state-tree'

const Settings = types.model('Settings', {
  digestFrequency: types.enumeration(['None', 'Daily', 'Weekly', 'Monthly']),
  digestFailReport: types.boolean
}).actions(self => ({
  setDigestFrequency(frequency : string) {
    self.digestFrequency = frequency
    if (frequency !== 'Daily')
      self.digestFailReport = false
  },
  toggleFailReport() {
    self.digestFailReport = !self.digestFailReport
  }
}))

export type ISettings = typeof Settings.Type
export default Settings