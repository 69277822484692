import styled from 'styled-components'

export const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 16px;
`

export const FormElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
`

export const CalendarsOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  width: 98.5%;
  padding-right: 15px;
  margin-bottom: 10px;
`

export const CalendarsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const CalendarContainer = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const CalendarLabel = styled.div`
  width: 100%;
  height: 16px;
  margin-bottom: 6px;
`

export const Calendar = styled.div`
  width: 100%;
  flex-grow: 1;
  margin-left: 4px;
  margin-right: 4px;
`
