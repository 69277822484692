import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './SaveReportModal.styled'
import * as modalStyled from '../../shared/Modals/Modals.styled'
import { IReportStore } from '../../../../stores/ReportStore'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ButtonLoading from '../../shared/Loading/ButtonLoading'
import { apiStatusDefinitions } from '../../../../utils/apiStatusDefinitions'
import whiteBee from '../../../content/img/whiteLogoBee32x32.png'

interface DownloadableReportProps {
  reportStore?: IReportStore
}

@inject((allStores: any) => {
  return {
    reportStore: allStores.reportStore as IReportStore
  }
})
@observer
class SaveDownloadableReport extends Component<DownloadableReportProps> {
  public state = {
    reportName: ''
  }

  private handleReportNameChange = (event: any) => {
    this.setState({ reportName: event.target.value })
  }

  private closeModal = () => {
    this.props.reportStore!.setAsyncReportModalVisible(false)
  }

  private saveReport = () => {
    this.props.reportStore!.retrieveAsyncReportData(this.state.reportName)
  }

  public render() {
    return (
      <modalStyled.ModalWrapper>
        <modalStyled.ModalContainer>
          <modalStyled.ModalTitleBar>
            <modalStyled.ModalTitle>
              <modalStyled.Logo src={whiteBee} />
              <>
                Save Report
              </>
            </modalStyled.ModalTitle>
            <modalStyled.ModalExit onClick={this.closeModal.bind(this)}>
              x
            </modalStyled.ModalExit>
          </modalStyled.ModalTitleBar>
          <modalStyled.ModalContent>
            <div>
              <styled.EditReportDownloadDialogueWrapper>
                <div>
                  <styled.Title>
                    Save Report
                  </styled.Title>
                  <styled.SubTitle>
                    The current report is too large to be displayed. Enter a name to save a downloadable version of this report.
                  </styled.SubTitle>
                  <styled.ReportDownloadInputContainer>
                    <TextField
                      onChange={this.handleReportNameChange}
                      type='text'
                      name='downloadableReportName'
                      value={this.state.reportName}
                      placeholder='Report Name'
                      label='Report Name'
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      key={`new_formField`}
                    />
                  </styled.ReportDownloadInputContainer>
                  <styled.ButtonRowWrapper>
                    <styled.ButtonRow>
                      <Button onClick={this.closeModal.bind(this)} variant='contained' style={{ marginRight: 10, color: 'white' }} color='secondary'>
                        Cancel
                      </Button>
                      <Button onClick={this.saveReport.bind(this)} style={{ minWidth: 137 }} variant='contained' color='primary'>
                        {this.props.reportStore!.apiStatus.checkApiStatus(apiStatusDefinitions.UPDATE_TASK_LAMBDA_ARN) ? (
                          <ButtonLoading />
                        ) : (
                          <>Save Report</>
                        )}
                      </Button>
                    </styled.ButtonRow>
                  </styled.ButtonRowWrapper>
                </div>
              </styled.EditReportDownloadDialogueWrapper>
            </div>
          </modalStyled.ModalContent>
        </modalStyled.ModalContainer>
      </modalStyled.ModalWrapper>

    )
  }
}

export default SaveDownloadableReport