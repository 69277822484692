import { types } from 'mobx-state-tree'

const ApiStatusStore = types.model({
  status: types.optional(types.array(types.string), [])
}).actions(self => ({
  toggleAPIStatus: (sourceButtonKey: string) => {
    if (self.status.includes(sourceButtonKey)) {
      self.status.splice(self.status.findIndex(element => element === sourceButtonKey), 1)
    } else {
      self.status.push(sourceButtonKey)
    }
  }
})).views(self =>({
  checkApiStatus(apiStatusDefinition: string) {
    return self.status.includes(apiStatusDefinition)
  }
}))

export type IApiStatusStore = typeof ApiStatusStore.Type
export default ApiStatusStore