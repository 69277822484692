import styled from 'styled-components'

export const AddTemplateImageContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  height: 91%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const AddTemplateImageForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

export const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 100%;
  font-size: 16px;
`

export const FormElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 98%;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
`

export const FormButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 33px;
`

export const SpaceOrPeriodError = styled.div`
  font-weight: 12px;
  color: #f44336;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: rgba(244, 67, 54, 0.08);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  text-indent: 10px;
`