import { types } from 'mobx-state-tree'

const MediaLibraryItem = types.model('MedialLibraryItem', {
  id: types.identifierNumber,
  name: types.string,
  description: types.string,
  itemPath: types.string,
  previewPath: types.string,
  uploadedDate: types.string,
  altText: types.maybeNull(types.string),
})

export type IMediaLibraryItem = typeof MediaLibraryItem.Type
export default MediaLibraryItem