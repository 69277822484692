import { types } from 'mobx-state-tree'

const SuggestedAttribute = types.model('SuggestedAttributes', {
  givenHeader: types.string,
  suggestedPropertyName: types.maybeNull(types.string)
})

const availableAttributes = types.model('AvailableAttributes', {
  displayName: types.string,
  propertyName: types.string
})

const AudienceListFinalization = types.model('AudienceListFinalization', {
  id: types.identifierNumber,
  suggestedAttributes: types.array(SuggestedAttribute),
  availableAttributes: types.array(availableAttributes)
})

export type IAudienceListFinalization = typeof AudienceListFinalization.Type
export default AudienceListFinalization
