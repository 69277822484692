import styled from 'styled-components'

interface MiddleContentProps {
  overflowHorizontal?: boolean
}

export const MiddleContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 34px;
  margin-top: 20px;
  flex-grow: 1;
  ${(props: MiddleContentProps) => { return props.overflowHorizontal ? 'overflow-x: auto' : ''}}

`

export const MiddleContentHeader = styled.div`
  font-size: 24px;
  line-height: 40px;
  color: #969696;
`

export const MiddleContentBody = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  color: #969696;
  font-size: 12px;
  text-align: center;
`