import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps } from 'react-router'
import Dashboard from '../../hocs/DashBoard/Dashboard'
import MiddleContent from '../../components/DashBoard/MiddleContent'
import RightContent from '../../components/DashBoard/RightContent'
import ReportContainer from '../../components/DashBoard/ReportsContainer'
import GeographicReportContainer from '../../components/DashBoard/GeographicReportContainer'
import { IReportStore } from '../../../stores/ReportStore'
import { INotificationStore } from '../../../stores/NotificationStore'
import { ICustomerStore } from '../../../stores/CustomerStore'
import ReportFilters from '../../components/DashBoard/ReportFilters'
import SaveReportModal from '../../components/ModalContent/Reports/SaveReportModal'
import * as styled from './Reports.styled'

interface ReportProps {
  reportStore: IReportStore,
  notificationStore?: INotificationStore,
  customerStore: ICustomerStore
}

@inject((allStores: any) => {
  return {
    reportStore: allStores.reportStore as IReportStore,
    notificationStore: allStores.notificationStore as INotificationStore,
    customerStore: allStores.customerStore as ICustomerStore
  }
})
@observer
class Reports extends Component<RouteComponentProps & ReportProps> {  
  public state = {
    filter: false
  }

  // Need to get real types on this
  public componentDidCatch(error: any, info: any) {
    this.props.reportStore.toggleReportError(true)
  }

  public setFilters () {
    this.setState({ filter: true })
  }

  private renderReport () {
    if (this.props.customerStore.setCustomerId > 0 && this.props.reportStore.error) {
      return <styled.NoDataMessage>Sorry but there is no data for this selection of report filters.  Please select different filters and try again.  If you believe this to be in error please contact your MessageBee administrator.</styled.NoDataMessage>
    } else if (this.props.customerStore.setCustomerId > 0 && this.props.reportStore.filters.communicationChannel !== 'mail') {
      return <ReportContainer customerStore={this.props.customerStore} reportStore={this.props.reportStore} />
    } else if (this.props.customerStore.setCustomerId > 0 && this.props.reportStore.filters.communicationChannel === 'mail') {
      return <GeographicReportContainer customerStore={this.props.customerStore} reportStore={this.props.reportStore} />
    } else {
      return <div>Loading...</div>
    }
  }

  public render () {
    return (
      <Dashboard history={this.props.history}>
        <MiddleContent title='Reports and Graphs'>
          {this.renderReport()}
        </MiddleContent>
        <RightContent title='Report Filters'>
          <ReportFilters customerStore={this.props.customerStore} reportStore={this.props.reportStore} />
        </RightContent>
        { this.props.reportStore.showAsyncReportModal ? <SaveReportModal /> : ''}
      </Dashboard>
    ) 
  }
}

export default Reports
