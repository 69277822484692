export const chartColors = [
  'hsla(27, 92%, 55%, 1)',
  'hsla(27, 72%, 65%, 1)',
  'hsla(27, 62%, 75%, 1)',
  'hsla(27, 62%, 85%, 1)',
  'hsla(231, 48%, 48%, .5)',
  'hsla(231, 48%, 48%, .3)',
  'hsla(231, 48%, 48%, .15)',
  'hsla(27, 32%, 75%, 1)',
  'hsla(27, 92%, 45%, 1)',
]

interface SeriesToolTips {
  SENT: string,
  SUCCESSFUL: string,
  FAILED: string,
  OPTOUT: string,
  [key: string]: string
}

export const seriesToolTips: SeriesToolTips = {
  SENT: 'Message processed and a delivery attempt has been made.',
  OPENED: 'Number of messages opened by recipients.',
  CLICKED: 'Number of times a link within the message has been clicked upon by the recipient.',
  OPTOUT: 'Recipient has chosen to opt-out from the message.',
  SUCCESSFUL: 'Message has been successfully delivered, been opened, and/or content within the message has been clicked by the recipient.',
  FAILED: 'Any unsuccessful delivery to a recipient.',
  SMS_SEGMENTS: 'Number of SMS segments sent.',
}

interface FailedToolTips {
  FAILED: string,
  REASON: string,
  [key: string]: string
}

export const failedToolTips: FailedToolTips = {
  FAILED: 'The email address or phone associated with the "bounced" email or unanswered call.',
  REASON: 'The reason why the email "bounced." or call failed.'
}

interface ClicksToolTips {
  URL: string,
  CLICKS: string,
  [key: string]: string
}

export const clicksToolTips: ClicksToolTips = {
  URL: 'The clicked url.',
  CLICKS: 'The number of clickthroughs for this particular url.'
}

export const seriesNames = [
  'bounce',
  'click',
  'delivered',
  'open',
  'sent'
]

export const defaultReportFilterValues = [
  { value: 1, name: 'Last 7 Days' },
  { value: 2, name: 'Last 14 Days' },
  { value: 3, name: 'Usage Notification Types (Opens and Clicks)' },
  { value: 4, name: 'Error Notification Types (Bounces, Blocked, and Spam)' },
]

export const communicationChannelValues = [
  { value: 'all', label: 'All' },
  { value: 'email', label: 'E-mail' },
  { value: 'sms', label: 'SMS' }
]

export const timezoneValues = [
  { value: '-0400', label: 'UTC -0400' },
  { value: '-0500', label: 'UTC -0500' },
  { value: '-0600', label: 'UTC -0600' },
  { value: '-0700', label: 'UTC -0700' },
  { value: '-0800', label: 'UTC -0800' },
]