import React, { Component, ReactNode } from 'react'
import * as styled from './RightContent.styled'
 
interface RightContentProps {
  title: string,
  children: ReactNode
}

interface RightContentState {
  filter: string | null
}

class RightContent extends Component<RightContentProps, RightContentState> {
  public render() {
    return (
      <styled.RightContentContainer>
        <styled.RightContentHeader>
          {this.props.title}
        </styled.RightContentHeader>
        <styled.RightContentBody>
          {this.props.children}
        </styled.RightContentBody>
      </styled.RightContentContainer>
    )
  }
}

export default RightContent
