import { types } from 'mobx-state-tree'

const PatronDatabaseMetaData = types.model('PatronDatabaseMetaData', {
  $id: types.string,
  $schema: types.string,
  description: types.string,
  required: types.array(types.string),
  title: types.string,
  type: types.string
}).volatile(() => ({
  properties: {},
  initialProperties: {}
}))

export type IPatronDatabaseMetaData = typeof PatronDatabaseMetaData.Type
export default PatronDatabaseMetaData

