import { api } from './axios'
import { apiHelper } from '../utils/apiHelper'

/* Patron Database Metadata */
export const createPatronDatabase = async (customerId: number): Promise<any> => {
  const patronDatabaseCreation = await apiHelper<any>(() => api.post(`/dynamic-list/${customerId}/database`, {}))
  return patronDatabaseCreation
}

export const getPatronDatabase = async (customerId: number): Promise<any> => {
  const patronDatabase = await apiHelper<any>(() => api.get(`/dynamic-list/${customerId}/database`))
  return patronDatabase
}

export const updatePatronDatabase = async (customerId: number, schema: any, addedFields: string[]): Promise<any> => {
  await apiHelper<any>(() => api.put(`/dynamic-list/${customerId}/database`, { schema, addedFields }))
}

/* Lists */
export const getPatronLists = async (customerId: number): Promise<any> => {
  const getPatronLists = await apiHelper<any>(() => api.get(`/dynamic-list/${customerId}/list`))
  return getPatronLists.data
}

export const createPatronList = async (customerId: number, name: string): Promise<any> => {
  const patronDatabaseCreation = await apiHelper<any>(() => api.post(`/dynamic-list/${customerId}/list`, { name }))
  return patronDatabaseCreation.data
}

export const getPatronList = async (customerId: number, id: number): Promise<any> => {
  const getPatronList = await apiHelper<any>(() => api.get(`/dynamic-list/${customerId}/list/${id}`))
  return getPatronList.data
}

export const savePatronListFilterDefinition = async (customerId: number, id: number, filterDefinition: string): Promise<any> => {
  await apiHelper<any>(() => api.put(`/dynamic-list/${customerId}/list/${id}`, { filterDefinition }))
}

export const updateListPreviewCount = async (customerId: number, id: number): Promise<any> => {
  await apiHelper<any>(() => api.put(`/dynamic-list/${customerId}/list/${id}/count`, {}))
}

export const deleteDynamicList = async (customerId: number, id: number): Promise<any> => {
  await apiHelper<any>(() => api.delete(`/dynamic-list/${customerId}/list/${id}`))
}

export const dowloadListPreview = async (customerId: number, id: number): Promise<any> => {
  const getPatronList = await api.get(`/dynamic-list/${customerId}/list/${id}/preview`)
  return getPatronList.data
}