import { types, getEnv, flow, cast } from 'mobx-state-tree'
import ReportData from '../models/ReportData'
import GeographicReportData from '../models/GeographicReportData'
import GeographicReportDataReduced from '../models/GeographicReportDataReduced'
import ReportFilters from '../models/ReportFilters'
import { apiStatusDefinitions } from '../utils/apiStatusDefinitions'
import { notificationTypeKeys } from '../ui/components/shared/Notifications/Notifications.copy'
import { differenceInCalendarDays } from 'date-fns'
import ApiStatusStore from './ApiStatusStore'
import CommunicationChannelStore from './CommunicationChannelStore'
import { getStartingEndDate } from '../utils/generalHelpers'

interface DayData {
  bounce: number,
  click: number,
  delivered: number,
  open: number,
  sent: number
  uniqueOpen: number
}

interface DayBreakdown {
  [day: string]: DayData
}

interface NotificationTypeBreakDown {
  [notificationType: string]: DayBreakdown
}

interface ReportData {
  formattedData: any,
  unformattedData: any,
  bounces: any,
  clickGroups: any,
  requiresAsync: boolean
}

export interface ClickDaysData {
  [day: string]: string[]
}

const BounceReportFields = types.model('BounceReportFields', {
  date: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  barcode: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  notificationName: types.maybeNull(types.string)
})


const ClickReportFields = types.model('ClickReportFields', {
  url: types.string,
  clicks: types.number,
  dates: types.maybeNull(types.frozen<ClickDaysData>())
})

const ReportStore = types.model({
  reportType: types.maybeNull(types.string),
  filters: ReportFilters,
  showGraph: types.optional(types.boolean, false),
  selectedDefaultReportFilter: types.maybeNull(types.number),
  data: types.optional(types.array(ReportData), []),
  geoGraphicReportEventData: types.optional(types.array(GeographicReportData), []),
  geoGraphicReportEventDataReduced: types.optional(types.array(GeographicReportDataReduced), []),
  unformattedData: types.maybeNull(types.array(types.frozen<NotificationTypeBreakDown>())),
  bounces: types.maybeNull(types.array(BounceReportFields)),
  clicks: types.maybeNull(types.array(ClickReportFields)),
  apiStatus: ApiStatusStore,
  communicationChannelStore: CommunicationChannelStore,
  showAsyncReportModal: false,
  asyncReport: false,
  error: types.optional(types.boolean, false)
}).views(self => ({
  get retrieveFilteredData() {
    let filtered = self.data.filter((item) => !self.filters.deselectedSeries.includes(item.id))
    return filtered
  },
  get notificationType() {
    const customerData = getEnv(self).customerStore.customerData
    return customerData.notificationTypes.find((notification: typeof customerData.customers.notificationType) => notification.name === self.filters.notificationType)
  },
  get allNotificationSubTypes() {
    const customerData = getEnv(self).customerStore.customerData
    return customerData.notificationTypes!.reduce((acc: any, notificationType: typeof customerData.customers.notificationType) => {
      return acc.concat(notificationType.subTypes.map((item: any) => {
        const subType = Object.assign({}, item)
        subType.notificationTypeName = notificationType.name
        return subType
      }))
    }, [])
  },
  get notificationData() {
    const customerStore = getEnv(self).customerStore
    const selectedNotificationType = this.notificationType
    let selectedNotificationTypeSubTypes
    if (self.communicationChannelStore.channelDefaultOrSelectedValue === 'all') {
      selectedNotificationTypeSubTypes = selectedNotificationType?.subTypes || []
    } else {
      selectedNotificationTypeSubTypes = selectedNotificationType?.subTypes?.filter((obj: typeof customerStore.customers.notificationType.subTypes) => obj.channel === self.communicationChannelStore.channelDefaultOrSelectedValue) || []
    }
    return selectedNotificationTypeSubTypes
  },
  get filteredNotificationSubTypes() {
    if (self.filters.notificationSubType && self.filters.notificationSubType !== 'all') {
      return self.filters.notificationSubType
    } else {
      let allSubTypes = this.allNotificationSubTypes
      if (self.communicationChannelStore.channelDefaultOrSelectedValue !== 'all') {
        allSubTypes = allSubTypes.filter((obj: any) => obj.channel === self.communicationChannelStore.channelDefaultOrSelectedValue)
      }
      if (self.filters.notificationType !== 'all') {
        allSubTypes = allSubTypes.filter((obj: any) => obj.notificationTypeName === self.filters.notificationType)
      }
      return allSubTypes.map((subType: any) => subType.id).join(',')
    }
  },
})).actions((self): any => {
  const { notificationStore, customerStore, api } = getEnv(self)
  const toggleReportError = (error: boolean): void => {
    self.error = error
  }
  const setAsyncReportModalVisible = (shouldShow : boolean)  => self.showAsyncReportModal = shouldShow
  const filterSeries = (seriesName: string): void => {
    if (self.filters && self.filters.deselectedSeries && self.filters!.deselectedSeries!.findIndex(element => element === seriesName) >= 0) {
      self.filters!.deselectedSeries!.splice(self.filters!.deselectedSeries!.findIndex(element => element === seriesName), 1)
    } else {
      self.filters!.deselectedSeries.push(seriesName)
    }
  }
  const filterDates = (date: string, type: string) => {
    if (type === 'start') {
      self.filters.startDate = date
    } else {
      self.filters.endDate = date
    }
  }
  const filterDataTable = (type: string) => {
    self.filters.dataTableSelection = type
  }
  const filterCommunicationChannel = (type: string) => {
    self.filters.notificationType = 'all'
    self.filters.notificationSubType = 0
    self.communicationChannelStore.communicationChannel = type
  }
  const setNotificationType = (notificationType: string) => {
    self.filters.notificationSubType = 0
    self.filters.notificationType = notificationType
  }
  const setNotificationSubType = (notificationSubType: number) => {
    self.filters.notificationSubType = notificationSubType
  }
  const setDefaultReportFilter = (defaultReportFilterValue: number) => {
    self.selectedDefaultReportFilter = defaultReportFilterValue
  }
  const setSeriesDefaultReportFilters = (defaultSeriesFilters: string[]) => {
    self.filters.deselectedSeries = cast(defaultSeriesFilters)
  }
  const setTimezoneOffset = (timezoneOffset: string) => {
    self.filters.timezone = timezoneOffset
  }
  const resetReportFiltersAndSelectCustomer = (customerId: number) => {
    self.communicationChannelStore.communicationChannel = null
    self.filters.notificationType = 'all'
    self.filters.notificationSubType = 0
    self.filters.timezone = '-0400'
    self.filters.startDate = getStartingEndDate(7)
    self.filters.endDate = getStartingEndDate(0)
    customerStore.setSelectedCustomerId(customerId)
  }
  const retrieveReportData = flow(function* (this: any) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.REPORT_DATA)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.REPORT_DATA_ERROR)
      if (differenceInCalendarDays(self.filters.endDate as any, self.filters.startDate as any) < 0) {
        notificationStore!.addNotification('error', 'Please select a date range of at least 1 day.', notificationTypeKeys.REPORT_DATA_ERROR)
      } else {
        if (self.asyncReport) {
          setAsyncReportModalVisible(true)
          self.apiStatus.toggleAPIStatus(apiStatusDefinitions.REPORT_DATA)
          return
        }
        const reportData: ReportData = yield api.retrieveReportData(self.filters.startDate, self.filters.endDate, customerStore.setCustomerId, self.filteredNotificationSubTypes, self.filters.timezone, self.communicationChannelStore.communicationChannel)
        if (reportData.requiresAsync) {
          setAsyncReportModalVisible(true)
          self.apiStatus.toggleAPIStatus(apiStatusDefinitions.REPORT_DATA)
          return
        }
        
        self.data = reportData!.formattedData
        self.unformattedData = reportData!.unformattedData
        self.bounces = reportData!.bounces
        self.clicks = reportData!.clickGroups
        
        let setError = false
        let showGraph = false
        self.unformattedData && self.unformattedData.forEach((notificationTypeData: any) => {
          const notificationName = notificationTypeData[0]
          const notificationDayData = notificationTypeData[1]
          const notificationDayDataEntries = Object.entries(notificationDayData)

          if (notificationDayDataEntries[0][0] === "Total" && notificationName !== 'All') {
            setError = true
          }

          if (notificationDayDataEntries.length && notificationDayDataEntries.length > 2) {
            showGraph = true
          }
        })

        self.error = setError
        self.showGraph = showGraph

        const clickData: ReportData = yield api.retrieveReportClickData(self.filters.startDate, self.filters.endDate, customerStore.setCustomerId, self.filteredNotificationSubTypes, self.filters.timezone, self.communicationChannelStore.communicationChannel)
        self.clicks = clickData!.clickGroups
      }
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.REPORT_DATA_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.REPORT_DATA)
  })
  const retrieveAsyncReportData = flow(function* (reportName: string) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.ASYNC_REPORT_DATA)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.REPORT_DATA_ERROR)
      const reportData: ReportData = yield api.retrieveReportData(self.filters.startDate, self.filters.endDate, customerStore.setCustomerId, self.filteredNotificationSubTypes, self.filters.timezone, self.communicationChannelStore.communicationChannel, true, reportName)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.REPORT_DATA_ERROR)
    }
    notificationStore!.addNotification('success', 'You will be notified when your report is available for download.', notificationTypeKeys.REPORT_DATA_ERROR)
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.ASYNC_REPORT_DATA)
    setAsyncReportModalVisible(false)
  })
  const toggleAsyncReport = () => {
    self.asyncReport = !self.asyncReport
  }
  
  return {
    toggleReportError,
    filterSeries,
    filterDates,
    filterDataTable,
    filterCommunicationChannel,
    retrieveReportData,
    setNotificationType,
    setNotificationSubType,
    setDefaultReportFilter,
    setSeriesDefaultReportFilters,
    setTimezoneOffset,
    resetReportFiltersAndSelectCustomer,
    setAsyncReportModalVisible,
    retrieveAsyncReportData,
    toggleAsyncReport
  }
})

export type IReportStore = typeof ReportStore.Type
export default ReportStore
