import { types } from 'mobx-state-tree'

const MediaItem = types.model('MediaItem', {
  id: types.identifierNumber,
  name: types.string,
  description: types.string,
  extension: types.string,
  itemPath: types.string,
  previewPath: types.string,
  uploadedDate: types.string,
})

const customerBranch = types.model('MediaItem', {
  id: types.identifierNumber,
})

const TemplateImateConfigurationItem = types.model('TemplateImateConfigurationItem', {
  id: types.identifierNumber,
  mediaItem: types.maybeNull(MediaItem),
  customerBranch: types.maybeNull(customerBranch),
  startDate: types.string,
  endDate: types.string,
  configurationLinkUrl: types.maybeNull(types.string),
  configurationHtml: types.maybeNull(types.string)
})



export type ITemplateImateConfigurationItem = typeof TemplateImateConfigurationItem.Type
export default TemplateImateConfigurationItem