import styled from 'styled-components'

export const DetailedSearchContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const InviteUserContainer = styled.div`
  width: 96%;
  height: 100%;
  padding-right: 35px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: left;
`

export const DataTableContainer = styled.div`
  width: 96%;
  height: 100%;
  padding-right: 35px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const FormContainer = styled.div`
  width: 100%;
`

export const SubTitle = styled.div`
  font-size: 16px;
  margin-top: 10px;
`

export const Instruction = styled.div`
  font-size: 12px;
  margin-top: 10px;
`

export const Form = styled.div`
  margin-top: 10px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const ButtonContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`

export const FormElementWrapper = styled.div`
  margin-bottom: 10px;
  width: 100%;
`

export const FontAwesomeInnerContainer = styled.div`
  opacity: 0.56;
  margin-bottom: 20px;
  -webkit-animation: AnimatedGradientChart 1s ease infinite;
  -moz-animation: AnimatedGradientChart 1s ease infinite;
  animation: AnimatedGradientChart 1s ease infinite;

  @-webkit-keyframes AnimatedGradientChart {
    0%{opacity: 0.40}
    50%{opacity: 0.6}
    100%{opacity: 0.40}
  }
  @-moz-keyframes AnimatedGradientChart {
      0%{opacity: 0.40}
      50%{opacity: 0.6}
      100%{opacity: 0.40}
  }
  @keyframes AnimatedGradientChart { 
      0%{opacity: 0.40}
      50%{opacity: 0.6}
      100%{opacity: 0.40}
  }
`

export const FontAwesomeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const LoadingContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`
