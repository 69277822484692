import { api } from './axios'
import { apiHelper } from '../utils/apiHelper'

export const createNewTemplateImage = async (customerId: number, variableName: string, friendlyName: string, html?: string) => {
  return await apiHelper<any>(() => api.post(`/template-image`, { customerId, variableName, friendlyName, html }))
}

export const retrieveTemplateImages = async (customerId: number) => {
  const apiUrl = `/template-image/?customerId=${customerId}`
  const getTemplateImages = await apiHelper<any>(() => api.get(apiUrl))
  return getTemplateImages.data.images
}

export const retrieveTemplateImageConfigurations = async (templateImageId: number, customerId: number) => {
  const apiUrl = `/template-image/${templateImageId}?customerId=${customerId}`
  const getTemplateImageConfigurations = await apiHelper<any>(() => api.get(apiUrl))
  return getTemplateImageConfigurations.data.configurations
}

export const createNewTemplateImageConfiguration = async (templateImageId: number, customerId: number, startDate: string, endDate: string, url: string, html?: string, branchId?: null | number) => {
  const apiUrl = `/template-image/${templateImageId}`
  return await apiHelper<any>(() => api.post(apiUrl, { customerId, startDate, endDate, url, html, branchId }))
}

export const retrieveImageConfigurationsBranchOverrides = async (customerId: number, templateImageId: number, branchId: number) => {
  const apiUrl = `/template-image/branch-overrides/${templateImageId}?customerId=${customerId}&branchId=${branchId}`
  const getTemplateImageConfigurationBranchOverrides = await apiHelper<any>(() => api.get(apiUrl))
  return getTemplateImageConfigurationBranchOverrides.data.configurations
}

export const editTemplateImageConfiguration = async (templateImageId: number, templateImageConfigurationId: number, customerId: number, startDate: string, endDate: string, url: string, html?: string, branchId?: null | number) => {
  const apiUrl = `/template-image/${templateImageId}/date-range-and-url/${templateImageConfigurationId}`
  return await apiHelper<any>(() => api.patch(apiUrl, { customerId, startDate, endDate, url, html, branchId }))
}

export const saveConifgurationImage = async (templateImageId: number, templateImageConfigurationId: number, customerId: number, mediaItemId: number, branchId?: null | number) => {
  const apiUrl = `/template-image/${templateImageId}/image/${templateImageConfigurationId}`
  return await apiHelper<any>(() => api.patch(apiUrl, { customerId, mediaItemId, branchId }))
}

export const saveFallbackImage = async (templateImageId: number, customerId: number, fallbackMediaItemId: number) => {
  const apiUrl = `/template-image/${templateImageId}`
  return await apiHelper<any>(() => api.patch(apiUrl, { customerId, fallbackMediaItemId }))
}

export const updateFallbackLinkUrlAndOrHtml = async (templateImageId: number, customerId: number, url: string, html?: string) => {
  const apiUrl = `/template-image/url-and-or-html/${templateImageId}`
  return await apiHelper<any>(() => api.patch(apiUrl, { customerId, url, html }))
}

export const deleteFallbackImage = async (templateImageId: number, customerId: number) => {
  const apiUrl = `/template-image/${templateImageId}?customerId=${customerId}`
  return await apiHelper<any>(() => api.delete(apiUrl))
}

export const deleteImageConfiguration = async (templateImageId: number, customerId: number, configurationId: number, branchId: number = null) => {
  const apiUrl = `/template-image/${templateImageId}/${configurationId}?customerId=${customerId}&branchId=${branchId}`
  return await apiHelper<any>(() => api.delete(apiUrl))
}