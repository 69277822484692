import styled from 'styled-components'

export const AppContainer = styled.div`
  font-family: 'Roboto';
  width: 100%;
  min-height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow-x: hidden;
`
