import React, { Component, useRef } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './EditAndAddDates.styled'
import { ITemplateCustomizationStore } from '../../../../stores/TemplateCustomizationStore'
import Button from '@material-ui/core/Button'
import Calendar from '../../shared/Calendar/Calendar'
import { getStartingEndDate } from '../../../../utils/generalHelpers'
import TextField from '@material-ui/core/TextField'
import JoditEditor from 'jodit-react'
import { InputLabel } from '@material-ui/core'

interface MediaLibraryContainerProps {
  templateCustomizationStore?: ITemplateCustomizationStore
}

@inject((allStores: any) => {
  return {
    templateCustomizationStore: allStores.templateCustomizationStore as ITemplateCustomizationStore
  }
})
@observer
class EditAndAddDates extends Component<MediaLibraryContainerProps> {
  public constructor(props: MediaLibraryContainerProps) {
    super(props)
    this.determineStartAndEndDatesAndLinkUrl = this.determineStartAndEndDatesAndLinkUrl.bind(this)
  }

  public state = {
    form: {
      startDate: '',
      endDate: '',
      configurationLinkUrl: '',
      configurationHtml: null
    },
    formErrors: {
      startDate: false,
      endDate: false,
      configurationLinkUrl: false
    },
    saveActive: false,
    dateRangeError: false
  }

  private handleChange = (dateOrText: string, type?: string) => (event?: any) => {
    const form = this.state.form
    let updatedForm
    updatedForm = {
      [dateOrText]: event.target.value
    }

    this.setState({ form: { ...form, ...updatedForm } })
  }

  private handleDateChange = (date: string, type?: string) => {
    const form = this.state.form
    let updatedForm
    updatedForm = {
      [`${type}Date`]: date
    }

    this.setState({ form: { ...form, ...updatedForm } })
  }

  private checkFieldsAndSubmit = async () => {
    if (!this.state.saveActive) {
      this.setState({ saveActive: true })
      const formErrors = this.state.formErrors
      const datesAndUrl = this.determineStartAndEndDatesAndLinkUrl()

      if (datesAndUrl.startDate!.length < 1) {
        formErrors.startDate = true
      } else {
        formErrors.startDate = false
      }

      if (datesAndUrl.endDate!.length < 1) {
        formErrors.endDate = true
      } else {
        formErrors.endDate = false
      }

      if (datesAndUrl.linkUrl.length < 1) {
        formErrors.configurationLinkUrl = true
      } else {
        formErrors.configurationLinkUrl = false
      }

      let blocked = false
      if (datesAndUrl.startDate!.length > 0 && datesAndUrl.endDate!.length > 0 && datesAndUrl.linkUrl.length > 0) {
        this.setState({ formErrors: formErrors })
        if (this.props.templateCustomizationStore!.templateImageConfigurations) {
          let filteredConfigurations
          if (this.props.templateCustomizationStore!.selectedTemplateImageConfigurationId) {
            filteredConfigurations = this.props.templateCustomizationStore!.templateImageConfigurations.filter(configuration => configuration.id !== this.props.templateCustomizationStore!.selectedTemplateImageConfigurationId)
          } else {
            filteredConfigurations = this.props.templateCustomizationStore!.templateImageConfigurations
          }

          filteredConfigurations.forEach(range => {
            const startDateForComparision = new Date(datesAndUrl.startDate!)
            const endDateForComparision = new Date(datesAndUrl.endDate!)
            const startDate = new Date(range.startDate)
            const endDate = new Date(range.endDate)
            if ((startDateForComparision >= startDate && startDateForComparision <= endDate) || (endDateForComparision >= startDate && endDateForComparision <= endDate) || (startDate >= startDateForComparision && startDate <= endDateForComparision) || (endDate >= startDateForComparision && endDate <= endDateForComparision)) {
              this.setState({ dateRangeError: true })
              blocked = true
              return
            }
          })
        }
        if (!blocked) {
          if (this.props.templateCustomizationStore!.templateImageFunctionType === 'EDIT') {
            await this.props.templateCustomizationStore!.editTemmplateImageConfiguration(datesAndUrl.startDate!, datesAndUrl.endDate!, datesAndUrl.linkUrl, datesAndUrl.html?.length > 0 ? datesAndUrl.html : undefined)
            this.setState({ saveActive: false })
          } else {
            await this.props.templateCustomizationStore!.createNewTemplateImageConfiguration(datesAndUrl.startDate!, datesAndUrl.endDate!, datesAndUrl.linkUrl, datesAndUrl.html?.length > 0 ? datesAndUrl.html : undefined)
            this.setState({ saveActive: false })
          }
        }
      } else {
        this.setState({ formErrors: formErrors, saveActive: false })
        return
      }

    }
  }

  private determineStartAndEndDatesAndLinkUrl = () => {
    let startDate: string | null = null
    let endDate: string | null = null
    let linkUrl = ''
    let html = ''


    if (this.state.form.startDate.length > 0) {
      startDate = this.state.form.startDate
    } else if (this.props.templateCustomizationStore!.templateImageFunctionType === 'ADD') {
      startDate = getStartingEndDate(0)
    }

    if (this.state.form.endDate.length > 0) {
      endDate = this.state.form.endDate
    } else if (this.props.templateCustomizationStore!.templateImageFunctionType === 'ADD') {
      endDate = getStartingEndDate(-1)
    }

    if (this.state.form.configurationLinkUrl.length > 0) {
      linkUrl = this.state.form.configurationLinkUrl
    }

    html = this.state.form.configurationHtml

    if (this.props.templateCustomizationStore!.templateImageConfigurations.length && this.props.templateCustomizationStore!.selectedTemplateImageConfigurationId && this.props.templateCustomizationStore!.templateImageFunctionType === 'EDIT') {
      const templateConfiguration = this.props.templateCustomizationStore!.templateImageConfigurations.find(configuration => configuration.id === this.props.templateCustomizationStore!.selectedTemplateImageConfigurationId)
      if (this.state.form.startDate.length === 0) {
        startDate = templateConfiguration!.startDate
      }

      if (this.state.form.endDate.length === 0) {
        endDate = templateConfiguration!.endDate
      }

      if (this.state.form.configurationLinkUrl.length === 0 && templateConfiguration!.configurationLinkUrl) {
        linkUrl = templateConfiguration!.configurationLinkUrl
      }

      if (html == null) {
        html = templateConfiguration!.configurationHtml
      }

      // if (this.state.form.configurationHtml.length === 0 && templateConfiguration!.configurationHtml) {
      //   html = templateConfiguration!.configurationHtml
      // }
    }

    return { startDate, endDate, linkUrl, html }
  }

  public render() {
    const datesAndUrl = this.determineStartAndEndDatesAndLinkUrl()
    const templateImageConfigurations = this.props.templateCustomizationStore!.templateImageConfigurations

    return (
      <styled.EditAndAddDatesContainer>
        <styled.AddTemplateImageForm>
          <styled.Label>
            Please create a template image configuration by selecting dates below:
          </styled.Label>
          <styled.Legend>
            <styled.LegendTitle>Legend:</styled.LegendTitle>
            A date with the color of
            <styled.LegendBox backgroundColor='rgb(173, 12, 12)' />
            or
            <styled.LegendBox backgroundColor='rgba(173, 12, 12, 0.35)' />
            means this date is already covered by another configuration.
          </styled.Legend>
          {this.state.dateRangeError && (
            <styled.DateRangeErrorPrompt>
              <strong>Error:</strong> You must select a starting and end date that do not overlap or include any previously defined configurations.
            </styled.DateRangeErrorPrompt>
          )}
          <styled.CalendarsContainer>
            <styled.CalendarContainer>
              <styled.CalendarLabel>
                Start Date
              </styled.CalendarLabel>
              <styled.Calendar>
                <Calendar type='start' setDateFunction={this.handleDateChange} selectedDate={datesAndUrl.startDate!} templateImageConfigurations={templateImageConfigurations} selectedConfiguration={this.props.templateCustomizationStore!.selectedTemplateImageConfigurationId} />
              </styled.Calendar>
            </styled.CalendarContainer>
            <styled.CalendarContainer>
              <styled.CalendarLabel>
                End Date
              </styled.CalendarLabel>
              <styled.Calendar>
                <Calendar type='end' setDateFunction={this.handleDateChange} selectedDate={datesAndUrl.endDate!} templateImageConfigurations={templateImageConfigurations} selectedConfiguration={this.props.templateCustomizationStore!.selectedTemplateImageConfigurationId} />
              </styled.Calendar>
            </styled.CalendarContainer>
          </styled.CalendarsContainer>

          <styled.FormElementWrapper>
            <TextField
              style={{ width: '98%', marginBottom: 10, marginTop: '-6px' }}
              onChange={this.handleChange('configurationLinkUrl')}
              type='text'
              name='configurationLinkUrl'
              fullWidth
              value={datesAndUrl.linkUrl}
              placeholder='Enter a link url'
              label='Enter a link url'
              margin="normal"
              variant="outlined"
              required
              error={this.state.formErrors.configurationLinkUrl}
              helperText={this.state.formErrors.configurationLinkUrl ? "You must enter a link url before continuing." : null}
            />
          </styled.FormElementWrapper>
          <styled.FormElementWrapper>
            <InputLabel>
              HTML
            </InputLabel>
            <Editor
              value={datesAndUrl.html}
              onChange={(content: string) => this.setState({ form: { ...this.state.form, configurationHtml: content } })}
            />
          </styled.FormElementWrapper>
          <styled.FormButtonContainer>
            <Button onClick={() => this.props.templateCustomizationStore!.setTemplateImageFunctionState('IMAGE_DETAIL')} variant='contained' color='primary' style={{ marginRight: 10 }}>
              Cancel
            </Button>
            <Button disabled={this.state.saveActive} onClick={this.checkFieldsAndSubmit} variant='contained' color='primary'>
              {this.props.templateCustomizationStore!.templateImageFunctionType !== 'EDIT' ? (
                <>
                  Save New Configuration
                </>
              ) : (
                <>
                  Save Edits
                </>
              )}
            </Button>
          </styled.FormButtonContainer>
        </styled.AddTemplateImageForm>
      </styled.EditAndAddDatesContainer>
    )
  }
}

export default EditAndAddDates


const config = {
  // readonly: false, // all options from https://xdsoft.net/jodit/doc/
  useSplitMode: true,
  // removeButtons: ["file", "outdent", "selectall", "print", "about", "video"],
}

function Editor(props: any) {
  const { value, onChange } = props
  const editor = useRef(null)

  return (
    <>
      <JoditEditor
        ref={editor}
        // @ts-ignore
        config={config}
        value={value}
        onChange={(content: string) => onChange(content)}
      />
    </>
  )
}