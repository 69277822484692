import React, { Component } from 'react'
import { observer } from 'mobx-react'
import * as styled from './ReportsContainer.styled'
import GeographicChart from '../shared/Charts/GeographicChart'
import { IReportStore } from '../../../stores/ReportStore'
import { ICustomerStore } from '../../../stores/CustomerStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'
import { apiStatusDefinitions } from '../../../utils/apiStatusDefinitions'
import DataTableMail from './DataTableMail'

interface ReportContainerProps {
  reportStore: IReportStore,
  customerStore: ICustomerStore
}

@observer
class GeographicReportContainer extends Component<ReportContainerProps, any> {


  public render() {
    const data = this.props.reportStore.geoGraphicReportEventDataReduced
    if (data.length && !(this.props.reportStore && this.props.reportStore.apiStatus.checkApiStatus(apiStatusDefinitions.REPORT_DATA))) {
      return (
        <styled.ReportOuterContainer>
          <styled.ReportInnerContainer >
            <GeographicChart data={data} style={{ marginLeft: '-30px' }} />
          </styled.ReportInnerContainer>
          <styled.ChartNote style={{ marginTop: 90 }}>
            <b>Note:</b> This report contains data showing how many mail events occured in the specified zip code.
          </styled.ChartNote>
          <styled.DataTableContainer style={{ width: 700, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 0 }}>
            <DataTableMail reportStore={this.props.reportStore} customerStore={this.props.customerStore} />
          </styled.DataTableContainer> 
        </styled.ReportOuterContainer>
      )
    } else if (this.props.reportStore && this.props.reportStore.apiStatus.checkApiStatus(apiStatusDefinitions.REPORT_DATA)) {
      return (
        <styled.ReportOuterContainer>
          <styled.LoadingContainer>
            <styled.FontAwesomeContainer>
              <styled.FontAwesomeInnerContainer>
                <FontAwesomeIcon size='10x' icon={faChartArea} />
              </styled.FontAwesomeInnerContainer>
              <i>Loading...</i>
            </styled.FontAwesomeContainer>
          </styled.LoadingContainer>
        </styled.ReportOuterContainer>
      )
    } else {
      return (
        <styled.ReportOuterContainer>
          <styled.LoadingContainer>
            <div style={{ textAlign: 'left', height: '100%', width: '100%', marginTop: 14 }}>
              Please click the <b>{`'Retrieve Report Data'`}</b> button ( {`->`} ) to the right in order to populate your Message Bee notification report. 
            </div>
          </styled.LoadingContainer>
        </styled.ReportOuterContainer>
      )
    }
  }
}

export default GeographicReportContainer
