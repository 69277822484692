import React, { Component } from 'react'
import { ICustomerStore } from '../../../stores/CustomerStore'
import { IReportStore } from '../../../stores/ReportStore'
import { observer } from 'mobx-react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { isEven } from '../../../utils/generalHelpers'
import { clicksToolTips } from '../../pages/Reports/Reports.copy'
import * as styled from './DataTableOverView.styled'
import ToolTip from '../shared/ToolTip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaste } from '@fortawesome/free-solid-svg-icons'
import CopyToClipboard from 'react-copy-to-clipboard'
 
interface DataTableProps {
  customerStore: ICustomerStore
  reportStore: IReportStore
  // notificationStore: INotificationStore
}

const CustomURLToolTip = ({ url }: {url: string}) => {
  return <styled.CustomURLToolTipContainer>{url}</styled.CustomURLToolTipContainer>
}

@observer
class DataTableClicks extends Component<DataTableProps> {
  public state = {
    visibleClickDetailRows: new Map([]),
    visibleToolTips: new Map([])
  }

  private showOrHideExpandedDetails = (url: string) => {
    const rowMap = this.state.visibleClickDetailRows
    rowMap.has(url) ? rowMap.delete(url) : rowMap.set(url, '')
    this.setState({ visibleSearchRows: rowMap })
  }

  public setUrlTooltip = (url: string) => {
    const rowMap = this.state.visibleToolTips
    rowMap.has(url) ? rowMap.delete(url) : rowMap.set(url, '')
    this.setState({ visibleToolTips: rowMap })
  }

  public render() {
    let rows = this.props.reportStore.clicks
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <styled.DataTableHeaderContainer>URL <ToolTip titleText={clicksToolTips['URL']} /></styled.DataTableHeaderContainer>
            </TableCell>
            <TableCell align="left">
              <styled.DataTableHeaderContainer># of Clicks <ToolTip titleText={clicksToolTips['CLICKS']} /></styled.DataTableHeaderContainer>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.map((click: any, i: number) => {
            const isRowShaded = isEven(i)
            return (
              <React.Fragment key={`dataTable_${i}`}>
                <CopyToClipboard 
                  text={click.url}
                >
                  <TableRow style={isRowShaded ? {cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.05)'} : {cursor: 'pointer'}} onClick={() => this.showOrHideExpandedDetails(click.url)} onMouseEnter={() => this.setUrlTooltip(click.url)} onMouseLeave={() => this.setUrlTooltip(click.url)}>
                    <TableCell align="left" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>
                      <styled.ClickURLButtonAndUrlContainer>
                        {(this.state.visibleClickDetailRows.has(click.url)) ? ( <styled.ExpansionButton>-</styled.ExpansionButton> ) : ( <styled.ExpansionButton>+</styled.ExpansionButton> )} 
                        <styled.URLContainer>
                          {click.url.length > 120 ? `${click.url.substring(0,120)}...` : click.url} 
                          <styled.CopyTextContainer>
                            <FontAwesomeIcon size='xs' style={{ marginLeft: 5, marginTop: 2 }} icon={faPaste} /> 
                            <styled.CopyText>copy</styled.CopyText>
                          </styled.CopyTextContainer>
                        </styled.URLContainer>
                        {(this.state.visibleToolTips.has(click.url) && click.url.length > 120) && <CustomURLToolTip url={click.url} />} 
                      </styled.ClickURLButtonAndUrlContainer>
                    </TableCell>
                    <TableCell align="left" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>{click.clicks}</TableCell>
                  </TableRow>
                </CopyToClipboard>
                {(this.state.visibleClickDetailRows.has(click.url)) && (
                  <TableRow style={isRowShaded ? {backgroundColor: 'rgba(0, 0, 0, 0.05)', width: '100%'} : {width: '100%'}} key={`click_detail_${i}`}>
                    <TableCell colSpan={2} style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>
                      <styled.ClickDetailContainer>
                        <styled.ClickDetailLeft><strong>Dates</strong></styled.ClickDetailLeft>
                        <styled.ClickDetailRight><strong>Email</strong></styled.ClickDetailRight>
                      </styled.ClickDetailContainer>  
                      {Object.keys(click.dates).map((key: string) => {
                        return (
                          <styled.ClickDetailContainer key={`daterow_${key}`}>
                            <styled.ClickDetailLeft>{key.split(" ")[0]}</styled.ClickDetailLeft>
                            <styled.ClickDetailRight>
                              {click.dates[key].map((email: string) => {
                                return <styled.EmailItem key={`${click.url}_${email}`}>{email}</styled.EmailItem>
                              })}
                            </styled.ClickDetailRight>
                          </styled.ClickDetailContainer>
                        )})}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            )}
          )}
        </TableBody>
      </Table>
    )
  }
}

export default DataTableClicks