import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as styled from './EagerUpdatingLoader.styled'


interface EagerUpdatingLoaderProps {
  style?: any,
  text: string
}

const EagerUpdatingLoader: React.SFC<EagerUpdatingLoaderProps> = ({ style, text }: EagerUpdatingLoaderProps) => {
  return (
    <styled.EagerLoadingContainer style={style || { position: 'absolute', right: 18, top: 16 }}>
      <CircularProgress size={20} color="secondary"/>
      <styled.EagerLoadingText>
        {text}
      </styled.EagerLoadingText>
    </styled.EagerLoadingContainer>
  )
}

export default EagerUpdatingLoader
