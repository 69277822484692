import React from 'react'
import * as styled from './VerticalMenuBar.styled'
import { verticalMenuBarIcons } from './Dashboard.copy'
import IconButton from '@material-ui/core/IconButton'
import Home from '@material-ui/icons/Home'
import InsertChartRounded from '@material-ui/icons/InsertChartRounded'
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup'
import { History } from 'history'
import { IRole } from '../../../models/Role'
import AdminDivider from './AdminDivider'
import AdvancedDivider from './AdvancedDivider'
import { faUserPlus, faBomb, faUserLock, faEnvelopeOpenText, faSearchPlus, faTasks, faBullhorn, faMapMarkedAlt, faCog, faAddressBook, faHatWizard, faKey, faBug, faIdBadge, faComment } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GetApp } from '@material-ui/icons'
import { observer } from 'mobx-react'

interface VerticalMenuBarProps {
  history?: History,
  roles?: IRole[],
  features?: string[]
}

const icons: any = {
  HOME: { component: <Home />, roles: [] },
  REPORTS: { component: <InsertChartRounded />, roles: [] },
  REPORTDOWNLOADS: { component: <GetApp />, roles: [] },
  COMMUNICATIONPREFERENCES: { component: <PhonelinkSetupIcon />, roles: [] },
  TEMPLATES: { component: <FontAwesomeIcon size='xs' icon={faEnvelopeOpenText} />, roles: ['Admin', 'Transactional'] },
  DETAILEDSEARCH: { component: <FontAwesomeIcon size='xs' icon={faSearchPlus} />, roles: [] },
  SMSMESSAGING: { component: <FontAwesomeIcon size='xs' icon={faComment} />,  features: ['SmsMessaging'] },
  SETTINGS: { component: <FontAwesomeIcon size='xs' icon={faCog} />, roles: [] },
  ADVANCEDDIVIDER: { component: <AdvancedDivider />, roles: ['Admin', 'ListMessage', 'CampaignEditor', 'ListBuilder'] },
  LISTCOMMUNICATIONS: { component: <FontAwesomeIcon size='xs' icon={faBullhorn} />, roles: ['Admin', 'ListMessage', 'CampaignEditor'] },
  AUDIENCELISTS: { component: <FontAwesomeIcon size='sm' icon={faAddressBook} />, roles: ['Admin', 'ListMessage', 'ListBuilder'] },
  DYNAMICLISTS: { component: <FontAwesomeIcon size='sm' icon={faHatWizard} />, roles: ['Admin', 'ListMessage', 'ListBuilder'] },
  ADMINDIVIDER: { component: <AdminDivider />, roles: ['Admin'] },
  FAILEDFILES: { component: <FontAwesomeIcon size='xs' icon={faBomb} />, roles: ['Admin'] },
  DEBUGFILES: { component: <FontAwesomeIcon size='xs' icon={faBug} />, roles: ['Admin'] },
  // INVITEUSERS: { component: <FontAwesomeIcon size='xs' icon={faUserPlus} />, roles: ['Admin'] },
  // MAPS: { component: <FontAwesomeIcon size='xs' icon={faMapMarkedAlt} />, roles: ['Admin'] },
  // ACCOUNTUNLOCK: { component: <FontAwesomeIcon size='xs' icon={faUserLock} />, roles: ['Admin'] },
  TASKS: { component: <FontAwesomeIcon size='xs' icon={faTasks} />, roles: ['Admin'] },
  PASSWORDRESET: { component: <FontAwesomeIcon size='xs' icon={faKey} />, roles: ['Admin'] },
  PATRONRENEWAL: { component: <FontAwesomeIcon size='xs' icon={faIdBadge} />, roles: ['Admin', 'PatronRenewal'], features: ['PatronRenewal'] }
}

@observer
class VerticalMenuBar extends React.Component<VerticalMenuBarProps> {
  private pushHistory = (name: string) => {
    this.props.history!.push(`/${name}`)
  }

  private renderMenuIcons = () => {
    return verticalMenuBarIcons.map(item => {
      const itemName = item.name.replace(' (', '').replace(')', '').replace(' ', '')
      const iconItem = icons[itemName.toUpperCase()]
      const hasFeature = this.props.features ? this.props.features.some(feature => iconItem.features?.includes(feature)) : false
      if ((iconItem.roles?.length === 0 ?? false) || this.props.roles.some(role => (iconItem.roles?.includes(role.name) ?? false)) || hasFeature)
        if (item.name !== 'AdminDivider' && item.name !== 'AdvancedDivider') {
          return (
            <styled.ButtonContainer
              onClick={() => item.url? window.location.assign(item.url) :this.pushHistory(item.route)}
              key={`vm_button_container_${itemName}`} active={`/${item.route}` === this.props.history!.location.pathname}>
              <styled.Icon>
                <IconButton
                  key={`vm_icon_${itemName}`}
                  aria-label="Open drawer"
                  color="inherit"
                >
                  {iconItem.component}
                </IconButton>
              </styled.Icon>
              {item.name.includes('(Defaults)') ? (
                <styled.IconName>{item.name.replace('(Defaults)', '')}<br />(Defaults)</styled.IconName>
              ) : (
                <styled.IconName>{item.name}</styled.IconName>
              )}
            </styled.ButtonContainer>
          )
        } else {
          return (

            item.name !== 'AdvancedDivider' ? (
              <AdminDivider key="admin_divider" />
            ) : (
              <AdvancedDivider key="advanced_divider" />
            )

          )
        }
    })
  }

  public render() {
    return (
      <styled.MenuWrapper>
        <styled.InnerMenuWrapper>
          {this.renderMenuIcons()}
        </styled.InnerMenuWrapper>
      </styled.MenuWrapper>
    )
  }
}

export default VerticalMenuBar
