import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { ITaskStore } from '../../../../stores/TaskStore'
import CustomerSchedule from './CustomerSchedule'
import CustomerTaskConfiguration from './CustomerTaskConfiguration'
import EditArn from './EditArn'
import AddTask from './AddTask'
import AddCustomer from './AddCustomer'
import GlobalSchedule from './GlobalSchedule'

interface TemplateImageContainerProps {
  taskStore?: ITaskStore
}

const TaskModalContent = {
  CUSTOMER_SCHEDULE: <CustomerSchedule  />,
  CUSTOMER_TASK_CONFIGURATION_SETUP: <CustomerTaskConfiguration />,
  EDIT_ARN: <EditArn />,
  ADD_TASK: <AddTask />,
  ADD_CUSTOMER: <AddCustomer />,
  GLOBAL_SCHEDULE: <GlobalSchedule />
}

@inject((allStores: any) => {
  return {
    taskStore: allStores.taskStore as ITaskStore
  }
})
@observer
class TaskContainer extends Component<TemplateImageContainerProps> {
  public render () {
    return (
      <>
        {TaskModalContent[this.props.taskStore!.taskModalFunction]}
      </>
    )
  }
}

export default TaskContainer