import React from 'react'
import * as Styled from './ButtonLoading.styled'
import SvgBee from './SvgBee'

const ButtonLoading: React.FC = () => {
  return(
    <Styled.LoadingWrapper>
      <Styled.LoadingWrapperInnerContainer>
        <SvgBee width={26} height={24} type='small' scale='0.063' />
      </Styled.LoadingWrapperInnerContainer>
    </Styled.LoadingWrapper>
  )
}

export default ButtonLoading