import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import * as styled from './DropDowns.styled'
import { disposeOnUnmount } from 'mobx-react'
import { when } from 'mobx'
import { IReportStore } from '../../../../stores/ReportStore'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { observer } from 'mobx-react'
import startCase from 'lodash/startCase'
import { ITemplateStore } from 'stores/TemplateStore'

interface CommunicationChannelProps {
  store: IReportStore | ITemplateStore

}

interface CommunicationChannelState {
  labelWidthCommunicationChannel: number
}

@observer
class CommunicationChannel extends Component<CommunicationChannelProps, CommunicationChannelState> {
  public state = {
    labelWidthCommunicationChannel: 240
  }
  
  private InputLabelRefCommunicationChannel: any

  public componentDidMount (): void {
    disposeOnUnmount(this,
      when(
        () => this.props.store.communicationChannelStore.channelVisbility,
        // eslint-disable-next-line react/no-find-dom-node
        () => console.log((ReactDOM.findDOMNode(this.InputLabelRefCommunicationChannel) as any).offsetWidth) // this.setState({labelWidthCommunicationChannel: (ReactDOM.findDOMNode(this.InputLabelRefCommunicationChannel) as any).offsetWidth})
      ))
  }

  private handleChange = (event: any): void => {
    this.props.store && this.props.store.filterCommunicationChannel ? this.props.store.filterCommunicationChannel(event.target.value) : this.props.store.communicationChannelStore.filterCommunicationChannel(event.target.value)
  }

  public render (): any {
    return (
      <>
        <styled.Label hidden={this.props.store.communicationChannelStore.channelVisbility}>
          Communication Channel:
        </styled.Label>
        <styled.NotificationTypeContainer hidden={this.props.store.communicationChannelStore.channelVisbility}>
          <FormControl style={{ width: '95%', marginRight: 20, marginBottom: 0 }} variant="outlined">
            <InputLabel
              ref={(ref): any => {
                this.InputLabelRefCommunicationChannel = ref
              }}
              htmlFor={`outlined-communicationChannel-simple`}
            >
                Select a Communication Channel
            </InputLabel>
            <Select
              value={this.props.store.communicationChannelStore.channelDefaultOrSelectedValue || ''}
              onChange={this.handleChange}
              placeholder='Select a communication channel...'
              autoWidth
              style={{ minWidth: 250, width: '100%', backgroundColor: 'rgba(255, 255, 255, 0)', textAlign: 'left' }}
              input={<OutlinedInput placeholder='Select a Communication Channel' labelWidth={this.state.labelWidthCommunicationChannel} name='communicationChannel' id={`outlined-communicationChannel-simple`} />}
            >
              {Object.keys(this.props.store.communicationChannelStore.availableChannels).map((channel: any) => {
                if (this.props.store.communicationChannelStore.availableChannels[channel]) {
                  return (
                    <MenuItem key={`default_communication_channel_${channel}`} value={channel}>
                      {channel === 'sms' ? channel.toUpperCase() : startCase(channel)}
                    </MenuItem>
                  )
                }
              })}
            </Select>
          </FormControl>  
        </styled.NotificationTypeContainer>
      </>
    )
  }
}

export default CommunicationChannel
