import { types } from 'mobx-state-tree'

const EditableStaticConfigItem = types.model('EditableStaticConfigItem', {
  propertyName: types.string,
  staticPropertyValue: types.maybeNull(types.string),
  isCustomProperty: types.boolean,
  plainText: types.optional(types.boolean, false),
  isModified: types.optional(types.boolean, false)
})


export type IEditableStaticConfigItem = typeof EditableStaticConfigItem.Type
export default EditableStaticConfigItem
