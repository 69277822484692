import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as Sentry from '@sentry/browser'

import 'ClerkComponents.css'

Sentry.init({ dsn: "https://29cc07e70a894fe5b768b2ffd35c8657@sentry.io/1499772" })

ReactDOM.render(<App />, document.getElementById('root'))
