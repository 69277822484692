import { types } from 'mobx-state-tree'

const Notification = types.model('Notification', {
  id: types.identifierNumber,
  type: types.string,
  message: types.string,
  key: types.maybeNull(types.string)
})

export type INotification = typeof Notification.Type
export default Notification