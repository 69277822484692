import { api } from './axios'
import { apiHelper } from '../utils/apiHelper'

interface PasswordResetRequestDto {
  email: string
}

interface PasswordResetDto {
  password: string,
  token: string
}

interface AuthedPasswordResetDto {
  currentPassword: string,
  newPassword: string
}

export const requestPasswordReset = async (email: PasswordResetRequestDto): Promise<any> => {
  const requestPasswordReset = await apiHelper<any>(() => api.post('/account/password/request', email))
  return requestPasswordReset.data
}

export const resetPassword = async (passwordAndToken: PasswordResetDto): Promise<any> => {
  const passwordReset = await apiHelper<any>(() => api.post('/account/password', passwordAndToken))
  return passwordReset.data
}

export const authedResetPassword = async (passwordAndToken: AuthedPasswordResetDto): Promise<any> => {
  const passwordReset = await apiHelper<any>(() => api.post('/account/password/reset', passwordAndToken))
  return passwordReset.data
}

export const updateDigestFrequency = async (settings: { digestFrequency: string, digestFailReport: boolean }): Promise<any> => {
  const digestUpdate = await apiHelper<any>(() => api.post('/account/settings/digest', settings))
  return digestUpdate.data
}

export const getUserSettings = async (): Promise<any> => {
  const settings = await apiHelper<any>(() => api.get('/account/settings'))
  return settings.data
}

export const userSearch = async (searchTerm: string) => {
  const searchResults = await apiHelper<any>(() => api.get(`/account/user-search?searchTerm=${encodeURI(searchTerm)}`))
  return searchResults.data.accounts
}

export const resetUserPassword = async (id: number, password: string) => {
  await apiHelper<any>(() => api.post('/account/password/admin', { id, password }))
}

export const getNotifications = async (): Promise<any> => {
  const notifications = await apiHelper<any>(() => api.get('/account/notification'))
  return notifications.data
}

export const markNotificationAsRead = async (notificationIds: number[]): Promise<any> => {
  await apiHelper<any>(() => api.patch('/account/notification', { notificationIds }))
}

export const deleteNotification = async (notificationIds: number[]): Promise<any> => {
  await apiHelper<any>(() => api.delete('/account/notification', { data: { notificationIds } }))
}