import React, { Component } from 'react'
import { observer } from 'mobx-react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import * as styled from './DataTableSearch.styled'
import { ISearchData, ItemData } from '../../../models/SearchData'
import { isEven } from '../../../utils/generalHelpers'
import { NotificationChannelEventType, VoiceEvents } from '../../pages/Administration/DetailedSearch/DetailedSearch.copy'

interface EventsProps {
  items: ItemData[],
}

const ExpandedDetails: React.FC<EventsProps> = ({ items }: { items: ItemData[] }) => {
  return (
    <>
      Events: 
      {items.map((item: ItemData) => {
        return (
          <styled.Event key={`eventTime_${item.eventTimestamp}`}>
            <div style={{ width: 130, marginRight: 6 }}>{item.eventTimestamp}</div>
            <div style={{ width: 80, marginRight: 6 }}>{item.eventId >= 300 ? VoiceEvents[item.eventId] : NotificationChannelEventType[item.eventId] || 'Unknown'}</div>
            <div style={{ width: 300, marginRight: 6 }}>{item.url}</div>
          </styled.Event>
        )      
      })}
    </>
  )
}

interface DataTableProps {
  data: ISearchData[],
  searchType: string,
  length: number
}

@observer
class DataTableSearch extends Component<DataTableProps> {
  public state = {
    visibleSearchRows: new Map([])
  }

  private showOrHideExpandedDetails (id: string) {
    const rowMap = this.state.visibleSearchRows
    rowMap.has(id) ? rowMap.delete(id) : rowMap.set(id, '')
    this.setState({ visibleSearchRows: rowMap })
  }

  public render () {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><styled.DataTableHeaderContainer>Notification Time</styled.DataTableHeaderContainer>
            </TableCell>
            <TableCell>
              <styled.DataTableHeaderContainer>Notification Final Status</styled.DataTableHeaderContainer>
            </TableCell>
            {(this.props.searchType === 'email' || this.props.searchType === 'barcode') && (
              <TableCell>
                <styled.DataTableHeaderContainer>Email</styled.DataTableHeaderContainer>
              </TableCell>
            )}
            {(this.props.searchType === 'phone' || this.props.searchType === 'barcode') && (
              <TableCell>
                <styled.DataTableHeaderContainer>Phone</styled.DataTableHeaderContainer>
              </TableCell>
            )}
            <TableCell>
              <styled.DataTableHeaderContainer>Barcode</styled.DataTableHeaderContainer>
            </TableCell>
            <TableCell>
              <styled.DataTableHeaderContainer>Notification Type</styled.DataTableHeaderContainer>
            </TableCell>
            <TableCell>
              <styled.DataTableHeaderContainer>Preview</styled.DataTableHeaderContainer>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ cursor: 'pointer' }}>
          {this.props.data.map((item: ISearchData, i: number) => {
            const isRowShaded = isEven(i)
            return (
              <React.Fragment key={`search_row_${item.id}`}>
                <TableRow style={ isRowShaded ? { backgroundColor: 'rgba(0, 0, 0, 0.05)' } : {} } onClick={() => this.showOrHideExpandedDetails(item.id)}>
                  <TableCell style={{ fontSize: '0.8rem', fontWeight: 500, color: 'rgba(0, 0, 0, 0.54)' }}>
                    <styled.ExpansionAndTimeContainer>
                      {(this.state.visibleSearchRows.has(item.id)) ? ( <styled.ExpansionButton>-</styled.ExpansionButton> ) : ( <styled.ExpansionButton>+</styled.ExpansionButton> )} 
                      <div>{item.sentTimestamp}</div>
                    </styled.ExpansionAndTimeContainer>
                  </TableCell>
                  <TableCell style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>
                    {item.notificationStatus}
                  </TableCell>
                  {(this.props.searchType === 'email' || this.props.searchType === 'barcode')  && (
                    <TableCell style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>
                      {item.email?.toLowerCase()}
                    </TableCell>
                  )}
                  {(this.props.searchType === 'phone' || this.props.searchType === 'barcode')  && (
                    <TableCell style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>
                      {item.phone}
                    </TableCell>
                  )}
                  <TableCell style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>
                    {item.barcode}
                  </TableCell>
                  <TableCell style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>
                    {item.notificationSubType}
                  </TableCell>
                  <TableCell style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>
                    <a href={item.previewUrl || ''} target='_blank'><PanoramaOutlinedIcon /></a>
                  </TableCell>
                </TableRow>
                {item.notificationStatus === 'Failed' && (
                  <TableRow style={ isRowShaded ? { backgroundColor: 'rgba(0, 0, 0, 0.05)' } : {} }>
                    <TableCell colSpan={6} style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>
                      <strong>Failure Reason:</strong> {item.failureReason}
                    </TableCell>
                  </TableRow>
                )}
                {(this.state.visibleSearchRows.has(item.id)) && (
                  <TableRow style={ isRowShaded ? { backgroundColor: 'rgba(0, 0, 0, 0.05)' } : {} } onClick={() => this.showOrHideExpandedDetails(item.id)}>
                    <TableCell colSpan={6} style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>
                      <ExpandedDetails items={item.items} />
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            )
          })}
        </TableBody>
      </Table>
    )
  }
}

export default DataTableSearch
