import styled from 'styled-components'

export const ReportOuterContainer = styled.div`
    width: 100%;
`

interface ReportInnerContainerProps {
  noGraph?: boolean
}
  

export const ReportInnerContainer = styled.div`
    width: 100%;
    height: ${(props: ReportInnerContainerProps) => props.noGraph ? '20px' : '450px' };
    display: flex;
    align-items: center;
    flex-direction: column;
`

export const ChartNote = styled.div`
    font-size: 12px;
    text-align: left;
    margin: 16px 10px 0px 10px;
`

export const LoadingContainer = styled.div`
    position: relative;
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const FontAwesomeContainer = styled.div`
    position: relative;
    width: 100%;
    height: 150px;
`

export const FontAwesomeInnerContainer = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 450px;
    width: 100%;
    opacity: 0.56;

    -webkit-animation: AnimatedGradientChart 1s ease infinite;
    -moz-animation: AnimatedGradientChart 1s ease infinite;
    animation: AnimatedGradientChart 1s ease infinite;
  
    @-webkit-keyframes AnimatedGradientChart {
      0%{opacity: 0.40}
      50%{opacity: 0.6}
      100%{opacity: 0.40}
    }
    @-moz-keyframes AnimatedGradientChart {
        0%{opacity: 0.40}
        50%{opacity: 0.6}
        100%{opacity: 0.40}
    }
    @keyframes AnimatedGradientChart { 
        0%{opacity: 0.40}
        50%{opacity: 0.6}
        100%{opacity: 0.40}
    }
`

export const SvgBeeContainer = styled.div`
    position: absolute;
    top: 45%;
    margin-top: -11.5px;
    left: 50%;
    margin-left: -11px;
    z-index: 2;
    width: 23px;
    height: 22px;
    opacity: 0.8;
`

export const Message = styled.div`
    position: absolute;
    width: 100%;
    height: 20px;
    bottom: 0px; 
    font-size: 12px;
    z-index: 3;
`

export const DataTableContainer = styled.div`
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
`

export const DataTableTitle = styled.div`
  font-size: 24px;
  float: left;
  margin-top: 46px;
  line-height: 40px;
  color: #969696;
  text-align: left;
`

export const DataTableNavigationContainer = styled.div`
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:last-child {
        margin-left: 4px;
    }

    &:first-child {
        margin-right: 4px;
    }
`

interface DataTableNavigationItem {
  active?: boolean
}

export const DataTableNavigationItem = styled.div`
    ${(props: DataTableNavigationItem) => { return props.active ? 'background-color: rgba(0, 0, 0, 0.1);' : 'background-color: rgba(0, 0, 0, 0.02);'}}
    ${(props: DataTableNavigationItem) => { return props.active ? 'border-bottom: 4px rgba(245, 128, 34, 1.0) solid;' : ''}}
    ${(props: DataTableNavigationItem) => { return props.active ? '' : 'cursor: pointer;' }}
    height: 40px;
    line-height: 40px;
    flex-grow: 1;
    color: #969696;

    &:hover {
        ${(props: DataTableNavigationItem) => { return props.active ? '' : 'border-bottom: 2px rgba(0, 128, 34, 0.1) solid;'}}
        ${(props: DataTableNavigationItem) => { return props.active ? 'background-color: rgba(0, 0, 0, 0.1);' : 'background-color: rgba(0, 0, 0, 0.05);'}}
    }
`

export const DataTableNavigationClicksFiller = styled.div`
    height: 40px;
    line-height: 40px;
    width: 33%;
`

export const ReportTabAndLabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`