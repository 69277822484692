import { types } from 'mobx-state-tree'

const ReportDataPoint = types.model('ReportDataPoint', {
  x: types.string,
  y: types.number
})

const ReportData = types.model('ReportData', {
  id: types.string, 
  color: types.string,
  data: types.array(ReportDataPoint)
})

export type IReportData = typeof ReportData.Type
export default ReportData