import styled from 'styled-components'

export const UploadDialogueWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  height: 91%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Roboto';
`

export const Title = styled.div`
  width: 100%;
  font-size: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const UploadDialogueContent = styled.div`
  padding-top: 20px;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 100%;
  text-align: center;
  margin-left: 20px;
  border: 1px dashed rgba(140, 140, 140, 0.8);
  border-radius: 3px;
  width: 48%;
`

export const InnerDropZone = styled.div`
  width: 95%;
  height: 95%;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
`

export const UploadDialogueForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
  padding-left: 10px;
  width: 45.5%;
  font-family: 'Roboto';
`

export const FormButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10px;
`

export const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 100%;
  font-size: 16px;
`

export const FormElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
`

export const FileName = styled.span`
  font-style: italic;
  margin-top: 16px;
  font-weight: bolder;
`