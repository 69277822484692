import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import * as styled from './Header.styled'
import { ICustomerData } from '../../../../models/CustomerData'
import CustomerDropDown from '../../shared/Header/CustomerDropDown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faBook } from '@fortawesome/free-solid-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom'
import { IAuthStore } from '../../../../stores/AuthStore'
import Notifications from './Notifications'
import { useClerk } from '@clerk/clerk-react'
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core'

interface DashboardProps {
  logOut: () => void,
  customers?: ICustomerData[],
  customerApiCallStatus: boolean
  setSelectedCustomerFunction: (customerId: number) => void,
  resetTemplates: () => void,
  setCustomerId?: number,
  resetListCommunications: () => void
  authStore?: IAuthStore,
}

const Header: React.FC<DashboardProps> = ({ logOut, customers, customerApiCallStatus, setSelectedCustomerFunction, resetTemplates, setCustomerId, resetListCommunications, authStore }: DashboardProps) => {
  const [open, setOpen] = React.useState(false);
  const { signOut, user } = useClerk()
  let foundCustomer = undefined
  if (customers) {
    foundCustomer = customers!.find(customer => customer.id === setCustomerId)
  }

  const hasSmsMessaging = authStore?.features?.some(feature => feature === 'SmsMessaging')
  const hasAccessedBetaSite = user?.unsafeMetadata?.hasAccessedBetaSite as boolean || false

  const betaFirstTimeRedirect = async () => {
    await user.update({
      unsafeMetadata: {
        hasAccessedBetaSite: true,
      }
    })
    window.location.href = 'https://beta.messagebee.uniquelibrary.com'
  }

  return (
    <AppBar position="static">
      <styled.HeaderFlexReset>
        <styled.HeaderLeft>
          <styled.Search customerApiCallStatus={customerApiCallStatus}>
            <CustomerDropDown
              customers={customers}
              customerApiCallStatus={customerApiCallStatus}
              setSelectedCustomerFunction={setSelectedCustomerFunction}
              resetTemplates={resetTemplates}
              setCustomerId={setCustomerId}
              resetListCommunications={resetListCommunications}
              foundCustomer={foundCustomer}
            />
          </styled.Search>
        </styled.HeaderLeft>
        <styled.HeaderLogoContainer />
        <styled.HeaderRight>
          {
            hasAccessedBetaSite ?
              <Button href='https://beta.messagebee.uniquelibrary.com' style={{ marginRight: '12px', color: 'white' }} variant='contained' color='secondary'>Try the Beta Site</Button>
              : <Button onClick={() => setOpen(true)} style={{ marginRight: '12px', color: 'white' }} variant='contained' color='secondary'>Try the Beta Site</Button>
          }
          <Notifications hasSmsMessaging={hasSmsMessaging} customerId={setCustomerId} />
          <Link to={{ pathname: `https://mbsupport.uniquelibrary.com/support/home` }} style={{ textDecoration: 'none', color: 'white' }} target="_blank">
            <IconButton
              key="documentation"
              aria-label="Documentation"
              color="inherit"
              style={{ marginRight: "0px" }}
            >

              <FontAwesomeIcon size='sm' icon={faBook} />
            </IconButton>
          </Link>
          <Link to={{ pathname: `https://mbsupport.uniquelibrary.com/support/tickets/new` }} style={{ textDecoration: 'none', color: 'white' }} target="_blank">
            <IconButton
              key="support"
              aria-label="Support"
              color="inherit"
              style={{ marginRight: "0px" }}
            >
              <FontAwesomeIcon size='sm' icon={faQuestionCircle} />
            </IconButton>
          </Link>
          <IconButton
            key="account"
            aria-label="Account menu"
            color="inherit"
            onClick={async () => { await signOut(); logOut() }}
          >
            <FontAwesomeIcon size='sm' icon={faSignOutAlt} />
          </IconButton>
        </styled.HeaderRight>
      </styled.HeaderFlexReset>
    <Dialog onClose={()=> setOpen(false)} aria-labelledby="beta-site-info" open={open}>
      <DialogTitle id="beta-site-info">Beta Site</DialogTitle>
      <DialogContent>
        <p><strong>You are about to be directed to the new MessageBee portal!&nbsp; </strong><br/>We are starting with releasing our improved reporting tools, but won&#39;t be stopping there.&nbsp; Over the next few months you&#39;ll continue to see more features added.&nbsp; Each of the existing portal&#39;s functions will be moved and improved from a usability standpoint, with enhancements to workflows that we think will make your life easier.&nbsp;&nbsp;</p>
         
        <Button onClick={betaFirstTimeRedirect} variant='contained' color='primary'>Try the Beta Site!</Button>
        <p>Follow this link to find a few help articles on the new portal:&nbsp;&nbsp;<a href="https://mbsupport.uniquelibrary.com/support/solutions/folders/70000483834">https://mbsupport.uniquelibrary.com/support/solutions/70000321268</a></p>
      </DialogContent>
    </Dialog>
    </AppBar>
  )
}

export default Header
