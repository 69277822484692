import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './AddImageTemplate.styled'
import { ITemplateCustomizationStore } from '../../../../stores/TemplateCustomizationStore'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

interface MediaLibraryContainerProps {
  templateCustomizationStore?: ITemplateCustomizationStore
}

@inject((allStores : any) => {
  return {
    templateCustomizationStore: allStores.templateCustomizationStore as ITemplateCustomizationStore
  }
})
@observer
class AddImageTemplate extends Component<MediaLibraryContainerProps> {
  public state = {
    form: {
      variableName: '',
      friendlyName: ''
    },
    formErrors: {
      variableName: false,
      friendlyName: false
    },
    saveActive: false,
    spaceOrPeriodError: false
  }

  private handleChange = (name: string) => (event: any) => {
    const form = this.state.form
    const value = event.target.value
    const testValue = value.charAt(value.length-1)
    if (name !== 'friendlyName' && (testValue === ' ' || testValue === '.')) {
      this.setState({ spaceOrPeriodError: true })
    } else {
      const updatedForm = {
        [name]: event.target.value
      }

      this.setState({ form: {...form, ...updatedForm} })
    }
  }

  private checkFieldsAndSubmit = () => {
    const formErrors = this.state.formErrors
    if (this.state.form.variableName.length < 1) {
      formErrors.variableName = true
    } else {
      formErrors.variableName = false
    }

    if (this.state.form.friendlyName.length < 1) {
      formErrors.friendlyName = true
    } else {
      formErrors.friendlyName = false
    }

    if (this.state.form.variableName.length > 0 && this.state.form.friendlyName.length > 0) {
      this.setState({ formErrors: formErrors, spaceOrPeriodError: false })
      this.props.templateCustomizationStore!.createNewTemplateImage(this.state.form.variableName, this.state.form.friendlyName)

    } else {
      this.setState({formErrors: formErrors})
      return
    }
  }

  public render () {
    return (
      <styled.AddTemplateImageContainer>
        <styled.AddTemplateImageForm>
          <styled.Label>
              Please create a template image by filling out the following form items:
          </styled.Label>
          {this.state.spaceOrPeriodError && (
            <styled.SpaceOrPeriodError>
              <strong>Note:</strong> You can not have any spaces or periods in the variable name field.
            </styled.SpaceOrPeriodError>
          )}
          <styled.FormElementWrapper>
            <TextField
              style={{ width: '98%', marginBottom: 10, marginTop: '-6px' }}
              onChange={this.handleChange('variableName')}
              type='text'
              name='variableName'
              fullWidth
              value={this.state.form.variableName}
              placeholder='Enter a variable name for this template image'
              label='Enter a variable name for this template image'
              margin="normal"
              variant="outlined"
              required
              error={this.state.formErrors.variableName}
              helperText={ this.state.formErrors.variableName ? "You must enter a variable name before continuing." : null}
            />
          </styled.FormElementWrapper>
          <styled.FormElementWrapper>
            <TextField
              style={{ width: '98%', marginBottom: 10, marginTop: '-6px' }}
              onChange={this.handleChange('friendlyName')}
              type='text'
              name='description'
              fullWidth
              value={this.state.form.friendlyName}
              placeholder='Enter a friendly description for this template image.'
              label='Enter a friendly name for this template image'
              margin="normal"
              variant="outlined"
              required
              error={this.state.formErrors.friendlyName}
              helperText={ this.state.formErrors.friendlyName ? "You must enter a frioendly name before continuing." : null}
            />
          </styled.FormElementWrapper>
          <styled.FormButtonContainer>
            <Button onClick={() => this.props.templateCustomizationStore!.setTemplateImageFunctionState('IMAGE_SELECTION')} variant='contained' color='primary' style={{ marginRight: 10 }}>
                Cancel
            </Button>
            <Button onClick={this.checkFieldsAndSubmit} variant='contained' color='primary'>
                Add New Template Image
            </Button>
          </styled.FormButtonContainer>
        </styled.AddTemplateImageForm>
      </styled.AddTemplateImageContainer>
    )
  }
}

export default AddImageTemplate