import React, { Component, ReactNode } from 'react'
import * as styled from './Dashboard.styled'
import Header from '../../components/shared/Header/Header'
import VerticalMenuBar from './VerticalMenuBar'
import { withAuthorization } from '../../hocs/Authorization'
import { IAuthStore } from '../../../stores/AuthStore'
import { ICustomerStore } from '../../../stores/CustomerStore'
import { IReportStore } from '../../../stores/ReportStore'
import { INotificationStore } from '../../../stores/NotificationStore'
import { ITemplateStore } from '../../../stores/TemplateStore'
import { History } from 'history'
import { inject, observer } from 'mobx-react'
import { roles } from '../../../utils/roleDefinitions'
import { IListCommunicationsStore } from '../../../stores/ListCommunicationsStore'
import { apiStatusDefinitions } from '../../../utils/apiStatusDefinitions'
import { withClerk } from '@clerk/clerk-react'
import { LoadedClerk } from '@clerk/types'

interface DashboardProps {
  authStore?: IAuthStore,
  customerStore?: ICustomerStore,
  reportStore?: IReportStore,
  notificationStore?: INotificationStore,
  templateStore?: ITemplateStore,
  children?: ReactNode,
  history?: History,
  listCommunicationsStore?: IListCommunicationsStore
  clerk: LoadedClerk
}

@inject((allStores: any) => {
  return {
    customerStore: allStores.customerStore as ICustomerStore,
    reportStore: allStores.reportStore as IReportStore,
    notificationStore: allStores.notificationStore as INotificationStore,
    templateStore: allStores.templateStore as ITemplateStore,
    authStore: allStores.authStore as IAuthStore,
    listCommunicationsStore: allStores.listCommunicationsStore as IListCommunicationsStore
  }
})
@observer
class DashBoardPreAuth extends Component<DashboardProps> {
  public componentDidMount() {
    this.props.clerk.session.getToken().then(() => {
      this.props.customerStore!.getCustomers()
      this.props.authStore!.getFeatures()
    })
  }

  public render() {
    return (
      <styled.DashboardWrapper>
        <styled.HeaderWrapper>
          <Header
            setSelectedCustomerFunction={this.props.reportStore!.resetReportFiltersAndSelectCustomer}
            resetTemplates={this.props.templateStore!.resetTemplates}
            setCustomerId={this.props.customerStore!.setCustomerId}
            customers={this.props.customerStore!.customers.toJSON()}
            customerApiCallStatus={this.props.customerStore!.apiStatus.checkApiStatus(apiStatusDefinitions.CUSTOMER_DATA)}
            logOut={() => this.props.authStore!.logOut(() => this.props.history?.push('/'))}
            authStore={this.props.authStore}
            resetListCommunications={this.props.listCommunicationsStore!.resetListCommunications}
          />
        </styled.HeaderWrapper>
        <styled.ContentWrapper>
          <VerticalMenuBar roles={this.props.authStore!.roles} history={this.props.history} features={this.props.authStore.features} />
          {this.props.children}
        </styled.ContentWrapper>
      </styled.DashboardWrapper>
    )
  }
}

const DashBoard = withAuthorization(withClerk(DashBoardPreAuth), [])
export default DashBoard
