import styled from 'styled-components'

export const CustomerScheduleDialogueWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  height: 91%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`

export const Title = styled.div`
  width: 100%;
  font-size: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const SubTitle = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  font-size: 14px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 470px;
  line-height: 45px;
`

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 470px;
  justify-content: flex-end;
  margin-top: 10px;
`

export const ItemColumn = styled.div`
  width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 20px;
`

export const DayColumn = styled.div`
  width: 220px;
  padding-left: 20px;
`

export const TimeColumn = styled.div`
  width: 220px;
  padding-left: 20px;
`

export const CronSyntaxInputContainer = styled.div`
  width: 220px;
  padding-left: 20px;
`

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
`

export const LoadingText = styled.div`
  font-size: 12px;
  margin-top: 20px;
`

export const NoTasksMessage = styled.div`
  margin-top: 20px;
  text-align: left;
  margin-bottom: 14px;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: normal;
  color: #969696;
`

export const NoScheduledText = styled.div`
  padding-left: 20px;
  font-size: 14px;
  color: #969696;
`