export const notificationTypes = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success'
}

export const notificationTypeKeys = {
  LOGIN_ERROR: 'login-error',
  REQUEST_PASSWORD_ERROR: 'request-password-error',
  REQUEST_PASSWORD_SUCCESS: 'request-password-success',
  RESET_PASSWORD_ERROR: 'reset-password-error',
  RESET_PASSWORD_SUCCESS: 'reset-password-success',
  REPORT_DATA_ERROR: 'report-data-error',
  USER_CREATION_ERROR: 'user-creation-error',
  USER_CREATION_SUCCESS: 'user-creation-success',
  CUSTOMER_DATA_ERROR: 'customer-data-error',
  GET_LOCKED_ACCOUNTS_ERROR: 'locked-account-error',
  ACCOUNT_UNLOCK_SUCCESS: 'customer-unlock-success',
  ACCOUNT_UNLOCK_ERROR: 'customer-unlock-error',
  GET_CUSTOMER_TEMPLATES_ERROR: 'get-customer-templates-error',
  GET_CUSTOMER_TEMPLATES_SUCCESS: 'get-customer-templates-success',
  SAVE_TEMPLATE_DEFAULTS_ERROR: 'save-template-defaults-error',
  PERFORM_SEARCH_API_ERROR: 'perform_search_api_error',
  PERFORM_SEARCH_INPUT_ERROR: 'perform_search_input_error',
  SEND_TEST_EMAIL_SUCCESS: 'send-test-email-success',
  SEND_TEST_EMAIL_ERROR: 'send-test-email-error',
  UPLOAD_TO_MEDIA_LIBRARY_ERROR: 'upload-to-media-library-error',
  RETRIEVE_MEDIA_LIBRARY_ERROR: 'retrieve-media-library-error',
  SAVE_EDITS_OF_MEDIA_ITEM_ERROR: 'save-edits-of-media-item-error',
  CREATE_NEW_TEMPLATE_IMAGE_ERROR: 'create-new-template-image-error',
  RETRIEVE_TEMPLATE_IMAGES_ERROR: 'retrieve-template-images-error',
  RETRIEVE_TEMPLATE_IMAGE_CONFIGURATIONS_ERROR: 'retrieve-template-image-configurations-error',
  CREATE_NEW_TEMPLATE_IMAGE_CONFIGURATION_ERROR: 'create-new-template-image-configuration-error',
  EDIT_TEMPLATE_IMAGE_CONFIGURATION_ERROR: 'edit-template-image-configuration-error',
  ASSIGN_CONFIGURATION_IMAGE_ERROR: 'assign-configuration-image-error',
  ASSIGN_FALLBACK_IMAGE_ERROR: 'assign-fallback-image-error',
  GET_NOTIFIERS_ERROR: 'get-notifiers-error',
  ADD_NOTIFIER_ERROR: 'add-notifiers-error',
  REMOVE_NOTIFIER_ERROR: 'remove-notifiers-error',
  GET_ADMIN_ACCOUNTS_ERROR: 'get-admin-accounts-error',
  GET_FAILED_FILES_ERROR: 'get-failed-files-error',
  GET_FAILED_FILE_ERROR: 'get-failed-file-error',
  REQUEUE_FAILED_FILE_ERROR: 'requeue-failed-file-error',
  REMOVE_FAILED_FILE_ERROR: 'remove-failed-file-error',
  UPDATE_FALLBACK_LINK_URL_AND_OR_HTML_ERROR: 'update-fallback-link-url-and-or-html-error',
  SAVE_MODIFICATION_EDITS_ERROR: 'save-modification-edits-error',
  SAVE_MODIFICATION_EDITS_SUCCESS: 'save-modification-edits-success',
  GET_TASKS_ERROR: 'get-tasks-error',
  GET_TASK_CUSTOMERS_ERROR: 'get-task-customers-error',
  GET_TASK_CUSTOMER_TIMINGS_ERROR: 'get-task-customer-timings-error',
  GET_TASK_GLOBAL_TIMINGS_ERROR: 'get-task-global-timings-error',
  GET_FNR_CONFIGS_ERROR: 'get-fnr-configs-error',
  CREATE_NEW_CUSTOMER_TIMING_ERROR: 'create-new-customer-timing-error',
  CREATE_NEW_GLOBAL_TIMING_ERROR: 'create-new-global-timing-error',
  CREATE_NEW_FAILED_NOTIFICATION_REPORT_CONFIG_ERROR: 'create-new-failed-notification-report-config-error',
  UPDATE_TASK_LAMBDA_ARN_SUCCESS: 'update-task-lambda-arn-success',
  UPDATE_TASK_LAMBDA_ARN_ERROR: 'update-task-lambda-arn-error',
  UPDATE_TASK_CUSTOMER_TIMING_ERROR: 'update-task-customer-timing-error',
  UPDATE_TASK_GLOBAL_TIMING_ERROR: 'update-task-global-timing-error',
  DELETE_FAILED_NOTIFICATION_REPORT_CONFIG_SUCCESS: 'delete-failed-notification-report-config-success',
  DELETE_FAILED_NOTIFICATION_REPORT_CONFIG_ERROR: 'delete-failed-notification-report-config-error',
  CREATE_NEW_TASK_ERROR: 'create-new-task-error',
  CREATE_NEW_TASK_CUSTOMER_ERROR: 'create-new-task-customer-error',
  UPDATE_TASK_CUSTOMER_SUCCESS: 'update-task-customer-status-success',
  UPDATE_TASK_CUSTOMER_ERROR: 'update-task-customer-status-error',
  GET_LIST_COMMUNICATIONS_ERROR: 'get-list-communications-error',
  CREATE_LIST_COMMUNICATION_ERROR: 'create-list-communication-error',
  HIDE_LIST_COMMUNICATION_ERROR: 'hide-list-communication-error',
  UPLOAD_LIST_MESSAGE_CUSTOMER_FILE_ERROR: 'upload-list-message-customer-file-error',
  UPLOAD_LIST_MESSAGE_CUSTOMER_FILE_SUCCESS: 'upload-list-message-customer-file-success',
  GET_LIST_COMMUNICATION_UPLOAD_HEADERS: 'get-list-communication-upload-headers',
  CONFIRM_SCHEDULED_UPLOAD_SUCCESS: 'confirm-schedule-upload-success',
  CANCEL_SCHEDULED_UPLOAD_SUCCESS: 'cancel_scheduled_upload-success',
  CONFIRM_SCHEDULED_UPLOAD_ERROR: 'confirm-schedule-upload-error',
  CANCEL_SCHEDULED_UPLOAD_ERROR: 'cancel-scheduled-upload-error',
  GET_SCHEDULED_UPLOADS_ERRORS: 'get-scheduled-uploads-errors',
  SAVE_EMAIL_EDITOR_COPY_MODIFICATIONS_SUCCESS: 'save-email-editor-copy-modifications-success',
  SAVE_EMAIL_EDITOR_COPY_MODIFICATIONS_ERROR: 'save-email-editor-copy-modifications-error',
  ADD_BASE_TEMPLATE_ERROR: 'add-base-template-error',
  GET_LIST_COMMUNICATION_ERROR: 'get-list-communication',
  GET_BASE_TEMPLATE_ERROR: 'get-base-template-error',
  UPDATE_BASE_TEMPLATE_ERROR: 'update-base-template-error',
  UPDATE_BASE_TEMPLATE_SUCCESS: 'update-base-template-success',
  SAVE_TEMPLATE_ERROR: 'save-template-error',
  SAVE_TEMPLATE_SUCCESS: 'save-template-success',
  UPDATE_SETTINGS_DIGEST_FREQUENCY_SUCCESS: 'update-settings-digest-frequency-success',
  UPDATE_SETTINGS_DIGEST_FREQUENCY_ERROR: 'update-settings-digest-frequency-error',
  GET_AUDIENCE_LISTS_ERROR: 'get-audience-lists-error',
  CREATE_AUDIENCE_LIST_ERROR: 'create-audience-list-error',
  UPLOAD_AUDIENCE_LIST_ERROR: 'upload-audience-list-error',
  FINALIZE_AUDIENCE_LIST_ERROR: 'finalize-audience-list-error',
  SEARCH_AUDIENCE_LIST_ERROR: 'search-audience-list-error',
  GET_AUDIENCE_RECORD_ERROR: 'get-audience-record-error',
  PUT_AUDIENCE_RECORD_ERROR: 'put-audience-record-error',
  PUT_AUDIENCE_RECORD_SUCCESS: 'put-audience-record-success',
  GET_EDITABLE_FIELDS_ERROR: 'get-editable-fields-error',
  SAVE_EDITABLE_FIELDS_ERROR: 'save-editable-fields-error',
  SAVE_COMMUNICATION_PREFERENCES_SUCCESS: 'save-communication-preferences-success',
  GET_ACCOUNTS_ERROR: 'get-accounts-error',
  ARCHIVE_MEDIA_ITEM_ERROR: 'archive-media-item-error',
  SET_LIST_MESSAGE_PERMISSION_ERROR: 'set-list-message-permission-error',
  GET_COMMUNICATION_PREFERENCES_REPORT_ERROR: 'get-communication-preferences-report-error',
  GET_CONFIG_SPECIFIC_CUSTOMER_BRANCHES_ERROR: 'get-config-specific-customer-branches-error',
  CREATE_PATRON_DATABASE_ERROR: 'create-patron-database-error',
  CREATE_PATRON_DATABASE_SUCCESS: 'create-patron-database-success',
  GET_PATRON_DATABASE_ERROR: 'get-patron-database-error',
  UPDATE_PATRON_DATABASE_ERROR: 'update-patron-database-error',
  UPDATE_PATRON_DATABASE_SUCCESS: 'update-patron-database-success',
  GET_PATRON_LISTS_ERROR: 'get-patron-lists-error',
  CREATE_PATRON_LISTS_ERROR: 'create-patron-lists-error',
  CREATE_PATRON_LISTS_SUCCESS: 'create-patron-lists-success',
  GET_PATRON_LIST_ERROR: 'get-patron-list-error',
  ADMIN_RESET_PASSWORD_USER_SEARCH_ERROR: 'admin-reset-password-user-search-error',
  ADMIN_RESET_PASSWORD_ERROR: 'admin-reset-password-error',
  ADMIN_RESET_PASSWORD_SUCCESS: 'admin-reset-password-success',
  RETRIEVE_IMAGE_CONFIGURATIONS_BRANCH_OVERRIDES_ERROR: 'retrieve-image-configurations-branch-overrides-error',
  SAVE_PATRON_LIST_FILTER_DEFINITION_ERROR: 'save-patron-list-filter-definition-error',
  SAVE_PATRON_LIST_FILTER_DEFINITION_SUCCESS: 'save-patron-list-filter-definition-success',
  UPDATE_LIST_PREVIEW_COUNT_ERROR: 'update-list-preview-count-error',
  DELETE_AUDIENCE_RECORD_ERROR: 'delete-audience-record-error',
  DELETE_AUDIENCE_RECORD_SUCCESS: 'delete-audience-record-success',
  CREATE_AUDIENCE_RECORD_ERROR: 'create-audience-record-error',
  CREATE_AUDIENCE_RECORD_SUCCESS: 'create-audience-record-success',
  POST_CONFIG_SPECIFIC_SENDER_INFO_ERROR: 'post-config-specific-sender-info-error',
  POST_CONFIG_SPECIFIC_SENDER_INFO_SUCCESS: 'post-config-specific-sender-info-success',
  DELETE_AUDIENCE_LIST_ERROR: 'delete-audience-list-error',
  DELETE_AUDIENCE_LIST_SUCCESS: 'delete-audience-list-success',
  DELETE_DYNAMIC_LIST_ERROR: 'delete-dynamic-list-error',
  DELETE_DYNAMIC_LIST_SUCCESS: 'delete-dynamic-list-success',
}
