import { types } from 'mobx-state-tree'

const ModalStore = types.model({
  title: types.optional(types.string, ""),
  hidden: types.optional(types.boolean, true)
}).actions(self => ({
  updateModalAttributes(title: string) {
    self.title = title
  },
  changeModalVisbility() {
    self.hidden = !self.hidden
  }
}))

export type IModalStore = typeof ModalStore.Type
export default ModalStore
