import React from 'react'
import { inject, observer } from 'mobx-react'
import LoginRedirect from '../components/shared/LoginRedirect'
import { IAuthStore } from '../../stores/AuthStore'
import { Redirect } from 'react-router-dom'
import { useUser, useAuth, useSession } from '@clerk/clerk-react'

interface WithAuthorizationProps {
  authStore?: IAuthStore,
  children?: any
}

type AuthOptions = {
  roles?: string[],
  features?: string[]
}

export const withAuthorization = <P extends object>(Component: React.ComponentType<P>, options: string[] | null | AuthOptions) => {
  const withAuthorization: React.FC<P & WithAuthorizationProps> = (p: P & WithAuthorizationProps) => {
    const { authStore, ...props } = p
    const { isSignedIn, user } = useUser()
    if (!isSignedIn)
      return <LoginRedirect />

    try {
      authStore && authStore.parseJwtPayloadFromCookie(isSignedIn, user)
      let authorized
      let roles
      if (Array.isArray(options)) {
        roles = options
      } else if (options && options.roles) {
        roles = options.roles
      }

      if (authStore.resetRequired) {
        return <Redirect to='/reset-password' />
      }

      if (roles && !roles.length) {
        authorized = true
      } else if (authStore && authStore.roles.length && roles && roles.length) {
        authorized = authStore.roles.some(role => roles.includes(role.name))
        if (!authorized && options && !Array.isArray(options) && options.features && options.features.length) {
          authorized = authStore && authStore.features.some(feature => options.features.includes(feature))
        }
      }
      else {
        authorized = false
      }
      return authorized ? <Component authStore={authStore} {...props as P} /> : <LoginRedirect />
    } catch (error) {
      console.log(`There was an error within the auth component.`)
    }
  }

  // @inject((allStores: any) => {
  //   return {
  //     authStore: allStores.authStore as IAuthStore
  //   }
  // })
  // class WithAuthorization extends React.Component<P & WithAuthorizationProps> {
  //   public render() {
  //     const { authStore, ...props } = this.props
  //     try {
  //       authStore && authStore.parseJwtPayloadFromCookie()
  //       let authorized
  //       let roles
  //       if (Array.isArray(options)) {
  //         roles = options
  //       } else if (options && options.roles) {
  //         roles = options.roles
  //       }

  //       if (authStore.resetRequired) {
  //         return <Redirect to='/reset-password' />
  //       }

  //       if (roles && !roles.length) {
  //         authorized = true
  //       } else if (authStore && authStore.roles.length && roles && roles.length) {
  //         authorized = authStore.roles.some(role => roles.includes(role.name))
  //         if (!authorized && options && !Array.isArray(options) && options.features && options.features.length) {
  //           authorized = authStore && authStore.features.some(feature => options.features.includes(feature))
  //         }
  //       }
  //       else {
  //         authorized = false
  //       }
  //       return authorized ? <Component authStore={authStore} {...props as P} /> : <LoginRedirect />
  //     } catch (error) {
  //       console.log(`There was an error within the auth component.`)
  //     }
  //   }
  // }

  return inject((allStores: any) => {
    return {
      authStore: allStores.authStore as IAuthStore
    }
  })(withAuthorization)
}

