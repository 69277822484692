import styled from 'styled-components'

export const ImageSelectionContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  height: 91%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Title = styled.div`
  width: 100%;
  font-size: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const SubTitle = styled.div`
  width: 100%;
  font-size: 14x;
  text-indent: 20px;
  font-weight: bold;
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const Images = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  cursor: pointer;
`

export const TemplateImageItem = styled.div`
  display: flex;
  flex-direction: column; 
  position: relative;
  width: 200px;
  height: 192px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 20px;
`

export const PreviewImageContainer = styled.div`
  width: 200px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.05);
  margin: 5px;
  border: 1px solid rgba(0,0,0,0.1);
`

export const FriendlyName = styled.div`
  width: 200px;
  height: 30px;
  top: 142px;
  text-indent: 10px;
  position: absolute;
  left: 0px;
  padding-top: 6px;
  line-height: 20px;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
`

export const EffectiveUntil = styled.div`
  font-size: 12px;
  width: 200px;
  height: 20px;
  text-indent: 10px;
  position: absolute;
  top: 172px;
`

export const ImagesLoader = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LoadingText = styled.div`
  margin-right: 40px;
`

export const VariableName = styled.div`
  font-size: 12px;
  text-align: center;
  height: 16px;
`