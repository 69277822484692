import React, { Component } from 'react'
import * as styled from './Modals.styled'
import { IModalStore } from '../../../../stores/ModalStore'
import { observer, inject } from 'mobx-react'
import whiteBee from '../../../content/img/whiteLogoBee32x32.png'
import { ITemplateCustomizationStore } from '../../../../stores/TemplateCustomizationStore'
import { IMediaLibraryStore } from '../../../../stores/MediaLibraryStore'
import { ITaskStore } from '../../../../stores/TaskStore'
import { ITemplateStore } from '../../../../stores/TemplateStore'
import MediaLibraryContainer from '../../ModalContent/MediaLibrary/MediaLibraryContainer'
import TemplateImageContainer from '../../ModalContent/TemplateCustomization/TemplateImageContainer'
import TasksConatiner from '../../ModalContent/Tasks/TasksContainer'
import ListMessagesModal from '../../ModalContent/ListMessages/ListMessagesModalContainer'

interface ModalsProps {
  modalStore?: IModalStore,
  mediaLibraryStore?: IMediaLibraryStore,
  templateCustomizationStore?: ITemplateCustomizationStore,
  taskStore?: ITaskStore,
  templateStore?: ITemplateStore
}

@inject((allStores : any) => {
  return {
    modalStore: allStores.modalStore as IModalStore,
    mediaLibraryStore: allStores.mediaLibraryStore as IMediaLibraryStore,
    templateCustomizationStore: allStores.templateCustomizationStore as ITemplateCustomizationStore,
    taskStore: allStores.taskStore as ITaskStore,
    templateStore: allStores.templateStore as ITemplateStore
  }
})
@observer
class Modals extends Component<ModalsProps> {
  private redirect() {
    if (this.props.modalStore!.title === 'Media Library') {
      this.props.mediaLibraryStore!.clearModal()
    }

    if (this.props.modalStore!.title === 'Template Image Selection') {
      this.props.templateCustomizationStore!.clearModal()
    }

    if (this.props.modalStore!.title === 'Tasks') {
      this.props.taskStore!.clearModal()
    }

    if (this.props.modalStore!.title === 'Upload List Messages Customers') {
      this.props.templateStore!.clearModal()
    }
  }

  public render() {
    return (
      <div hidden={this.props.modalStore!.hidden}>
        <styled.ModalWrapper>
          <styled.ModalContainer>
            <styled.ModalTitleBar>
              <styled.ModalTitle>
                <styled.Logo src={whiteBee} />
                <>
                  {this.props.modalStore!.title}
                </>
              </styled.ModalTitle>
              <styled.ModalExit onClick={this.redirect.bind(this)}>
                x
              </styled.ModalExit>
            </styled.ModalTitleBar>
            <styled.ModalContent>
              {this.props.modalStore!.title === 'Media Library' && (
                <MediaLibraryContainer />
              )}
              {this.props.modalStore!.title === 'Template Image Selection' && (
                <TemplateImageContainer />
              )}
              {this.props.modalStore!.title === 'Tasks' && (
                <TasksConatiner />
              )}
              {(this.props.modalStore!.title === 'Upload List Messages Customers') && (
                <ListMessagesModal />
              )}
            </styled.ModalContent>
          </styled.ModalContainer>
        </styled.ModalWrapper>
      </div>
    )
  }
}

export default Modals
