import styled from 'styled-components'

export const DashboardWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  min-height: 141%;
  background-color: #f5f5f5;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
`

export const HeaderWrapper = styled.div`
  width: 100%;
  z-index: 1;
`

export const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: row;
`