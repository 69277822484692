export const searchTypes = [
  { id: 1, name: 'email' },
  { id: 2, name: 'phone' },
  { id: 3, name: 'barcode' }
]

//Common and Email Events
export enum NotificationChannelEventType {
  Sent = 0,
  Bounce,
  Click, 
  Deferred,
  Delivered,
  Dropped,
  Open,
  Other
}

// Voice Events - Starts at 3XX
export enum VoiceEvents {
  Initiated = 300,
  Ringing,
  'Answered by Patron',
  'Left Voicemail',
  UnknownAnswered,
  Busy,
  Cancelled,
  Completed,
  Failed,
  NoAnswer
}