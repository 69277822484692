import styled from 'styled-components'

export const RightContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  width: 350px;
  background-color: #dddddd;
`

export const RightContentHeader = styled.div`
  font-size: 24px;
  line-height: 40px;
  color: #858585;
`

export const RightContentBody = styled.div`
  color: #969696;
  font-size: 12px;
`