import axios from 'axios'

let apiSuffix = 'api/'
let baseURL = `/${apiSuffix}`

export const api = axios.create({
  baseURL
})

api.interceptors.request.use((config) => {
  return config
}, (error) => {
  return Promise.reject(error)
})
