import { types, getEnv, flow, cast } from 'mobx-state-tree'
import ApiStatusStore from './ApiStatusStore'
import TaskData from '../models/TaskData'
import TaskCustomer from '../models/TaskCustomer'
import TaskCustomerTiming from '../models/TaskCustomerTiming'
import TaskGlobalTiming from '../models/TaskGlobalTiming'

import FailedNotificationReportConfig from '../models/FailedNotificationReportConfig'
import { apiStatusDefinitions } from '../utils/apiStatusDefinitions'
import { notificationTypeKeys } from '../ui/components/shared/Notifications/Notifications.copy'

const TaskStore = types.model({
  tasks: types.maybeNull(types.array(TaskData)),
  taskCustomers: types.optional(types.array(TaskCustomer), []),
  taskCustomerTimings: types.optional(types.array(TaskCustomerTiming), []),
  taskGlobalTimings: types.optional(types.array(TaskGlobalTiming), []),
  taskModalFunction: types.optional(types.string, 'CUSTOMER_SCHEDULE'),
  taskModalFunctionType: types.optional(types.string, 'EDIT'),
  selectedTaskCustomerId: types.maybeNull(types.number),
  selectedTask: types.maybeNull(types.number),
  failedNotificationReportConfigs: types.optional(types.array(FailedNotificationReportConfig), []),
  apiStatus: ApiStatusStore
}).actions(self => {
  const { notificationStore, modalStore, api } = getEnv(self)
  const setSelectedTaskId = (taskId: number) => {
    self.selectedTask = taskId
  }
  const retrieveTaskCustomerTiming = flow(function*() {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_TASK_CUSTOMER_TIMINGS)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_TASK_CUSTOMER_TIMINGS_ERROR)
      const data = yield api.retrieveTaskCustomerTimings(self.selectedTaskCustomerId)
      self.taskCustomerTimings = cast(data.taskCustomerTimings)
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_TASK_CUSTOMER_TIMINGS_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_TASK_CUSTOMER_TIMINGS)
  })
  const retrieveTaskGlobalTiming = flow(function*() {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_TASK_GLOBAL_TIMINGS)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_TASK_GLOBAL_TIMINGS_ERROR)
      const data = yield api.retrieveTaskGlobalTimings(self.selectedTask)
      self.taskGlobalTimings = cast(data.taskGlobalTimings)
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_TASK_GLOBAL_TIMINGS_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_TASK_GLOBAL_TIMINGS)
  })
  
  const populateAndDisplayTasksModal = (functionType: string, type: string | null, item: number | null) => {
    if (functionType === 'CUSTOMER_SCHEDULE' || functionType === 'CUSTOMER_TASK_CONFIGURATION_SETUP') {
      self.selectedTaskCustomerId = item
    } else if (functionType === 'EDIT_ARN' || functionType === 'GLOBAL_SCHEDULE') {
      if (item) {
        setSelectedTaskId(item)
      }
    }
    self.taskModalFunction = functionType
    if (type) {
      self.taskModalFunctionType = type
    }
    modalStore.updateModalAttributes('Tasks')
    modalStore.changeModalVisbility()
  }
  const retrieveTasks = flow(function*() {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_TASKS)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_TASKS_ERROR)
      const data = yield api.retrieveTasks()
      self.tasks = cast(data.tasks)
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_TASKS_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_TASKS)
  })
  const retrieveTaskCustomers = flow (function*(taskId: number) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_TASK_CUSTOMERS)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_TASK_CUSTOMERS_ERROR)
      const data = yield api.retrieveTaskCustomers(taskId)
      self.taskCustomers = cast(data.taskCustomers)
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_TASK_CUSTOMERS_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_TASK_CUSTOMERS)
  })
  const clearModal = () => {
    self.taskModalFunction = 'CUSTOMER_SCHEDULE'
    self.taskModalFunctionType = 'EDIT'
    self.taskCustomerTimings = cast([])
    modalStore.changeModalVisbility()
    modalStore.updateModalAttributes('')
  }
  const setTaskModalFunctionType = (type: string) => {
    self.taskModalFunctionType = type
  }
  const retrieveFailedNotificationReportConfig = flow (function*() {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_FNR_CONFIGS)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_FNR_CONFIGS_ERROR)
      const data = yield api.retrieveFailedNotificationReportConfig(self.selectedTaskCustomerId)
      self.failedNotificationReportConfigs = cast(data.failedNotificationReportConfigs)
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_FNR_CONFIGS_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_FNR_CONFIGS)
  })
  const createNewCustomerTiming = flow (function*(time: any) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_NEW_CUSTOMER_TIMING)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.CREATE_NEW_CUSTOMER_TIMING_ERROR)
      yield api.createNewCustomerTiming(self.selectedTaskCustomerId, time)
      setTaskModalFunctionType('EDIT')
      self.taskCustomerTimings = cast([])
      retrieveTaskCustomerTiming()
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.CREATE_NEW_CUSTOMER_TIMING_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_NEW_CUSTOMER_TIMING)
  })

  const createNewGlobalTiming = flow (function*(time: any) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_NEW_GLOBAL_TIMING)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.CREATE_NEW_GLOBAL_TIMING_ERROR)
      yield api.createNewGlobalTiming(self.selectedTask, time)
      setTaskModalFunctionType('EDIT')
      self.taskGlobalTimings = cast([])
      retrieveTaskGlobalTiming()
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.CREATE_NEW_GLOBAL_TIMING_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_NEW_GLOBAL_TIMING)
  })

  const createNewFailedNotificationReportConfig = flow (function*(notificationConfig: string) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_NEW_FAILED_NOTIFICATION_REPORT_CONFIG)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.CREATE_NEW_FAILED_NOTIFICATION_REPORT_CONFIG_ERROR)
      yield api.createNewFailedNotificationReportConfig(self.selectedTaskCustomerId, notificationConfig)
      self.taskModalFunctionType = 'EDIT'
      retrieveFailedNotificationReportConfig()
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.CREATE_NEW_FAILED_NOTIFICATION_REPORT_CONFIG_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_NEW_FAILED_NOTIFICATION_REPORT_CONFIG)
  })
  const createNewTask = flow (function*(lambdaArn: string, friendlyName: string, global: boolean) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_NEW_TASK)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.CREATE_NEW_TASK_ERROR)
      yield api.createNewTask(lambdaArn, friendlyName, global)
      retrieveTasks()
      clearModal()
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.CREATE_NEW_TASK_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_NEW_TASK)
  })
  const createNewTaskCustomer = flow (function*(customerId: number) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_NEW_TASK_CUSTOMER)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.CREATE_NEW_TASK_CUSTOMER_ERROR)
      yield api.createNewTaskCustomer(self.selectedTask, customerId)
      retrieveTaskCustomers(self.selectedTask!)
      clearModal()
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.CREATE_NEW_TASK_CUSTOMER_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CREATE_NEW_TASK_CUSTOMER)
  })
  const updateTaskLambdaArn = flow (function*(lambdaArn: string) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_TASK_LAMBDA_ARN)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.UPDATE_TASK_LAMBDA_ARN_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.UPDATE_TASK_LAMBDA_ARN_SUCCESS)
      yield api.updateTaskLambdaArn(self.selectedTask, lambdaArn)
      retrieveTasks()
      notificationStore!.addNotification('success', 'Success!  That particular Lambda ARN has been updated.', notificationTypeKeys.UPDATE_TASK_LAMBDA_ARN_SUCCESS)
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.UPDATE_TASK_LAMBDA_ARN_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_TASK_LAMBDA_ARN)
  })
  const updateTaskCustomerTiming = flow(function*(taskCustomerTimingId: number | string, time: string) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_TASK_CUSTOMER_TIMING)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.UPDATE_TASK_CUSTOMER_TIMING_ERROR)
      yield api.updateTaskCustomerTiming(taskCustomerTimingId, time)
      setTaskModalFunctionType('EDIT')
      self.taskCustomerTimings = cast([])
      retrieveTaskCustomerTiming()
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.UPDATE_TASK_CUSTOMER_TIMING_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_TASK_CUSTOMER_TIMING)
  })
  const updateGlobalTiming = flow(function*(taskGlobalId: number, time: string ) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_TASK_GLOBAL_TIMING)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.UPDATE_TASK_GLOBAL_TIMING_ERROR)
      yield api.updateTaskGlobalTiming(taskGlobalId, time)
      setTaskModalFunctionType('EDIT')
      self.taskGlobalTimings = cast([])
      retrieveTaskGlobalTiming()
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.UPDATE_TASK_GLOBAL_TIMING_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_TASK_GLOBAL_TIMING)
  })
  const updateTaskCustomer = flow(function*(taskCustomerId: number, status: boolean) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_TASK_CUSTOMER_STATUS)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.UPDATE_TASK_CUSTOMER_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.UPDATE_TASK_CUSTOMER_SUCCESS)
      yield api.updateTaskCustomer(taskCustomerId, status)
      retrieveTaskCustomers(self.selectedTask!)
      notificationStore!.addNotification('success', 'Success!  The selected customer status has been modified.', notificationTypeKeys.UPDATE_TASK_CUSTOMER_SUCCESS)
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.UPDATE_TASK_CUSTOMER_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPDATE_TASK_CUSTOMER_STATUS)
  })
  const deleteFailedNotificationReportConfig = flow (function*(failedNotificationReportConfig: number) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.DELETE_FAILED_NOTIFICATION_REPORT_CONFIG)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.DELETE_FAILED_NOTIFICATION_REPORT_CONFIG_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.DELETE_FAILED_NOTIFICATION_REPORT_CONFIG_SUCCESS)
      yield api.deleteFailedNotificationReportConfig(failedNotificationReportConfig)
      retrieveFailedNotificationReportConfig()
      notificationStore!.addNotification('success', 'Success!  The selected failed notification report configuration has been deleted.', notificationTypeKeys.DELETE_FAILED_NOTIFICATION_REPORT_CONFIG_SUCCESS)
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.DELETE_FAILED_NOTIFICATION_REPORT_CONFIG_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.DELETE_FAILED_NOTIFICATION_REPORT_CONFIG)
  })
  const clearTaskCustomers = () => {
    self.taskCustomers = cast([])
  }

  return {
    retrieveTaskCustomerTiming,
    retrieveTaskGlobalTiming,
    populateAndDisplayTasksModal,
    retrieveTasks,
    retrieveTaskCustomers,
    clearModal,
    setSelectedTaskId,
    setTaskModalFunctionType,
    retrieveFailedNotificationReportConfig,
    createNewCustomerTiming,
    createNewGlobalTiming,
    createNewFailedNotificationReportConfig,
    createNewTask,
    createNewTaskCustomer,
    updateTaskLambdaArn,
    updateTaskCustomerTiming,
    updateGlobalTiming,
    updateTaskCustomer,
    deleteFailedNotificationReportConfig,
    clearTaskCustomers
  }
}).views(self => ({
  getTaskName(taskId: number) {
    if (self.tasks && self.tasks.length > 0) {
      const task = self.tasks!.find(task => task.id == taskId)
      return task!.friendlyName
    } 
    return 'Loading'
  },
  get taskArn() {
    if (self.tasks && self.tasks.length > 0) {
      const task = self.tasks!.find(task => task.id == self.selectedTask)
      return task!.lambdaArn
    } 
    return 'Loading'
  },
  getCustomerName() {
    if (self.taskCustomers.length > 0) {
      return self.taskCustomers!.find(taskCustomer => taskCustomer.id === self.selectedTaskCustomerId)!.customer.name
    }
    return 'Loading'  
  },
  get globalTasksExist () {
    if (self.tasks && self.tasks.length && self.tasks.filter(x => x.global).length) {
      return true
    }
    return false
  },
  get customerTasksExist () {
    if (self.tasks && self.tasks.length && self.tasks.filter(x => !x.global).length) {
      return true
    }
    return false
  }
}))

export type ITaskStore = typeof TaskStore.Type
export default TaskStore