import { types, flow, getEnv, cast } from 'mobx-state-tree'
import { apiStatusDefinitions } from '../utils/apiStatusDefinitions'
import { notificationTypeKeys } from '../ui/components/shared/Notifications/Notifications.copy'
import ApiStatusStore from './ApiStatusStore'
import CommunicationPreferences from '../models/CommunicationPreferences'
import CommunicationPreferencesReportItem from '../models/CommunicationPreferencesReportData'

interface CommunicationPreferencesStoreVolatile {
  notificationChannelConfigs : {[channel : string] : { name: string, blockable: boolean, baseTemplateId: number, baseTemplateName: string }[] },
  interests : { audienceListId: number, audienceListName: string, selected: boolean}[]
}

const CommunicationPreferencesStore = types.model('CommunicationPreferencesStore', {
  customerCode: types.maybeNull(types.number),
  id: types.maybeNull(types.string),
  preferences: types.maybeNull(CommunicationPreferences),
  customerName: types.maybeNull(types.string),
  communicationPreferencesReportData: types.maybeNull(types.array(CommunicationPreferencesReportItem)),
  optOut: types.maybeNull(types.boolean),
  customerPhone: types.maybeNull(types.string),
  loadFailure: false,
  showSearch: false,
  apiStatus: ApiStatusStore
}).volatile<CommunicationPreferencesStoreVolatile>(self => ({
  notificationChannelConfigs : {},
  interests: []
})).actions(self => {
  const { notificationStore, api, customerStore } = getEnv(self)
  const load = (customerCode : string, id : string) => {
    self.showSearch = false
    if (customerCode === void 0 || customerCode === null || id === void 0 || id === null) {
      self.showSearch = true
      return
    }

    try {
      self.customerCode = parseInt(customerCode)
      self.id = id
    } catch {
      self.loadFailure = true
    }
  }
  const getCommunicationPreferences = flow(function*() {
    if (self.id === null || self.customerCode === null) 
      return

    const request = yield api.getCommunicationPreferences(self.customerCode, self.id)
    if (request.status !== 200) 
      self.loadFailure = true
    
    self.preferences = CommunicationPreferences.create()
    let postRequestProcessing = JSON.parse(request.data.communicationPreferences)
    postRequestProcessing?.channels && self.preferences.setChannels(postRequestProcessing?.channels)
    self.notificationChannelConfigs = request.data.notificationChannelConfigs
    self.interests = request.data.interests
    self.customerName = request.data.customerName
    self.optOut = request.data.optOut
    self.customerPhone = request.data.customerPhone
  })
  const saveCommunicationPreferences = flow(function* () {
    notificationStore!.removeNotification(null, notificationTypeKeys.SAVE_COMMUNICATION_PREFERENCES_SUCCESS)
    if (self.id === null || self.customerCode === null)
      return

    let shouldRemoveSetTimestamp = false;
    try {
      shouldRemoveSetTimestamp = Object.keys(self.notificationChannelConfigs).reduce<boolean>((acc, channel) => {
        const configs = self.notificationChannelConfigs[channel]
        return acc && configs.every(config => {
          const channels = self.preferences?.channels[channel]
          if (channels == null) {
            return acc
          }
          if (config.baseTemplateId !== 0 && config.baseTemplateId != null) {
            return !channels.disabledListBaseIds.includes(config.baseTemplateId) && !channels.listDisabled
          } else {
            return !channels.disabledNotificationTypes.includes(config.name) && !channels.transactionalDisabled
          }
        })
      }, true)
    } catch {}
    yield api.setCommunicationPreferences(self.customerCode, self.id, JSON.stringify({
      channels: self.preferences!.channels
    }), shouldRemoveSetTimestamp)
    notificationStore!.addNotification('success', 'Your preferences have been saved.', notificationTypeKeys.SAVE_COMMUNICATION_PREFERENCES_SUCCESS)
  })

  const getCommunicationPreferencesReport = flow(function*() {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_COMMUNICATION_PREFERENCES_REPORT)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_COMMUNICATION_PREFERENCES_REPORT_ERROR)
      const request = yield api.getCommunicationPreferencesReport(customerStore.setCustomerId)
      self.communicationPreferencesReportData = cast(request.communicationPreferencesResults)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_COMMUNICATION_PREFERENCES_REPORT_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_COMMUNICATION_PREFERENCES_REPORT)
  })
  return {
    load,
    getCommunicationPreferences,
    saveCommunicationPreferences,
    getCommunicationPreferencesReport
  }
})

export type ICommunicationPreferencesStore = typeof CommunicationPreferencesStore.Type
export default CommunicationPreferencesStore