import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './UploadDialogue.styled'
import { IMediaLibraryStore } from '../../../../stores/MediaLibraryStore'
import { ITemplateCustomizationStore } from '../../../../stores/TemplateCustomizationStore'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { apiStatusDefinitions } from '../../../../utils/apiStatusDefinitions'
import CircularProgress from '@material-ui/core/CircularProgress'

interface MediaLibraryContainerProps {
  mediaLibraryStore?: IMediaLibraryStore,
  templateCustomizationStore?: ITemplateCustomizationStore,
  passthrough?: boolean
}

@inject((allStores: any) => {
  return {
    mediaLibraryStore: allStores.mediaLibraryStore as IMediaLibraryStore,
    templateCustomizationStore: allStores.templateCustomizationStore as ITemplateCustomizationStore
  }
})
@observer
class UploadDialogue extends Component<MediaLibraryContainerProps> {
  public state = {
    form: {
      name: '',
      description: '',
      fileName: '',
      altText: ''
    },
    formErrors: {
      name: false,
      description: false,
      fileName: false
    },
    file: null,
    uploadActive: false
  }

  private handleChange = (name: string) => (event: any) => {
    const form = this.state.form

    const updatedForm = {
      [name]: event.target.value
    }

    if (name === 'fileName') {
      this.setState({ file: event.target.files[0], form: { ...form, ...updatedForm } })
    } else {
      this.setState({ form: { ...form, ...updatedForm } })
    }
  }

  private checkFieldsAndSubmit = () => {
    const formErrors = this.state.formErrors
    if (this.state.form.name.length < 1) {
      formErrors.name = true
    } else {
      formErrors.name = false
    }

    if (this.state.form.description.length < 1) {
      formErrors.description = true
    } else {
      formErrors.description = false
    }

    if (this.state.form.name.length > 0 && this.state.form.description.length > 0 && this.state.file) {
      this.setState({ formErrors: formErrors })
      this.props.mediaLibraryStore!.submitFileForUploading(this.state.form.name, this.state.form.description, this.state.file!, this.state.form.altText.length > 0 ? this.state.form.altText : undefined, this.props.passthrough)
    } else {
      this.setState({ formErrors: formErrors })
      return
    }
  }

  public render() {
    return (
      <styled.UploadDialogueWrapper>
        <styled.Title>
          Upload an item to your media library:
        </styled.Title>
        <styled.UploadDialogueContent>
          <styled.DropZone>
            <input
              accept="image/*"
              style={{ display: 'none', height: '100%', width: '100%' }}
              id="image-button-file"
              onChange={this.handleChange('fileName')}
              type="file"
            />
            <label htmlFor="image-button-file" style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <styled.InnerDropZone>
                {this.props.mediaLibraryStore!.apiStatus.checkApiStatus(apiStatusDefinitions.UPLOAD_TO_MEDIA_LIBRARY) && (
                  <CircularProgress style={{ marginBottom: 20, marginTop: '-60px' }} color="secondary" />
                )}
                {!this.state.file ? (
                  <>Click here in order to select a file<br /> for uploading to your media library.
                    <br />
                    <i>Images larger than 1200px wide will be resized.</i>
                  </>
                ) : (
                  <>
                    Now that a file has been selected, please<br /> complete the form and upload the file.
                    <styled.FileName>
                      File to be uploaded: {this.state.form.fileName.replace(/.*[\/\\]/, '')}
                    </styled.FileName>
                  </>
                )}
              </styled.InnerDropZone>
            </label>
          </styled.DropZone>
          <styled.UploadDialogueForm>
            <styled.Label>
              Please complete the form below:
            </styled.Label>
            <styled.FormElementWrapper>
              <TextField
                style={{ width: '95%', marginRight: 20, marginBottom: 10, marginTop: '-6px' }}
                onChange={this.handleChange('name')}
                type='text'
                name='name'
                fullWidth
                value={this.state.form.name}
                placeholder='Enter a name'
                label='Enter a name'
                margin="normal"
                variant="outlined"
                required
                error={this.state.formErrors.name}
                helperText={this.state.formErrors.name ? "You must enter a name before continuing." : null}
              />
            </styled.FormElementWrapper>
            <styled.FormElementWrapper>
              <TextField
                style={{ width: '95%', marginRight: 20, marginBottom: 10, marginTop: '-6px' }}
                onChange={this.handleChange('description')}
                type='text'
                name='description'
                fullWidth
                value={this.state.form.description}
                placeholder='Enter a description'
                label='Enter a description'
                margin="normal"
                variant="outlined"
                multiline
                rows="1"
                rowsMax="1"
                required
                error={this.state.formErrors.description}
                helperText={this.state.formErrors.description ? "You must enter a description before continuing." : null}
              />
            </styled.FormElementWrapper>
            <styled.FormElementWrapper>
              <TextField
                style={{ width: '95%', marginRight: 20, marginBottom: 10, marginTop: '-6px' }}
                onChange={this.handleChange('altText')}
                type='text'
                name='altText'
                fullWidth
                value={this.state.form.altText}
                placeholder='Alt Text'
                label='Alt Text'
                margin="normal"
                variant="outlined"
                multiline
                rows="1"
                rowsMax="1"
              />
            </styled.FormElementWrapper>
            <styled.FormButtonContainer>
              <Button onClick={() => { this.props.passthrough ? this.props.templateCustomizationStore!.setTemplateImageFunctionState('ADD_IMAGE_GALLERY_PASSTHROUGH') : this.props.mediaLibraryStore!.setMediaLibraryFunctionState('IMAGE_GALLERY') }} variant='contained' color='primary' style={{ marginRight: 10 }}>
                Cancel
              </Button>
              <Button onClick={this.checkFieldsAndSubmit} variant='contained' color='primary'>
                Upload
              </Button>
            </styled.FormButtonContainer>
          </styled.UploadDialogueForm>
        </styled.UploadDialogueContent>
      </styled.UploadDialogueWrapper>
    )
  }
}

export default UploadDialogue