import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './AddCustomer.styled'
import { ITaskStore } from '../../../../stores/TaskStore'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import { ICustomerStore } from '../../../../stores/CustomerStore'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import ButtonLoading from '../../shared/Loading/ButtonLoading'
import { apiStatusDefinitions } from '../../../../utils/apiStatusDefinitions'

interface MediaLibraryContainerProps {
  taskStore?: ITaskStore,
  customerStore?: ICustomerStore
}

@inject((allStores : any) => {
  return {
    taskStore: allStores.taskStore as ITaskStore,
    customerStore: allStores.customerStore as ICustomerStore
  }
})
@observer
class AddCustomer extends Component<MediaLibraryContainerProps> {
  public state = {
    customerId: null,
    customerIdError: false
  }

  public handleCustomerChange = (event: any) => {
    this.setState({ customerId: event.target.value, customerIdError: false })
  }

  private cancelCustomerAdd = () => {
    this.props.taskStore!.clearModal()
  }

  private saveNewCustomer = () => {
    if (!this.state.customerId) {
      this.setState({ customerIdError: true })
    }
    this.props.taskStore!.createNewTaskCustomer(this.state.customerId!)
  }

  public render () {
    return (
      <div>
        <styled.AddCustomerDialogue>
          <div>
            <styled.Title>
              Add a customer to the {this.props.taskStore!.getTaskName(this.props.taskStore!.selectedTask!)}:
            </styled.Title>
            <styled.SubTitle>
              Add a new customer to the selected task by selecting one of the available customers in the dropdown below. Click {`"save"`} to create the new customer or cancel to close this dialogue.
            </styled.SubTitle>
            <styled.FormItemContainer>
              <FormControl style={{ width: '95%', marginRight: 20, marginBottom: 10 }} variant="outlined">
                <InputLabel
                  htmlFor={`outlined-taskDay-simple`}
                >
                  Select a customer
                </InputLabel>
                <Select
                  value={this.state.customerId}
                  onChange={this.handleCustomerChange}
                  placeholder='Select a customer...'
                  autoWidth
                  error={this.state.customerIdError}
                  input={<OutlinedInput placeholder='Select a customer...' labelWidth={128} name='customerId' id={`outlined-customerId-simple`} />}
                >
                  {this.props.customerStore && this.props.taskStore && this.props.customerStore.customers.map((customer) => {
                    if (!this.props.taskStore!.taskCustomers.find(taskCustomer => taskCustomer.customer.id === customer.id)) {
                      return (
                        <MenuItem key={`customer_id_${customer.id}`} value={customer.id}>
                          {customer.name}
                        </MenuItem>
                      )
                    }
                  })}
                </Select>
                {this.state.customerIdError && (
                  <FormHelperText style={{ color: 'red' }}>* You must select a customer.</FormHelperText>
                )}
              </FormControl>
            </styled.FormItemContainer>
            <styled.ButtonRowWrapper>
              <styled.ButtonRow>
                <Button onClick={this.cancelCustomerAdd.bind(this)} variant='contained' style={{ marginRight: 10, color: 'white' }} color='secondary'>
                  Cancel
                </Button>
                <Button onClick={this.saveNewCustomer.bind(this)} style={{ minWidth: 202 }} variant='contained' color='primary'>
                  {this.props.taskStore!.apiStatus.checkApiStatus(apiStatusDefinitions.CREATE_NEW_TASK_CUSTOMER) ? (
                    <ButtonLoading />
                  ) : (
                    <>
                      Create New Customer
                    </>
                  )}
                </Button>
              </styled.ButtonRow>
            </styled.ButtonRowWrapper>
          </div>
        </styled.AddCustomerDialogue>
      </div>
    )
  }
}

export default AddCustomer