import { types } from 'mobx-state-tree'

const AudienceListSearchResult = types.model('AudienceListSearchResult', {
  id: types.identifierNumber,
  customId: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  phone: types.maybeNull(types.string)
})

export type IAudienceListSearchResult = typeof AudienceListSearchResult.Type
export default AudienceListSearchResult
