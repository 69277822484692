import React from 'react'
import Logo from '../../../content/img/smallTextLogo.png'
import * as Styled from './ScreenLoading.styled'
import SvgBee from './SvgBee'

const ScreenLoading: React.FC = () => {
  return(
    <Styled.LoadingWrapper>
      <Styled.Content>
        <Styled.LogoContainer>
          <img src={Logo} alt='Message Bee Logo' />
        </Styled.LogoContainer>
        <Styled.BeeLoader>
          <SvgBee width={100} height={108} type='large' startingColor='#3b51a2' endingColor='#f58022' duration={2.5} scale='0.25' />
        </Styled.BeeLoader>
        <Styled.Message>Loading...</Styled.Message>
      </Styled.Content>
    </Styled.LoadingWrapper>
  )
}

export default ScreenLoading
