import { types } from 'mobx-state-tree'

const FailedFileListItem = types.model('FailedFileListItem', {
  id: types.identifierNumber,
  key: types.string,
  customerName: types.maybeNull(types.string),
  reason: types.string,
  failedTimestamp: types.Date
})

const FailedFile = FailedFileListItem.named('FailedFile').props({
  adqRefId: types.number,
  fileId: types.number,
  customerName: types.string,
  configName: types.string,
  fileText: types.string
}).actions(self =>({
  updateFileText(text : string) {
    self.fileText = text
  }
}))

export type IFailedFileListItem = typeof FailedFileListItem.Type
export type IFailedFile = typeof FailedFile.Type
export { 
  FailedFileListItem,
  FailedFile
}