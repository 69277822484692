import { types } from 'mobx-state-tree'

const Channel = types.model('Channel', {
  name: types.string
})

const ListCommunication = types.model('ListCommunication', {
  id: types.identifierNumber,
  name: types.string,
  channel: types.maybeNull(Channel)
})

const ListCommunications = types.model('ListCommunications', {
  configs: types.maybeNull(types.array(ListCommunication)),
  baseTemplates: types.maybeNull(types.array(ListCommunication))
})

export type IListCommunications = typeof ListCommunications.Type
export type IListCommunication = typeof ListCommunication.Type
export default ListCommunications
