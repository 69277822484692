import styled from 'styled-components'

export const EditAndAddDatesContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  height: 91%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const AddTemplateImageForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

export const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 100%;
  font-size: 16px;
`

export const FormElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 98%;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
`

export const FormButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 53px;
`

export const CalendarsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const CalendarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  flex-direction: column;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  margin-right: 12px;
  margin-top: 12px;
  margin-left: 12px;
  margin-bottom: 12px;
`

export const CalendarLabel = styled.div`
  width: 100%;
  height: 16px;
  margin-bottom: 6px;
`

export const Calendar = styled.div`
  width: 100%;
  flex-grow: 1;
  margin-left: 4px;
  margin-right: 4px;
`

export const Legend = styled.div`
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
`

interface LegendBox {
  backgroundColor?: string
}

export const LegendBox = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${(props: LegendBox) => props.backgroundColor};
  margin-left: 4px;
  margin-right: 4px;
`

export const LegendTitle = styled.div`
  font-weight: bold;
  margin-right: 4px;
`

export const DateRangeErrorPrompt = styled.div`
  font-weight: 12px;
  color: #f44336;
  margin-top: 12px;
  margin-bottom: 8px;
  background-color: rgba(244, 67, 54, 0.08);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  margin-right: 47px;
  text-indent: 10px;
`