import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import * as styled from './DetailedSearchFields.styled'
import Calendar from '../shared/Calendar/Calendar'
import { observer } from 'mobx-react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { startCase } from 'lodash'
import Button from '@material-ui/core/Button'
import { searchTypes } from '../../pages/Administration/DetailedSearch/DetailedSearch.copy'
import { ISearchStore } from '../../../stores/SearchStore'
import { IReportStore } from '../../../stores/ReportStore'
import { ICustomerStore } from '../../../stores/CustomerStore'
import { apiStatusDefinitions } from '../../../utils/apiStatusDefinitions'
import ButtonLoading from '../shared/Loading/ButtonLoading'
import NotificationTypesAndSubTypes from '../shared/DropDowns/NotificationTypesAndSubTypes'

interface DetailedSearchFieldsProps {
  searchStore: ISearchStore,
  reportStore: IReportStore,
  customerStore: ICustomerStore
}

@observer
class DetailedSearchFields extends Component<DetailedSearchFieldsProps> {
  public state = {
    labelWidthTemplate: 0,
    form: {
      searchType: '',
      searchTerm: ''
    }
  }

  private InputLabelRefTemplate: any

  public componentDidMount = () => {
    this.setState({
      labelWidthTemplate: (ReactDOM.findDOMNode(this.InputLabelRefTemplate) as any).offsetWidth
    })
  }

  private handleChange = (name: string) => (event: any) => {
    const form = this.state.form
    const updatedForm = {
      [name]: event.target.value
    }
    this.setState({form: {...form, ...updatedForm}})
  }

  public render () {
    return (
      <>
        <styled.Label>
          Search Type:
        </styled.Label>
        <styled.FormElementWrapper>
          <FormControl style={{ width: '95%', marginRight: 20, marginBottom: 10 }} variant="outlined">
            <InputLabel
              ref={ref => {
                this.InputLabelRefTemplate = ref
              }}
              htmlFor={`outlined-defaultReportFilters-simple`}
            >
              Select a search type
            </InputLabel>
            <Select
              value={this.state.form.searchType}
              placeholder='Select a search type...'
              autoWidth
              style={{ minWidth: 250, width: '100%', textAlign: 'left' }}
              onChange={this.handleChange('searchType')}
              input={<OutlinedInput placeholder='Select a search type:' labelWidth={this.state.labelWidthTemplate} name='defaultFilters' id={`outlined-defaultReportFilters-simple`} />}
            >
              {
                searchTypes.map(searchType => {
                  return (
                    <MenuItem key={`detailed_search_type_${searchType.name}`} value={searchType.name}>
                      {startCase(searchType.name)}
                    </MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
        </styled.FormElementWrapper>
          <styled.Label>
            Search Term:
          </styled.Label>
          <styled.FormElementWrapper>
            <TextField
              style={{ width: '95%', marginRight: 20, marginBottom: 10, marginTop: '-6px' }}
              onChange={this.handleChange('searchTerm')}
              type={this.state.form.searchType === 'phone' ? 'number' : 'text'}
              name='searchTerm'
              fullWidth
              value={this.state.form.searchTerm}
              placeholder='Enter a search term'
              label='Enter a search term'
              margin="normal"
              variant="outlined"
            />
          </styled.FormElementWrapper>
          <NotificationTypesAndSubTypes customerStore={this.props.customerStore} reportStore={this.props.reportStore} />
          <styled.CalendarsOuterContainer>
            <styled.Label>
              Dates:
            </styled.Label>
            <styled.CalendarsOuterContainer>
              <styled.CalendarsContainer>
                <styled.CalendarContainer>
                  <styled.CalendarLabel>
                    Start Date
                  </styled.CalendarLabel>
                  <styled.Calendar>
                    <Calendar type='start' setDateFunction={this.props.searchStore.filterDates} selectedDate={this.props.searchStore.startDate}/>
                  </styled.Calendar>
                </styled.CalendarContainer>
                <styled.CalendarContainer>
                  <styled.CalendarLabel>
                    End Date
                  </styled.CalendarLabel>
                  <styled.Calendar>
                    <Calendar type='end' setDateFunction={this.props.searchStore.filterDates} selectedDate={this.props.searchStore.endDate}/>
                  </styled.Calendar>
                </styled.CalendarContainer>
              </styled.CalendarsContainer>
            </styled.CalendarsOuterContainer>
          </styled.CalendarsOuterContainer>
          <div onClick={() => this.props.searchStore.performSearch(this.state.form.searchType, this.state.form.searchTerm, this.props.reportStore.filteredNotificationSubTypes)} >
            <Button variant='contained' style={{ minHeight: 38, minWidth: 197, marginTop: 10 }} color='primary'>
              {this.props.searchStore.apiStatus.checkApiStatus(apiStatusDefinitions.PERFORM_SEARCH) ? (
                <ButtonLoading />
              ) : (
                  <>Search</>
              )}
            </Button>
          </div>
      </>
    ) 
  }
}

export default DetailedSearchFields
