import styled from 'styled-components'

export const CalendarWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
`

export const Row = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
`

interface Day {
  header?: boolean
  status?: string,
  blocked?: boolean
}

export const Day = styled.div`
  width: 17px;
  height: 17px;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 2px;
  color: ${(props: Day) => {
    if (props.blocked) {
      return `#ffffff`
    } else if (props.status === 'inactive') {
      return '#b3b3b3'
    } else if (props.status === 'active') {
      return '#f7f7f7'
    } else {
      return '#616060'
    }
  }};
  font-weight: ${(props: Day) => props.status === 'active' ? 'bold' : 'normal' };
  ${(props: Day) => props.header ? `font-weight: bold;` : ``}
  background-color: ${(props: Day) => {
    if (props.blocked && props.status === 'inactive') {
      return `rgba(173, 12, 12, 0.35)`
    } else if (props.blocked) {
      return `rgb(173, 12, 12)`
    } else if (props.status === 'inactive') {
      return `#dddddd`
    } else if (props.status === 'active') {
      return `#848484`
    } else {
      return `#c4c4c4`
    }
  }}; 
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  cursor: ${(props => props.blocked ? 'normal' : 'pointer')};

  &:hover {
    background-color: ${(props: Day) => {
    if (props.status === 'inactive' && props.blocked) {
      return `rgb(173, 12, 12, 0.35)`
    } else if (props.status === 'inactive' && !props.header) {
      return `#dddddd`
    } else if (props.blocked) {
      return `rgb(173, 12, 12)`
    } else if (props.status === 'active') {
      return `#848484`
    } else if (!props.header){
      return `#b3b2b2`
    } else {
      return `#c4c4c4`
    }
  }}; 
  }
`

export const MonthControlsAndTitleContainer = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
`

export const MonthToggleSwitch = styled.div`
  cursor: pointer;
`
