import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { ITemplateCustomizationStore } from '../../../../stores/TemplateCustomizationStore'
import ImageSelection from './ImageSelection'
import ImageDetail from './ImageDetail'
import EditAndAddDates from './EditAndAddDates'
import ImageGallery from '../MediaLibrary/ImageGallery'
import AddImageTemplate from '../TemplateCustomization/AddImageTemplate'
import UploadDialogue from '../MediaLibrary/UploadDialogue'
import AddOrEditFallbackLinkUrl from './AddOrEditFallbackLinkUrl'

interface TemplateImageContainerProps {
  templateCustomizationStore?: ITemplateCustomizationStore
}

const templateImageContent = {
  IMAGE_SELECTION: <ImageSelection  />,
  IMAGE_SELECTION_ADMIN: <ImageSelection admin />,
  ADD_TEMPLATE_IMAGE: <AddImageTemplate />,
  IMAGE_DETAIL: <ImageDetail />,
  ADD_DATES: <EditAndAddDates />,
  EDIT_DATES: <EditAndAddDates />,
  ADD_IMAGE_GALLERY_PASSTHROUGH: <ImageGallery passthrough />,
  EDIT_IMAGE_GALLERY_PASSTHROUGH: <ImageGallery passthrough />,
  UPLOAD: <UploadDialogue passthrough />,
  ADD_FALLBACK_URL: <AddOrEditFallbackLinkUrl />,
  EDIT_FALLBACK_URL_AND_OR_HTML: <AddOrEditFallbackLinkUrl />
}

@inject((allStores: any) => {
  return {
    templateCustomizationStore: allStores.templateCustomizationStore as ITemplateCustomizationStore
  }
})
@observer
class TemplateImageContainer extends Component<TemplateImageContainerProps> {
  public render () {
    return (
      <>
        {templateImageContent[this.props.templateCustomizationStore!.templateImageFunctionState]}
      </>
    )
  }
}

export default TemplateImageContainer