import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './UploadCustomersSuccess.styled'
import { ITemplateStore } from '../../../../stores/TemplateStore'
// import 'react-quill/dist/quill.snow.css'
import '../../../content/style/quill/theme.css'
import Button from '@material-ui/core/Button'
import ButtonLoading from '../../shared/Loading/ButtonLoading'
import { apiStatusDefinitions } from '../../../../utils/apiStatusDefinitions'

interface UploadCustomersSuccessProps {
  templateStore?: ITemplateStore
}

@inject((allStores: any) => {
  return {
    templateStore: allStores.templateStore as ITemplateStore
  }
})
@observer
class UploadCustomersSuccess extends Component<UploadCustomersSuccessProps> {
  public render() {
    return (
      <styled.Container>
        <styled.Title>
          Confirm Scheduled Message
        </styled.Title>
        <styled.SubTitle>
          Please confirm whether or not the record count below matches the uploaded file before confirming or canceling the schedule message.
        </styled.SubTitle>
        <styled.UploadDialogueContent>
          <styled.UploadDialogueForm>
            <styled.DropZone>
              <styled.InnerDropZone>
                <p>We detected that there were <strong>{this.props.templateStore!.listMessageFileUploadItems!.fileLength}</strong> records!</p>
                <p>Do you want to continue scheduling this message or abandon it? Please select which below.</p>
              </styled.InnerDropZone>
            </styled.DropZone>
            <styled.FormButtonContainer>
              <Button onClick={() => this.props.templateStore!.cancelScheduledUpload()} variant='contained' color='primary' style={{ marginRight: 10, minWidth: 88 }}>
                {this.props.templateStore!.apiStatus.checkApiStatus(apiStatusDefinitions.CANCEL_SCHEDULED_UPLOAD) ? (
                  <ButtonLoading />
                ) : (
                  <>
                    Cancel
                  </>
                )}
              </Button>
              <Button onClick={() => this.props.templateStore!.confirmScheduledUpload()} variant='contained' color='primary' style={{ minWidth: 97 }}>
                {this.props.templateStore!.apiStatus.checkApiStatus(apiStatusDefinitions.CONFIRM_SCHEDULED_UPLOAD) ? (
                  <ButtonLoading />
                ) : (
                  <>
                    Confirm
                  </>
                )}
              </Button>
            </styled.FormButtonContainer>
          </styled.UploadDialogueForm>
        </styled.UploadDialogueContent>
      </styled.Container>
    )
  }
}

export default UploadCustomersSuccess