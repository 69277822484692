import styled from 'styled-components'

export const LoadingWrapper = styled.div`
position: absolute;
left: 0px;
top: 0px;
width: 100%;
height: 100%;
background-color: #e1e2e1;
display: flex;
align-items: center;
justify-content: center;
`

export const Content = styled.div`
display: flex;
flex-direction: column;
width: 300px;
height: 400px;
justify-content: center;
align-items: center;
`

export const BeeLoader = styled.div`
width: 100px;
height: 108px;
position: relative;
`

export const BeeLoaderBackGround = styled.div`
width: 100px;
height: 108px;
z-index: 1;
position: absolute;
top: 0px;
margin-left: auto;
border: 0px solid rgba(0,0,0,0);
margin-right: auto;
background: linear-gradient(270deg, #3b51a2, #f58022);
background-size: 400% 400%;

-webkit-animation: AnimatedGradient 4s ease infinite;
-moz-animation: AnimatedGradient 4s ease infinite;
animation: AnimatedGradient 4s ease infinite;

  @-webkit-keyframes AnimatedGradient {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-moz-keyframes AnimatedGradient {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @keyframes AnimatedGradient { 
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
`

export const BeeLoaderForeGround = styled.div`
width: 100px;
height: 108px;
position: absolute;
margin-left: auto;
margin-right: auto;
top: 0px;
border: 0px solid #ccc;
z-index: 2;
`

export const LogoContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
margin-bottom: 30px;
`

export const Message = styled.div`
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
  color: #626262;
`
