import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { ITemplateStore } from '../../../../stores/TemplateStore'
import { modalState } from '../../../../utils/modalState'
import UploadCustomers from './UploadCustomers'
import UploadCustomersSuccess from './UploadCustomersSuccess'

interface MediaLibraryContainerProps {
  templateStore?: ITemplateStore
}

const listMessagesModalContent = {
  [modalState.UPLOAD_CUSTOMERS]: <UploadCustomers />,
  [modalState.UPLOAD_SUCCESS]: <UploadCustomersSuccess />,
}

@inject((allStores : any) => {
  return {
    templateStore: allStores.templateStore as ITemplateStore
  }
})
@observer
class ListMessagesModalContainer extends Component<MediaLibraryContainerProps> {
  public render () {
    return (
      <>
        {listMessagesModalContent[this.props.templateStore!.listMessagesModalState]}
      </>
    )
  }
}

export default ListMessagesModalContainer
