import { types } from 'mobx-state-tree'

const LockedAccountData = types.model('LockedAccountData', {
  id: types.number,
  email: types.string, 
  firstName: types.string,
  lastName: types.string
})

export type ILockedAccountData = typeof LockedAccountData.Type
export default LockedAccountData
