import styled from 'styled-components'

export const ReportFilterOuterContainer = styled.div`
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
`

export const SeriesLegend = styled.div`
  margin-top: 10px;
  width: 100%;
`

export const ChipsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const ChipContainer = styled.div`
  margin: 3px 2px 3px 2px;
  cursor: pointer;
  color: white;
`

export const CalendarsOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
`

export const CalendarsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const CalendarContainer = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const CalendarLabel = styled.div`
  width: 100%;
  height: 16px;
  margin-bottom: 6px;
`

export const Calendar = styled.div`
  width: 100%;
  flex-grow: 1;
  margin-left: 4px;
  margin-right: 4px;
`

export const FilterNote = styled.div`
  font-size: 12px;
  text-align: left;
  margin: 16px 10px 0px 10px;
`

export const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 16px;
`

// REMOVE THIS!!!!!!!
export const NotificationTypeContainer = styled.div`
  padding: 10px;
  width: 100%;
`

