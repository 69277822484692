import HandleBars, { template } from 'handlebars/dist/cjs/handlebars'
import { ITemplatesData, ISubtypes } from '../models/Templates'
import { TemplateObjectDto } from '../stores/TemplateStore'
import { stringToObject } from './generalHelpers'
import { daysSince, getPreviewLink, sumArray, formatPhoneNumber } from './dateHelpers'
import merge from 'lodash/merge'
import moment from 'moment'
import 'moment/locale/fr';
import 'moment/locale/pl';
import 'moment/locale/es';
import 'moment/locale/es';
moment.locale('en');


require('@budibase/handlebars-helpers')({
  handlebars: HandleBars
})

const aggregateBy = (aggArray: any, key: string) => {
  const items = aggArray.reduce((acc: any, aggItem: any) => {
    const item = acc.find((accItem: any) => accItem.value == aggItem[key])
    if (item)
      item.items.push(aggItem)
    else
      acc.push({ value: aggItem[key], items: [aggItem] })

    return acc
  }, [])

  items.sort((a: any, b: any) => a.value.localeCompare(b.value))
  return items
}

export const getPreferencesLink = (a: any, b: any, context: any) => {
  if (typeof a === 'object') {
    context = a
  }
  const { id, customerCode } = context.data.root
  return `https://messagebee.uniquelibrary.com/communication-preferences?id=${id}&code=${customerCode}`;
}

//HandleBars.registerHelper("dateFormat", require("handlebars-dateformat"));
HandleBars.registerHelper("dateFormat", function dateFormat(date, format, locale) {
  console.log(date, format, locale)
  const d = moment(date)
  if (typeof locale === 'string') {
    d.locale(locale)
  }
  return d.format(format);
});
HandleBars.registerHelper('daysSince', daysSince)
HandleBars.registerHelper('preferences', getPreferencesLink) //Need to register so it doesnt fail
HandleBars.registerHelper('aggBy', aggregateBy)
HandleBars.registerHelper('preview', getPreviewLink)
HandleBars.registerHelper("sumArray", sumArray);
HandleBars.registerHelper("formatPhoneNumber", formatPhoneNumber);

export const removeNotificationDetailsFromString = (field: string): string => {
  if (field.indexOf('notificationDetails.items.[0].') !== -1) {
    return field.replace('notificationDetails.items.[0].', '')
  } else {
    return field
  }
}

export const deepCopyTemplateObjectWhereExists = (destinationObject: any, templateObject: any) => {
  Object.keys(destinationObject).forEach(key => {
    if (destinationObject[key] != null && typeof (destinationObject[key]) == 'object') {
      if (templateObject[key]) {
        destinationObject[key] = deepCopyTemplateObjectWhereExists(destinationObject[key], templateObject[key])
      }
    } else if (templateObject[key] !== void 0) {
      // specifically looking for undefined so we overwrite populated fields
      // even when the other object contains an empty string
      destinationObject[key] = templateObject[key]
    }
  })
  return destinationObject
}

export const createInitialTemplateObject = (templateData: string): TemplateObjectDto => {
  const templateDataCopy = templateData
  if (templateDataCopy.indexOf('{{') !== -1) {
    // If handle bars are present in the email template
    const fieldsExtractedFromCopy: string[] = templateDataCopy!.match(/{{\s*.+\s*}}/g)!
    const fieldsSanitizedFromExtraction = fieldsExtractedFromCopy!.map(function (x: string) {
      return x.match(/{([^{}]+)}/g)!.map(item => {
        return item.replace('{', '').replace('}', '')
      })
    }).flat()
    let obj: TemplateObjectDto = {}
    let loopFlag = false
    let objectPopulationFromLoop = false // I'm using this to maintain the order of the individual fields
    fieldsSanitizedFromExtraction.forEach((field: string) => {
      if (field.indexOf('each') !== -1 && field.indexOf('/each') === -1) {
        loopFlag = true
      } else if (field.indexOf('/each') !== -1) {
        loopFlag = false
      }

      if (field.indexOf('each') === -1) {
        if (field.indexOf(' ') > 0) {
          const fieldSplitOnSpace = field.split(' ')
          field = fieldSplitOnSpace[1]
        }

        let objectFromField
        let fieldFirstCharacter = field.charAt(0)
        if (fieldFirstCharacter !== '/') {
          if ((loopFlag && !objectPopulationFromLoop) || (!loopFlag && field.indexOf('notificationDetails.items.[0].') !== -1)) {
            // This means we're in the each loop
            field = removeNotificationDetailsFromString(field)
            objectFromField = stringToObject(field, '')
            objectFromField = { notificationDetails: { items: [objectFromField] } }
            objectPopulationFromLoop = true
            merge(obj, objectFromField)
          } else if ((loopFlag && objectPopulationFromLoop) || (objectPopulationFromLoop && field.indexOf('notificationDetails.items.[0].') !== -1)) {
            // n+ item in each loop
            field = removeNotificationDetailsFromString(field)
            objectFromField = stringToObject(field, '')
            obj.notificationDetails!.items.push(objectFromField)
          } else {
            // Non looping fields
            objectFromField = stringToObject(field, '')
            merge(obj, objectFromField)
          }
        }
      }
    })

    if (obj.hasOwnProperty('notificationDetails') && obj.notificationDetails!.hasOwnProperty('items') && obj.notificationDetails!.items.length) {
      let itemsObj = {}
      obj.notificationDetails!.items.forEach(item => {
        merge(itemsObj, item)
      })
      obj.notificationDetails!.items = [itemsObj]
    }

    return obj
  } else {
    return {}
  }
}

const contentFields = [
  'subject',
  'preHeader',
  'wording1',
  'wording2',
  'wording3'
]

export const mergeCustomAndDefined = (parsedObject: TemplateObjectDto, selectedSubtype?: string | null, templateData?: ITemplatesData): TemplateObjectDto => {
  if (selectedSubtype) {
    const foundSubtype = templateData!.subTypes!.find((subtype: any) => {
      return subtype.name === selectedSubtype
    })

    if (foundSubtype!.static!.defined) {
      parsedObject = deepCopyTemplateObjectWhereExists(parsedObject, foundSubtype!.static!.defined)
      contentFields.forEach(field => {
        if (Object.prototype.hasOwnProperty.call(foundSubtype!.static!.defined, field)) {
          if (!Object.prototype.hasOwnProperty.call(parsedObject, 'content')) {
            parsedObject.content = {}
          }
          parsedObject.content[field] = foundSubtype!.static!.defined[field]
        }
      })
    }

    if (foundSubtype!.static!.custom && parsedObject!.notificationDetails && parsedObject!.notificationDetails.items.length > 0 && parsedObject!.notificationDetails.items[0].custom) {
      let currentItemsArrayObject = parsedObject!.notificationDetails.items[0].custom
      currentItemsArrayObject = deepCopyTemplateObjectWhereExists(currentItemsArrayObject, foundSubtype!.static!.custom!)
      parsedObject!.notificationDetails.items[0].custom = currentItemsArrayObject
    }

    if (foundSubtype!.static.images) {
      parsedObject.images = foundSubtype!.static.images
    }
  }
  return parsedObject
}

export const finalizeTemplateObject = (templateData: ITemplatesData, customerId: number | null, selectedSubType: string | null, selectedCustomerTemplate: string | null, previousTemplateObject?: TemplateObjectDto) => {
  const templateObjectFromHTMLTemplate = createInitialTemplateObject(templateData!.template)
  const templateObjectFromSubject = createInitialTemplateObject(templateData!.subjectTemplate)
  let templateObject = merge(templateObjectFromHTMLTemplate, templateObjectFromSubject, previousTemplateObject)
  templateObject.images = {}

  if (customerId) {
    // Admin saved defaults
    if (templateData!.defaults.length) {
      templateObject = deepCopyTemplateObjectWhereExists(templateObject, JSON.parse(templateData!.defaults))
    }

    // Local saved examples
    if (localStorage.getItem(`template_${customerId}_${selectedCustomerTemplate}${selectedSubType ? `_${selectedSubType}` : ``}`)) {
      templateObject = deepCopyTemplateObjectWhereExists(templateObject, JSON.parse(localStorage.getItem(`template_${customerId}_${selectedCustomerTemplate}${selectedSubType ? `_${selectedSubType}` : ``}`) || "") || {})
    }
  }

  templateObject = mergeCustomAndDefined(templateObject, selectedSubType, templateData!)
  return templateObject
}

export const compileTemplate = (templateData: ITemplatesData, templateText: string): string => {
  const templateObject = JSON.parse(JSON.stringify(templateData.templateObject!))
  const template = HandleBars.compile(templateText)
  const finalTemplate = HandleBars.compile(template(templateObject))
  return finalTemplate(templateObject)
}

export const getSubtypeConfigIdFromName = (subTypes: ISubtypes[], selectedSubType: string) => {
  const subType = subTypes.find(subType => subType.name === selectedSubType)
  return subType!.configId
}