import { types } from 'mobx-state-tree'

const ItemData = types.model('SearchEventData', {
  eventId: types.number,
  eventTimestamp: types.string,
  url: types.maybeNull(types.string)
})

const SearchData = types.model('SearchData', {
  id: types.identifier,
  sentTimestamp: types.string,
  notificationStatus: types.string,
  email: types.maybeNull(types.string),
  barcode: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  notificationSubType: types.maybeNull(types.string),
  failureReason: types.maybeNull(types.string),
  previewUrl: types.maybeNull(types.string),
  items: types.optional(types.array(ItemData), [])
})

export type ISearchData = typeof SearchData.Type
export type ItemData = typeof ItemData.Type
export default SearchData
