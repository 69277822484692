import React, { createContext } from 'react'
import NotificationStore, { INotificationStore } from './NotificationStore'
import CommunicationChannelStore from './CommunicationChannelStore'
import ModalStore, { IModalStore } from './ModalStore'
import * as authApi from '../api/auth'
import * as userApi from '../api/user'
import * as reportApi from '../api/reports'
import * as customerApi from '../api/customers'
import * as templateApi from '../api/templates'
import * as searchApi from '../api/search'
import * as MediaLibraryApi from '../api/mediaLibrary'
import * as TemplateCustomizationApi from '../api/templateCustomization'
import * as failedFilesApi from '../api/failedFilesApi'
import * as preferencesApi from '../api/communicationPreferences'
import * as tasksApi from '../api/tasks'
import * as listCommunicationsApi from '../api/listCommunications'
import * as audienceListsApi from '../api/audienceLists'
import * as dynamicListsApi from '../api/dynamicLists'
import UserStore, { IUserStore } from './UserStore'
import AuthStore, { IAuthStore } from './AuthStore'
import ReportStore, { IReportStore } from './ReportStore'
import CustomerStore, { ICustomerStore } from './CustomerStore'
import TemplateStore, { ITemplateStore } from './TemplateStore'
import SearchStore, { ISearchStore } from './SearchStore'
import MediaLibraryStore, { IMediaLibraryStore } from './MediaLibraryStore'
import TemplateCustomizationStore, { ITemplateCustomizationStore } from './TemplateCustomizationStore'
import FailedFilesStore, { IFailedFilesStore } from './FailedFilesStore'
import TaskStore, { ITaskStore } from './TaskStore'
import ListCommunicationsStore, { IListCommunicationsStore } from './ListCommunicationsStore'
import AudienceListsStore, { IAudienceListsStore } from './AudienceListsStore'
import ReportFilters from '../models/ReportFilters'
import ApiStatusStore from './ApiStatusStore'
import DynamicListsStore, { IDynamicListsStore } from './DynamicLists'
import CommunicationPreferences, { ICommunicationPreferencesStore } from './CommunicationPreferencesStore'

class RootStore {
  public notificationStore: INotificationStore
  public modalStore: IModalStore
  public userStore: IUserStore
  public authStore: IAuthStore
  public customerStore: ICustomerStore
  public reportStore: IReportStore
  public templateStore: ITemplateStore
  public searchStore: ISearchStore
  public templateCustomizationStore: ITemplateCustomizationStore
  public mediaLibraryStore: IMediaLibraryStore
  public failedFilesStore: IFailedFilesStore
  public communicationPreferencesStore: ICommunicationPreferencesStore
  public taskStore: ITaskStore
  public listCommunicationsStore: IListCommunicationsStore
  public audienceListsStore: IAudienceListsStore
  public dynamicListsStore: IDynamicListsStore

  public constructor() {
    this.notificationStore = NotificationStore.create()
    this.modalStore = ModalStore.create()
    this.authStore = AuthStore.create({ roles: [], jwtPayload: null, apiStatus: ApiStatusStore.create() }, { notificationStore: this.notificationStore, api: authApi })
    this.userStore = UserStore.create({ user: null, apiStatus: ApiStatusStore.create() }, { notificationStore: this.notificationStore, api: userApi })
    this.customerStore = CustomerStore.create({ apiStatus: ApiStatusStore.create() }, { notificationStore: this.notificationStore, api: customerApi, authStore: this.authStore })
    this.reportStore = ReportStore.create({ reportType: null, filters: ReportFilters.create(), apiStatus: ApiStatusStore.create(), communicationChannelStore: CommunicationChannelStore.create({ source: 'reports' }) }, { userStore: this.userStore, notificationStore: this.notificationStore, customerStore: this.customerStore, api: reportApi })
    this.templateStore = TemplateStore.create({ apiStatus: ApiStatusStore.create(), communicationChannelStore: CommunicationChannelStore.create({ source: 'templates' }) }, { notificationStore: this.notificationStore, modalStore: this.modalStore, customerStore: this.customerStore, api: templateApi, listApi: listCommunicationsApi })
    this.searchStore = SearchStore.create({ apiStatus: ApiStatusStore.create() }, { notificationStore: this.notificationStore, api: searchApi, customerStore: this.customerStore })
    this.templateCustomizationStore = TemplateCustomizationStore.create({ apiStatus: ApiStatusStore.create() }, { notificationStore: this.notificationStore, modalStore: this.modalStore, customerStore: this.customerStore, api: TemplateCustomizationApi, templateStore: this.templateStore })
    this.mediaLibraryStore = MediaLibraryStore.create({ apiStatus: ApiStatusStore.create() }, { notificationStore: this.notificationStore, modalStore: this.modalStore, customerStore: this.customerStore, templateCustomizationStore: this.templateCustomizationStore, api: MediaLibraryApi })
    this.failedFilesStore = FailedFilesStore.create({ apiStatus: ApiStatusStore.create() }, { notificationStore: this.notificationStore, api: failedFilesApi })
    this.communicationPreferencesStore = CommunicationPreferences.create({ apiStatus: ApiStatusStore.create() }, { notificationStore: this.notificationStore, api: preferencesApi, customerStore: this.customerStore })
    this.taskStore = TaskStore.create({ apiStatus: ApiStatusStore.create() }, { notificationStore: this.notificationStore, modalStore: this.modalStore, api: tasksApi })
    this.listCommunicationsStore = ListCommunicationsStore.create({ apiStatus: ApiStatusStore.create() }, { notificationStore: this.notificationStore, api: listCommunicationsApi, templateStore: this.templateStore, customerStore: this.customerStore })
    this.audienceListsStore = AudienceListsStore.create({ apiStatus: ApiStatusStore.create() }, { notificationStore: this.notificationStore, api: audienceListsApi, customerStore: this.customerStore })
    this.dynamicListsStore = DynamicListsStore.create({ apiStatus: ApiStatusStore.create() }, { notificationStore: this.notificationStore, api: dynamicListsApi, customerStore: this.customerStore })
  }

  public getStores() {
    return {
      notificationStore: this.notificationStore,
      modalStore: this.modalStore,
      userStore: this.userStore,
      authStore: this.authStore,
      customerStore: this.customerStore,
      reportStore: this.reportStore,
      templateStore: this.templateStore,
      searchStore: this.searchStore,
      templateCustomizationStore: this.templateCustomizationStore,
      mediaLibraryStore: this.mediaLibraryStore,
      communicationPreferencesStore: this.communicationPreferencesStore,
      failedFilesStore: this.failedFilesStore,
      taskStore: this.taskStore,
      listCommunicationsStore: this.listCommunicationsStore,
      audienceListsStore: this.audienceListsStore,
      dynamicListsStore: this.dynamicListsStore
    }
  }
}

const rootStore = new RootStore()
export default rootStore

export interface AllStores {
  notificationStore: INotificationStore
  modalStore: IModalStore
  userStore: IUserStore
  authStore: IAuthStore
  customerStore: ICustomerStore
  reportStore: IReportStore
  templateStore: ITemplateStore
  searchStore: ISearchStore
  templateCustomizationStore: ITemplateCustomizationStore
  mediaLibraryStore: IMediaLibraryStore
  failedFilesStore: IFailedFilesStore
  taskStore: ITaskStore
  communicationPreferencesStore: ICommunicationPreferencesStore
  listCommunicationsStore: IListCommunicationsStore
  audienceListsStore: IAudienceListsStore
  dynamicListsStore: IDynamicListsStore
}

export {
  RootStore
}