export const apiStatusDefinitions = {
  AUTHENTICATE: 'authenticate',
  REQUEST_PASSWORD_RESET: 'request-password-reset',
  RESET_PASSWORD: 'reset-password',
  ASYNC_REPORT_DATA: ' async-report-data',
  REPORT_DATA: ' report-data',
  INVITE_USER: 'invite-user',
  CREATE_USER: 'create-user',
  CUSTOMER_DATA: 'customer-data',
  LOCKED_ACCOUNTS: 'locked-accounts',
  UNLOCK_ACCOUNT: 'unlock-account',
  GET_CUSTOMER_TEMPLATES: 'get-customer-templates',
  PERFORM_SEARCH: 'perform-search',
  SEND_TEST_EMAIL: 'send-test-email',
  UPLOAD_TO_MEDIA_LIBRARY: 'upload-to-media-library',
  RETRIEVE_MEDIA_LIBRARY: 'retrieve-media-library-content',
  SAVE_EDITS_OF_MEDIA_ITEM: 'save-edits-of-media-item',
  CREATE_NEW_TEMPLATE_IMAGE: 'create-new-template-image',
  RETRIEVE_TEMPLATE_IMAGES: 'retrieve-template-images',
  RETRIEVE_TEMPLATE_IMAGE_CONFIGURATIONS: 'retrieve-template-image-configurations',
  CREATE_NEW_TEMPLATE_IMAGE_CONFIGURATION: 'create-new-template-image-configuration',
  EDIT_TEMPLATE_IMAGE_CONFIGURATION: 'edit-template-image-configuration',
  ASSIGN_CONFIGURATION_IMAGE: 'save-configuration-image',
  ASSIGN_FALLBACK_IMAGE: 'assign-fallback-image',
  UPDATE_FALLBACK_LINK_URL_AND_OR_HTML: 'update-fallback-link-url-and-or-html',
  GET_NOTIFIERS: 'get-notifiers',
  ADD_NOTIFIER: 'add-notifiers',
  REMOVE_NOTIFIER: 'remove-notifiers',
  GET_ADMIN_ACCOUNTS: 'get-admin-accounts',
  GET_FAILED_FILES: 'get-failed-files',
  GET_FAILED_FILE: 'get-failed-file',
  REQUEUE_FAILED_FILE: 'requeue-failed-file',
  REMOVE_FAILED_FILE: 'remove-failed-file',
  SAVE_MODIFICATION_EDITS: 'save-modification-edits',
  GET_TASKS: 'get-tasks',
  GET_TASK_CUSTOMERS: 'get-task-customers',
  GET_TASK_GLOBAL_TIMINGS: 'get-task-global-timings',
  GET_TASK_CUSTOMER_TIMINGS: 'get-task-customer-timings',
  GET_FNR_CONFIGS: 'get-fnr-configs',
  CREATE_NEW_CUSTOMER_TIMING: 'create-new-customer-timing',
  CREATE_NEW_GLOBAL_TIMING: 'create-new-global-timing',
  CREATE_NEW_FAILED_NOTIFICATION_REPORT_CONFIG: 'create-new-failed-notification-report-config',
  UPDATE_TASK_LAMBDA_ARN: 'update-task-lambda-arn',
  UPDATE_TASK_CUSTOMER_TIMING: 'update-task-customer-timing',
  UPDATE_TASK_GLOBAL_TIMING: 'update-task-global-timing',
  DELETE_FAILED_NOTIFICATION_REPORT_CONFIG: 'delete-failed-notification-report-config',
  CREATE_NEW_TASK: 'create-new-task',
  CREATE_NEW_TASK_CUSTOMER: 'create-new-task-customer',
  UPDATE_TASK_CUSTOMER_STATUS: 'update-task-customer-status',
  GET_LIST_COMMUNICATIONS: 'get-list-communications',
  CREATE_LIST_COMMUNICATION: 'create-list-communication',
  HIDE_LIST_COMMUNICATION: 'hide-list-communication',
  UPLOAD_LIST_MESSAGE_CUSTOMER_FILE: 'upload-list-message-customer-file',
  GET_LIST_COMMUNICATION_UPLOAD_HEADERS: 'get-list-communication-upload-headers',
  CONFIRM_SCHEDULED_UPLOAD: 'confirm-schedule-upload',
  CANCEL_SCHEDULED_UPLOAD: 'cancel_scheduled_upload',
  GET_SCHEDULED_UPLOADS: 'get-scheduled-uploads',
  SAVE_EMAIL_EDITOR_COPY_MODIFICATIONS: 'save-email-editor-copy-modifications',
  ADD_BASE_TEMPLATE: 'add-base-template',
  GET_LIST_COMMUNICATION: 'get-list-communication',
  GET_BASE_TEMPLATE: 'get-base-template',
  UPDATE_BASE_TEMPLATE: 'update-base-template',
  SAVE_TEMPLATE: 'save-template',
  UPDATE_SETTINGS_DIGEST_FREQUENCY: 'update-settings-digest-frequency',
  GET_AUDIENCE_LISTS: 'get-audience-lists',
  CREATE_AUDIENCE_LIST: 'create-audience-list',
  UPLOAD_AUDIENCE_LIST: 'upload-audience-list',
  FINALIZE_AUDIENCE_LIST: 'finalize-audience-list',
  SEARCH_AUDIENCE_LIST: 'search-audience-list',
  GET_AUDIENCE_RECORD: 'get-audience-record',
  PUT_AUDIENCE_RECORD: 'put-audience-record',
  GET_EDITABLE_FIELDS: 'get-editable-fields',
  SAVE_EDITABLE_FIELDS: 'save-editable-fields',
  GET_ACCOUNTS: 'get-accounts',
  ARCHIVE_MEDIA_ITEM: 'archive-media-item',
  SET_LIST_MESSAGE_PERMISSION: 'set-list-message-permission',
  GET_COMMUNICATION_PREFERENCES_REPORT: 'get-communication-preferences-report',
  GET_CONFIG_SPECIFIC_CUSTOMER_BRANCHES: 'get-config-specific-customer-branches',
  CREATE_PATRON_DATABASE: 'create-patron-database',
  GET_PATRON_DATABASE: 'get-patron-database',
  UPDATE_PATRON_DATABASE: 'update-patron-database',
  GET_PATRON_LISTS: 'get-patron-lists',
  CREATE_PATRON_LISTS: 'create-patron-lists',
  GET_PATRON_LIST: 'get-patron-list',
  SAVE_PATRON_LIST_FILTER_DEFINITION: 'save-patron-list-filter-definition',
  ADMIN_RESET_PASSWORD_USER_SEARCH: 'admin-reset-password-user-search',
  ADMIN_RESET_PASSWORD: 'admin-reset-password',
  RETRIEVE_IMAGE_CONFIGURATIONS_BRANCH_OVERRIDES: 'retrieve-image-configurations-branch-overrides',
  UPDATE_LIST_PREVIEW_COUNT: 'update-list-preview-count',
  DELETE_AUDIENCE_RECORD: 'delete-audience-record',
  CREATE_AUDIENCE_RECORD: 'create-audience-record',
  POST_CONFIG_SPECIFIC_SENDER_INFO: 'post-config-specific-sender-info',
  DELETE_AUDIENCE_LIST: 'delete-audience-list',
  DELETE_DYNAMIC_LIST: 'delete-dynamic-list'
}