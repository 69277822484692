import { types, getEnv, flow, cast } from 'mobx-state-tree'
import MediaLibraryItem from '../models/MediaLibraryItem'
import { apiStatusDefinitions } from '../utils/apiStatusDefinitions'
import { notificationTypeKeys } from '../ui/components/shared/Notifications/Notifications.copy'
import ApiStatusStore from './ApiStatusStore'

const MediaLibraryStore = types.model({
  mediaLibraryItems: types.optional(types.array(MediaLibraryItem), []),
  mediaLibraryFunctionState: types.optional(types.string, "IMAGE_GALLERY"),
  editTarget: types.maybeNull(types.number),
  editTargetName: types.maybeNull(types.string),
  editTargetDescription: types.maybeNull(types.string),
  editTargetAltText: types.maybeNull(types.string),
  mediaLibraryEntrySourceRevolv: types.optional(types.boolean, false),
  apiStatus: ApiStatusStore
}).volatile(() => ({
  revolvImageSetFunction: null
})).views(self => ({
  get editTargetImage() {
    let foundImage = self.mediaLibraryItems.find((item) => item.id === self.editTarget)
    return foundImage
  },
  get isEditDialogSaving() {
    return self.apiStatus.checkApiStatus(apiStatusDefinitions.SAVE_EDITS_OF_MEDIA_ITEM)
  }
})).actions(self => {
  const { notificationStore, modalStore, customerStore, templateCustomizationStore, api } = getEnv(self)
  const populateAndDisplayImageGalleryModal = () => {
    modalStore.updateModalAttributes('Media Library')
    modalStore.changeModalVisbility()
  }
  const setMediaLibraryFunctionState = (newState: string, passthrough?: boolean) => {
    if (passthrough) {
      templateCustomizationStore.templateImageFunctionState = 'UPLOAD'
    }
    self.mediaLibraryFunctionState = newState
  }
  const setMediaLibraryEditTargetAndState = (id: number) => {
    self.editTarget = id
    self.mediaLibraryFunctionState = 'EDIT'
    const editableImage = self.editTargetImage
    self.editTargetName = editableImage!.name
    self.editTargetDescription = editableImage!.description
    self.editTargetAltText = editableImage!.altText
  }
  const saveEditsToDatabase = flow(function* (this: any) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SAVE_EDITS_OF_MEDIA_ITEM)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.SAVE_EDITS_OF_MEDIA_ITEM_ERROR)
      yield api.updateMediaItem(customerStore.setCustomerId, self.editTarget, self.editTargetName, self.editTargetDescription, self.editTargetAltText)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.SAVE_EDITS_OF_MEDIA_ITEM_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SAVE_EDITS_OF_MEDIA_ITEM)
    setMediaLibraryFunctionState('IMAGE_GALLERY')
  })
  const submitFileForUploading = flow(function* (this: any, name: string, description: string, file: File, altText?: string, passthrough?: boolean) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPLOAD_TO_MEDIA_LIBRARY)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.UPLOAD_TO_MEDIA_LIBRARY_ERROR)
      yield api.uploadMediaItem(customerStore.setCustomerId, name, description, file, altText)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.UPLOAD_TO_MEDIA_LIBRARY_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UPLOAD_TO_MEDIA_LIBRARY)
    if (passthrough) {
      templateCustomizationStore.setTemplateImageFunctionState('ADD_IMAGE_GALLERY_PASSTHROUGH')
    } else {
      setMediaLibraryFunctionState('IMAGE_GALLERY')
    }
  })
  const retrieveMedialLibrary = flow(function* (this: any, activeImage?: boolean) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.RETRIEVE_MEDIA_LIBRARY)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.RETRIEVE_MEDIA_LIBRARY_ERROR)
      let medialLibraryItems = []
      if (activeImage) {
        medialLibraryItems = yield api.retrieveActiveMediaLibraryItems(customerStore.setCustomerId)
      } else {
        medialLibraryItems = yield api.retrieveMediaLibraryItems(customerStore.setCustomerId)
      }
      self.mediaLibraryItems = cast(medialLibraryItems!)
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.RETRIEVE_MEDIA_LIBRARY_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.RETRIEVE_MEDIA_LIBRARY)
  })
  const updateEditTargetName = (newName: string) => {
    self.editTargetName = newName
  }
  const updateEditTargetDescription = (newDescription: string) => {
    self.editTargetDescription = newDescription
  }
  const updateEditTargetAltText = (newAltText: string) => {
    self.editTargetAltText = newAltText
  }
  const clearModal = () => {
    self.mediaLibraryFunctionState = 'IMAGE_GALLERY'
    self.mediaLibraryItems = cast([])
    self.editTarget = null
    self.mediaLibraryEntrySourceRevolv = false
    self.editTargetName = null
    self.editTargetDescription = null
    self.editTargetAltText = null
    modalStore.changeModalVisbility()
    modalStore.updateModalAttributes('')
  }
  const setRevolvImageSrc = (revolv: any) => {
    self.revolvImageSetFunction = revolv
  }
  const callMediaLibraryFromRevolv = (setImageFunction: any) => {
    setRevolvImageSrc(setImageFunction)
    setMediaLibraryFunctionState('REVOLV_IMAGE_GALLERY_WITH_PASSTHROUGH')
    self.mediaLibraryEntrySourceRevolv = true
    populateAndDisplayImageGalleryModal()
  }
  const archiveMediaItem = flow(function* (mediaItemId: number, callBack: () => void) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.ARCHIVE_MEDIA_ITEM)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.ARCHIVE_MEDIA_ITEM_ERROR)
      yield api.archiveMediaItem(customerStore.setCustomerId, mediaItemId)
      retrieveMedialLibrary()
      callBack()
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.ARCHIVE_MEDIA_ITEM_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.ARCHIVE_MEDIA_ITEM)
  })
  return {
    populateAndDisplayImageGalleryModal,
    setMediaLibraryFunctionState,
    saveEditsToDatabase,
    submitFileForUploading,
    retrieveMedialLibrary,
    setMediaLibraryEditTargetAndState,
    updateEditTargetName,
    updateEditTargetDescription,
    updateEditTargetAltText,
    clearModal,
    setRevolvImageSrc,
    callMediaLibraryFromRevolv,
    archiveMediaItem
  }
})

export type IMediaLibraryStore = typeof MediaLibraryStore.Type
export default MediaLibraryStore
