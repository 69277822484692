import React, { Component } from 'react'
import * as styled from './DetailedSearch.styled'
import RightContent from '../../../components/DashBoard/RightContent'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps } from 'react-router'
import Dashboard from '../../../hocs/DashBoard/Dashboard'
import MiddleContent from '../../../components/DashBoard/MiddleContent'
import DetailedSearchFields from '../../../components/DetailedSearch/DetailedSearchFields'
import { ISearchStore } from '../../../../stores/SearchStore'
import DataTableSearch from '../../../components/DetailedSearch/DataTableSearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { apiStatusDefinitions } from '../../../../utils/apiStatusDefinitions'
import ReportStore, { IReportStore } from '../../../../stores/ReportStore'
import { ICustomerStore } from '../../../../stores/CustomerStore'

interface TemplatesProps {
  searchStore: ISearchStore
  reportStore: IReportStore,
  customerStore: ICustomerStore
}

@inject((allStores: any) => {
  return {
    searchStore: allStores.searchStore as ISearchStore,
    reportStore: allStores.reportStore as IReportStore,
    customerStore: allStores.customerStore as ICustomerStore
  }
})
@observer
class DetailedSearch extends Component<TemplatesProps & RouteComponentProps> {
  public render () {
    return (
      <Dashboard history={this.props.history} >
        <MiddleContent title='Detailed Search'>
          <styled.InviteUserContainer>
            {!this.props.searchStore.data ? (
              <>
                <styled.SubTitle>Perform a detailed search of your notifications</styled.SubTitle>
                <styled.Instruction>Please enter a {`"search type"`} (Phone, email, or barcode), a {`"search term"`}, and then click {`"Search"`} to the right in order to perform a detailed notification search.</styled.Instruction>
              </>
            ) : (
              <>
              {this.props.searchStore.data.length ? (
                <>
                  <styled.SubTitle>Search results:</styled.SubTitle>
                  <styled.Instruction>Note: Click on any row to expand it and see all associated events.</styled.Instruction>
                  <DataTableSearch length={this.props.searchStore.data.length} data={this.props.searchStore.data} searchType={this.props.searchStore.searchType!} />
                </>
              ) : (
                <>
                  <styled.SubTitle>No search results:</styled.SubTitle>
                  <styled.Instruction>There was no data returned for this combination of search type and term.  Please update your search criteria, or update the selected date range, and try again.</styled.Instruction>
                </>
              )} 
              </>
            )}
            {this.props.searchStore.apiStatus.checkApiStatus(apiStatusDefinitions.PERFORM_SEARCH) && (
              <styled.LoadingContainer>
                <styled.FontAwesomeContainer>
                  <styled.FontAwesomeInnerContainer>
                    <FontAwesomeIcon size='10x' icon={faSearch} />
                  </styled.FontAwesomeInnerContainer>
                  <i>Searching. Please wait...</i>
                </styled.FontAwesomeContainer>
              </styled.LoadingContainer>
            )}
          </styled.InviteUserContainer>
        </MiddleContent>
        <RightContent title='Search Fields'>
          <styled.DetailedSearchContainer>
            <DetailedSearchFields searchStore={this.props.searchStore} reportStore={this.props.reportStore} customerStore={this.props.customerStore} />
          </styled.DetailedSearchContainer>
        </RightContent>
      </Dashboard>
    ) 
  }
}

export default DetailedSearch
