import { types } from 'mobx-state-tree'

const CommunicationPreferencesReportItem = types.model('CommunicationPreferencesReport', {
  recipientId: types.string,
  email: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  spam: types.boolean,
  optOut: types.maybe(types.boolean),
  lastManagedTime: types.string
})

export default CommunicationPreferencesReportItem