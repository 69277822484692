import { api } from './axios'
import { apiHelper } from '../utils/apiHelper'
import { mockedData } from '../utils/mockedData'

interface Channel {
  name: string
}

export interface ListMessageFolder {
  id: number
  name: string
}

export interface BaseItem {
  id: number,
  name: string,
  channel: Channel
}

export interface ListCommunicationItem extends BaseItem {
  folder: ListMessageFolder
}

export interface GetListCommunicationsResponse {
  configs: Array<ListCommunicationItem>,
  baseTemplates: Array<BaseItem>
}

export const getListCommunications = async (customerId: number): Promise<GetListCommunicationsResponse> => {
  const listCommunications = await apiHelper<any>(() => api.get(`/list-message?customerId=${customerId}`))
  return listCommunications.data
  // return mockedData.listMessageData
}

export const addBaseTemplate = async (customerId: number, notificationType: string, notificationChannel: string, name: string,): Promise<any> => {
  await apiHelper<any>(() => api.post('/template/base-template', { customerId, notificationType, notificationChannel, name }))
  return
}

export const hideListCommunication = async (configurationId: number, customerId: number): Promise<any> => {
  await apiHelper<any>(() => api.post('/list-message/hide', { configurationId, customerId }))
  return
}

export const createConfiguration = async (name: string, customerId: number, baseTemplateId: number, folderId?: number) => {
  await apiHelper<any>(() => api.post('/list-message', { name, customerId, baseTemplateId, folderId }))
  return
}

export const getListCommunication = async (customerId: number, id: number) => {
  const listCommunication = await apiHelper<any>(() => api.get(`/list-message/${id}/?customerId=${customerId}`))
  return listCommunication.data
}

export interface GetListFoldersResponse {
  folders: Array<ListMessageFolder>,
}

export const getListMessageFolders = async (customerId: number): Promise<GetListFoldersResponse> => {
  const listCommunications = await apiHelper<any>(() => api.get(`/list-message/folder?customerId=${customerId}`))
  return listCommunications.data
}

export const createListMessageFolder = async (name: string, customerId: number) => {
  await apiHelper<any>(() => api.post('/list-message/folder', { name, customerId }))
  return
}

export const associateListMessageFolder = async (folderId: number, configId: number, customerId: number) => {
  await apiHelper<any>(() => api.post(`/list-message/folder/${folderId}`, { configId, customerId }))
  return
}

export const renameListMessageFolder = async (folderId: number, name: string, customerId: number) => {
  await apiHelper<any>(() => api.patch(`/list-message/folder/${folderId}`, { name, customerId }))
  return
}

export const saveTemplate = async (configId: number, customerId: number, subject: string, revolvContent: string | undefined, htmlContent: string | undefined, textContent: string | undefined, copy: any, definedFields: any, customFields: any, variables: any) => {
  return await apiHelper<any>(() => api.post('/list-message/template', { configId, customerId, subject, revolvContent, htmlContent, textContent, copy, definedFields, customFields, variables }))
}

export const getRecurringScheduledUploads = async (customerId: number): Promise<any> => {
  const notifications = await apiHelper<any>(() => api.get(`/scheduled-upload/recurring?customerId=${customerId}`))
  return notifications.data
}

export const cancelRecurringScheduledUpload = async (recurringUploadId: number, customerId: number): Promise<any> => {
  await apiHelper<any>(() => api.delete(`/scheduled-upload/recurring/${recurringUploadId}?customerId=${customerId}`))
}

export const getListMessageSmsMedia = async (customerId: number, listMessageId: number): Promise<any> => {
  const media = await apiHelper<any>(() => api.get(`/list-message/${listMessageId}/media?customerId=${customerId}`))
  return media.data
}

export const addListMessageSmsMedia = async (customerId: number, listMessageId: number, mediaUrl: string): Promise<any> => {
  await apiHelper<any>(() => api.post(`/list-message/${listMessageId}/media`, { customerId, mediaUrl, listMessageId }))
}

export const deleteListMessageSmsMedia = async (customerId: number, listMessageId: number, mediaUrl: string): Promise<any> => {
  await apiHelper<any>(() => api.delete(`/list-message/${listMessageId}/media?customerId=${customerId}&mediaUrl=${mediaUrl}`))
}