import React, { useState } from 'react'
import { ICustomerStore } from '../../../stores/CustomerStore'
import { IReportStore } from '../../../stores/ReportStore'
import { observer } from 'mobx-react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { isEven } from '../../../utils/generalHelpers'
import { seriesToolTips } from '../../pages/Reports/Reports.copy'
import * as styled from './DataTableOverView.styled'
import ToolTip from '../shared/ToolTip'

interface DataTableProps {
  customerStore: ICustomerStore
  reportStore: IReportStore,
}

const DataTableOverView = observer(({ customerStore, reportStore }: DataTableProps) => {
  const [notificationVisibility, setNotificationVisibility] = useState<string[]>([])
  let rows = reportStore.unformattedData
  let showEmailFields = false
  let showSmsFields = false

  // this is confusing but if channelvisibility is true it means the channel visibility drop down will not show
  // Reason being it ties directly to hidden which must be true when something is hidden, i.e. not more than one channel.
  if (reportStore.communicationChannelStore.availableChannels.email && ((!reportStore.filters.communicationChannel && reportStore.communicationChannelStore.channelVisbility) || ((reportStore.filters.communicationChannel === 'all' && reportStore.communicationChannelStore.channelVisbility) || reportStore.filters.communicationChannel === 'email' || reportStore.communicationChannelStore.communicationChannel === 'email'))) {
    showEmailFields = true
  }
  if (reportStore.communicationChannelStore.availableChannels.sms && ((!reportStore.filters.communicationChannel && reportStore.communicationChannelStore.channelVisbility) || (reportStore.filters.communicationChannel === 'sms' || reportStore.communicationChannelStore.communicationChannel === 'sms'))) {
    showSmsFields = true
  }

  const toggleNotificationVisibility = (notificationType: string) => {
    if (notificationVisibility.includes(notificationType)) {
      const filteredArray = notificationVisibility.filter(item => item !== notificationType)
      setNotificationVisibility([...filteredArray])
    } else {
      setNotificationVisibility([...notificationVisibility, notificationType])
    }
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell align="right">
            <span>Sent <ToolTip titleText={seriesToolTips['SENT']} /></span>
          </TableCell>
          {showEmailFields && (
            <TableCell align="right">
              <span>Opened <ToolTip titleText={seriesToolTips['OPENED']} /></span>
            </TableCell>
          )}
          {showEmailFields && (
            <TableCell align="right">
              <span>Clicks <ToolTip titleText={seriesToolTips['CLICKED']} /></span>
            </TableCell>
          )}
          <TableCell align="right">
            <span>Opt-Out <ToolTip titleText={seriesToolTips['OPTOUT']} /></span>
          </TableCell>
          <TableCell align="right">
            <span>Successful <ToolTip titleText={seriesToolTips['SUCCESSFUL']} /></span>
          </TableCell>
          <TableCell align="right">
            <span>Failed <ToolTip titleText={seriesToolTips['OPENS']} /></span>
          </TableCell>
          {showSmsFields && (
            <TableCell align="right">
              <span>Sms Segments <ToolTip titleText={seriesToolTips['SMS_SEGMENTS']} /></span>
            </TableCell>
          )}

        </TableRow>
      </TableHead>
      <TableBody>
        {rows && rows.map((notification: any, i: number) => {
          const dayRows = Object.entries(notification[1]) as any
          return (
            <>
              <TableRow key={`notification_row_${notification[0]}`} style={notification[0] === 'All' ? { backgroundColor: 'rgb(63 81 181)', color: 'white', fontWeight: 'bold' } : { backgroundColor: 'rgba(0, 0, 0, 0.15)' }}>
                {notification[0] === 'All' ? (
                  <>
                    <TableCell style={{ color: 'white', fontWeight: 'bold' }}>{notification[0]}</TableCell>
                    <TableCell align="right" style={{ color: 'white', fontWeight: 'bold' }}><b>{notification[1].Total.sent}</b></TableCell>
                    {showEmailFields && (
                      <TableCell align="right" style={{ color: 'white', fontWeight: 'bold' }}><b>{notification[1].Total.open}</b></TableCell>
                    )}
                    {showEmailFields && (
                      <TableCell align="right" style={{ color: 'white', fontWeight: 'bold' }}><b>{notification[1].Total.click}</b></TableCell>
                    )}
                    <TableCell align="right" style={{ color: 'white', fontWeight: 'bold' }}><b>{notification[1].Total.optOut}</b></TableCell>
                    <TableCell align="right" style={{ color: 'white', fontWeight: 'bold' }}><b>{notification[1].Total.delivered}</b></TableCell>
                    <TableCell align="right" style={{ color: 'white', fontWeight: 'bold' }}><b>{notification[1].Total.bounce}</b></TableCell>
                    {showSmsFields && (
                      <TableCell align="right" style={{ color: 'white', fontWeight: 'bold' }}><b>{notification[1].Total.segmentCount}</b></TableCell>)
                    }
                  </>
                ) : (
                  <>
                    <TableCell onClick={() => toggleNotificationVisibility(notification[0])} style={{ cursor: 'pointer ' }} colSpan={notificationVisibility.includes(notification[0]) ? 1 : 99}>{notification[0]}</TableCell>
                    {notificationVisibility.includes(notification[0]) && (
                      <>
                        <TableCell onClick={() => toggleNotificationVisibility(notification[0])} style={{ cursor: 'pointer ' }} align="right"><b>{dayRows[dayRows.length - 1]![1].sent}</b></TableCell>
                        {showEmailFields && (
                          <TableCell onClick={() => toggleNotificationVisibility(notification[0])} style={{ cursor: 'pointer ' }} align="right"><b>{dayRows[dayRows.length - 1]![1].open}</b></TableCell>
                        )}
                        {showEmailFields && (
                          <TableCell onClick={() => toggleNotificationVisibility(notification[0])} style={{ cursor: 'pointer ' }} align="right"><b>{dayRows[dayRows.length - 1]![1].click}</b></TableCell>
                        )}
                        <TableCell onClick={() => toggleNotificationVisibility(notification[0])} style={{ cursor: 'pointer ' }} align="right"><b>{dayRows[dayRows.length - 1]![1].optOut}</b></TableCell>
                        <TableCell onClick={() => toggleNotificationVisibility(notification[0])} style={{ cursor: 'pointer ' }} align="right"><b>{dayRows[dayRows.length - 1]![1].delivered}</b></TableCell>
                        <TableCell onClick={() => toggleNotificationVisibility(notification[0])} style={{ cursor: 'pointer ' }} align="right"><b>{dayRows[dayRows.length - 1]![1].bounce}</b></TableCell>
                        {showSmsFields && (
                          <TableCell onClick={() => toggleNotificationVisibility(notification[0])} style={{ cursor: 'pointer ' }} align="right"><b>{dayRows[dayRows.length - 1]![1].segmentCount}</b></TableCell>
                        )}
                      </>
                    )}
                  </>
                )}
              </TableRow>
              {notification[0] !== 'All' && !notificationVisibility.includes(notification[0]) && dayRows && dayRows.map((day: any, i: number) => {
                const isRowShaded = isEven(i)
                return (
                  <TableRow key={`dataTable_${notification[0]}_${i}`} style={!isRowShaded ? { backgroundColor: 'rgba(0, 0, 0, 0.05)' } : {}}>
                    <TableCell component="th" scope="row" style={{ fontSize: '0.7rem', fontWeight: 500, color: 'rgba(0, 0, 0, 0.54)' }}>
                      {day[0] as any === 'Total' ? <b>{day[0]}</b> : day[0]}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>{day[0] as any === 'Total' ? <b>{day[1].sent}</b> : day[1].sent}</TableCell>
                    {showEmailFields && (
                      <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>{day[0] as any === 'Total' ? <b>{day[1].open}</b> : day[1].open}</TableCell>
                    )}
                    {showEmailFields && (
                      <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>{day[0] as any === 'Total' ? <b>{day[1].click}</b> : day[1].click}</TableCell>
                    )}
                    <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>{day[0] as any === 'Total' ? <b>{day[1].optOut}</b> : day[1].optOut}</TableCell>
                    <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>{day[0] as any === 'Total' ? <b>{day[1].delivered}</b> : day[1].delivered}</TableCell>
                    <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>{day[0] as any === 'Total' ? <b>{day[1].bounce}</b> : day[1].bounce}</TableCell>
                    {showSmsFields && (
                      <TableCell align="right" style={{ fontSize: '0.7rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}>{day[0] as any === 'Total' ? <b>{day[1].segmentCount}</b> : day[1].segmentCount}</TableCell>
                    )}
                  </TableRow>
                )
              }
              )}
            </>
          )
        }
        )}
      </TableBody>
    </Table>
  )
})

export default DataTableOverView
