import axios from 'axios'

declare const BETA_API_URL: string
const client = axios.create({ baseURL: BETA_API_URL })
client.interceptors.request.use(async (config) => {
  // @ts-ignore
  const token = await window.Clerk.session.getToken()
  config.headers.Authorization = `Bearer ${token}`
  return config
})

export type Chat = {
  lastMessageTimestamp: string,
  recipientPhone: string,
  unreadMessageCount: number,
  customerPhone: string,
  lastMessagePreview: string,
  hasUnreadMessages: boolean,
}

export type Message = {
  messageId?: string,
  messageText: string,
  direction: string,
  messageTimestamp: string,
}

type ChatsResponse = {
  chats: Chat[],
  nextCursor: string
}

export const getChats = async (cursor: string): Promise<ChatsResponse> => {
  const response = await client.get(`/sms-messaging/chat`, { params: { cursor } })
  return response.data
}

export const getChat = async (recipientPhone: string): Promise<Message[]> => {
  const response = await client.get(`/sms-messaging/chat/${recipientPhone}`)
  return response.data
}

export const sendMessage = async (recipientPhone: string, messageText: string): Promise<Message[]> => {
  const response = await client.post(`/sms-messaging/message`, { recipientPhone, messageText })
  return response.data
}

export const createChat = async (recipientPhone: string): Promise<Chat> => {
  try {
    const response = await client.post(`/sms-messaging/chat`, { recipientPhone, regionCode: 'US' })
    return response.data
  } catch (e) {
    throw new Error(e.response.data.message)
  }
}

export const hasUnread = async (): Promise<{ hasUnread: boolean }> => {
  try {
    const response = await client.get(`/sms-messaging/unread`)
    return response.data
  } catch (e) {
    return { hasUnread: false }
  }
}
