import React from 'react'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

interface AddTaskCustomerTimingButtonProps {
  taskAddButtonFunction: any,
  style?: any
}

const TaskAddButton: React.SFC<AddTaskCustomerTimingButtonProps> = ({ taskAddButtonFunction, style }: AddTaskCustomerTimingButtonProps) => {
  return (
    <Fab style={style || { position: 'absolute', right: 18, bottom: 16, color: 'white' }} color="secondary" onClick={taskAddButtonFunction} aria-label="add">
      <AddIcon />
    </Fab>
  )
}

export default TaskAddButton
