import styled from 'styled-components'

export const ImageDetailOuterContainer = styled.div`
  overflow-x: none;
`

export const ImageDetailContainer = styled.div`
  width: 99%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  height: 91%;
  display: flex;
  flex-direction: column;
  overflow-x: none;
`

export const ButtonAndTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 99%;
  padding-right: 10px;
  justify-content: space-between;
`

export const BackButton = styled.div`
  display: flex;
  justify-content: left;
`

export const Title = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const ImagesAndDates = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 30px;
  margin-right: 30px;
  overflow-x: auto;
`

interface ImageDetailProps {
  fallback?: boolean
}

export const ImageDetail = styled.div`
  background-color: ${(props: ImageDetailProps) => props.fallback ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0)'};
  border: 1px solid rgba(0,0,0,0.1);
  width: 250px;
  margin-top: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const ImageDetailDate = styled.div`
  margin-top: 10px;
  height: 30px;
  font-size: 14px;
  width: 250px;
  text-align: center;
`

export const PreviewImage = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 120px;
  border: 1px solid rgba(0,0,0,0.2);
  background-color: rgba(0, 0, 0, 0.05);
`

export const ImageName = styled.div`
  margin-top: 10px;
  height: 30px;
  font-size: 14px;
  width: 250px;
  text-align: center;
`

export const ImageUrl = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  width: 250px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`

export const FallbackImageNote = styled.div`
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 8px 0;
`

export const ArrowIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 300px;
  margin-top: 30px;
  min-width: 27px;
  width: 27px;
  overflow-x: hidden;
  color: rgb(133, 133, 133, 0.4);
`

export const EditSpan = styled.span`
  cursor: pointer;
  font-weight: bold;
  margin-left: 4px;
  font-size: 12px;
  text-decoration: underline;
`

export const AddNewImage = styled.span`
  cursor: pointer;
`

export const AddNewImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const PreviewImageAndEdit = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const EditImage = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  color: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  text-transform: uppercase;
  font-size: 18px;
  cursor: pointer;
  font-weight: bolder;
`

export const ImagesAndDatesLoader = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LoadingText = styled.div`
  margin-right: 40px;
`

export const AddFallbackLinkUrlMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
`

export const CustomAddButton = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-right: 4px;
  width: 16px;
  height: 16px;
  background-color: #f58022;
  border-radius: 50%;
  color: white;

  &:hover {
    background-color: #d06c1c;
  }
`

export const CustomerBranchContainer = styled.div`
  margin-left: -10px;
  margin-top: 14px;
  margin-bottom: -14px;
  width: 266px;
`

export const EditLinkAndHtmlContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const EditLinkContainer = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  width: 250px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
`