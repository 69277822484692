import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import * as styled from './DropDowns.styled'
import { IReportStore } from '../../../../stores/ReportStore'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { defaultReportFilterValues } from '../../../pages/Reports/Reports.copy'
import { getStartingEndDate } from '../../../../utils/generalHelpers'
import { observer } from 'mobx-react'

interface DefaultSelectionsProps {
  reportStore: IReportStore
}

@observer
class DefaultSelections extends Component<DefaultSelectionsProps> {
  public state = {
    labelWidthDefaultReportFilters: 0
  }
  
  private InputLabelRefDefaultFilters: any

  public componentDidMount () {
    this.setState({
      labelWidthDefaultReportFilters: (ReactDOM.findDOMNode(this.InputLabelRefDefaultFilters) as any).offsetWidth
    })
  }

  private handleChange = (event: any) => {
    switch (event.target.value) {
      case 1:
        this.props.reportStore.setDefaultReportFilter(1)
        this.props.reportStore.filterDates(getStartingEndDate(7), 'start')
        this.props.reportStore.filterDates(getStartingEndDate(0), 'end')
        break
      case 2:
        this.props.reportStore.setDefaultReportFilter(2)
        this.props.reportStore.filterDates(getStartingEndDate(14), 'start')
        this.props.reportStore.filterDates(getStartingEndDate(0), 'end')
        break
      case 3:
        this.props.reportStore.setDefaultReportFilter(3)
        this.props.reportStore.setSeriesDefaultReportFilters(['bounce', 'dropped'])
        break
      case 4:
        this.props.reportStore.setDefaultReportFilter(4)
        this.props.reportStore.setSeriesDefaultReportFilters(['click', 'delivered', 'open', 'sent'])
        break
    }
  }

  public render () {
    return (
      <>
        <styled.Label>
          Defaults (Predefined report filters):
        </styled.Label>
        <styled.NotificationTypeContainer>
          <FormControl style={{ width: '95%', marginRight: 20, marginBottom: 10 }} variant="outlined">
            <InputLabel
              ref={ref => {
                this.InputLabelRefDefaultFilters = ref
              }}
              htmlFor={`outlined-defaultReportFilters-simple`}
            >
              Select a Default Report Filter
            </InputLabel>
            <Select
              value={this.props.reportStore.selectedDefaultReportFilter ? this.props.reportStore.selectedDefaultReportFilter : ''}
              onChange={this.handleChange}
              placeholder='Select a predefined report filter...'
              autoWidth
              style={{ minWidth: 250, width: '100%', backgroundColor: 'rgba(255, 255, 255, 0)', textAlign: 'left' }}
              input={<OutlinedInput placeholder='Select a predefined report filter:' labelWidth={this.state.labelWidthDefaultReportFilters} name='defaultFilters' id={`outlined-defaultReportFilters-simple`} />}
            >
              {defaultReportFilterValues.map(filter => {
                return (
                  <MenuItem key={`default_report_filter_${filter.name}`} value={filter.value!}>
                    {filter.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>  
        </styled.NotificationTypeContainer>
      </>
    )
  }
}

export default DefaultSelections
