import styled from 'styled-components'

export const EditReportDownloadDialogueWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  height: 91%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`

export const Title = styled.div`
  width: 100%;
  font-size: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const SubTitle = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 97%;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

`

export const ReportDownloadInputContainer = styled.div`
  width: 360px;
  padding-left: 20px;
`

export const ButtonRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 96%;
  justify-content: flex-end;
  margin-top: 10px;
  padding-right: 20px;
`