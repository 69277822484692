import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const UploadDialogueWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  height: 91%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Title = styled.div`
  width: 100%;
  font-size: 20px;
  padding-left: 20px;
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const UploadDialogueContent = styled.div`
  padding-top: 20px;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 100%;
  text-align: center;
  margin-left: 20px;
  border: 1px dashed rgba(140, 140, 140, 0.8);
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 300px;
  width: 96%;
`

export const InnerDropZone = styled.div`
  width: 97%;
  height: 280px;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const UploadDialogueForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
  padding-left: 10px;
  width: 96.5%;
`

export const FormButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 100%;
  font-size: 16px;
`

export const FormElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
`

export const FileName = styled.span`
  font-style: italic;
  margin-top: 16px;
  font-weight: bolder;
`

export const LoadingHeadersMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
`

export const HeaderListing = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  margin-bottom: 20px;
  font-size: 14px;
`

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  padding-left: 18px;
  padding-right: 30px;
  margin-top: -16px;
`

export const DateItems = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: baseline;
`

export const LoadingAudienceLists = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LoadingText = styled.div`
  font-size: 12px;
  margin-top: 20px;
`
export const ListSendingStep = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const ListSendingStepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  background-color: #f58022;
  color: white;
  border-radius: 100%;
  font-size: 20px;
  font-weight: 600;
  margin-right: 10px;
`

export const ListSelectionMethod = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
`

export const ListSendingFormWrapper = styled.div`
  padding-left: 60px;
`

export const ListOption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  min-width: 300px;
`

export const SchedulDateContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-left: -16px;
`