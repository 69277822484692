import React from 'react'
import { SignIn } from "@clerk/clerk-react"
import { SignInWrapper } from './BetaSignIn.styled'

export const BetaSignIn = () => {
  return (
    <SignInWrapper>
      <SignIn />
    </SignInWrapper>
  )
}
