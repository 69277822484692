import { types, cast } from 'mobx-state-tree'

interface CommunicationPreferencesVolatile {
  channels : {[channel : string] : ICommunicationPreferencesEntry }
}

export class ListNotificationTypes 
{
  public static readonly Values: string[] = ['ListMessage', 'listmessage']
}


const CommunicationPreferencesEntry = types.model('CommunicationPreferencesEntry', {
  transactionalDisabled: types.boolean, 
  listDisabled: types.boolean,
  disabledListBaseIds: types.array(types.number),
  disabledNotificationTypes: types.array(types.string)
}).views(self => ({
  isDisabledNotificationType(notificationType : string, baseTemplateId?: number) {
    if (baseTemplateId) {
      const index = self.disabledListBaseIds.findIndex((type) => type === baseTemplateId)  
      return index !== -1
    }
    const index = self.disabledNotificationTypes.findIndex((type) => type === notificationType)
    return index !== -1
  }
})).actions(self => ({
  disableNotificationType(notificationType : string, baseTemplateId?: number) {
    if (baseTemplateId) {
      self.disabledListBaseIds.push(baseTemplateId)
    } else {
      self.disabledNotificationTypes.push(notificationType)
    }
  },
  enableNotificationType(notificationType : string, baseTemplateId?: number) {
    if (baseTemplateId) {
      const index = self.disabledListBaseIds.findIndex((type) => type === baseTemplateId)
      self.disabledListBaseIds.splice(index, 1)
    } else {
      const index = self.disabledNotificationTypes.findIndex((type) => type === notificationType)
      self.disabledNotificationTypes.splice(index, 1)
    }
  },
  disableTransactional() {
    self.transactionalDisabled = true
  },
  enableTransactional() {
    self.transactionalDisabled = false
    self.disabledNotificationTypes = cast(self.disabledNotificationTypes.filter(type => {
      return ListNotificationTypes.Values.findIndex(value => value === type) !== -1
    }))
  },
  disableList() {
    self.listDisabled = true
  },
  enableList() {
    self.listDisabled = false
    self.disabledNotificationTypes = cast(self.disabledNotificationTypes.filter(type => {
      return ListNotificationTypes.Values.findIndex(value => value === type) === -1
    }))
  }
}))

const CommunicationPreferences = types.model('CommunicationPreferences', {})
  .volatile<CommunicationPreferencesVolatile>(() => ({
  channels: {}
})).views(self => ({
  isDisabled(channel: string, notificationType: string, baseTemplateId?: number) {
    const entry = self.channels[channel]
    if (entry === void 0 || entry === null)
      return false

    const isListType = ListNotificationTypes.Values.findIndex(value => value === notificationType) !== -1

    if (isListType && (entry.listDisabled || (baseTemplateId && entry.disabledListBaseIds.includes(baseTemplateId)))) {
      return true
    }

    if (!isListType && entry.transactionalDisabled) {
      return true
    }

    return entry.isDisabledNotificationType(notificationType)
  },
  currentChannels() {
    console.log('currentChannels() ', self.channels)
    return Object.keys(self.channels).sort()
  },
  transactionalDisabled(channel : string) {
    const entry = self.channels[channel]
    if (entry === void 0 || entry === null)
      return false

    return entry.transactionalDisabled
  },
  listDisabled(channel : string) {
    const entry = self.channels[channel]
    if (entry === void 0 || entry === null)
      return false

    return entry.listDisabled
  }
})).actions(self => ({
  setChannels(channels: any) {
    Object.keys(channels).forEach(key => {
      self.channels[key] = CommunicationPreferencesEntry.create(channels[key])
    })
  },
  toggleDisabled(channel: string, notificationType: string, baseTemplateId?: number) {
    const entry = self.channels[channel]
    if (entry === void 0 || entry === null)
      return

    if (entry.isDisabledNotificationType(notificationType, baseTemplateId)) {
      entry.enableNotificationType(notificationType, baseTemplateId)
    }
    else {
      entry.disableNotificationType(notificationType, baseTemplateId)
    }
  },
  toggleTransactional(channel: string) {
    const entry = self.channels[channel]
    if (entry === void 0 || entry === null)
      return

    if (self.transactionalDisabled(channel)) {
      entry.enableTransactional()
    } else {
      entry.disableTransactional()
    }
  },
  toggleList(channel: string) {
    const entry = self.channels[channel]
    if (entry === void 0 || entry === null)
      return

    if (self.listDisabled(channel)) {
      entry.enableList()
    } else {
      entry.disableList()
    }
  }
}))


export type ICommunicationPreferences = typeof CommunicationPreferences.Type
export type ICommunicationPreferencesEntry = typeof CommunicationPreferencesEntry.Type
export default CommunicationPreferences
