import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './ImageDetail.styled'
import { ITemplateCustomizationStore } from '../../../../stores/TemplateCustomizationStore'
import { ITemplateImateConfigurationItem } from '../../../../models/TemplateImageConfigurationItem'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import TemplateImagePlusButton from './TemplateImagePlusButton'
import { format } from 'date-fns'
import sortBy from 'lodash/orderBy'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { apiStatusDefinitions } from '../../../../utils/apiStatusDefinitions'
import CircularProgress from '@material-ui/core/CircularProgress'
import CustomerBranch from '../../shared/DropDowns/CustomerBranch'
import { ICustomerStore } from '../../../../stores/CustomerStore'
import { Box, IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { Stack } from '@mui/material'
import { useMutation } from 'react-query'
import { deleteFallbackImage, deleteImageConfiguration } from 'api/templateCustomization'

interface MediaLibraryContainerProps {
  templateCustomizationStore?: ITemplateCustomizationStore,
  customerStore?: ICustomerStore
}

@inject((allStores: any) => {
  return {
    templateCustomizationStore: allStores.templateCustomizationStore as ITemplateCustomizationStore,
    customerStore: allStores.customerStore as ICustomerStore,
  }
})
@observer
class ImageDetail extends Component<MediaLibraryContainerProps> {
  public state = {
    activeImage: null,
    selectedBranchId: false
  }

  private customerStore = this.props.customerStore
  private branches = this.customerStore && this.customerStore.customerData && this.customerStore.customerData.notificationTypes && this.customerStore.customerBranches && this.customerStore.customerBranches

  public componentDidMount = () => {
    this.props.templateCustomizationStore!.retrieveTemplateImages()
    if (this.customerStore?.setCustomerBranchId) {
      this.props.templateCustomizationStore?.retrieveImageConfigurationBranchOverrides()
      this.setState({ selectedBranchId: true })
    }
  }

  private removeActiveImageOnHoverOut = (imageId: string | number) => {
    if (this.state.activeImage === imageId) {
      this.setState({ activeImage: null })
    }
  }

  public customerBranchCallback = (branchId: null | number) => {
    this.setState({ selectedBranchId: branchId ? true : false })
    branchId && this.props.templateCustomizationStore!.retrieveImageConfigurationBranchOverrides()
  }

  private refreshImages = () => {
    if (this.state.selectedBranchId) {
      this.props.templateCustomizationStore!.retrieveImageConfigurationBranchOverrides()
    }
    this.props.templateCustomizationStore!.retrieveTemplateImages()
  }

  public render() {
    const selectedImage = this.props.templateCustomizationStore!.templateImageData!.find(image => image.id === this.props.templateCustomizationStore!.selectedTemplateImageId)
    let sortedTemplateConfiguations: ITemplateImateConfigurationItem[] = []
    if (this.props.templateCustomizationStore!.templateImageConfigurations.length) {
      sortedTemplateConfiguations = sortBy(this.props.templateCustomizationStore!.templateImageConfigurations, (configurationItem) => {
        return new Date(configurationItem.startDate)
      }).filter(configuration => new Date(configuration.endDate) > new Date())
    }

    return (
      <styled.ImageDetailOuterContainer>
        <styled.ImageDetailContainer>
          <styled.ButtonAndTitleContainer>
            <styled.Title>
              Image Configurations (Dates and Images) - Editing {`"${selectedImage.friendlyName}"`}
            </styled.Title>
            <styled.BackButton>
              <Button style={{ color: 'white', marginRight: 20 }} onClick={() => this.props.templateCustomizationStore!.setTemplateImageFunctionState('IMAGE_SELECTION')} variant='contained' color='secondary'>
                <FontAwesomeIcon size='sm' style={{ marginRight: 4 }} icon={faArrowLeft} />
                Back
              </Button>
            </styled.BackButton>
          </styled.ButtonAndTitleContainer>
          {this.branches && (
            <styled.CustomerBranchContainer>
              <CustomerBranch hideLabel branches={this.customerStore && this.customerStore.customerData && this.customerStore.customerData.notificationTypes && this.customerStore.customerBranches && this.customerStore.customerBranches} callback={this.customerBranchCallback} />
            </styled.CustomerBranchContainer>
          )}
          {this.props.templateCustomizationStore!.apiStatus.checkApiStatus(apiStatusDefinitions.RETRIEVE_TEMPLATE_IMAGES) ? (
            <styled.ImagesAndDatesLoader>
              <CircularProgress style={{ marginBottom: 20, marginTop: '-60px', marginRight: 44 }} color="secondary" />
              <styled.LoadingText>Loading, please wait.</styled.LoadingText>
            </styled.ImagesAndDatesLoader>
          ) : (
            <styled.ImagesAndDates>
              <styled.ImageDetail fallback>
                <styled.ImageDetailDate>(No dates for fallback image)</styled.ImageDetailDate>
                <styled.PreviewImage>
                  {selectedImage!.fallbackMediaItem ? (
                    <styled.PreviewImageAndEdit onMouseOver={() => this.setState({ activeImage: 'fallback' })} onMouseOut={this.removeActiveImageOnHoverOut.bind(this, 'fallback')}>
                      <img style={{ maxWidth: '100%', maxHeight: '100%', zIndex: 1 }} src={selectedImage!.fallbackMediaItem.previewPath} />
                      <div style={{ width: '100%', height: '100%', zIndex: 2, position: 'absolute' }} hidden={this.state.activeImage !== 'fallback' || this.state.selectedBranchId}>
                        <styled.EditImage onClick={() => this.props.templateCustomizationStore!.selectNewConfigurationImage(null, true, selectedImage!.fallbackMediaItem!.id)}>
                          Click to edit
                        </styled.EditImage>
                      </div>
                    </styled.PreviewImageAndEdit>
                  ) : (
                    <styled.PreviewImageAndEdit>
                      <styled.AddNewImageContainer onClick={() => this.props.templateCustomizationStore!.selectNewConfigurationImage(null, true, null)}>
                        <styled.AddNewImage>
                          Add a fallback image
                        </styled.AddNewImage>
                        <Fab style={{ width: 34, height: 14, marginTop: 6, boxShadow: 'none', color: 'white' }} color="secondary" aria-label="add">
                          <AddIcon />
                        </Fab>
                      </styled.AddNewImageContainer>
                    </styled.PreviewImageAndEdit>
                  )}
                </styled.PreviewImage>

                <styled.ImageName>
                  {selectedImage!.fallbackMediaItem ? (
                    <>
                      {selectedImage!.fallbackMediaItem.name}
                    </>
                  ) : (
                    <>
                      No Fallback Image Name
                    </>
                  )}
                </styled.ImageName>
                <styled.ImageUrl>
                  {selectedImage!.fallbackLinkUrl ? (
                    <styled.EditLinkAndHtmlContainer>
                      <styled.EditLinkContainer>
                        <a style={{ color: '#3f51b5' }} href={`${selectedImage!.fallbackLinkUrl}`} rel="noopener noreferrer" target='_blank'>
                          Link
                        </a>
                        <div hidden={this.state.selectedBranchId}>
                          <styled.EditSpan onClick={() => this.props.templateCustomizationStore!.setTemplateImageFunctionState('EDIT_FALLBACK_URL_AND_OR_HTML')}>edit</styled.EditSpan>
                        </div>
                      </styled.EditLinkContainer>
                      <styled.ImageUrl style={{ marginTop: 10 }}>
                        HTML
                        <div hidden={this.state.selectedBranchId}>
                          <styled.EditSpan onClick={() => this.props.templateCustomizationStore!.setTemplateImageFunctionState('EDIT_FALLBACK_URL_AND_OR_HTML')}>edit</styled.EditSpan>
                        </div>
                      </styled.ImageUrl>
                    </styled.EditLinkAndHtmlContainer>
                  ) : (
                    <styled.AddFallbackLinkUrlMessage onClick={() => this.props.templateCustomizationStore!.setTemplateImageFunctionState('ADD_FALLBACK_URL')}>
                      <styled.CustomAddButton>
                        +
                      </styled.CustomAddButton>
                      Add a fallback link URL <br />and/or HTML
                    </styled.AddFallbackLinkUrlMessage>
                  )}
                </styled.ImageUrl>
                <styled.FallbackImageNote>
                  <strong>Note:</strong> Changing the fallback image takes effect immediately.  All emails sent from this point forward, when another image is not already defined for a given date range, will contain this image.
                </styled.FallbackImageNote>
                {
                  !this.state.selectedBranchId && (
                    <DeleteFallbackImage imageId={selectedImage.id} customerId={this.customerStore?.setCustomerId} onDelete={this.refreshImages} />
                  )
                }
              </styled.ImageDetail>
              {sortedTemplateConfiguations.length > 0 && (
                <styled.ArrowIconContainer>
                  <FontAwesomeIcon size='2x' icon={faLongArrowAltRight} />
                </styled.ArrowIconContainer>
              )}
              {sortedTemplateConfiguations.length > 0 && sortedTemplateConfiguations.map((configuration, i) => {
                return (
                  <React.Fragment key={configuration.id}>
                    <styled.ImageDetail>
                      <styled.ImageDetailDate>
                        {format(new Date(configuration.startDate), 'MM/DD/YYYY')} - {format(new Date(configuration.endDate), 'MM/DD/YYYY')}{configuration.startDate && configuration.endDate && (
                          <styled.EditSpan onClick={() => this.props.templateCustomizationStore!.setSelectedTemplateConfigurationId(configuration.id)}>
                            edit
                          </styled.EditSpan>
                        )}
                      </styled.ImageDetailDate>
                      <styled.PreviewImage>
                        {configuration.mediaItem ? (
                          <styled.PreviewImageAndEdit onMouseOver={() => this.setState({ activeImage: configuration.id })} onMouseOut={this.removeActiveImageOnHoverOut.bind(this, configuration.id)}>
                            <img style={{ maxWidth: '100%', maxHeight: '100%', zIndex: 1, position: 'absolute' }} src={configuration!.mediaItem.previewPath} />
                            <div style={{ width: '100%', height: '100%', zIndex: 2, position: 'absolute' }} hidden={this.state.activeImage !== configuration.id}>
                              <styled.EditImage onClick={() => this.props.templateCustomizationStore!.selectNewConfigurationImage(configuration.id, false, configuration!.mediaItem!.id)}>
                                Click to edit
                              </styled.EditImage>
                            </div>
                          </styled.PreviewImageAndEdit>
                        ) : (
                          <styled.PreviewImageAndEdit>
                            <styled.AddNewImageContainer onClick={() => this.props.templateCustomizationStore!.selectNewConfigurationImage(configuration.id, false, null)}>
                              <styled.AddNewImage>
                                Add an image
                              </styled.AddNewImage>
                              <Fab style={{ width: 34, height: 14, marginTop: 6, boxShadow: 'none', color: 'white' }} color="secondary" aria-label="add">
                                <AddIcon />
                              </Fab>
                            </styled.AddNewImageContainer>
                          </styled.PreviewImageAndEdit>
                        )}
                      </styled.PreviewImage>
                      <styled.ImageName>
                        {configuration!.mediaItem ? (
                          <>
                            {configuration!.mediaItem.name}
                          </>
                        ) : (
                          <>
                            Image Name Undefined
                          </>
                        )}
                      </styled.ImageName>
                      {configuration!.configurationLinkUrl ? (
                        <>
                          <styled.ImageUrl style={{ marginTop: 10 }}>
                            <a style={{ color: '#3f51b5' }} href={`${configuration!.configurationLinkUrl}`} rel="noopener noreferrer" target='_blank'>
                              Link
                            </a>
                            <styled.EditSpan onClick={() => this.props.templateCustomizationStore!.setSelectedTemplateConfigurationId(configuration.id)}>edit</styled.EditSpan>
                          </styled.ImageUrl>
                          <styled.ImageUrl>
                            HTML
                            <styled.EditSpan onClick={() => this.props.templateCustomizationStore!.setSelectedTemplateConfigurationId(configuration.id)}>edit</styled.EditSpan>
                          </styled.ImageUrl>
                        </>
                      ) : (
                        <styled.ImageUrl>
                          No URL
                        </styled.ImageUrl>
                      )}
                      <DeleteImage imageId={selectedImage.id} customerId={this.customerStore?.setCustomerId}
                        configurationId={configuration.id} onDelete={this.refreshImages} branchId={this.props.customerStore.setCustomerBranchId} />
                    </styled.ImageDetail>
                    {(i + 1) < sortedTemplateConfiguations.length && (
                      <styled.ArrowIconContainer>
                        <FontAwesomeIcon size='2x' icon={faLongArrowAltRight} />
                      </styled.ArrowIconContainer>
                    )}
                  </React.Fragment>
                )
              })}
            </styled.ImagesAndDates>
          )}
        </styled.ImageDetailContainer>
        <>
          <Button onClick={() => this.props.templateCustomizationStore!.clearModal()} style={{ position: 'absolute', right: 90, bottom: 16, color: 'white', borderRadius: 40, height: 54, boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%) !important' }} variant="contained" color="secondary">Close</Button>
          <TemplateImagePlusButton redirectFunction={this.props.templateCustomizationStore!.setTemplateImageFunctionState} location="ADD_DATES" />
        </>
      </styled.ImageDetailOuterContainer>
    )
  }
}

export default ImageDetail

const DeleteFallbackImage: React.FC<{ imageId: number, customerId: number, onDelete: () => void }> = ({ imageId, customerId, onDelete }) => {
  const { mutate } = useMutation(async () => {
    await deleteFallbackImage(imageId, customerId);
  }, {
    onSuccess: onDelete
  });
  return (
    <Stack alignContent='center' justifyContent='center' mt='auto'>
      <Button size='small' variant='contained' color='primary' style={{ margin: '6px 50px' }}
        onClick={() => mutate()}>Delete</Button>
    </Stack>
  )
}

const DeleteImage: React.FC<{ imageId: number, customerId: number, configurationId: number, branchId: number, onDelete: () => void }> = ({ imageId, customerId, configurationId, branchId, onDelete }) => {
  const { mutate } = useMutation(async () => {
    await deleteImageConfiguration(imageId, customerId, configurationId, branchId);
  }, {
    onSuccess: onDelete
  });
  return (
    <Stack alignContent='center' justifyContent='center' mt='auto'>
      <Button size='small' variant='contained' color='primary' style={{ margin: '6px 50px' }}
        onClick={() => mutate()}>Delete</Button>
    </Stack>
  )
}
