import { differenceInCalendarDays } from 'date-fns'

export const getLongMonthName = (date: Date) => {
  return date.toLocaleString('en-us', { month: 'long' })
}

export const getMonthNumber = (date: Date) => {
  return date.getMonth() + 1
}

export const daysSince = (date: string): number => {
  return Math.abs(differenceInCalendarDays(date, new Date()))
}

export const getPreviewLink = (customerCode: any, recipientId: any) => {
  const timestamp = Date.now();
  const link = `https://msgs.is/preview/%${recipientId}.${customerCode}.${timestamp}`
  return link;
}

export const sumArray = (arr : any, prop : string) => {
  try {
    const propParts = prop.split('.')
    const sum = arr.map((a : any) => propParts
      .reduce((acc, val) => acc && acc[val] || acc, a))
      .reduce((acc : number, val : any) => acc + (Number(val) || 0), 0)
    
    return sum;
  } catch {
    return 0
  }
}

export const formatPhoneNumber = (value : any) => {
  if(value == null){return '';}
  let num = value.toString().replace(/\D+/g, '');
  let prefix = '';
  if (num === '' || !(num.length == 10 || num.length == 11)) {
    return value;
  }
  else if(num.length == 11 && num.substr(0,1) == '1'){
    prefix = '1 ';
    num = num.substr(1);
  }
  return prefix + '(' + num.substr(0,3) + ') '
    + num.substr(3,3) + '-'
    + num.substr(6,4);
}