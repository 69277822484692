import { types } from 'mobx-state-tree'

const User = types.model('User', {
  id: types.identifierNumber,
  email: types.string,
  firstName: types.string,
  lastName: types.string
})
  

export type IUser = typeof User.Type
export default User