import { types, getEnv } from 'mobx-state-tree'
import ApiStatusStore from './ApiStatusStore'
import { apiStatusDefinitions } from '../utils/apiStatusDefinitions'
import ListCommunications from '../models/ListCommunications'

const ListCommunicationsStore = types.model({
  apiStatus: ApiStatusStore,
  listCommunicationsCalled: false,
  editorType: types.optional(types.string, 'derivative'),
  listCommunications: types.maybeNull(ListCommunications),
  selectedListMessageChannel: types.maybeNull(types.string)
}).actions(self => {
  const {  templateStore } = getEnv(self)
  const setSelectedListMessage = async (configurationId: number, listMessage: string, type: string, callback: (id: number, type: string) => void, channel: string) => {
    self.editorType = type
    self.selectedListMessageChannel = channel
    templateStore.setSelectedSubtypeConfigId(configurationId)
    callback(configurationId, type)
  }
  const resetListCommunications = () => {
    self.listCommunicationsCalled = false
  }
  const setEditorType = (editorType: string) => {
    self.editorType = editorType
  }
  const clearSelectedChannel = () => {
    self.selectedListMessageChannel = null
  }

  return {
    setSelectedListMessage,
    resetListCommunications,
    setEditorType,
    clearSelectedChannel
  }
}).views(self => ({
  get loadingBaseTemplateRevolvData() {
    const { templateStore } = getEnv(self)
    if (templateStore.apiStatus.checkApiStatus(apiStatusDefinitions.GET_LIST_COMMUNICATION) || typeof templateStore?.templateData?.template !== 'string') {
      return true
    }
    return false
  },
  get loadingListCommunicationRevolvData() {
    const { templateStore } = getEnv(self)
    if (templateStore.apiStatus.checkApiStatus(apiStatusDefinitions.GET_LIST_COMMUNICATION) || typeof templateStore?.templateData?.template !== 'string') {
      return true
    }
    return false
  }
}))

export type IListCommunicationsStore = typeof ListCommunicationsStore.Type
export default ListCommunicationsStore
