/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Markers,
  Marker,
} from "react-simple-maps"


const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json"
// const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/counties-10m.json"

class GeographicChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      zoom: 1
    }

    this.renderColorArray = this.renderColorArray.bind(this)
    this.changeZoom = this.changeZoom.bind(this)
  }

  changeZoom = (direction) => {
    if (direction === 'plus' && this.state.zoom <= 15) {
      this.setState({zoom: (this.state.zoom + 1)})  
    } else if (direction === 'minus' && this.state.zoom > 1) {
      this.setState({zoom: (this.state.zoom - 1)})  
    }
  }

  renderColorArray = (data) => {
    return data.map((series) => {
      return series.color
    })
  }

  renderDataWithDateRanges = (data, startDate, endDate) => {
    return data.map((series) => {
      const newSeriesData = series.data.filter((dataPoint) => !(dataPoint.x > startDate && dataPoint.x < endDate))
      return newSeriesData
    })
  }

  render() {
    if(this.props.data) {
      return (
        <div style={{ position: 'relative', width: 675, height: 300, marginLeft: '-40px' }}>
          <div style={{ position: 'relative', zIndex: 2, display: 'flex', flexDirection: 'row', width: '100%', right: 0, top: 0, float: 'right', justifyContent: 'flex-end', marginBottom: 6 }}>
            <div onClick={() => this.changeZoom('plus')} 
              style={{
                backgroundColor: 'rgba(245, 128, 34, 0.8)',
                borderRadius: '50%',
                width: 20,
                height: 20,
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 6,
                cursor: 'pointer',
                fontWeight: 'bold'
              }}
            >
              +
            </div>
            <div onClick={() => this.changeZoom('minus')}
              style={{
                backgroundColor: 'rgba(245, 128, 34, 0.8)',
                borderRadius: '50%',
                width: 20,
                height: 20,
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 6,
                cursor: 'pointer',
                fontWeight: 'bold'
              }}
            >-</div>
          </div>
          <div style={{ position: 'relative', zIndex: 1, width: '100%', height: '100%', left: 0, top: 0 }}>
            <ComposableMap projection='geoAlbersUsa'>
              <ZoomableGroup 
                center={[ -97, 40 ]}
                zoom={this.state.zoom}
              >
                <Geographies geography={geoUrl}>
                  {({ geographies }) =>
                    geographies.map(geo => {
                      return (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          style={{
                            default: {
                              fill: "rgba(221, 221, 221, 1)",
                              stroke: "#f5f5f5",
                              strokeWidth: 1,
                              outline: "none",
                            },
                            hover: {
                              fill: "rgba(245, 128, 34, 0.2)",
                              stroke: "#f5f5f5",
                              strokeWidth: 1,
                              outline: "none",
                            },
                            pressed: {
                              fill: "rgba(245, 128, 34, 0.6)",
                              stroke: "#f5f5f5",
                              strokeWidth: 1,
                              outline: "none",
                            }
                          }}
                        />
                      );
                    })
                  }
                </Geographies>
                {this.props.data.map(({ toolTip, location }) => (
                  <Marker key={toolTip} coordinates={location}>
                    <g
                      fill={this.state.zoom > 1 ? this.state.zoom > 2 ? "rgba(245, 128, 34, 0.6)" : "rgba(245, 128, 34, 0.8)" : "rgba(245, 128, 34, 0.9)"}
                      stroke="#FF5533"
                      strokeWidth="0"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      transform="translate(-12, -24)"
                    >
                      <circle cx="0" cy="0" r={this.state.zoom > 1 ? this.state.zoom > 2 ? "3" : "4" : "5"} />
                    </g>
                    <text
                      textAnchor="middle"
                      y={-15}
                      style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                    >
                      {toolTip}
                    </text>
                  </Marker>
                ))}
              </ZoomableGroup>
            </ComposableMap>
          </div>
        </div>
      )
    } else {
      return <div></div>
    } 
  }
}
  
export default GeographicChart
