import { api } from './axios'
import { apiHelper } from '../utils/apiHelper'
import { PermissionType } from '../stores/CustomerStore'
import { mockedData } from '../utils/mockedData'

interface InviteUserDto {
  email: string | number,
  customerId: string | number
  orgId: number | null,
  listMessage: boolean, 
  patronDatabase: boolean, 
  transactional: boolean
}

export const getCustomers = async (): Promise<any> => {
  const getCustomers = await apiHelper<any>((): any => api.get('/customer'))
  return getCustomers.data
  // return mockedData.customersTs
}

export const inviteNewUser = async (parameters: InviteUserDto): Promise<any> => {
  const newUserRequest = await apiHelper<any>((): any => api.post('/Account/request', parameters))
  return newUserRequest.data
}

export const getLockedAccounts = async (): Promise<any> => {
  const lockedAccountRequest = await apiHelper<any>((): any => api.get('/Account/locked'))
  return lockedAccountRequest.data.accounts
}

export const unlockAccount = async (id: number): Promise<any> => {
  const unlockAccountRequest = await apiHelper<any>((): any => api.post(`/Account/locked/${id}`))
  return unlockAccountRequest.data
}

export const getAccounts = async (customerId: number): Promise<any> => {
  const getAccounts = await apiHelper<any>(() => api.get(`/customer/${customerId}/accounts`))
  return getAccounts.data
  // return mockedData.customersTs.customer
}

export const setUserPermissions = async (accountId: number, selection: boolean, type: PermissionType) => {
  const setUserPermissions = await apiHelper<any>((): any => api.post(`/Account/user-permissions`, { accountId, selection, type }))
  return setUserPermissions.data
}