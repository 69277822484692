import React, { Component } from 'react'
import { observer } from 'mobx-react'
import * as styled from './ReportFilters.styled'
import { IReportData } from '../../../models/ReportData'
import Calendar from '../../components/shared/Calendar/Calendar'
import MaterialChip from '../../components/shared/MaterialChip'
import RemoveRedEye from '@material-ui/icons/RemoveRedEye'
import { chartColors } from '../../pages/Reports/Reports.copy'
import { IReportStore } from '../../../stores/ReportStore'
import { ICustomerStore } from '../../../stores/CustomerStore'
import Button from '@material-ui/core/Button'
import ButtonLoading from '../shared/Loading/ButtonLoading'
import { apiStatusDefinitions } from '../../../utils/apiStatusDefinitions'
import CommunicationChannel from '../shared/DropDowns/CommunicationChannel'
import DefaultSelections from '../shared/DropDowns/DefaultSelections'
import Timezone from '../shared/DropDowns/Timezone'
import NotificationTypesAndSubTypes from '../shared/DropDowns/NotificationTypesAndSubTypes'
import { Checkbox, FormControlLabel } from '@material-ui/core'

interface ReportContainerProps {
  reportStore: IReportStore,
  customerStore: ICustomerStore
}

@observer
class ReportFilters extends Component<ReportContainerProps> {
  public constructor(props: ReportContainerProps) {
    super(props)
    this.toggleSeriesVisibility = this.toggleSeriesVisibility.bind(this)
  }

  private reaction: any

  private toggleSeriesVisibility(id: string): void {
    this.props.reportStore.filterSeries(id)
  }

  private renderFilterChips(data: IReportData[]): any {
    if (data.length && !(this.props.reportStore && this.props.reportStore.apiStatus.checkApiStatus(apiStatusDefinitions.REPORT_DATA))) {
      return data.map((series, i): any => {
        return (
          <styled.ChipContainer key={`chipConatiner_${series.id}`} onClick={() => this.toggleSeriesVisibility(series.id)}>
            <MaterialChip style={{ backgroundColor: `${chartColors[i]}`, cursor: 'pointer', opacity: this.props.reportStore.filters.deselectedSeries.findIndex((element: any) => element === series.id) >= 0 ? 0.25 : 1.0 }} label={series.id}>
              <RemoveRedEye />
            </MaterialChip>
          </styled.ChipContainer>
        )
      })
    } else if (this.props.reportStore && this.props.reportStore.apiStatus.checkApiStatus(apiStatusDefinitions.REPORT_DATA)) {
      return <div><i>Loading...</i></div>
    } else {
      return <div>Please click {"Retrieve Report Data"} below.</div>
    }
  }

  private refreshReportData = (): void => {
    this.props.reportStore.toggleReportError(false)
    this.props.reportStore.retrieveReportData()
  }

  private renderButtonText = (): any => {
    if ((this.props.reportStore && this.props.reportStore.apiStatus.checkApiStatus(apiStatusDefinitions.REPORT_DATA))) {
      return <ButtonLoading />
    } else {
      return <>Retrieve Report Data</>
    }
  }

  public render(): any {
    return (
      <styled.ReportFilterOuterContainer>
        <styled.SeriesLegend>
          <CommunicationChannel store={this.props.reportStore} />
          <DefaultSelections reportStore={this.props.reportStore} />
          <NotificationTypesAndSubTypes customerStore={this.props.customerStore} reportStore={this.props.reportStore} />
          <styled.Label>
            Series:
          </styled.Label>
          <styled.ChipsContainer>
            {this.renderFilterChips(this.props.reportStore.data.toJSON())}
          </styled.ChipsContainer>
          <styled.CalendarsOuterContainer>
            <styled.Label>
              Dates:
            </styled.Label>
            <styled.CalendarsOuterContainer>
              <styled.CalendarLabel style={{ display: 'none' }}>
                Timezone
              </styled.CalendarLabel>
              <Timezone reportStore={this.props.reportStore} />
              <styled.CalendarsContainer>
                <styled.CalendarContainer>
                  <styled.CalendarLabel>
                    Start Date
                  </styled.CalendarLabel>
                  <styled.Calendar>
                    <Calendar type='start' setDateFunction={this.props.reportStore.filterDates} selectedDate={this.props.reportStore.filters.startDate}/>
                  </styled.Calendar>
                </styled.CalendarContainer>
                <styled.CalendarContainer>
                  <styled.CalendarLabel>
                    End Date
                  </styled.CalendarLabel>
                  <styled.Calendar>
                    <Calendar type='end' setDateFunction={this.props.reportStore.filterDates} selectedDate={this.props.reportStore.filters.endDate}/>
                  </styled.Calendar>
                </styled.CalendarContainer>
              </styled.CalendarsContainer>
            </styled.CalendarsOuterContainer>
          </styled.CalendarsOuterContainer>
          <FormControlLabel
                control={
                  <Checkbox
                    onChange={this.props.reportStore.toggleAsyncReport}
                    defaultChecked={this.props.reportStore.asyncReport} />
                }
                label='Download Report' />
          <Button onClick={this.refreshReportData} variant='contained' style={{ minHeight: 38, minWidth: 197, marginTop: 20 }} color='primary'>
            {this.renderButtonText()}
          </Button>
        </styled.SeriesLegend>
      </styled.ReportFilterOuterContainer>
    )
  }
}

export default ReportFilters
