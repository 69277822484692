import { types, getEnv, flow, cast } from 'mobx-state-tree'
import CustomerData from '../models/CustomerData'
import LockedAccountData from '../models/LockedAccountData'
import AccountsData from '../models/AccountsData'
import { apiStatusDefinitions } from '../utils/apiStatusDefinitions'
import { notificationTypeKeys } from '../ui/components/shared/Notifications/Notifications.copy'
import ApiStatusStore from './ApiStatusStore'
import * as Sentry from '@sentry/browser'
import { setCurrentCustomer } from '../api/auth'
import { action } from 'mobx'
export enum PermissionType {
  ListMessage = "ListMessage",
  PatronDatabase = "PatronDatabase",
  Transactional = "Transactional"
}

const CustomerStore = types.model({
  customers: types.optional(types.array(CustomerData), []),
  selectedCustomerTemplate: types.maybeNull(types.number),
  accounts: types.maybeNull(AccountsData),
  lockedAccounts: types.optional(types.array(LockedAccountData), []),
  currentlyUnlockingAccountId: types.maybeNull(types.number),
  setCustomerId: types.optional(types.number, 0),
  setCustomerBranchId: types.maybeNull(types.number),
  apiStatus: ApiStatusStore
}).actions(self => {
  const { notificationStore, api, authStore } = getEnv(self)
  const setSelectedCustomerId = flow(function* (customerId: number) {
    // @ts-ignore
    const metadata =  window.Clerk.user.publicMetadata
    const shouldRefresh = metadata.customerId != customerId
    
    const selectedCustomer = yield setCurrentCustomer(customerId, shouldRefresh)
    const currentCustomer = self.customers.find(customer => customer.id === customerId)
    if (currentCustomer)
      Object.assign(currentCustomer, cast(selectedCustomer))
    //this isnt great but it is what it is
    if (shouldRefresh) {
      // @ts-ignore
      yield window.Clerk.session.getToken({ skipCache: true })
    }
    self.setCustomerId = Number(customerId)
    // localStorage.setItem('setCustomerId', String(customerId))

    yield authStore.getFeatures()
  })
  const afterCreate = () => {
    // const localStorageSelectedCustomerId = localStorage.getItem('setCustomerId')
    // const localStorageCustomers = localStorage.getItem('customerData')
    // if (localStorageSelectedCustomerId) {
    //   self.setCustomerId = parseInt(localStorageSelectedCustomerId)
    // }
    // if (localStorageCustomers) {
    //   self.customers = JSON.parse(localStorageCustomers)
    // }

  }
  const getCustomers = flow(function* (this: any) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CUSTOMER_DATA)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.CUSTOMER_DATA_ERROR)
      const customerData = yield api.getCustomers()
      self.customers = cast(customerData.customers)
    // @ts-ignore
    const metadata =  window.Clerk.user.publicMetadata
      if (!self.setCustomerId) {
        if (metadata.customerId) {
          yield setSelectedCustomerId(metadata.customerId)
        } else {
          yield setSelectedCustomerId(customerData!.customers[0].id)
        }
      } else {
        yield setSelectedCustomerId(self.setCustomerId)
      }
    } catch (error) {
      console.log(error)
      notificationStore!.addNotification('error', 'We were unable to load customer data.  Please try again.', notificationTypeKeys.CUSTOMER_DATA_ERROR)
      Sentry.captureException(error)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.CUSTOMER_DATA)
  })
  const inviteNewUser = flow(function* (this: any, { email, orgId, listMessage, patronDatabase, transactional, callback }: { email: string | number | null, orgId?: number | null, listMessage: boolean, patronDatabase: boolean, transactional: boolean, callback?: () => void }) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.INVITE_USER)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.RESET_PASSWORD_ERROR)
      notificationStore!.removeNotification(null, notificationTypeKeys.RESET_PASSWORD_SUCCESS)
      if (orgId) {
        yield api.inviteNewUser({ email, organizationId: orgId, listMessage, patronDatabase, transactional })
      } else {
        yield api.inviteNewUser({ email, customerId: self.setCustomerId, listMessage, patronDatabase, transactional })
      }

      notificationStore!.addNotification('success', 'Success!  An invite has been sent to that email address.', notificationTypeKeys.REQUEST_PASSWORD_SUCCESS)
    } catch (error) {
      // @ts-ignore
      if (error.data.includes('Exception: There is a pending invite for this account')) {
        notificationStore!.addNotification('error', 'Sorry but there is already a pending invite for this account.  Either invite the user with a different email or inform the invited user that they need to accept the invitation.', notificationTypeKeys.REQUEST_PASSWORD_ERROR)
      } else {
        notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.REQUEST_PASSWORD_ERROR)
      }
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.INVITE_USER)
    callback && callback()
  })

  const getLockedAccounts = flow(function* (this: any) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.LOCKED_ACCOUNTS)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_LOCKED_ACCOUNTS_ERROR)
      const lockedAccountData = yield api.getLockedAccounts()
      self.lockedAccounts = cast(lockedAccountData!)
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_LOCKED_ACCOUNTS_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.LOCKED_ACCOUNTS)
  })
  const unlockAccount = flow(function* (this: any, id: number) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UNLOCK_ACCOUNT)
    self.currentlyUnlockingAccountId = id
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.ACCOUNT_UNLOCK_SUCCESS)
      notificationStore!.removeNotification(null, notificationTypeKeys.ACCOUNT_UNLOCK_ERROR)
      yield api.unlockAccount(id)
      self.lockedAccounts.splice(self.lockedAccounts.findIndex(item => item.id === id), 1)
      notificationStore!.addNotification('success', 'Success!  The user account has been unlocked.  Please inform the user that they are free to try to login again or reset their email.', notificationTypeKeys.ACCOUNT_UNLOCK_SUCCESS)
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.ACCOUNT_UNLOCK_ERROR)
    }
    self.currentlyUnlockingAccountId = null
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.UNLOCK_ACCOUNT)
  })
  const getAccounts = flow(function* () {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_ACCOUNTS)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.GET_ACCOUNTS_ERROR)
      self.accounts = null
      const accounts = yield api.getAccounts(self.setCustomerId)
      self.accounts = accounts
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.GET_ACCOUNTS_ERROR)
    }
    self.currentlyUnlockingAccountId = null
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.GET_ACCOUNTS)
  })
  const setUserPermissions = flow(function* (accountId: number, selection: boolean, type: PermissionType) {
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SET_LIST_MESSAGE_PERMISSION)
    try {
      notificationStore!.removeNotification(null, notificationTypeKeys.SET_LIST_MESSAGE_PERMISSION_ERROR)
      yield api.setUserPermissions(accountId, selection, type)
      getAccounts()
    } catch (error) {
      notificationStore!.addNotification('error', 'Something went wrong with the request.  Please try again.', notificationTypeKeys.SET_LIST_MESSAGE_PERMISSION_ERROR)
    }
    self.apiStatus.toggleAPIStatus(apiStatusDefinitions.SET_LIST_MESSAGE_PERMISSION)
  })
  const setSelectedCustomerBranchId = (branchId: number, callback?: (branchId: null | number) => void) => {
    self.setCustomerBranchId = branchId === 0 ? null : branchId
    callback && callback(self.setCustomerBranchId)
  }
  return {
    afterCreate,
    getCustomers,
    inviteNewUser,
    setSelectedCustomerId,
    getLockedAccounts,
    unlockAccount,
    getAccounts,
    setUserPermissions,
    setSelectedCustomerBranchId
  }
}).views(self => ({
  get customerData() {
    return self.customers.find(customer => customer.id === self.setCustomerId)!
  },
  get allNotificationTypes() {
    const customerData = this.customerData
    return customerData.notificationTypes.reduce((notificationType: any) => notificationType.name) || []
  },
  get notificationStore() {
    return getEnv(self).notificationStore
  },
  get customerBranches() {
    return self.customers.find(customer => customer.id === self.setCustomerId)?.customerBranches || null
  },
  get customerAllowedApis() {
    return self.customers.find(customer => customer.id === self.setCustomerId)?.allowedApiChannels || null
  }
}))

export type ICustomerStore = typeof CustomerStore.Type
export default CustomerStore
