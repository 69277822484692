import { types, getEnv } from 'mobx-state-tree'
import { atLeastTwoAreTrue } from '../utils/generalHelpers'

const CommunicationChannelStore = types.model({
  communicationChannel: types.maybeNull(types.string),
  source: types.maybeNull(types.string),
  error: types.optional(types.boolean, false)
}).views(self => ({
  get availableChannels() {
    const customerStore = getEnv(self).customerStore
    const customer = customerStore.customerData
    const cumulativeReducedChannels: { all?: boolean, email: boolean, sms: boolean, voice: boolean, mail: boolean } = { email: false, sms: false, voice: false, mail: false }
    if (customer && customer.notificationTypes) {
      customer.notificationTypes.forEach((notificationType: typeof customerStore.customers.notificationType) => {
        if (notificationType && notificationType.subTypes) {
          notificationType.subTypes.forEach((subType: typeof customerStore.customers.notificationType.subTypes) => {
            cumulativeReducedChannels[subType.channel] = true
          })
        }
      })
    }
    if (self.source === 'reports') {
      cumulativeReducedChannels.all = true
    }
    return cumulativeReducedChannels
  },
  get channelVisbility() {
    const cumulativeReducedChannels = this.availableChannels
    if (atLeastTwoAreTrue(cumulativeReducedChannels.email, cumulativeReducedChannels.sms, cumulativeReducedChannels.voice)) {
      return false
    } else {
      return true
    }
  },
  get channelDefaultOrSelectedValue() {
    const cumulativeReducedChannels = this.availableChannels
    if (self.communicationChannel) {
      return self.communicationChannel
    } else {
      if (atLeastTwoAreTrue(cumulativeReducedChannels.email, cumulativeReducedChannels.sms, cumulativeReducedChannels.voice)) {
        return self.source === 'reports' ? 'all' : 'email'
      } else if (cumulativeReducedChannels.email || cumulativeReducedChannels.sms || cumulativeReducedChannels.voice) {
        let channel = null
        Object.keys(cumulativeReducedChannels).forEach(function (key) {
          if (cumulativeReducedChannels[key]) {
            channel = key
          }
        })
        return channel
      } else {
        return self.source === 'reports' ? 'all' : 'email'
      }
    }
  },
  get defaultChannel() {
    const channels = this.availableChannels
    if (channels.email)
      return 'email'
    if (channels.sms)
      return 'sms'
    if (channels.voice)
      return 'voice'

    return 'email'
  },
  get selectedOrDefault() {
    return self.communicationChannel || this.defaultChannel
  },
  get filterNotificationTypesBasedOnChannel() {
    const customerData = getEnv(self).customerStore.customerData
    const filteredNotificationTypes: typeof customerData.customers.notificationType = []
    if (customerData && customerData.notificationTypes) {
      customerData.notificationTypes.forEach((notificationType: typeof customerData.customers.notificationType) => {
        notificationType.subTypes.forEach((subType: typeof customerData.customers.notificationType.subTypes) => {
          if ((subType.channel === this.channelDefaultOrSelectedValue || this.channelDefaultOrSelectedValue === 'all') && filteredNotificationTypes.indexOf(notificationType) === -1) {
            filteredNotificationTypes.push(notificationType)
          }
        })
      })
    }
    return filteredNotificationTypes
  }
})).actions((self): any => {
  const filterCommunicationChannel = (type: string) => {
    self.communicationChannel = type
  }
  return {
    filterCommunicationChannel
  }
})

export type ICommunicationChannelStore = typeof CommunicationChannelStore.Type
export default CommunicationChannelStore