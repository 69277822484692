import styled from 'styled-components'

export const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 16px;
`

export const NotificationTypeContainer = styled.div`
  padding: 10px;
  width: 100%;
`
