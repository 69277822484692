import styled from 'styled-components' 

export const Divider = styled.div`
    font-family: roboto;
    height: 13px;
    line-height: 13.5px;
    width: 100px;
    margin-top: 9px;
    margin-bottom: 3px;
    font-weight: 200;
    background-color: rgba(0, 0, 0, .3);
    color: white;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;

`