import { types } from 'mobx-state-tree'
import TemplateImateConfigurationItem from './TemplateImageConfigurationItem'

const FallbackMediaItem = types.model('FallbackMediaItem', {
  id: types.identifierNumber,
  name: types.string,
  description: types.string,
  extension: types.string,
  itemPath: types.string,
  previewPath: types.string,
  uploadedDate: types.string,
})

const TemplateImateItem = types.model('TemplateImateItem', {
  id: types.identifierNumber,
  customerId: types.number,
  variableName: types.string,
  friendlyName: types.string,
  fallbackLinkUrl: types.maybeNull(types.string),
  fallbackMediaItem: types.maybeNull(FallbackMediaItem),
  fallbackHtml: types.maybeNull(types.string),
  templateImageConfigurations: types.optional(types.array(TemplateImateConfigurationItem), [])
})

export type ITemplateImateItem = typeof TemplateImateItem.Type
export default TemplateImateItem