import styled from 'styled-components'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import red from '@material-ui/core/colors/red'
import { notificationTypes } from './Notifications.copy'

export const NotificationWrapper = styled.div`
  position: fixed;
  z-index: 10000;
  top: 10px;
  width: 100%;
  margin-left:auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center; 
`

interface IndividualNotificationProps {
  notificationType?: string
}

export const IndividualNotification = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 3px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0px 14px 0px;
  background-color: ${(props: IndividualNotificationProps) => {
    switch (props.notificationType) {
      case notificationTypes.ERROR: 
        return red[900]
      case notificationTypes.WARNING:
        return amber[700]
      case notificationTypes.SUCCESS:
        return green[600]    
    }
  }};
`


export const NotificationLeftContent = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin-top: -3px;
  color: white;
`

export const MessageWrapper = styled.div`
  line-height: 28px;
  margin-left: 16px;
  padding-top: 6px;
  font-size: 14px; 
  color: white;
`

export const NotificationRightContent = styled.div`
  margin-left: 12px;
  align-items: center;
  margin-right: 6px;
`

export const CloseButtonWrapper = styled.div`
  color: white;
  float: left;
`