import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import * as styled from './ImageGallery.styled'
import { IMediaLibraryStore } from '../../../../stores/MediaLibraryStore'
import { ITemplateCustomizationStore } from '../../../../stores/TemplateCustomizationStore'
import UploadButton from './UploadButton'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'

interface MediaLibraryContainerProps {
  mediaLibraryStore?: IMediaLibraryStore,
  templateCustomizationStore?: ITemplateCustomizationStore
  passthrough?: boolean
}

@inject((allStores : any) => {
  return {
    mediaLibraryStore: allStores.mediaLibraryStore as IMediaLibraryStore,
    templateCustomizationStore: allStores.templateCustomizationStore as ITemplateCustomizationStore
  }
})
@observer
class ImageGallery extends Component<MediaLibraryContainerProps> {
  public state = {
    activeTile: null,
    confirmationId: null,
    activeImages: false
  }

  public componentDidMount () {
    this.props.mediaLibraryStore!.retrieveMedialLibrary()
  }

  private selectImage = (passthrough: boolean | null, id: number, url: string) => {
    if (this.props.mediaLibraryStore!.mediaLibraryEntrySourceRevolv || (this.props.passthrough && this.props.mediaLibraryStore!.mediaLibraryFunctionState === 'REVOLV_IMAGE_GALLERY_WITH_PASSTHROUGH')) {
      // @ts-ignore
      this.props.mediaLibraryStore!.revolvImageSetFunction(url)
      this.props.mediaLibraryStore!.clearModal()
    } else if (this.props.passthrough) {
      this.props.templateCustomizationStore!.setSelectedImageFromImageGallery(passthrough, id)
    }
  }

  private setConfirmationId = (confirmationId: number) => {
    this.setState({confirmationId})
  }

  private clearConfirmationId = () => {
    this.setState({confirmationId: null})
  }

  private handleActiveImageChange = () => {
    if (this.state.activeImages) {
      this.setState({ activeImages: false })
      this.props.mediaLibraryStore!.retrieveMedialLibrary()
    } else {
      this.setState({ activeImages: true })
      this.props.mediaLibraryStore!.retrieveMedialLibrary(true)
    }
  }

  public render () {
    return (
      <div>
        <styled.ImageGalleryWrapper>
          <styled.ButtonAndTitleContainer>
            <styled.Title>
              <div style={{ width: 200 }}>
                Image Gallery
              </div>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <Switch checked={this.state.activeImages} onChange={this.handleActiveImageChange} name="checkedC" />
                </Grid>
                <Grid item style={{ color: '#969696' }}>Active Images</Grid>
              </Grid>
            </styled.Title>
            {(this.props.passthrough || this.props.mediaLibraryStore!.mediaLibraryEntrySourceRevolv) && (
              <Button style={{ color: 'white', marginRight: 20 }} onClick={() => this.props.templateCustomizationStore!.setTemplateImageFunctionState('IMAGE_DETAIL')} variant='contained' color='secondary'>
                <FontAwesomeIcon size='sm' style={{ marginRight: 4 }} icon={faArrowLeft} />
                Back
              </Button>
            )}
          </styled.ButtonAndTitleContainer>
          {(this.props.passthrough || this.props.mediaLibraryStore!.mediaLibraryEntrySourceRevolv) && (
            <styled.SubTitle>
              Select an image to use within your template:
            </styled.SubTitle>
          )}
          <styled.ImageGallery>
            {this.props.mediaLibraryStore!.mediaLibraryItems.length && !this.state.confirmationId ? (
              <>
              {this.props.mediaLibraryStore!.mediaLibraryItems.map(item => {
                return (
                  <styled.GalleryItem 
                    selected={this.props.templateCustomizationStore!.previouslySelectedImageId === item.id} 
                    onClick={() => this.selectImage(this.props.passthrough || this.props.mediaLibraryStore!.mediaLibraryEntrySourceRevolv || false, item.id, item.itemPath)} passthrough={this.props.passthrough || this.props.mediaLibraryStore!.mediaLibraryEntrySourceRevolv} 
                    key={`image_${item.id}`} 
                    onMouseOver={() => this.setState({ activeTile: item.id })}
                  >
                    <styled.PreviewImageContainer>
                      <img style={{ maxWidth: '100%', maxHeight: '100%', zIndex: 1 }} src={item.previewPath} />
                    </styled.PreviewImageContainer>
                    <styled.ImageName>{item.name}</styled.ImageName>
                    {(!this.props.passthrough && !this.props.mediaLibraryStore!.mediaLibraryEntrySourceRevolv) && (
                      <div hidden={this.state.activeTile !== item.id}>
                        <styled.ImageOverlay>
                          <div>
                            <styled.ImageDetail><strong>Description:</strong> {item.description}</styled.ImageDetail>
                            <styled.ImageDetail><strong>Uploaded Date:</strong> {new Date(item.uploadedDate).toLocaleString("en-US")}</styled.ImageDetail>
                            <styled.ImageDetail><strong>Alt Text:</strong> {item.altText ? item.altText : '-'}</styled.ImageDetail>
                          </div>  
                          <styled.EditButton>
                            <Button onClick={() => this.setConfirmationId(item.id)} variant='contained' color='primary' style={{ marginRight: 3 }}>
                              Archive
                            </Button>
                            <Button onClick={() => this.props.mediaLibraryStore!.setMediaLibraryEditTargetAndState(item.id)} variant='contained' color='secondary' style={{ color: 'white', marginLeft: 3 }}>
                              Edit
                            </Button>
                          </styled.EditButton>
                        </styled.ImageOverlay>
                      </div>
                    )} 
                  </styled.GalleryItem>
                )
              })}
              </>
            ) : (
              <>
              {this.state.confirmationId ? (
                <styled.ConfirmationDialog>
                  Are you sure you would like to archive this image?  This action is currently irreversible.  
                  <styled.ConfirmationDialogButtonContainer>
                    <Button onClick={() => this.clearConfirmationId()} variant='contained' color='secondary' style={{ marginRight: 3, color: 'white' }}>
                      Cancel
                    </Button>
                    <Button onClick={() => this.props.mediaLibraryStore!.archiveMediaItem(this.state.confirmationId!, this.clearConfirmationId)} variant='contained' color='primary' style={{ marginLeft: 3 }}>
                      Archive
                    </Button>
                  </styled.ConfirmationDialogButtonContainer>
                </styled.ConfirmationDialog>
              ) : (
                <>
                  You do not have any media library items yet.  Click the orange + button to add some.
                </>
              )}
              </>
            )}
          </styled.ImageGallery>
        </styled.ImageGalleryWrapper>  
        {!this.state.confirmationId && (
          <UploadButton uploadMediaLibraryFunction={() => { this.props.passthrough && !this.props.mediaLibraryStore!.mediaLibraryEntrySourceRevolv ? this.props.templateCustomizationStore!.setTemplateImageFunctionState('UPLOAD') : this.props.mediaLibraryStore!.setMediaLibraryFunctionState('UPLOAD')}} />
        )}
      </div>
    )
  }
}

export default ImageGallery