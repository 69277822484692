import { types } from 'mobx-state-tree'
import Notification from '../models/Notification'

const NotificationStore = types.model({
  notifications: types.optional(types.array(Notification), [])
}).actions(self => ({
  removeNotification(id: number | null, key: string | null = null) {
    if (key === null) {
      self.notifications.splice(self.notifications.findIndex(element => element.id === id), 1)
    } else {
      self.notifications.splice(self.notifications.findIndex(element => element.key === key), 1)
    }
  },
  addNotification(notificationType: string, notificationMessage: string, key: string | null = null) {
    const maxId = self.notifications.reduce((prev, current) => { return prev.id > current.id ? prev : current }, {id: 0}).id + 1
    self.notifications.push({id: maxId, type: notificationType, message: notificationMessage, key})
  },
  timedClosing(key: string | null = null) {
    setTimeout(this.removeNotification.bind(self, null, key), 2000)
  }
}))

export type INotificationStore = typeof NotificationStore.Type
export default NotificationStore
