import { types } from 'mobx-state-tree'

const TemplateVersions = types.model('SubTypeData', {
  isLatest: types.boolean,
  versionId: types.string,
  lastModified: types.string
})

const TemplateEditorData = types.model('TemplateEditorData', {
  previewOrEditMode: types.optional(types.boolean, false),
  contentModified: types.optional(types.boolean, false),
  testEmailSent: types.optional(types.boolean, false),
  templateType: types.optional(types.string, 'html'),
  templateVersions: types.maybeNull(types.array(TemplateVersions)),
  setTemplateVersionId: types.optional(types.string, '')
})

export type ITemplateEditorData = typeof TemplateEditorData.Type
export default TemplateEditorData
